import colors from 'barn/tokens/colors';

export const drawerLight = {
  bg: colors.greys[7],
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08)',
};

export const drawerDark = {
  bg: colors.greys[9],
  boxShadow: 'none',
};
