import colors from 'barn/tokens/colors';

export const progressBarLight = {
  bg: colors.greys[6],
  borderColor: 'rgba(0,0,0,0.08)',
  info: colors.greens[4],
  warn: colors.yellows[4],
  danger: colors.reds[4],
};

export const progressBarDark = {
  ...progressBarLight,
  bg: colors.greys[13],
  borderColor: colors.greys[16],
  info: colors.greens[9],
};
