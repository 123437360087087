import colors from 'barn/tokens/colors';

export const ExpandRight = ({
  size = 16,
  color = colors.greys[2],
  style = {},
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={style}
  >
    <path
      d='M15.7067 8.70701C16.0977 8.31601 16.0977 7.68401 15.7067 7.29301L12.7067 4.29301C12.3157 3.90201 11.6837 3.90201 11.2927 4.29301C11.0977 4.48801 10.9997 4.74401 10.9997 5.00001C10.9997 5.25601 11.0977 5.51201 11.2927 5.70701L12.5857 7.00001L4.99975 7.00001C4.44775 7.00001 3.99975 7.44701 3.99975 8.00001C3.99975 8.55301 4.44775 9.00001 4.99975 9.00001L12.5857 9.00001L11.2927 10.293C10.9017 10.684 10.9017 11.316 11.2927 11.707C11.6837 12.098 12.3157 12.098 12.7067 11.707L15.7067 8.70701Z'
      fill={color}
    />
    <path
      d='M2 0.999999C2 0.447715 1.55229 -6.3153e-07 1 -6.55671e-07C0.447715 -6.79812e-07 -1.95703e-08 0.447715 -4.37114e-08 0.999999L-6.55671e-07 15C-6.79812e-07 15.5523 0.447714 16 0.999999 16C1.55228 16 2 15.5523 2 15L2 0.999999Z'
      fill={color}
    />
  </svg>
);
