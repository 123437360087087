import colors from 'barn/tokens/colors';

export const globalLight = {
  body: {
    bg: colors.greys[6],
    color: colors.greys[1],
  },
  a: {
    color: '#3c73ff',
  },
  scrollbarTrack: {
    bg: '#d2dce7',
  },
  scrollbarThumb: {
    bg: '#b1bcc6',
    hover: {
      bg: colors.greys[2],
    },
  },
  tablist: {
    borderColor: '#d2dce7',
  },
  tab: {
    color: colors.greys[2],
    selected: {
      borderColor: colors.greens[4],
      color: colors.greys[0],
    },
  },
  dropdownMenuButton: {
    color: colors.greys[1],
    hover: {
      bg: '#d2dce7',
    },
  },
  dropdownMenuList: {
    bg: colors.greys[8],
    borderColor: '#d2dce7',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
  },
  dropdownMenuItem: {
    selected: {
      bg: '#f2f6fa',
      color: colors.greys[1],
    },
  },
  rcTooltipInner: {
    bg: colors.greys[8],
    borderColor: '#d2dce7',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
  },
  rcTooltipArrow: {
    borderColor: '#d2dce7',
    after: {
      borderColor: colors.greys[8],
    },
  },

  ReactModalOverlay: {
    before: {
      bg: 'rgba(0, 0, 0, 0.7)',
    },
  },
  barnModal: {
    bg: colors.greys[8],
  },
};

export const selectBoxLight = {
  listboxPopover: {
    bg: colors.greys[8],
    borderColor: colors.greys[4],
    boxShadow: 'rgb(0 0 0 / 15%) 0px 2px 6px;',
    color: colors.greys[1],
  },
  listboxButton: {
    borderColor: colors.greys[5],
    bg: colors.greys[8],
    color: colors.greys[1],
  },
  currentNav: {
    bg: colors.blues[8],
  },
  hover: {
    bg: colors.blues[8],
    color: colors.greys[1],
  },
};

export const globalDark = {
  ...globalLight,
  scrollbarTrack: {
    bg: colors.greys[0],
  },
  scrollbarThumb: {
    bg: colors.greys[1],
    hover: {
      bg: colors.greys[2],
    },
  },
  body: {
    bg: colors.greys[9],
    color: colors.greys[7],
  },
  dropdownMenuButton: {
    color: colors.greys[8],
    hover: {
      bg: colors.greys[12],
    },
  },
  dropdownMenuList: {
    bg: colors.greys[9],
    borderColor: colors.greys[16],
    boxShadow: 'none',
    selected: {
      bg: 'black',
    },
  },
  dropdownMenuItem: {
    selected: {
      bg: colors.greys[14],
      color: colors.greys[7],
    },
  },
  rcTooltipInner: {
    bg: colors.greys[9],
    borderColor: colors.greys[16],
    boxShadow: 'none',
  },
  rcTooltipArrow: {
    borderColor: colors.greys[12],
    after: {
      borderColor: colors.greys[12],
    },
  },
  tablist: {
    borderColor: colors.greys[16],
  },
  tab: {
    color: colors.greys[7],
    selected: {
      borderColor: colors.greens[9],
      color: colors.greys[8],
    },
  },
  ReactModalOverlay: {
    before: {
      bg: 'rgba(0, 0, 0, 0.7)',
    },
  },
  barnModal: {
    bg: colors.greys[11],
  },
};

export const selectBoxDark = {
  ...selectBoxLight,
  listboxPopover: {
    bg: colors.greys[9],
    borderColor: colors.greys[16],
    boxShadow: 'none',
    color: colors.greys[7],
  },
  listboxButton: {
    borderColor: colors.greys[1],
    bg: colors.greys[11],
    color: colors.greys[7],
  },
  hover: {
    bg: colors.greys[14],
    color: colors.greys[7],
  },
};
