import React from 'react';
import { Apple } from 'barn/icons/Apple';
import { Win8 } from 'barn/icons/Win8';
import { Win10 } from 'barn/icons/Win10';
import { Android } from 'barn/icons/Android';
import { Segment } from '../Button/styled';
import Styled from './styled';

export enum Device {
  Android = 'android',
  Win8 = 'windows8',
  Win10 = 'windows10',
  Apple = 'apple',
}

interface DeviceSwitchProps {
  active: Device;
  onChange: (device: Device) => void;
}
const DeviceSwitch: React.FunctionComponent<DeviceSwitchProps> = ({
  active,
  onChange,
}) => {
  const handleClick = (device: Device) => {
    onChange(device);
  };

  return (
    <Styled.DeviceSwitch>
      <Segment
        aria-label='android preview'
        className={active === Device.Android && 'active'}
        onClick={() => handleClick(Device.Android)}
      >
        <Android />
      </Segment>

      <Segment
        aria-label='windows 8 preview'
        className={active === Device.Win8 && 'active'}
        onClick={() => handleClick(Device.Win8)}
      >
        <Win8 />
      </Segment>
      <Segment
        aria-label='windows 10 preview'
        className={active === Device.Win10 && 'active'}
        onClick={() => handleClick(Device.Win10)}
      >
        <Win10 />
      </Segment>

      <Segment
        aria-label='mac os preview'
        className={active === Device.Apple && 'active'}
        onClick={() => handleClick(Device.Apple)}
      >
        <Apple />
      </Segment>
    </Styled.DeviceSwitch>
  );
};

export default DeviceSwitch;
