import { createModel } from '@rematch/core';
import { RootModel } from '.';

interface CommonState {
  isSidebarOpen: boolean;
}

const initialState = (): CommonState => ({
  isSidebarOpen: false,
});

const common = createModel<RootModel>()({
  state: initialState(),

  reducers: {
    toggleSidebar(state: CommonState): CommonState {
      return {
        ...state,
        isSidebarOpen: !state.isSidebarOpen,
      };
    },
  },
});

export default common;
