import styled from 'styled-components';
import tokens from 'barn/tokens';

export const StyledTickCircleHolder = styled.div`
  display: flex;
  align-items: center;

  .withLabel {
    margin-right: ${tokens.space.margin[3]};
  }
`;

export const StyledTickCircle = styled.div<{ size: string }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 3px solid ${({ theme }) => theme.tickCircle.borderColor};
  border-radius: 50%;
  background: ${({ theme }) => theme.tickCircle.bg};
  width: ${props => props.size};
  height: ${props => props.size};

  &.active {
    background: ${({ theme }) => theme.tickCircle.active.bg};
    border-color: ${({ theme }) => theme.tickCircle.active.borderColor};
  }

  &.complete {
    background: ${({ theme }) => theme.tickCircle.complete.bg};
    border-color: ${({ theme }) => theme.tickCircle.complete.borderColor};

    .iconColor {
      fill: ${({ theme }) => theme.tickCircle.complete.iconColor.fill};
    }
  }
`;
