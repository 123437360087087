import { v1 as uuidv1 } from 'uuid';
import colors from 'barn/tokens/colors';

const Calendar = ({ color = colors.greys[2], style = {} }) => {
  const id = uuidv1();

  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' style={style}>
      <mask
        id={`maskCalendar${id}`}
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='2'
        y='2'
        width='16'
        height='16'
      >
        <path
          className='iconColor'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17.2 3.6H14.8V2.8C14.8 2.3576 14.4424 2 14 2C13.5576 2 13.2 2.3576 13.2 2.8V3.6H6.8V2.8C6.8 2.3576 6.4424 2 6 2C5.5576 2 5.2 2.3576 5.2 2.8V3.6H2.8C2.3576 3.6 2 3.9576 2 4.4V17.2C2 17.6424 2.3576 18 2.8 18H17.2C17.6424 18 18 17.6424 18 17.2V4.4C18 3.9576 17.6424 3.6 17.2 3.6ZM3.6 16.4H16.4V8.4H3.6V16.4ZM3.6 6.8H16.4V5.2H3.6V6.8ZM6.8 10C6.3576 10 6 10.3576 6 10.8C6 11.2424 6.3576 11.6 6.8 11.6C7.2424 11.6 7.6 11.2424 7.6 10.8C7.6 10.3576 7.2424 10 6.8 10ZM6.8 13.2C6.3576 13.2 6 13.5576 6 14C6 14.4424 6.3576 14.8 6.8 14.8C7.2424 14.8 7.6 14.4424 7.6 14C7.6 13.5576 7.2424 13.2 6.8 13.2ZM10 13.2C9.5576 13.2 9.2 13.5576 9.2 14C9.2 14.4424 9.5576 14.8 10 14.8C10.4424 14.8 10.8 14.4424 10.8 14C10.8 13.5576 10.4424 13.2 10 13.2ZM10 10C9.5576 10 9.2 10.3576 9.2 10.8C9.2 11.2424 9.5576 11.6 10 11.6C10.4424 11.6 10.8 11.2424 10.8 10.8C10.8 10.3576 10.4424 10 10 10ZM13.2 10C12.7576 10 12.4 10.3576 12.4 10.8C12.4 11.2424 12.7576 11.6 13.2 11.6C13.6424 11.6 14 11.2424 14 10.8C14 10.3576 13.6424 10 13.2 10Z'
          fill={color}
        />
      </mask>
      <g mask={`url(#maskCalendar${id})`}>
        <rect className='iconColor' width='20' height='20' fill={color} />
      </g>
    </svg>
  );
};

export default Calendar;
