import colors from 'barn/tokens/colors';

export const pricingLight = {
  color: colors.greys[1],
  borderColor: colors.greys[5],
  secondary: {
    color: colors.greys[2],
  },
  hyperlinkButton: {
    color: colors.greys[2],
    hover: {
      color: colors.blues[5],
    },
    active: {
      color: colors.blues[2],
    },
  },
  button: {
    bg: colors.greys[0],
    color: colors.greys[8],
    disabled: {
      bg: colors.greys[5],
    },
  },
  input: {
    bg: colors.greys[8],
  },
  couponLabel: {
    bg: '#fbe7a4',
  },
  section: {
    bg: colors.greys[5],
  },
  card: {
    bg: colors.greys[8],
    active: {
      color: colors.blues[5],
    },
  },
  radioTab: {
    color: colors.greys[0],
    borderColor: colors.greys[0],
  },
  highlightedText: {
    color: colors.greys[8],
    selected: {
      color: colors.blues[5],
    },
  },
};

export const pricingDark = {
  ...pricingLight,
  color: colors.greys[7],
  borderColor: colors.greys[16],
  secondary: {
    color: colors.greys[7],
  },
  card: {
    bg: colors.greys[15],
    active: {
      color: colors.blues[5],
    },
  },
  section: {
    bg: colors.greys[16],
  },
  radioTab: {
    color: colors.greys[7],
    borderColor: colors.greys[16],
  },
  button: {
    bg: colors.greys[11],
    color: colors.greys[8],
    disabled: {
      bg: colors.greys[1],
    },
  },
  highlightedText: {
    color: colors.greys[0],
    selected: {
      color: colors.blues[5],
    },
  },
};
