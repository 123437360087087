export enum TrackingElement {
  ACR_TOGGLE_BUTTON = 'acr toggle button',
  BA_TOGGLE_BUTTON = 'ba toggle button',
  BA_SMS_TOGGLE_BUTTON = 'ba sms toggle button',
  WELCOME_SERIES_TOGGLE_BUTTON = 'welcome series toggle button',
  CREATE_CAMPAIGN_BUTTON = 'create campaign button',
  SEND_CAMPAIGN_BUTTON = 'send campaign button',
  OPTIN_TOGGLE_BUTTON = 'optin toggle button',
  OPTIN_SAVE_BUTTON = 'optin save button',
  USER = 'user',
}

export enum TrackingCategory {
  OPTIN_PROMPT = 'optin prompt',
  CAMPAIGN_CREATION = 'campaign creation',
  AUTH = 'auth',
  AUTOMATION = 'automation',
}

export enum TrackingAction {
  CLICKED = 'clicked',
  OPENED = 'opened',
  SUBMITTED = 'submitted',
  COMPLETED = 'completed',
  EXITED = 'exited',
  VISITED = 'visited',
  LOGGEDIN = 'loggedin',
}

export enum TrackingLocation {
  LEFT_NAVBAR = 'left navbar',
  CAMPAIGN_LIST = 'campaign list',
  EMPTY_CAMPAIGN_LIST = 'empty campaign list',
  AUTOMATION_LIST = 'automation list',
  AUTOMATION_PAGE = 'automation page',
  OPTIN_PAGE = 'optin page',
  OPTIN_EDITOR = 'optin editor',
}
