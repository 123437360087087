import { v1 as uuidv1 } from 'uuid';
import colors from 'barn/tokens/colors';

const Logout = ({ color = colors.greys[2], style = {} }) => {
  const id = uuidv1();

  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' style={style}>
      <mask
        id={`maskLogout${id}`}
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='2'
        y='2'
        width='17'
        height='16'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4 10C4 13.309 6.691 16 10 16C10.553 16 11 16.448 11 17C11 17.552 10.553 18 10 18C5.589 18 2 14.411 2 10C2 5.589 5.589 2 10 2C10.553 2 11 2.448 11 3C11 3.552 10.553 4 10 4C6.691 4 4 6.691 4 10ZM14.707 6.293L17.707 9.293C18.098 9.684 18.098 10.316 17.707 10.707L14.707 13.707C14.512 13.902 14.256 14 14 14C13.744 14 13.488 13.902 13.293 13.707C12.902 13.316 12.902 12.684 13.293 12.293L14.586 11H10C9.447 11 9 10.552 9 10C9 9.448 9.447 9 10 9H14.586L13.293 7.707C12.902 7.316 12.902 6.684 13.293 6.293C13.684 5.902 14.316 5.902 14.707 6.293Z'
          fill='white'
        />
      </mask>
      <g mask={`url(#maskLogout${id})`}>
        <path className='iconColor' d='M0 0H21V21H0V0Z' fill={color} />
      </g>
    </svg>
  );
};

export default Logout;
