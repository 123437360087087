import StyledAvatar from './StyledAvatar';

interface Props {
  size: string;
  src: string;
  alt: string;
}

const Avatar: React.FC<Props> = ({ size = 'medium', src, alt }) => (
  <StyledAvatar size={size} src={src} alt={alt} />
);

export default Avatar;
