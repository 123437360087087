import colors from 'barn/tokens/colors';

export const hyperlinkLight = {
  default: {
    color: colors.blues[5],
    active: {
      color: colors.blues[2],
    },
  },
  secondary: {
    color: colors.greys[1],
    active: {
      color: colors.greys[9],
    },
  },
  button: {
    bg: colors.greens[4],
    borderColor: colors.greens[4],
    color: colors.greys[8],
    hover: {
      bg: colors.greens[3],
      borderColor: colors.greens[3],
      boxShadow: '0px 6px 12px rgba(52, 57, 70, 0.16)',
      disabled: {
        bg: colors.greens[4],
        borderColor: colors.greens[4],
      },
    },
  },
};

export const hyperlinkDark = {
  ...hyperlinkLight,
  secondary: {
    color: colors.greys[7],
    active: {
      color: colors.greys[5],
    },
  },
  button: {
    ...hyperlinkLight.button,
    hover: {
      ...hyperlinkLight.button.hover,
      boxShadow: 'none',
    },
  },
};
