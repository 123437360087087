import { v1 as uuidv1 } from 'uuid';
import colors from 'barn/tokens/colors';

const ChevronUp = ({ color = colors.greys[2], style = {} }) => {
  const id = uuidv1();

  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' style={style}>
      <mask
        id={`maskChevronUp${id}`}
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='20'
        height='20'
      >
        <rect
          className='iconColor'
          x='20'
          y='20'
          width='20'
          height='20'
          transform='rotate(180 20 20)'
          fill={color}
        />
      </mask>
      <g mask={`url(#maskChevronUp${id})`}>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10 7.33336C10.1707 7.33336 10.3413 7.39869 10.4713 7.52869L13.8045 10.8619C14.0652 11.1225 14.0652 11.5439 13.8045 11.8045C13.5439 12.0652 13.1225 12.0652 12.8619 11.8045L10 8.94263L7.13812 11.8045C6.87746 12.0652 6.45615 12.0652 6.19549 11.8045C5.93484 11.5439 5.93484 11.1225 6.19549 10.8619L9.52869 7.52869C9.65868 7.39869 9.82934 7.33336 10 7.33336Z'
          fill={color}
        />
      </g>
    </svg>
  );
};

export default ChevronUp;
