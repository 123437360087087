import { v1 as uuidv1 } from 'uuid';
import colors from 'barn/tokens/colors';

const Home = ({ color = colors.greys[2], style = {} }) => {
  const id = uuidv1();

  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' style={style}>
      <mask
        id={`maskHome${id}`}
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='2'
        y='2'
        width='16'
        height='17'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17.731 8.60184L10.531 2.20184C10.2278 1.93304 9.77178 1.93304 9.46858 2.20184L8.39978 3.15224V2.80024C8.39978 2.35864 8.04218 2.00024 7.59978 2.00024H4.39978C3.95738 2.00024 3.59978 2.35864 3.59978 2.80024V7.41864L2.26858 8.60184C1.93818 8.89624 1.90858 9.40104 2.20218 9.73224C2.49498 10.0618 3.00058 10.0898 3.33098 9.79864L3.59978 9.55944V17.2002C3.59978 17.6418 3.95738 18.0002 4.39978 18.0002H15.5998C16.0422 18.0002 16.3998 17.6418 16.3998 17.2002V9.55944L16.6686 9.79864C16.8206 9.93384 17.011 10.0002 17.1998 10.0002C17.4198 10.0002 17.6398 9.90984 17.7974 9.73224C18.091 9.40104 18.0614 8.89624 17.731 8.60184ZM14.7998 16.4002H13.1998V12.4002C13.1998 11.9586 12.8422 11.6002 12.3998 11.6002H7.59978C7.15738 11.6002 6.79978 11.9586 6.79978 12.4002V16.4002H5.19978V8.137L9.99978 3.8706L14.7998 8.137V16.4002ZM8.39978 16.4002H11.5998V13.2002H8.39978V16.4002ZM5.19978 3.6002H6.79978V4.5746L5.19978 5.997V3.6002Z'
          fill='#31373D'
        />
      </mask>
      <g mask={`url(#maskHome${id})`}>
        <rect
          className='iconColor'
          width='16.8'
          height='16.8'
          transform='translate(2 2)'
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Home;
