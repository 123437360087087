import colors from 'barn/tokens/colors';

export const upgradeButtonLight = {
  bg: `linear-gradient(0deg, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), ${colors.blues[4]}`,
  hover: {
    bg: `linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), ${colors.blues[4]}`,
  },
  lockIcon: {
    color: colors.blues[4],
  },
};

export const upgradeButtonDark = upgradeButtonLight;
