import colors from 'barn/tokens/colors';

export const gettingStartedLight = {
  bg: colors.greys[8],
  arrow: {
    borderColor: colors.greys[0],
  },
  addText: {
    color: colors.greys[8],
    bg: colors.greys[1],
  },
  tooltip: {
    color: colors.greys[8],
  },
};

export const gettingStartedDark = {
  ...gettingStartedLight,
  bg: colors.greys[11],
};
