import { subDays } from 'date-fns';
import { post, get, put, patch } from './axios';

export enum BACKGROUND_TASKS {
  SYNC_SUBSCRIBERS_FROM_SHOPIFY = 'sync_subscribers_from_shopify',
  NUMBER_VERIFICATION = 'number_verification',
}

const getSubscriberReport = () => {
  const endDate = new Date();
  const startDate = subDays(endDate, 30);

  // cover the whole day by setting time to 00:00:00
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);

  return get(
    `/dashboard/subscriber/report/?page=home&start_time=${startDate.toISOString()}&end_time=${endDate.toISOString()}`,
  );
};

const getSummary = () => get(`/home/summary/`);

const getGettingStarted = () => get(`/onboarding/sms/steps/`);

const setGettingStarted = (step: { [key: string]: boolean }): any =>
  patch(`/onboarding/sms/steps/`, step);

const syncSubscribers = (): any =>
  post('/tasks/', {
    task_type: BACKGROUND_TASKS.SYNC_SUBSCRIBERS_FROM_SHOPIFY,
  });

const getSyncSubscribersStatus = (): any =>
  get(`/tasks/?task_type=${BACKGROUND_TASKS.SYNC_SUBSCRIBERS_FROM_SHOPIFY}`);

const getNumberVerificationStatus = (): any =>
  get(`/tasks/?task_type=${BACKGROUND_TASKS.NUMBER_VERIFICATION}`);

const setTaskTypeStatus = (payload): any => put('/tasks/', payload);

export default {
  syncSubscribers,
  getSyncSubscribersStatus,
  setTaskTypeStatus,
  getSubscriberReport,
  getSummary,
  getGettingStarted,
  setGettingStarted,
  getNumberVerificationStatus,
};
