import { bannersDark, bannersLight } from './Banners/theme';
import { cardsLight, cardsDark } from './Cards/theme';
import { dateRangeLight, dateRangeDark } from './DaterangeFilter/theme';
import {
  flyoutWiedgetPreviewLight,
  flyoutWiedgetPreviewDark,
} from './FlyoutWidget/theme';
import {
  labelledToggleLight,
  labelledToggleDark,
} from './LabelledToggle/theme';

import { popoverLight, popoverDark } from './Popover/theme';
import { profileMenuLight, profileMenuDark } from './ProfileMenu/theme';
import { reportsSaverLight, reportsSaverDark } from './ReportSaver/theme';
import {
  sessionValidationLight,
  sessionValidationDark,
} from './SessionValidation/theme';
import { sidebarLight, sidebarDark } from './Sidebar/theme';
import { stepIndicatorLight, stepIndicatorDark } from './StepIndicator/theme';
import { tableLight, tableDark } from './Table/theme';
import { upgradeButtonLight, upgradeButtonDark } from './UpgradeButton/theme';
import {
  overlayConfigLight,
  overlayConfigDark,
} from './OverlayConfiguration/theme';
import {
  notificationTemplateDark,
  notificationTemplateLight,
} from './CampaignTemplate/theme';
import { skeletonDark, skeletonLight } from './Skeleton/theme';
import { comingSoonDark, comingSoonLight } from './ComingSoon/theme';
import { newDark, newLight } from './New/theme';

const light = {
  notificationTemplate: notificationTemplateLight,
  cards: cardsLight,
  dateRange: dateRangeLight,
  popover: popoverLight,
  profileMenu: profileMenuLight,
  flyoutWidgetPreview: flyoutWiedgetPreviewLight,
  reportSaver: reportsSaverLight,
  sessionValidation: sessionValidationLight,
  sidebar: sidebarLight,
  stepIndicator: stepIndicatorLight,
  table: tableLight,
  upgradeButton: upgradeButtonLight,
  labelledToggle: labelledToggleLight,
  overlayConfig: overlayConfigLight,
  banners: bannersLight,
  skeleton: skeletonLight,
  comingSoon: comingSoonLight,
  new: newLight,
};

const dark = {
  ...light,
  notificationTemplate: notificationTemplateDark,
  sidebar: sidebarDark,
  cards: cardsDark,
  profileMenu: profileMenuDark,
  sessionValidation: sessionValidationDark,
  stepIndicator: stepIndicatorDark,
  dateRange: dateRangeDark,
  popover: popoverDark,
  flyoutWidgetPreview: flyoutWiedgetPreviewDark,
  reportSaver: reportsSaverDark,
  table: tableDark,
  upgradeButton: upgradeButtonDark,
  labelledToggle: labelledToggleDark,
  overlayConfig: overlayConfigDark,
  banners: bannersDark,
  skeleton: skeletonDark,
  comingSoon: comingSoonDark,
  new: newDark,
};

export default {
  light,
  dark,
};
