import { getAxios, post } from './axios';

export const fetchNotificationTemplates = async () => {
  const axios = getAxios();
  return axios
    .get(`/dashboard/campaigns/templates/?status=published`)
    .then(res => {
      return { data: res.data.result, error: null };
    })
    .catch(error => ({
      error,
      data: {},
    }));
};

export const fetchPreview: any = async payload =>
  post('/preview/sms/', payload);
