import colors from 'barn/tokens/colors';

export const newLight = {
  bg: colors.blues[3],
  color: colors.greys[6],
};

export const newDark = {
  ...newLight,
};
