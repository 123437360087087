import { init, RematchRootState, RematchDispatch, Plugin } from '@rematch/core';
import createPersistPlugin from '@rematch/persist';
import storage from 'redux-persist/lib/storage';
import middlewares from './middleware';
import models, { persistWhitelist, RootModel } from './models';

const persistPlugin = createPersistPlugin({
  key: 'root_1',
  version: 2,
  whitelist: persistWhitelist,
  storage,
}) as Plugin<RootModel>;

const store = init<RootModel>({
  models,
  redux: {
    middlewares,
  },
  plugins: [persistPlugin],
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type StoreState = RematchRootState<RootModel>;

export default store;
