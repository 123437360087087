import colors from 'barn/tokens/colors';

export const toastLight = {
  bg: colors.greys[1],
  color: colors.greys[8],
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
};

export const toastDark = {
  ...toastLight,
  bg: colors.greys[14],
  color: colors.greys[7],
};
