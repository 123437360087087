import styled from 'styled-components';
import Button, {
  ButtonAppearance,
  ButtonVariant,
} from 'barn/components/Button';
import CloseIcon from 'barn/icons/Close';
import tokens from 'barn/tokens';

/* close button */
const StyledCloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: transparent;
  background: transparent;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: ${tokens.space.padding[7]} ${tokens.space.padding[6]};
  cursor: pointer;
  z-index: ${tokens.zIndex[3]};
  border-top-right-radius: ${tokens.radii[5]};

  &:hover {
    background-color: ${({ theme }) => theme.modal.closeButton.hover.bg};
  }
`;

export const CloseButton: React.FunctionComponent<{
  onClick: Function;
  style?: AnyObject;
}> = ({ onClick, style = {} }) => (
  <StyledCloseButton
    style={style}
    onClick={event => {
      event.preventDefault();
      onClick();
    }}
  >
    <CloseIcon />
  </StyledCloseButton>
);

/* (bottom) action bar */
interface StyledActionBarProps {
  isSuccessType: boolean;
}
const StyledActionBar = styled.div`
  display: flex;
  ${(props: StyledActionBarProps) => {
    return props.isSuccessType
      ? 'justify-content: center;'
      : 'justify-content: flex-end;';
  }}
  padding-top: ${tokens.space.padding[11]};

  > * {
    margin-left: ${tokens.space.margin[3]};
  }
`;

export interface ActionBarProps {
  onCancel?: ReactEventHandler;
  onConfirm: ReactEventHandler;
  isLoading?: boolean;
  cancelText?: string;
  confirmText?: string;
  confirmButtonAppearance?: ButtonAppearance;
  confirmButtonVariant?: ButtonVariant;
  confirmButtonDisabled?: boolean;
  hideCancelButton?: boolean;
  hideConfirmButton?: boolean;
  type?: string;
}
export const ActionBar: React.FunctionComponent<ActionBarProps> = ({
  onCancel,
  onConfirm,
  isLoading,
  confirmText = 'Okay',
  confirmButtonAppearance = 'primary',
  confirmButtonVariant = 'default',
  confirmButtonDisabled = false,
  cancelText = 'Cancel',
  hideCancelButton,
  hideConfirmButton,
  type = 'default',
}) => {
  const isSuccessType = type === 'success';
  const isWidgetType = type === 'widget';

  return (
    <StyledActionBar
      isSuccessType={isSuccessType}
      style={isWidgetType ? { padding: tokens.space.padding[4] } : {}}
    >
      {!hideCancelButton && (
        <Button
          onClick={onCancel}
          testHandle='button-cancel'
          appearance='secondary'
        >
          {cancelText}
        </Button>
      )}
      {!hideConfirmButton && (
        <Button
          onClick={onConfirm}
          appearance={confirmButtonAppearance}
          variant={confirmButtonVariant}
          disabled={confirmButtonDisabled}
          isLoading={isLoading}
          testHandle='button-confirm'
        >
          {confirmText}
        </Button>
      )}
    </StyledActionBar>
  );
};

export default {
  CloseButton,
  ActionBar,
};
