import colors from 'barn/tokens/colors';

export const notificationCreatorLight = {
  borderColor: colors.greys[5],
  color: colors.greys[1],
  imagePreview: {
    bg: colors.greys[4],
  },
  previewSection: {
    bg: colors.greys[8],
  },
  bold: {
    color: colors.greys[0],
  },
};

export const notificationCreatorDark = {
  ...notificationCreatorLight,
  borderColor: colors.greys[12],
  color: colors.greys[7],
  imagePreview: {
    bg: colors.greys[8],
  },
  previewSection: {
    bg: colors.greys[9],
  },
  bold: {
    color: colors.greys[8],
  },
};
