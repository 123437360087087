import colors from 'barn/tokens/colors';

export const campaignCreatorLight = {
  loader: {
    color: colors.greens[4],
  },
};

export const campaignCreatorDark = {
  loader: {
    color: colors.greens[9],
  },
};
