import { createModel } from '@rematch/core';
import api from 'src/lib/api';
import { CampaignTypes } from 'src/components/ReportSaver/models';
import { DaysInSeconds, TimeFilter } from 'src/components/DaterangeFilter';
import { RootModel } from 'src/store/models';

export interface CampaignData {
  title: string;
  body: string;
  id?: number;
}

export enum DISPATCH_TYPE {
  SEND = 'send',
  RESEND = 'resend',
}

export enum CAMPAIN_RESEND_STATUS {
  PAUSED = 'paused',
  SUCCESSFUL = 'successful',
}

export enum CAMPAIN_RESEND_PAUSED_REASONS {
  OVERCONSUMPTION = 'overconsumption',
}

export const CAMPAIGN_PAGINATION_LIMIT = 10;

export enum CampaignType {
  SENT = 'sent',
  SCHEDULED = 'scheduled',
  PAUSED = 'paused',
  DRAFT = 'draft',
}
interface Campaigns {
  page: number;
  totalCount: number;
  campaigns: AnyObject[];
  offset: number;
}

export interface CampaignsModelState {
  isFetching: boolean;
  sent: Campaigns;
  scheduled: Campaigns;
  paused: Campaigns;
  draft: Campaigns;
  reports: {
    isFetching: boolean;
    timeFilter: TimeFilter;
    campaignTypes: CampaignTypes[];
    segmentsList: { id: number; name: string; campaignsSent: number }[];
    segmentIds: string[];
    totalStats: {
      impressions: number;
      clicks: number;
      ctr: number;
      revenue: number;
    };
  };
}

const initialState: CampaignsModelState = {
  isFetching: true,
  sent: {
    page: 1,
    campaigns: [],
    totalCount: 0,
    offset: 0,
  },
  scheduled: {
    page: 1,
    campaigns: [],
    totalCount: 0,
    offset: 0,
  },
  paused: {
    page: 1,
    campaigns: [],
    totalCount: 0,
    offset: 0,
  },
  draft: {
    page: 1,
    campaigns: [],
    totalCount: 0,
    offset: 0,
  },
  reports: {
    isFetching: true,
    timeFilter: {
      type: 'fixed',
      value: DaysInSeconds.ALL_TIME,
      label: 'All Time',
    },
    campaignTypes: [
      CampaignTypes.REGULAR,
      CampaignTypes.FLASH,
      CampaignTypes.SMART,
    ],
    segmentsList: [],
    segmentIds: [],
    totalStats: {
      impressions: 0,
      clicks: 0,
      ctr: 0,
      revenue: 0,
    },
  },
};

const campaigns = createModel<RootModel>()({
  state: initialState,

  effects: dispatch => ({
    async updateFiltersAndFetch(payload: {
      segmentIds?: string[];
      campaignTypes?: CampaignTypes[];
      timeFilter?: TimeFilter;
    }) {
      this.setReports(payload);
      this.resetSentRecordsPerPage();
      this.fetchSentCampaigns();
      this.fetchTotalStats([CampaignType.SENT]);
    },

    async fetchReportingSegments() {
      const { error, segmentsList } =
        await api.reports.fetchReportingSegments();

      if (error) {
        dispatch.saveToast.showError(
          `Segment details couldn't be fetched, please refresh and try again`,
        );
        return;
      }

      this.setReports({ segmentsList });
    },

    async fetchTotalStats() {
      this.setReports({ isFetching: true });

      const {
        data: { impressions, ctr, revenue, clicks },
        error,
      } = await api.reports.fetchTotalCampaignStats();

      if (error) {
        dispatch.saveToast.showError(
          'There was an error fetching campaign performance details. Please try again.',
        );
      }

      this.setReports({ totalStats: { impressions, clicks, ctr, revenue } });
      this.setReports({ isFetching: false });
    },

    async fetchSentCampaigns(
      payload: { offset?: number } = { offset: 0 },
      rootState,
    ) {
      this.setState({ isFetching: true });
      const { offset } = payload;

      const { campaigns } = rootState;

      const {
        sent: { campaigns: fetchedCampaigns },
      } = campaigns;

      const {
        data: { campaigns: sentCampaigns, summary },
        error,
      } = await api.reports.fetchSentCampaigns(
        CAMPAIGN_PAGINATION_LIMIT,
        offset,
      );

      if (error) {
        this.setState({
          isFetching: false,
        });
        dispatch.saveToast.showError(
          `Sent campaign details couldn't be fetched, please refresh and try again`,
        );
        return;
      }

      if (offset > 0) {
        this.setSentState({
          campaigns: [...fetchedCampaigns, ...sentCampaigns],
          totalCount: summary.total_count,
          offset,
        });
      } else {
        this.setSentState({
          campaigns: sentCampaigns,
          totalCount: summary.total_count,
          offset: 0,
        });
      }

      this.setState({
        isFetching: false,
      });
    },

    async fetchScheduledCampaigns(_, rootState) {
      const {
        campaigns: {
          scheduled: { campaigns: fetchedCampaigns, offset },
        },
      } = rootState;

      const {
        error,
        data: { scheduledCampaigns },
      } = await api.reports.fetchScheduledCampaigns(
        CAMPAIGN_PAGINATION_LIMIT,
        offset,
      );

      if (error) {
        dispatch.saveToast.showError(
          `Scheduled campaign details couldn't be fetched, please refresh and try again`,
        );
        return;
      }

      this.setScheduledState({
        campaigns: [...fetchedCampaigns, ...scheduledCampaigns],
        totalCount: scheduledCampaigns.summary.total_count,
        offset: offset + scheduledCampaigns.length,
      });
    },

    async fetchPausedCampaigns(
      payload: { offset?: number } = { offset: 0 },
      rootState,
    ) {
      this.setState({ isFetching: true });
      const { offset } = payload;

      const { campaigns } = rootState;

      const {
        paused: { campaigns: fetchedCampaigns },
      } = campaigns;

      const {
        data: { campaigns: pausedCampaigns, summary },
        error,
      } = await api.reports.fetchPausedCampaigns(
        CAMPAIGN_PAGINATION_LIMIT,
        offset,
      );

      if (error) {
        this.setState({
          isFetching: false,
        });
        dispatch.saveToast.showError(
          `Paused campaign details couldn't be fetched, please refresh and try again`,
        );
        return;
      }

      if (offset > 0) {
        this.setPausedState({
          campaigns: [...fetchedCampaigns, ...pausedCampaigns],
          totalCount: summary.total_count,
          offset,
        });
      } else {
        this.setPausedState({
          campaigns: pausedCampaigns,
          totalCount: summary.total_count,
          offset: 0,
        });
      }

      this.setState({
        isFetching: false,
      });
    },

    async fetchDraftCampaigns(
      payload: { offset?: number } = { offset: 0 },
      rootState,
    ) {
      this.setState({ isFetching: true });
      const { offset } = payload;

      const { campaigns } = rootState;

      const {
        draft: { campaigns: fetchedCampaigns },
      } = campaigns;

      const {
        data: { campaigns: draftCampaigns, summary },
        error,
      } = await api.reports.fetchDraftCampaigns(
        CAMPAIGN_PAGINATION_LIMIT,
        offset,
      );

      if (error) {
        this.setState({
          isFetching: false,
        });
        dispatch.saveToast.showError(
          `Draft campaign details couldn't be fetched, please refresh and try again`,
        );
        return;
      }

      if (offset > 0) {
        this.setDraftState({
          campaigns: [...fetchedCampaigns, ...draftCampaigns],
          totalCount: summary.total_count,
          offset,
        });
      } else {
        this.setDraftState({
          campaigns: draftCampaigns,
          totalCount: summary.total_count,
          offset: 0,
        });
      }

      this.setState({
        isFetching: false,
      });
    },

    async delete({
      id,
      type,
    }: {
      id: number;
      type: Exclude<CampaignType, CampaignType.SENT>;
    }) {
      const { error } = await api.campaigns.delete(id);

      if (error) {
        dispatch.saveToast.showError(
          'Campaign could not be deleted, please refresh and try again',
        );
        return;
      }

      if (type === CampaignType.PAUSED) {
        this.fetchPausedCampaigns();
        this.fetchTotalStats([CampaignType.PAUSED]);
      } else if (type === CampaignType.SCHEDULED) {
        this.fetchScheduledCampaigns();
        this.fetchTotalStats([CampaignType.SCHEDULED]);
      } else
        this.fetchCampaigns({
          type,
          offset: 0,
        });

      dispatch.saveToast.showDone('Campaign successfully deleted');
    },

    async sendOrResend({
      campaignId,
      dispatchType,
    }: {
      campaignId: number;
      dispatchType: DISPATCH_TYPE.SEND | DISPATCH_TYPE.RESEND;
    }) {
      const { data, error } = await api.campaigns.resend(campaignId);

      if (error)
        dispatch.saveToast.showError(
          dispatchType === DISPATCH_TYPE.RESEND
            ? 'Campaign could not be resent, please refresh and try again'
            : 'Campaign could not be sent, please refresh and try again',
        );

      if (data.status === CAMPAIN_RESEND_STATUS.SUCCESSFUL)
        dispatch.saveToast.showDone(
          dispatchType === DISPATCH_TYPE.RESEND
            ? 'Campaign successfully resent'
            : 'Campaign successfully sent',
        );

      if (
        data.status === CAMPAIN_RESEND_STATUS.PAUSED &&
        data.reason === CAMPAIN_RESEND_PAUSED_REASONS.OVERCONSUMPTION
      ) {
        dispatch.saveToast.showError(
          dispatchType === DISPATCH_TYPE.RESEND
            ? 'Campaign not sent because of insufficient credits. Please topup to resend campaign.'
            : 'Campaign not sent because of insufficient credits. Please topup to send campaign.',
        );
      }

      this.fetchTotalStats([CampaignType.PAUSED]);
      this.fetchPausedCampaigns();
    },
  }),

  reducers: {
    setSentState(state, payload: Partial<CampaignsModelState['sent']>) {
      return {
        ...state,
        sent: {
          ...state.sent,
          ...payload,
        },
      };
    },

    setScheduledState(
      state,
      payload: Partial<CampaignsModelState['scheduled']>,
    ) {
      return {
        ...state,
        scheduled: {
          ...state.scheduled,
          ...payload,
        },
      };
    },

    setPausedState(state, payload: Partial<CampaignsModelState['paused']>) {
      return {
        ...state,
        paused: {
          ...state.paused,
          ...payload,
        },
      };
    },

    setDraftState(state, payload: Partial<CampaignsModelState['draft']>) {
      return {
        ...state,
        draft: {
          ...state.draft,
          ...payload,
        },
      };
    },

    setState(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },

    setReports(state, payload: Partial<CampaignsModelState['reports']>) {
      return {
        ...state,
        reports: {
          ...state.reports,
          ...payload,
        },
      };
    },

    resetSentRecordsPerPage(state) {
      return {
        ...state,
        sent: {
          ...state.sent,
          recordsPerPage: 10,
        },
      };
    },
  },
});

export default campaigns;
