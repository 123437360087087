import { automationFlowLight } from './automation-flows/theme';
import { automationLight } from './automation/theme';
import { campaignCreatorLight } from './campaign-creator/theme';
import { campaignsLight } from './campaigns/theme';
import { gettingStartedLight } from './getting-started/theme';
import { homeLight } from './home/theme';
import { integrationsLight } from './integrations/theme';
import { loginLight } from './login/theme';
import { notificationCreatorLight } from './notification-creator/theme';
import { optinsLight } from './optins/theme';
import { pricingLight } from './pricing/theme';
import { promotionPopupsLight } from './promotion-popups/theme';
import { segmentsLight } from './segments/theme';
import { settingsLight } from './settings/theme';
import { subscribersLight } from './subscribers/theme';
import { improvementChecklistLight } from './improvement-checklist/theme';

const light = {
  automation: automationLight,
  automationFlow: automationFlowLight,
  campaignCreator: campaignCreatorLight,
  campaigns: campaignsLight,
  gettingStarted: gettingStartedLight,
  home: homeLight,
  integrations: integrationsLight,
  login: loginLight,
  notificationCreator: notificationCreatorLight,
  optins: optinsLight,
  pricing: pricingLight,
  promotionPopup: promotionPopupsLight,
  segments: segmentsLight,
  settings: settingsLight,
  subscribers: subscribersLight,
  improvementChecklist: improvementChecklistLight,
};

export default light;
