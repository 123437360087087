import { v1 as uuidv1 } from 'uuid';
import colors from 'barn/tokens/colors';

const Tick = ({ color = colors.greys[2], style = {} }) => {
  const id = uuidv1();

  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' style={style}>
      <mask
        id={`maskTick${id}`}
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='20'
        height='20'
      >
        <rect
          className='iconColor'
          x='20'
          y='20'
          width='20'
          height='20'
          transform='rotate(180 20 20)'
          fill={color}
        />
      </mask>
      <g mask={`url(#maskTick${id})`}>
        <path
          className='iconColor'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.74432 13.7664L5.24437 11.366C4.91854 11.0532 4.91854 10.5475 5.24437 10.2347C5.5702 9.92182 6.09685 9.92182 6.42268 10.2347L8.28597 12.0237L13.5342 6.27971C13.8342 5.94366 14.36 5.90526 14.7083 6.1925C15.0583 6.48054 15.0992 6.98461 14.7992 7.31986L8.96596 13.7208C8.81513 13.8904 8.5968 13.9912 8.36514 14C8.11264 14.0008 7.90015 13.9168 7.74432 13.7664Z'
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Tick;
