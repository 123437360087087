import colors from 'barn/tokens/colors';

export const loginLight = {
  bg: '#343744',
  color: colors.greys[8],
};

export const loginDark = {
  bg: colors.greys[9],
  color: colors.greys[7],
};
