import colors from 'barn/tokens/colors';

export const optinsLight = {
  color: colors.greys[1],
  borderColor: colors.greys[5],
  secondary: {
    color: colors.greys[2],
  },
  bold: {
    color: colors.greys[0],
  },
  modal: {
    bg: 'rgba(0, 0, 0, 0.6)',
    content: {
      color: colors.greys[8],
    },
  },
  radioWrap: {
    desc: {
      color: colors.greys[2],
    },
    borderColor: colors.greys[4],
  },
  liveTag: {
    bg: colors.greens[4],
    color: colors.greys[8],
    off: {
      bg: colors.reds[4],
    },
  },
};

export const optinsDark = {
  ...optinsLight,
  color: colors.greys[7],
  borderColor: colors.greys[11],
  secondary: {
    color: colors.greys[4],
  },
  bold: {
    color: colors.greys[8],
  },
  radioWrap: {
    borderColor: colors.greys[16],
    desc: {
      color: colors.greys[19],
    },
  },
  liveTag: {
    bg: colors.greens[9],
    color: colors.greys[8],
    off: {
      bg: colors.reds[4],
    },
  },
};
