import * as styled from 'styled-components';

export const DatePicker = styled.createGlobalStyle`
  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::after {
    border-bottom-color: ${({ theme }) =>
      theme.datePicker.triangle.borderBottomColor};
  }

  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::after {
    border-top-color: ${({ theme }) =>
      theme.datePicker.triangle.borderTopColor};
  }

  .react-datepicker {
    background-color: ${({ theme }) => theme.datePicker.bg};
    color: ${({ theme }) => theme.datePicker.color};
    border: 1px solid ${({ theme }) => theme.datePicker.triangle.color};
  }

  .react-datepicker__header {
    background-color: ${({ theme }) => theme.datePicker.header.bg};
    border-bottom: 1px solid
      ${({ theme }) => theme.datePicker.header.borderColor};
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: ${({ theme }) => theme.datePicker.color};
  }

  .react-datepicker__time-container {
    border-left: 1px solid ${({ theme }) => theme.datePicker.header.borderColor};
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    background-color: ${({ theme }) => theme.datePicker.header.bg};
  }
  .react-datepicker__month-container {
    background-color: ${({ theme }) => theme.datePicker.bg};
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${({ theme }) => theme.datePicker.list.bg};
    color: ${({ theme }) => theme.datePicker.list.color};
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled {
    color: ${({ theme }) => theme.datePicker.disabled.color};
  }

  .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
    background-color: ${({ theme }) => theme.datePicker.hover.bg};
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: ${({ theme }) => theme.datePicker.color};
  }

  .react-datepicker__month--selected,
  .react-datepicker__month--in-selecting-range,
  .react-datepicker__month--in-range,
  .react-datepicker__quarter--selected,
  .react-datepicker__quarter--in-selecting-range,
  .react-datepicker__quarter--in-range {
    background-color: ${({ theme }) => theme.datePicker.list.bg};
    color: ${({ theme }) => theme.datePicker.list.bg};
  }

  .react-datepicker__month--disabled,
  .react-datepicker__quarter--disabled {
    color: ${({ theme }) => theme.datePicker.disabled.color};
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    background-color: ${({ theme }) => theme.datePicker.hover.bg};
    color: ${({ theme }) => theme.datePicker.hover.color};
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: ${({ theme }) => theme.datePicker.selected.bg};
    color: ${({ theme }) => theme.datePicker.selected.color};
  }
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    background-color: ${({ theme }) => theme.datePicker.selected.hover.bg};
    color: ${({ theme }) => theme.datePicker.selected.hover.color};
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: ${({ theme }) => theme.datePicker.selected.keyboard.bg};
    color: ${({ theme }) => theme.datePicker.selected.keyboard.color};
  }

  .react-datepicker__month--selecting-range
    .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
  .react-datepicker__month--selecting-range
    .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
  .react-datepicker__month--selecting-range
    .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
  .react-datepicker__month--selecting-range
    .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range) {
    color: ${({ theme }) => theme.datePicker.color};
  }
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    color: ${({ theme }) => theme.datePicker.disabled.color};
  }

  .react-datepicker__time-container > div.react-datepicker__time > div > ul {
    background-color: ${({ theme }) => theme.datePicker.list.bg};
  }
`;
