import colors from 'barn/tokens/colors';

export const comingSoonLight = {
  bg: colors.greys[3],
  color: colors.greys[6],
};

export const comingSoonDark = {
  ...comingSoonLight,
};
