import { get } from './axios';

interface SignRequestParams {
  filename: string;
  filetype: string;
}
export const signS3 = async ({ filename, filetype }: SignRequestParams) => {
  const encodedFilename = encodeURIComponent(filename);

  const {
    data: { data: s3data, url },
    error,
  } = await get(`/sign-s3/?filename=${encodedFilename}&filetype=${filetype}`);

  return {
    data: {
      s3data,
      url,
    },
    error,
  };
};

const fetchBanners = () => get('/dashboard/banner/v2/');

export default {
  signS3,
  fetchBanners,
};
