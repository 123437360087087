import styled, { DefaultTheme } from 'styled-components';
import { fontSizes } from 'barn/tokens/typography';
import tokens from 'barn/tokens';

export const StyledSearch = styled.input`
  border: 0;
  border-radius: ${tokens.radii[4]};
  border-bottom: 1px solid ${({ theme }) => theme.dropdown.borderColor};
  margin-bottom: 0;
  padding: ${tokens.space.padding[4]};
  width: 100%;
  font-size: ${fontSizes[2]};
  color: ${({ theme }) => theme.dropdown.color};
  background-color: ${({ theme }) => theme.dropdown.search.bg};

  &::placeholder {
    color: ${({ theme }) => theme.dropdown.search.placeholder.color};
  }

  &:placeholder-shown {
    background: url('/static/svg/Search.svg') no-repeat,
      ${({ theme }) => theme.dropdown.search.bg};
    background-position: 12px center;
  }
`;

export const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 ${tokens.space.padding[3]};
  min-width: max-content;
  max-height: 150px;
  overflow-y: auto;
`;

export const StyledListItemText = styled.div`
  background: ${({ theme }) => theme.dropdown.listItem.bg};
  width: 100%;
  font-size: ${fontSizes[2]};
  color: ${({ theme }) => theme.dropdown.color};
  cursor: pointer;

  .mr-40 {
    margin-right: ${tokens.space.margin[11]};
  }

  &:hover {
    background: ${({ theme }) => theme.dropdown.listItem.hover.bg};
    color: ${({ theme }) => theme.dropdown.listItem.hover.color};
  }

  &.disabled {
    background: ${({ theme }) => theme.dropdown.listItem.disabled.bg};
  }
`;

export const StyledGroupContainer = styled.div`
  &:nth-child(n + 2) {
    border-top: 1px solid
      ${({ theme }) => theme.dropdown.groupContainer.borderColor};
  }
`;

export const StyledGroupLabel = styled.div`
  background: ${({ theme }) => theme.dropdown.groupLabel.bg};
  padding: ${tokens.space.padding[3]} ${tokens.space.padding[4]};
  width: 100%;
  font-size: ${fontSizes[1]};
  text-transform: capitalize;
  color: ${({ theme }) => theme.dropdown.color};
  font-weight: 500;

  &:nth-child(n + 2) {
    border-top: 1px solid
      ${({ theme }) => theme.dropdown.groupLabel.borderColor};
  }
`;

export const StyledListItemCheckbox = styled.label`
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.dropdown.listItemCheckbox.bg};
  padding: ${tokens.space.padding[3]} ${tokens.space.padding[4]};
  width: 100%;
  font-size: ${fontSizes[2]};
  color: ${({ theme }) => theme.dropdown.color};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.dropdown.listItemCheckbox.hover.bg};
    color: ${({ theme }) => theme.dropdown.listItemCheckbox.hover.color};

    .checkmark {
      border-color: ${({ theme }) =>
        theme.dropdown.listItemCheckbox.checkmark.borderColor};
    }
  }

  .secondaryText {
    color: ${({ theme }) => theme.dropdown.listItemCheckbox.secondary.color};
  }
`;

const MultiSelectInnerWrap = styled.div<{ isOpen: boolean }>`
  cursor: pointer;
  position: relative;
  border-radius: ${tokens.radii[4]};
  border-bottom-right-radius: ${props =>
    props.isOpen ? '0' : tokens.radii[3]};
  border-bottom-left-radius: ${props => (props.isOpen ? '0' : tokens.radii[3])};
  padding: ${tokens.space.padding[2]} ${tokens.space.padding[4]};
  padding-right: 50px;
  background-color: white;
  border-color: ${({ theme }) => theme.dropdown.borderColor};
  border-width: 1px;
  border-style: solid;
  background-color: ${({ theme }) => theme.dropdown.multiSelectButton.bg};
`;

const MultiSelectBaseMenu = styled.ul`
  padding: 0;
  margin-top: 0;
  position: absolute;
  width: max-content;
  max-height: 20rem;
  overflow-y: auto;
  overflow-x: hidden;
  outline: 0;
  transition: opacity 0.1s ease;
  border-radius: 0 0 0.28571429rem 0.28571429rem;
  box-shadow: ${({ theme }) => theme.dropdown.boxShadow};
  border-color: ${({ theme }) => theme.dropdown.borderColor};
  border-top-width: 0;
  border-right-width: 1px;
  border-bottom-width: ${tokens.radii[1]};
  border-left-width: 1px;
  border-style: solid;
  border: ${(props: any) => (props.isOpen ? null : 'none')};
  background-color: ${({ theme }) => theme.dropdown.listItem.bg};
`;

const MultiSelectToggleButton = styled('button')({
  backgroundColor: 'transparent',
  border: 'none',
  position: 'absolute',
  right: 0,
  top: 0,
  cursor: 'pointer',
  width: 47,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});

const MultiSelectMenuItem = styled.li`
  position: relative;
  cursor: pointer;
  display: block;
  border: none;
  height: auto;
  text-align: left;
  border-top: none;
  line-height: 1em;
  color: ${({ theme }) => theme.dropdown.menuItem.color};
  font-size: ${fontSizes[1]};
  text-transform: none;
  font-weight: 400;
  box-shadow: none;
  padding: 0.8rem 1.1rem;
  white-space: normal;
  word-wrap: normal;
  color: ${(props: {
    theme: DefaultTheme;
    isActive?: boolean;
    isSelected?: boolean;
  }) => {
    if (props.isActive) return props.theme.dropdown.menuItem.active.color;
    if (props.isSelected) return props.theme.dropdown.menuItem.selected.color;
    return null;
  }};
  background: ${(props: any) =>
    props.isActive ? props.theme.dropdown.menuItem.active.bg : null};
  font-weight: ${(props: any) => (props.isSelected ? '600' : null)};
`;

const MultiSelectItemTag = styled.div`
  margin: ${tokens.space.margin[1]};
  padding: ${tokens.space.padding[1]} ${tokens.space.padding[4]};
  display: inline-block;
  word-wrap: none;
  background-color: ${({ theme }) => theme.dropdown.multiSelectItemTag.bg};
  border-radius: ${tokens.radii[2]};
`;

const MultiSelectItemTagInnerWrap = styled.div`
  display: grid;
  grid-gap: ${tokens.space.margin[3]};
  grid-auto-flow: column;
  align-items: center;
`;

const MultiSelectItemTagRemoveButton = styled.div`
  cursor: pointer;
  line-height: 0.8;
  border: none;
  background-color: transparent;
  padding: '0';
  font-size: ${fontSizes[1]};
`;

const MultiSelectInput = styled.input`
  border: none;
  margin-left: ${tokens.space.margin[3]};
  flex: 1;
  font-size: inherit;
  min-height: 27px;
  background: ${({ theme }) => theme.input.bg};
  color: ${({ theme }) => theme.input.color};
  border-color: ${({ theme }) => theme.input.borderColor};
`;

export const SelectUnselectButtons = styled.div`
  align-self: flex-start;
  display: grid;
  gap: ${tokens.space.margin[3]};
  padding: 0px ${tokens.space.padding[4]};
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
`;

export default {
  MultiSelectInnerWrap,
  MultiSelectBaseMenu,
  MultiSelectItemTag,
  MultiSelectItemTagInnerWrap,
  MultiSelectItemTagRemoveButton,
  MultiSelectToggleButton,
  MultiSelectMenuItem,
  MultiSelectInput,
};
