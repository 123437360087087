import colors from 'barn/tokens/colors';

export const modalLight = {
  closeButton: {
    hover: {
      bg: colors.greys[5],
    },
  },
  header: {
    borderColor: colors.greys[5],
    title: {
      color: colors.greys[1],
    },
  },
  alert: {
    title: {
      color: colors.greys[0],
    },
    subtitle: {
      color: colors.greys[1],
    },
  },
  success: {
    title: {
      color: colors.greys[1],
    },
    subtitle: {
      color: colors.greys[2],
    },
  },
  simple: {
    title: {
      color: colors.greys[1],
    },
  },
  topbar: {
    borderColor: colors.greys[5],
    title: {
      color: colors.greys[1],
    },
  },
};

export const modalDark = {
  closeButton: {
    hover: {
      bg: colors.greys[12],
    },
  },
  header: {
    borderColor: colors.greys[17],
    title: {
      color: colors.greys[8],
    },
  },
  alert: {
    title: {
      color: colors.greys[8],
    },
    subtitle: {
      color: colors.greys[7],
    },
  },
  success: {
    title: {
      color: colors.greys[8],
    },
    subtitle: {
      color: colors.greys[7],
    },
  },
  simple: {
    title: {
      color: colors.greys[8],
    },
  },
  topbar: {
    borderColor: colors.greys[17],
    title: {
      color: colors.greys[8],
    },
  },
};
