import { Stack, VStack } from 'barn/components/Stack';
import space from 'barn/tokens/space';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import colors from 'barn/tokens/colors';
import { fontSizes } from 'barn/tokens/typography';
import { DynamicPillType } from '.';

const pillVariantColorMap = {
  success: {
    bgColor: colors.greens[7],
    textColor: colors.greys[13],
  },
  error: {
    bgColor: colors.reds[7],
    textColor: colors.greys[13],
  },
  inProgress: {
    bgColor: colors.greys[13],
    textColor: colors.greys[8],
  },
};

const PillContainer = styled.div<{
  variant: DynamicPillType['variant'];
  isExpanded: boolean;
}>`
  max-width: 300px;
  width: 300px;
  height: 36px;
  position: relative;
  background-color: ${props => pillVariantColorMap[props.variant].bgColor};
  color: ${props => pillVariantColorMap[props.variant].textColor};
  border-radius: ${props => (props.isExpanded ? '18px 18px 0 0' : '18px')};
  padding: ${space.padding[3]} ${space.padding[5]};
  margin-right: ${space.margin[4]};
  transition: border-radius 0.2s ease-in-out;
`;

const PillHeading = styled.p`
  font-size: ${fontSizes[1]};
`;

const PillDismissButton = styled.button`
  text-decoration: none;
  border: none;
  background-color: none;
  cursor: pointer;
  padding: 0;
  display: flex;
`;

const PillDrawer = styled(motion.div)`
  position: absolute;
  width: 100%;
  border-radius: 0 0 18px 18px;
  background-color: inherit;
  color: inherit;
  left: 0;
  overflow: hidden;
`;

const PillDrawerContent = styled(VStack)`
  padding: ${space.padding[4]};
  /* hard coding the left padding for the subheading to align to the heading */
  padding-left: 44px;
`;

const PillIconContainer = styled(Stack)`
  width: 20px;
  height: 20px;
`;

export default {
  PillContainer,
  PillHeading,
  PillDismissButton,
  PillDrawer,
  PillDrawerContent,
  PillIconContainer,
};
