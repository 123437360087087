import colors from 'barn/tokens/colors';

export const segmentsLight = {
  bg: colors.greys[8],
  color: colors.greys[1],
  borderColor: colors.greys[5],
  hover: {
    boxShadow: '0 0px 10px 2px rgba(0, 0, 0, 0.15)',
  },
  subheading: {
    color: colors.greys[2],
  },
  alternateRows: {
    bg: 'rgba(0, 0, 0, 0.02)',
  },
  light: {
    borderColor: colors.greys[6],
  },
  divider: {
    borderColor: '#ccc',
  },
};

export const segmentsDark = {
  ...segmentsLight,
  bg: colors.greys[12],
  color: colors.greys[7],
  borderColor: colors.greys[14],
  hover: {
    boxShadow: 'none',
  },

  alternateRow: {
    bg: colors.greys[14],
  },
  subheading: {
    color: colors.greys[7],
  },
  light: {
    borderColor: colors.greys[14],
  },
  divider: {
    borderColor: colors.greys[14],
  },
};
