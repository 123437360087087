import { Models } from '@rematch/core';
import campaigns from 'src/modules/campaigns/models';
import campaignCreator from 'src/modules/campaign-creator/models';
import notificationCreator from 'src/modules/notification-creator/models';
import settings from 'src/modules/settings/models';
import abandonedCartAutomation from 'src/modules/automation/models/abandoned-cart';
import backInStockAutomation from 'src/modules/automation/models/back-in-stock';
import browseAbandonmentAutomation from 'src/modules/automation/models/browse-abandoment';
import priceDropAutomation from 'src/modules/automation/models/price-drop';
import shippingAutomation from 'src/modules/automation/models/shipping';
import welcomeAutomation from 'src/modules/automation/models/welcome';
import optins from 'src/modules/optins/models';
import home from 'src/modules/home/models';
import integrations from 'src/modules/integrations/models';
import automationFlow from 'src/modules/automation-flows/models';
import pricing from 'src/modules/pricing/models';
import segments from 'src/modules/segments/models';
import subscribers from 'src/modules/subscribers/models';
import promotionPopups from 'src/modules/promotion-popups/models';
import onboarding from 'src/modules/onboarding/models';
import gettingStarted from 'src/modules/getting-started/models';
import reportSaver from 'src/components/ReportSaver/models';
import automationStats from 'src/modules/automation/models/automationStats';
import improvementChecklist from 'src/modules/improvement-checklist/models';
import banners from 'src/components/Banners/model';
import welcomeServiceMessage from 'src/modules/service-messages/model/welcome';
import saveToast from './save-toast';
import user from './user';
import common from './common';

export default {
  common,
  saveToast,
  onboarding,
  campaigns,
  campaignCreator,
  notificationCreator,
  settings,
  abandonedCartAutomation,
  backInStockAutomation,
  browseAbandonmentAutomation,
  priceDropAutomation,
  shippingAutomation,
  welcomeAutomation,
  segments,
  optins,
  home,
  integrations,
  automationFlow,
  pricing,
  subscribers,
  promotionPopups,
  gettingStarted,
  reportSaver,
  automationStats,
  user,
  banners,
  improvementChecklist,
  welcomeServiceMessage,
};

export const persistWhitelist = [
  'campaignCreator',
  'notificationCreator',
  'gettingStarted',
];

export interface RootModel extends Models<RootModel> {
  common: typeof common;
  saveToast: typeof saveToast;
  onboarding: typeof onboarding;
  campaigns: typeof campaigns;
  campaignCreator: typeof campaignCreator;
  notificationCreator: typeof notificationCreator;
  settings: typeof settings;
  abandonedCartAutomation: typeof abandonedCartAutomation;
  backInStockAutomation: typeof backInStockAutomation;
  browseAbandonmentAutomation: typeof browseAbandonmentAutomation;
  priceDropAutomation: typeof priceDropAutomation;
  shippingAutomation: typeof shippingAutomation;
  welcomeAutomation: typeof welcomeAutomation;
  segments: typeof segments;
  optins: typeof optins;
  home: typeof home;
  integrations: typeof integrations;
  automationFlow: typeof automationFlow;
  pricing: typeof pricing;
  subscribers: typeof subscribers;
  promotionPopups: typeof promotionPopups;
  gettingStarted: typeof gettingStarted;
  reportSaver: typeof reportSaver;
  automationStats: typeof automationStats;
  user: typeof user;
  banners: typeof banners;
  improvementChecklist: typeof improvementChecklist;
  welcomeServiceMessage: typeof welcomeServiceMessage;
}
