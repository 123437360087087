import colors from 'barn/tokens/colors';

const Switch = ({ color = colors.greys[0], style = {} }) => (
  <svg width='20' height='20' fill='none' viewBox='0 0 32 32' style={style}>
    <path
      fill={color}
      d='M31.531 5.269a1.599 1.599 0 0 1 0 2.262l-4.8 4.8a1.596 1.596 0 0 1-2.61-.519 1.598 1.598 0 0 1 .348-1.743l2.069-2.07H11.2c-3.53 0-6.4 2.871-6.4 6.4a1.6 1.6 0 1 1-3.2 0c0-5.293 4.306-9.6 9.6-9.6h15.338l-2.07-2.068A1.6 1.6 0 1 1 26.732.47l4.8 4.8Zm-4.33 10.73a1.6 1.6 0 0 1 1.6 1.6c0 5.297-4.305 9.6-9.6 9.6H5.461l2.07 2.07a1.6 1.6 0 1 1-2.263 2.262l-4.8-4.8a1.598 1.598 0 0 1 0-2.262l4.8-4.8A1.6 1.6 0 0 1 7.53 21.93L5.462 24H19.2c3.53 0 6.4-2.87 6.4-6.4a1.6 1.6 0 0 1 1.6-1.6Z'
    />
  </svg>
);

export default Switch;
