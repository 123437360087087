import React from 'react';
import { withRouter, NextRouter } from 'next/router';
import checkAuth from './check-auth';

export default function withAuth(Component: any): React.ReactNode {
  class ComponentWithAuth extends React.Component<
    { router: NextRouter },
    { loggedInUser: AnyObject; shouldRenderPage: boolean }
  > {
    constructor(props) {
      super(props);
      this.state = {
        loggedInUser: null,
        shouldRenderPage: false,
      };
    }

    componentDidMount() {
      const { router } = this.props;
      const { loggedInUser, shouldRenderPage } = checkAuth(router);
      this.setState({ loggedInUser, shouldRenderPage });
    }

    render() {
      const { loggedInUser, shouldRenderPage } = this.state;

      if (!loggedInUser && !shouldRenderPage) {
        return null;
      }

      return <Component />;
    }
  }

  return withRouter(ComponentWithAuth);
}
