import { v1 as uuidv1 } from 'uuid';
import colors from 'barn/tokens/colors';

const SmallClose = ({ color = colors.greys[2], style = {} }) => {
  const id = uuidv1();

  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' style={style}>
      <mask
        id={`maskSmallClose${id}`}
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='4'
        y='3'
        width='13'
        height='13'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.4142 10L15.7072 5.70701C16.0982 5.31601 16.0982 4.68401 15.7072 4.29301C15.3162 3.90201 14.6842 3.90201 14.2933 4.29301L10.0002 8.58601L5.70725 4.29301C5.31625 3.90201 4.68425 3.90201 4.29325 4.29301C3.90225 4.68401 3.90225 5.31601 4.29325 5.70701L8.58625 10L4.29325 14.293C3.90225 14.684 3.90225 15.316 4.29325 15.707C4.48825 15.902 4.74425 16 5.00025 16C5.25625 16 5.51225 15.902 5.70725 15.707L10.0002 11.414L14.2933 15.707C14.4882 15.902 14.7443 16 15.0002 16C15.2562 16 15.5122 15.902 15.7072 15.707C16.0982 15.316 16.0982 14.684 15.7072 14.293L11.4142 10Z'
          fill='#31373D'
        />
      </mask>
      <g mask={`url(#maskSmallClose${id})`}>
        <rect className='iconColor' width='20' height='20' fill={color} />
      </g>
    </svg>
  );
};

export default SmallClose;
