import colors from 'barn/tokens/colors';

export const tagLight = {
  primary: {
    bg: colors.greys[6],
    color: colors.greys[1],
    emphasis: {
      color: colors.greens[4],
    },
  },
  secondary: {
    color: colors.greys[2],
  },
  error: {
    color: colors.reds[5],
  },
};

export const tagDark = {
  primary: {
    bg: colors.greys[15],
    color: colors.greys[7],
    emphasis: {
      color: colors.greens[9],
    },
  },
  secondary: {
    color: colors.greys[4],
  },
  error: {
    color: colors.reds[6],
  },
};
