import { createModel } from '@rematch/core';
import {
  fetchNotificationTemplates,
  fetchPreview,
} from 'src/lib/api/notification';
import { RootModel } from 'src/store/models';
import { v1 as uuidv1 } from 'uuid';

export type HeroImage = {
  desktop?: null | string;
  mobile?: null | string;
  macos?: null | string;
};

export type Button = {
  id: string;
  title: string;
  link: string;
  titleError: boolean;
  linkError: boolean;
};

export interface CampaignTemplates {
  id: string;
  title: string;
  description: string;
  categories: string[];
  icon_image: string;
  mac_image: string;
  mobile_image: string;
  window_image: string;
  status: string;
  image: string;
  redirected_url: string;
  created_at: string;
  modified_at: string;
}

export enum CountryCode {
  CANADA = 'CA',
  UNITED_STATES = 'US',
}

type Preview = {
  error: string | null;
  isFetching: boolean;
  message: string;
  characterCount: number;
  creditsConsumed: number;
  maxCharacterCount: number;
};

export interface NCState {
  notificationCreatorType: NOTIFICATION_CREATOR_TYPE | null;
  shouldShortenURL: boolean;
  countryCode: CountryCode;
  campaignTemplates: {
    templates: CampaignTemplates[];
    isFetching: boolean;
    errorMessage: null | string;
    lastAppliedTemplateId: null | string;
  };
  preview: Preview;
  title: string;
  reminderTime: string;
  minimumCartValue: number | null;
  message: string;
  primaryLink: string;
  discountCode: string | null;
  heroImage: HeroImage;
  buttons: Array<Button>;
  icon: string;
  isModified: boolean;
  titleError: boolean;
  primaryLinkError: boolean;
  discountCodeError: boolean;
  errors: AnyObject;
}

const initialState = (): NCState => ({
  notificationCreatorType: null,
  shouldShortenURL: true,
  countryCode: CountryCode.UNITED_STATES,
  campaignTemplates: {
    templates: [],
    isFetching: false,
    errorMessage: null,
    lastAppliedTemplateId: null,
  },
  preview: {
    error: null,
    isFetching: false,
    message: '',
    characterCount: 0,
    creditsConsumed: 0,
    maxCharacterCount: 0,
  },
  title: '',
  reminderTime: '600',
  minimumCartValue: null,
  message: '',
  titleError: false,
  primaryLinkError: false,
  discountCodeError: false,
  primaryLink: '',
  discountCode: null,
  heroImage: {
    desktop: null,
    mobile: null,
    macos: null,
  },
  buttons: [],
  icon: null,
  isModified: false,
  errors: {
    title: null,
    minimumCartValue: null,
    message: null,
    discountCode: null,
  },
});

export enum NOTIFICATION_CREATOR_TYPE {
  BROWSE_ABANDONMENT = 'browse-abandonment',
  ABANDONED_CART = 'abandoned-cart',
  WELCOME_MESSAGE = 'welcome-service-message',
  CAMPAIGNS = 'campaigns',
}

const notificationCreator = createModel<RootModel>()({
  state: initialState(),

  reducers: {
    setCampaignTemplates(state: NCState, payload: AnyObject): NCState {
      return {
        ...state,
        campaignTemplates: { ...state.campaignTemplates, ...payload },
      };
    },

    shut() {
      return initialState();
    },

    loadState(state: NCState, payload: AnyObject): NCState {
      return {
        // ...initialState(),
        ...state,
        ...payload,
      };
    },

    // for (title, message, primaryLink, errors, and logo)
    setProp(state: NCState, payload: { prop: string; value: any }): NCState {
      return {
        ...state,
        [payload.prop]: payload.value,
        isModified: true,
        errors: {
          ...state.errors,

          minimumCartValue:
            payload.prop === 'minimumCartValue'
              ? false
              : state.errors.minimumCartValue,

          message: payload.prop === 'message' ? false : state.errors.message,

          discountCode:
            payload.prop === 'discountCode' ? false : state.errors.discountCode,

          title: payload.prop === 'title' ? false : state.errors.title,
        },
      };
    },

    setErrors(state: NCState, payload: AnyObject): NCState {
      const { merge = true, errors } = payload;
      const newErrors = merge ? { ...state.errors, ...errors } : errors;
      return {
        ...state,
        errors: newErrors,
      };
    },

    setHeroImage(state: NCState, payload: HeroImage): NCState {
      // Explicitly checking for `desktop` and `mobile` to be as keys
      // in the payload, they can be `null` too.
      // Don't replace with payload.desktop || state.heroImage.desktop
      return {
        ...state,
        heroImage: {
          desktop:
            'desktop' in payload ? payload.desktop : state.heroImage.desktop,
          mobile: 'mobile' in payload ? payload.mobile : state.heroImage.mobile,
          macos: 'macos' in payload ? payload.macos : state.heroImage.macos,
        },
        isModified: true,
      };
    },

    addButton(state: NCState): NCState {
      if (state.buttons.length === 2) {
        return state;
      }

      return {
        ...state,
        buttons: [
          ...state.buttons,
          {
            id: uuidv1(),
            title: '',
            link: '',
            titleError: false,
            linkError: false,
          },
        ],
        isModified: true,
      };
    },

    updateButton(state: NCState, payload: Button) {
      return {
        ...state,
        buttons: state.buttons.map((button: Button) =>
          button.id === payload.id ? payload : button,
        ),
        isModified: true,
      };
    },

    removeButton(state: NCState, payload: number) {
      return {
        ...state,
        buttons: state.buttons.filter((button, i) => i !== payload),
        isModified: true,
      };
    },
  },

  effects: {
    async fetchTemplates() {
      this.setCampaignTemplates({
        isFetching: true,
      });

      const {
        data: { data: templates = [], error_message: errorMessage = null } = {},
      } = await fetchNotificationTemplates();

      this.setCampaignTemplates({
        isFetching: false,
        errorMessage,
        templates,
      });
    },

    async getPreview(_, rootState) {
      const {
        message,
        preview,
        countryCode,
        shouldShortenURL,
        discountCode,
        notificationCreatorType,
      } = rootState.notificationCreator;
      const payload = {
        message,
        country_code: countryCode,
        should_shorten_url: shouldShortenURL,
        discount_code: discountCode,
        automation_type: notificationCreatorType,
      };

      this.loadState({
        preview: {
          ...preview,
          error: null,
          isFetching: true,
        },
      });

      const { data, error } = await fetchPreview(payload);

      if (error) {
        this.loadState({
          preview: {
            ...preview,
            error,
            isFeching: false,
          },
        });
        return;
      }

      this.loadState({
        preview: {
          error: null,
          isFetching: false,
          message: data.preview,
          characterCount: data.character_count,
          creditsConsumed: data.credits_consumed,
          maxCharacterCount: data.max_character_count,
        },
      });
    },
  },
});

export default notificationCreator;
