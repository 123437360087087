import ReactModal from 'react-modal';
import styled from 'styled-components';
import space from 'barn/tokens/space';
import { fontSizes } from 'barn/tokens/typography';
import { CloseButton, ActionBar, ActionBarProps } from './components';

interface ModalComposition {
  Header: React.FunctionComponent;
  ActionBar: React.FunctionComponent<ActionBarProps>;
}
interface ModalProps {
  isOpen: boolean;
  onAfterOpen?: () => void;
  onRequestClose?: Function;
  showCloseButton?: boolean;
  type?: string;
  noPadding?: boolean;
  testHandle?: string;
}

export const closeTimeoutMS = 250;

const Modal: React.FunctionComponent<ModalProps> & ModalComposition = ({
  isOpen,
  onAfterOpen,
  onRequestClose = null,
  showCloseButton = true,
  noPadding = false,
  type = 'default',
  testHandle = '',
  children,
}) => {
  const handleRequestClose: (
    event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
  ) => void = event => {
    if (event) {
      event.preventDefault();
    }
    if (onRequestClose) {
      onRequestClose();
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={handleRequestClose}
      className={`barn-modal barn-modal--${type} ${
        !showCloseButton && 'barn-modal-no-close-button'
      } ${noPadding && 'barn-modal-no-padding'}`}
      // Needs to  equal the closing animation duration
      closeTimeoutMS={closeTimeoutMS}
      overlayClassName='barn-modal__overlay'
      ariaHideApp={false}
      testId={testHandle}
    >
      {children}
      {showCloseButton && <CloseButton onClick={handleRequestClose} />}
    </ReactModal>
  );
};

const StyledHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.modal.header.borderColor};
  margin: 0 -${space.margin[6]} ${space.margin[6]};
  padding: 0 ${space.padding[6]} ${space.padding[6]};
  .title {
    text-align: center;
    font-size: ${fontSizes[4]};
    color: ${({ theme }) => theme.modal.header.title.color};
  }
`;
const ModalHeader: React.FunctionComponent = ({ children }) => {
  return <StyledHeader>{children}</StyledHeader>;
};

Modal.Header = ModalHeader;
Modal.ActionBar = ActionBar;

export default Modal;
