import { buttonLight } from 'barn/components/Button/theme';
import { alertLight } from 'barn/components/Alert/theme';
import { deviceSwitchLight } from 'barn/components/DeviceSwitch/theme';
import { breadcrumbsLight } from 'barn/components/Breadcrumbs/theme';
import { bottombarLight } from 'barn/components/Bottombar/theme';
import { checkboxLight } from './components/form/checkbox/theme';
import { textareaLight } from './components/form/textarea/theme';
import { inputLight } from './components/form/input/theme';
import { radioLight } from './components/form/radio/theme';
import { modalLight } from './components/Modal/theme';
import { paneLight } from './components/Pane/theme';
import { navlinkLight } from './components/Navlink/theme';
import { segmentBuilderLight } from './components/SegmentBuilder/theme';
import { spinnerLight } from './components/Spinner/theme';
import { tickCircleLight } from './components/TickCircle/theme';
import { toastLight } from './components/Toast/theme';
import { toggleLight } from './components/Toggle/theme';
import { tooltipLight } from './components/Tooltip/theme';
import { topbarLight } from './components/Topbar/theme';
import { hyperlinkLight } from './components/Hyperlink/theme';
import { tagLight } from './components/Tag/theme';
import { protipLight } from './components/Protip/theme';
import { progressBarLight } from './components/ProgressBar/theme';
import { autoCompleteInputLight } from './components/AutoCompleteInput/theme';
import { globalLight, selectBoxLight } from './globalStyle/theme';
import { dropdownLight } from './components/Dropdown/theme';
import { textLight } from './components/Text/theme';
import { datePickerLight } from './globalStyle/datepickerTheme';
import { skipLinksLight } from './skipThemeLinks';
import { drawerLight } from './components/Drawer/theme';
import { islandLight } from './components/Island/theme';

const light = {
  island: islandLight,
  drawer: drawerLight,
  button: buttonLight,
  pane: paneLight,
  alert: alertLight,
  deviceSwitch: deviceSwitchLight,
  breadcrumbs: breadcrumbsLight,
  bottombar: bottombarLight,
  checkbox: checkboxLight,
  textarea: textareaLight,
  input: inputLight,
  radio: radioLight,
  modal: modalLight,
  navlink: navlinkLight,
  segmentBuilder: segmentBuilderLight,
  spinner: spinnerLight,
  tickCircle: tickCircleLight,
  toast: toastLight,
  toggle: toggleLight,
  tooltip: tooltipLight,
  topbar: topbarLight,
  hyperlink: hyperlinkLight,
  tag: tagLight,
  protip: protipLight,
  progressBar: progressBarLight,
  autoCompleteInput: autoCompleteInputLight,
  global: globalLight,
  selectBox: selectBoxLight,
  dropdown: dropdownLight,
  text: textLight,
  datePicker: datePickerLight,
  skipLinks: skipLinksLight,
};

export default light;
