import colors from 'barn/tokens/colors';

export const breadcrumbsLight = {
  color: colors.greys[1],
  a: {
    hover: {
      color: colors.greys[1],
    },
  },
};

export const breadcrumbsDark = {
  color: colors.greys[5],
  a: {
    hover: {
      color: colors.greys[9],
    },
  },
};
