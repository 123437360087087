import colors from 'barn/tokens/colors';

export const inputLight = {
  label: {
    color: colors.greys[0],
  },
  borderColor: colors.greys[5],
  bg: colors.greys[8],
  color: colors.greys[0],
  hover: {
    borderColor: colors.blues[5],
  },
  disabled: {
    color: colors.greys[3],
    hover: {
      borderColor: colors.greys[5],
    },
  },
  error: {
    color: colors.reds[3],
    borderColor: colors.reds[5],
  },
  inactive: {
    bg: colors.greys[6],
    borderColor: colors.greys[6],
    color: colors.greys[3],
  },
  colorPicker: {
    borderColor: colors.greys[5],
  },
  asterisk: {
    color: colors.reds[5],
  },
};

export const inputDark = {
  label: {
    color: colors.greys[7],
  },
  borderColor: colors.greys[15],
  bg: colors.greys[11],
  color: colors.greys[7],
  hover: {
    borderColor: colors.blues[5],
  },
  disabled: {
    color: colors.greys[3],
    hover: {
      borderColor: colors.greys[5],
    },
  },
  error: {
    color: colors.reds[3],
    borderColor: colors.reds[3],
  },
  inactive: {
    bg: colors.greys[11],
    borderColor: colors.greys[15],
    color: colors.greys[4],
  },
  colorPicker: {
    borderColor: colors.greys[5],
  },
  asterisk: {
    color: colors.reds[3],
  },
};
