import { createModel } from '@rematch/core';
import { subSeconds } from 'date-fns';
import { DaysInSeconds, TimeFilter } from 'src/components/DaterangeFilter';
import api from 'src/lib/api';
import { RootModel } from 'src/store/models';

interface SubscribersReport {
  isFetchingFilteredData: boolean;
  subscriberLimit: number;
  subscribers: {
    isFetching: boolean;
    data: Array<AnyObject>;
  };
  report: {
    isFetching: boolean;
    countries: Array<{ country: string; count: number }>;
    subscribers: {
      daily_breakdown: Array<{
        created_date: string;
        subscriber_gain: number;
        subscriber_loss: number;
        previous_day_total_subscribers: number;
      }>;
      summary: {
        filtered_total: number;
        overall_enabled_total: number;
        last_week: number;
        unsubscribes: number;
      };
    };
  };
  timeFilter: {
    type: string;
    value: number | AnyObject;
  };
}

const initialState = (): SubscribersReport => ({
  isFetchingFilteredData: false,
  subscriberLimit: 0,
  subscribers: {
    isFetching: true,
    data: [],
  },
  report: {
    isFetching: true,
    subscribers: {
      summary: {
        filtered_total: 0,
        overall_enabled_total: 0,
        last_week: 0,
        unsubscribes: 0,
      },
      daily_breakdown: [],
    },
    countries: [],
  },
  timeFilter: {
    type: 'fixed',
    value: DaysInSeconds.LAST_30_DAYS,
  },
});

const subscribers = createModel<RootModel>()({
  state: initialState(),

  effects: dispatch => ({
    async fetchReport() {
      const end_date = new Date();
      const start_date = subSeconds(end_date, DaysInSeconds.LAST_30_DAYS);

      const { data, error } = await api.subscribers.getSubscriberReport({
        start_date,
        end_date,
      });

      if (error) {
        dispatch.saveToast.showError(
          'Failed to fetch your subscriber report, please try again.',
        );
        return;
      }

      this.storeReport(data);
    },

    async fetchSubscriberList() {
      const { data, error } = await api.subscribers.getSubscriberList();

      if (error) {
        dispatch.saveToast.showError(
          'Failed to fetch your subscriber list, please try again.',
        );
        return;
      }

      this.storeSubscriberList(data);
    },

    async applyTimeFilter(payload: {
      start_date: Date;
      end_date: Date;
      timeFilter: TimeFilter;
    }) {
      this.setTimeFilter({ timeFilter: payload.timeFilter });
      const start_date = new Date(payload.start_date);
      const end_date = new Date(payload.end_date);

      this.setIsFetchingFilteredData(true);

      const { data, error } = await api.subscribers.getSubscriberReport({
        start_date,
        end_date,
      });
      this.setIsFetchingFilteredData(false);

      if (error) {
        dispatch.saveToast.showError(
          'Failed to fetch your subscriber report, please try again.',
        );
        return;
      }

      this.storeReport(data);
    },
  }),

  reducers: {
    storeReport(state, payload: SubscribersReport['report']) {
      return {
        ...state,
        report: {
          isFetching: false,
          ...payload,
        },
      };
    },

    storeSubscriberList(state, data: SubscribersReport['subscribers']['data']) {
      return {
        ...state,
        subscribers: {
          isFetching: false,
          data,
        },
        subscriberLimit: data.length > 50 ? 50 : data.length,
        isFetching: false,
      };
    },

    setTimeFilter(state, payload: Partial<SubscribersReport>) {
      return {
        ...state,
        ...payload,
      };
    },

    showMoreSubscribers(state) {
      return {
        ...state,
        subscriberLimit:
          state.subscriberLimit + 50 < state.subscribers.data.length
            ? state.subscriberLimit + 50
            : state.subscribers.data.length,
      };
    },

    setIsFetchingFilteredData(
      state,
      payload: SubscribersReport['isFetchingFilteredData'],
    ) {
      return {
        ...state,
        isFetchingFilteredData: payload,
      };
    },
  },
});

export default subscribers;
