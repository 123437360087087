import { createModel } from '@rematch/core';
import _debounce from 'lodash.debounce';
import {
  ReportSaverState,
  ReportState,
} from 'src/components/ReportSaver/models';
import api from 'src/lib/api';
import { RootModel } from 'src/store/models';

// debounced api call
const savePrivacy = _debounce(
  (privacy: any, successCallback: () => void, errorCallback: () => void) => {
    api.settings.setPrivacy(privacy).then(res => {
      if (!res.error) {
        successCallback();
      } else {
        errorCallback();
      }
    });
  },
  500,
);

export enum AttributionDurationLimits {
  DAYS = 28,
  HOURS = 672,
}

export enum AttributionDuration {
  '3 Days' = 72,
}

export enum AttributionAction {
  IMPRESSION = 'Impression based',
  CLICK = 'Click based',
  ALL = 'All Attributed Revenue',
}

export enum AttributionWindow {
  HOURS = 'Hours',
  DAYS = 'Days',
}

export const attributionActionDropdown = [
  { value: AttributionAction.ALL },
  { value: AttributionAction.IMPRESSION },
  { value: AttributionAction.CLICK },
];

export const transformAttributionSettingsResponse = ({
  attribution_type: type,
  attribution_duration_seconds: seconds,
}) => {
  let attributionAction = AttributionAction.ALL;
  let attributionDuration = 0;
  let attributionWindow = AttributionWindow.HOURS;

  if (type === 'delivered_interval')
    attributionAction = AttributionAction.IMPRESSION;
  else if (type === 'clicked_interval')
    attributionAction = AttributionAction.CLICK;

  if (seconds === null)
    return { attributionAction, attributionDuration, attributionWindow };

  /**
   * If the duration in seconds is perfectly divisible by 24 * 3600 = 1 day
   * use DAYS as the window, else, use HOURS with the corresponding duration
   *
   * ex:
   * 86400 seconds / (24 * 3600) = 1, make it "1 Day"
   * 90000 / (24 * 3600) = 1.04, convert to hours, make it "25 Hours"
   */
  if (seconds % (24 * 3600) === 0) {
    attributionWindow = AttributionWindow.DAYS;
    attributionDuration = seconds / (24 * 3600);
  } else attributionDuration = seconds / 3600;

  return { attributionAction, attributionDuration, attributionWindow };
};

export const transformAttributionSettingsRequest = ({
  attributionAction: action,
  attributionWindow: window,
  attributionDuration: duration,
}) => {
  let attribution_type = null;
  let attribution_duration_seconds = null;

  if (action === AttributionAction.IMPRESSION)
    attribution_type = 'delivered_interval';
  else if (action === AttributionAction.CLICK)
    attribution_type = 'clicked_interval';

  /**
   * If window is in HOURS, duration in seconds = attributionDuration * 3600
   * else if DAYS, duration in seconds = attributionDuration * 24 * 3600
   */
  if (action !== AttributionAction.ALL)
    attribution_duration_seconds =
      window === AttributionWindow.HOURS
        ? duration * 3600
        : duration * 24 * 3600;

  return { attribution_type, attribution_duration_seconds };
};

const initialState = () => ({
  consumption: {
    isFetching: true,
    impressionsConsumed: 0,
    impressionsLimit: 0,
    impressionsRemaining: 0,
    resetDate: null,
    breakdown: null,
  },

  integrations: {
    isFetching: true,
    klaviyo: {
      enabled: false,
    },
  },

  privacy: {
    isFetching: true,
    admin_access: false,
    customer_id: false,
    ip: 'no_ip', // or 'forget_last_octet'
    location: false,
    notification_preference: {
      enabled: false,
      message: '',
      actions: {
        access_data: {
          enabled: false,
          text: '',
        },
        delete_data: {
          enabled: false,
          text: '',
        },
        unsubscribe: {
          enabled: false,
          text: '',
        },
      },
    },
    subscriber_info: {
      email: 'full',
      location: true,
      name: true,
    },
  },

  reports: {
    isFetching: true,
    count: 0,
    next: null,
    previous: null,
    results: [] as ReportSaverState[],
  },

  attributionWindow: {
    isFetching: true,
    attributionAction: AttributionAction.IMPRESSION,
    attributionDuration: AttributionDuration['3 Days'],
    attributionWindow: AttributionWindow.HOURS,
  },
});

const settings = createModel<RootModel>()({
  state: initialState(),

  effects: dispatch => ({
    async fetchGILBreakdown() {
      const { data, error } = await api.settings.getGILBreakdown();
      return error ? [] : data;
    },

    async getIntegrations(_, rootState) {
      const { settings } = rootState;

      const { data, error } = await api.settings.getKlaviyoIntegration();
      if (error) {
        this.storeIntegrations({
          isFetching: false,
          klaviyo: {
            enabled: settings.integrations.klaviyo.enabled,
          },
        });
        dispatch.saveToast.showError(
          'Error fetching details for information. Please retry ',
        );
        return;
      }

      this.storeIntegrations({
        isFetching: false,
        klaviyo: {
          enabled: data.data.enabled,
        },
      });
    },

    async setIntegrations(payload: { enabled: boolean }) {
      const { error } = await api.settings.setKlaviyoIntegration({
        enabled: payload.enabled,
      });

      if (error) {
        dispatch.saveToast.showError(
          'Error toggling Klaviyo Integration. Please try again later ',
        );
        return;
      }

      this.storeIntegrations({
        isFetching: false,
        klaviyo: {
          enabled: payload.enabled,
        },
      });
      dispatch.saveToast.showDone('Changes saved.');
    },

    async fetchConsumption() {
      const { data, error } = await api.settings.getConsumption();

      if (error) {
        dispatch.saveToast.showError(
          "We couldn't fetch your consumption settings, please try again.",
        );
        return;
      }

      const {
        impressions_consumed,
        impressions_limit,
        impressions_remaining,
        reset_date,
        breakdown,
      } = data;

      this.storeConsumption({
        impressionsConsumed: impressions_consumed,
        impressionsLimit: impressions_limit,
        impressionsRemaining: impressions_remaining,
        resetDate: reset_date,
        breakdown,
      });
    },

    async fetchPrivacy() {
      await api.settings.getPrivacy().then(res => {
        if (!res.error) {
          this.storePrivacy(res.data);
        }
      });
    },

    async fetchReports() {
      this.storeReports({ isFetching: true });

      const { data, error } = await api.reports.fetchAllReports();

      if (error) {
        this.storeReports({ isFetching: false });
        dispatch.saveToast.showDone("Couldn't fetch your reports");
        return;
      }

      this.storeReports({ ...data, isFetching: false });
    },

    /* eslint-disable consistent-return */
    async fetchAttributionWindow() {
      const { data, error } = await api.settings.getAttributionWindow();

      if (error)
        return dispatch.saveToast.showDone(
          "Couldn't fetch your attribution settings",
        );

      const { value } = data[0];

      this.storeAttributionWindow(transformAttributionSettingsResponse(value));
    },

    async setPrivacyProp(
      payload: Partial<ReturnType<typeof initialState>['privacy']> = {
        isFetching: false,
      },
      rootState,
    ) {
      const prevState = rootState.settings.privacy;
      const { isFetching = false, ...privacy } = { ...prevState, ...payload };

      this.storePrivacy({ isFetching, ...privacy });

      // save privacy data to backend (with debounce)
      savePrivacy(
        privacy,
        () => {
          dispatch.saveToast.showDone('Your changes have been saved');
        },
        () => {
          this.storePrivacy(prevState);
        },
      );
    },

    async sendReportNow(id: number) {
      const { error } = await api.reports.sendReportNow(id);

      if (error)
        dispatch.saveToast.showError(
          'Your report could not be sent, please refresh and try again',
        );
      else dispatch.saveToast.showDone('Your report has been sent');
    },

    async setAsNonRecurringReport(id: number, rootState) {
      const { results } = rootState.settings.reports;

      const report = results.find(r => r.id === id);
      report.isRecurringSelected = false;

      this.storeReports({ results: [...results] });

      const { error } = await api.reports.patchReport({
        id,
        schedule: {},
        state: ReportState.SENT,
        name: report.reportType,
      });

      if (error)
        dispatch.saveToast.showError(
          'Your recurring settings could not be updated, please refresh and try again',
        );
      else dispatch.saveToast.showDone('Your report has been updated');
    },

    async deleteReport(id: number, rootState) {
      const { results } = rootState.settings.reports;

      this.storeReports({ results: results.filter(r => r.id !== id) });

      const { error } = await api.reports.deleteReport(id);
      if (error)
        dispatch.saveToast.showError(
          'Your report could not be deleted, please refresh and try again',
        );
      else dispatch.saveToast.showDone('Your report has been deleted');
    },

    async saveAttributionSettings(settings: {
      attributionDuration: number;
      attributionWindow: AttributionWindow;
      attributionAction: AttributionAction;
    }) {
      const { error } = await api.settings.saveAttributionWindow({
        preferences: [
          {
            key: 'attribution_window',
            value: transformAttributionSettingsRequest(settings),
          },
        ],
      });

      if (error)
        return dispatch.saveToast.showDone(
          "Couldn't save your attribution settings, Please try again later or contact support",
        );

      this.storeAttributionWindow(settings);

      const { attributionDuration, attributionWindow, attributionAction } =
        settings;

      if (attributionAction === AttributionAction.ALL)
        dispatch.saveToast.showDone(
          `Attribution Model changed to ${attributionAction}`,
        );
      else
        dispatch.saveToast.showDone(
          `Attribution Model changed to ${attributionAction} - ${attributionDuration} ${attributionWindow.slice(
            0,
            -1,
          )} Window`,
        );
    },
  }),

  reducers: {
    storeConsumption(state, payload) {
      return {
        ...state,
        consumption: {
          ...payload,
          isFetching: false,
        },
      };
    },

    storePrivacy(state, payload) {
      return {
        ...state,
        privacy: {
          ...payload,
          isFetching: false,
        },
      };
    },

    storeReports(state, payload) {
      return {
        ...state,
        reports: {
          ...state.reports,
          ...payload,
        },
      };
    },

    storeAttributionWindow(state, payload) {
      return {
        ...state,
        attributionWindow: {
          ...payload,
          isFetching: false,
        },
      };
    },

    storeState(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },

    storeIntegrations(state, payload) {
      return {
        ...state,
        integrations: {
          ...payload,
        },
      };
    },
  },
});

export default settings;
