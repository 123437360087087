import colors from 'barn/tokens/colors';

export const bottombarLight = {
  bg: colors.greys[8],
  borderColor: colors.greys[5],
};

export const bottombarDark = {
  bg: colors.greys[11],
  borderColor: colors.greys[11],
};
