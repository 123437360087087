import barnTheme from 'barn/theme';
import componentsTheme from 'src/components/theme';
import moduleThemeLight from 'src/modules/light';
import moduleThemeDark from 'src/modules/dark';
import layoutTheme from 'src/layouts/theme';

const light = {
  ...barnTheme.light,
  ...componentsTheme.light,
  ...moduleThemeLight,
  ...layoutTheme.light,
  themeName: 'light',
};

const dark = {
  ...light,
  ...barnTheme.dark,
  ...moduleThemeDark,
  ...componentsTheme.dark,
  ...layoutTheme.dark,
  themeName: 'dark',
};

export const theme = {
  light,
  dark,
};

export type ThemeType = typeof light;
