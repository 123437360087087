import { automationFlowDark } from './automation-flows/theme';
import { automationDark } from './automation/theme';
import { campaignCreatorDark } from './campaign-creator/theme';
import { campaignsDark } from './campaigns/theme';
import { gettingStartedDark } from './getting-started/theme';
import { homeDark } from './home/theme';
import { integrationsDark } from './integrations/theme';
import { loginDark } from './login/theme';
import { notificationCreatorDark } from './notification-creator/theme';
import { optinsDark } from './optins/theme';
import { pricingDark } from './pricing/theme';
import { promotionPopupsDark } from './promotion-popups/theme';
import { segmentsDark } from './segments/theme';
import { settingsDark } from './settings/theme';
import { subscribersDark } from './subscribers/theme';
import { improvementChecklistDark } from './improvement-checklist/theme';

const dark = {
  automation: automationDark,
  automationFlow: automationFlowDark,
  campaignCreator: campaignCreatorDark,
  campaigns: campaignsDark,
  gettingStarted: gettingStartedDark,
  home: homeDark,
  integrations: integrationsDark,
  login: loginDark,
  notificationCreator: notificationCreatorDark,
  optins: optinsDark,
  pricing: pricingDark,
  promotionPopup: promotionPopupsDark,
  segments: segmentsDark,
  settings: settingsDark,
  subscribers: subscribersDark,
  improvementChecklist: improvementChecklistDark,
};

export default dark;
