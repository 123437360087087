import colors from 'barn/tokens/colors';

export const improvementChecklistLight = {
  sidebar: {
    bg: colors.greys[8],
  },
  item: {
    borderColor: colors.greys[4],
    linkColor: colors.greens[4],
  },
};

export const improvementChecklistDark = {
  ...improvementChecklistLight,
  sidebar: {
    bg: colors.greys[11],
  },
  item: {
    borderColor: colors.greys[17],
    linkColor: colors.greens[5],
  },
};
