import { createModel } from '@rematch/core';
import api from 'src/lib/api';
import { RootModel } from 'src/store/models';
import {
  TrackingElement,
  track,
  TrackingCategory,
  TrackingAction,
} from 'src/lib/tracking';
import Router from 'next/router';

type Time = null | Date;

export enum CampaignStatus {
  SUCCESSFUL = 'successful',
  PAUSED = 'paused',
}

export enum SendType {
  SCHEDULED = 'scheduled',
  NOW = 'now',
}

export enum FunctionMode {
  Create = 'create',
  Edit = 'edit',
}

export enum CampaignType {
  Regular = 'regular',
  FlashSale = 'flash',
}

export type DiscountCodeValidity = {
  type?: 'success' | 'failure' | 'warning';
  message: string | null;
  isChecking: boolean;
};

export interface CampaignCreatorState {
  id: number;
  mode: FunctionMode;
  isSaving: boolean;
  isCampaignPaused: boolean;
  // Remove all the unnecessary state variables
  isCreated: boolean;
  isCreating: boolean;
  inProgress: boolean;
  isDraft: boolean;
  isSavingDraft: boolean;
  campaignType: CampaignType;
  segment: {
    id: any;
    name: string;
    count: number;
  };
  sendType: 'now' | 'scheduled';
  scheduledTime: Time;
  endCampaignTime: Time;
  smartDelivery: boolean;
  error: string;
  discountCodeValidity: DiscountCodeValidity;
}

const initialState = (): CampaignCreatorState => ({
  isSaving: false,
  mode: FunctionMode.Create,
  id: null,
  isCampaignPaused: false,
  isCreated: false,
  isCreating: false,
  inProgress: true,
  campaignType: CampaignType.Regular,
  segment: null,
  isDraft: true,
  isSavingDraft: false,
  sendType: 'now',
  scheduledTime: null,
  endCampaignTime: null,
  smartDelivery: false,
  error: null,
  discountCodeValidity: {
    message: null,
    isChecking: false,
  },
});

const campaignCreator = createModel<RootModel>()({
  state: initialState(),

  reducers: {
    setState(state, payload: any) {
      return {
        ...state,
        ...payload,
      };
    },

    start(
      state: CampaignCreatorState,
      payload: Partial<CampaignCreatorState> | undefined,
    ) {
      return {
        ...state,
        inProgress: true,
        ...(payload || {}),
      };
    },

    shut() {
      return initialState();
    },

    clearDiscountCodeValidation(state: CampaignCreatorState) {
      return {
        ...state,
        discountCodeValidity: {
          message: null,
          isChecking: false,
        },
      };
    },

    loadState(state: CampaignCreatorState, payload: AnyObject) {
      return {
        ...state,
        ...payload,
      };
    },

    setProp(
      state: CampaignCreatorState,
      payload: { prop: string; value: any },
    ) {
      return {
        ...state,
        [payload.prop]: payload.value,
      };
    },
  },

  effects: dispatch => ({
    setCampaignErrorState({
      isDraft,
      error,
    }: {
      isDraft: boolean;
      error: string;
    }) {
      if (isDraft)
        this.setState({
          isSavingDraft: false,
          error: 'Changes not saved',
        });
      else
        this.setState({
          isCreating: false,
          error,
        });
    },

    async createDraftCampaign(_, rootState) {
      this.setState({ isSaving: true, isSavingDraft: true, error: null });

      const { notificationCreator, campaignCreator } = rootState;
      const { id } = campaignCreator;

      const requestData = {
        body: notificationCreator.message,
        should_shorten_url: true,
        title: notificationCreator.title,
        category: 'custom',
      };

      // save campaign draft state only when `message` and `title` are not empty. If sent empty to the backend then backend will throw an error 400 with message `Field can't be empty`
      if (requestData.body.trim() !== '' && requestData.title.trim() !== '') {
        const { data, error } = id
          ? await api.campaigns.editDraftCampaign(requestData, id)
          : await api.campaigns.createDraftCampaign(requestData);

        // this setState when API call fails for any reason (network error, server error, etc)
        if (error) {
          this.setState({
            isSaving: false,
            isSavingDraft: false,
            error: 'Changes not saved',
          });
          return;
        }

        // this setState is when API call is successful and we receive an id in the response
        this.setState({
          id: data.id,
          isSavingDraft: false,
          error: null,
          isSaving: false,
        });
      }
      // this setState is when API call is made with empty title or message
      else {
        this.setState({
          isSaving: false,
          isSavingDraft: false,
          error: 'Changes not saved',
        });
      }
    },

    async createOrEditCampaign(_, rootState) {
      this.setState({ isSaving: true });

      track({
        element: TrackingElement.SEND_CAMPAIGN_BUTTON,
        category: TrackingCategory.CAMPAIGN_CREATION,
        action: TrackingAction.CLICKED,
      });

      const { notificationCreator, campaignCreator } = rootState;
      const { id: campaignId } = campaignCreator;

      const requestData = {
        body: notificationCreator.message,
        should_shorten_url: true,
        title: notificationCreator.title,
        category: 'custom',
      };

      const { data, error } = campaignId
        ? await api.campaigns.editCampaign(requestData, campaignId)
        : await api.campaigns.createCampaign(requestData);

      if (error) {
        this.setState({ isSaving: false });
        dispatch.saveToast.showError(
          `Campaign couldn't be sent, please try again later. Contact support if issue persists.`,
        );
        return;
      }

      if (data.status === CampaignStatus.PAUSED) {
        this.setState({ isCampaignPaused: true });
        this.setState({ isSaving: false });
        return;
      }

      dispatch.saveToast.showDone('Campaign sent successfully');
      Router.push('/campaigns');
      this.shut();
    },

    async validateDiscountCode(discountCode: string) {
      this.setState({
        discountCodeValidity: { message: null, isChecking: true },
      });

      const res = await api.campaigns.validateDiscountCode(discountCode);

      if (!res.error) {
        this.setState({
          discountCodeValidity: { ...res.data, isChecking: false },
        });
        return;
      }

      this.setState({
        discountCodeValidity: {
          type: 'warning',
          message: 'Unable to validate discount code',
          isChecking: false,
        },
      });
    },
  }),
});

export default campaignCreator;
