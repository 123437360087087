// @ts-nocheck

export const loadAnalytics = () => {
  // Segment
  const segmentScript = `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${process.env.NEXT_PUBLIC_SEGMENT_API}";;analytics.SNIPPET_VERSION="4.15.3";
  analytics.load("${process.env.NEXT_PUBLIC_SEGMENT_API}", { integrations: { Intercom: false, FullStory: false } });
  analytics.page();
  }}();`;

  // Canny
  const cannyScript = `
  !function(w,d,i,s){function l(){if(!d.getElementById(i)){var f=d.getElementsByTagName(s)[0]
    ,e=d.createElement(s);e.type="text/javascript",e.async=!0,e.src="https://canny.io/sdk.js",
    f.parentNode.insertBefore(e,f)}}if("function"!=typeof w.Canny){var c=function(){c.q.push(arguments)};
    c.q=[],w.Canny=c,"complete"===d.readyState?l():w.attachEvent?w.attachEvent("onload",l)
    :w.addEventListener("load",l,!1)}}(window,document,"canny-jssdk","script");
  `;

  const segmentScriptTag = document.createElement('script');
  segmentScriptTag.type = 'text/javascript';
  segmentScriptTag.text = segmentScript;
  document.body.appendChild(segmentScriptTag);

  const cannyScriptTag = document.createElement('script');
  cannyScriptTag.type = 'text/javascript';
  cannyScriptTag.text = cannyScript;
  document.body.appendChild(cannyScriptTag);

  if (typeof window.Canny === 'function') {
    const changelogConfig = {
      appID: '63db5494fde0090f5d87c032',
      position: 'bottom',
      align: 'right',
    };

    window.Canny('initChangelog', changelogConfig);
    window.Canny('closeChangelog');
  }
};
