import { createModel } from '@rematch/core';
import _debounce from 'lodash.debounce';
import api from 'src/lib/api';
import store from 'src/store';
import { RootModel } from 'src/store/models';
import { AutomationConfig, PD_BIS_Report, Widget } from './types';

// debounced api call
const saveWidget = _debounce(
  (widget: any, successCallback: () => void, errorCallback: () => void) => {
    api.automation.backInStock.setWidget(widget).then(res => {
      if (!res.error) {
        successCallback();
      } else {
        errorCallback();
      }
    });
  },
  500,
);

interface BackInStockState {
  isFetching: boolean;
  config: {
    enabled: boolean;
    metadata: AutomationConfig['metadata'];
  };
  report: PD_BIS_Report;
  widget: Widget;
}

const initialState = (): BackInStockState => ({
  isFetching: true,
  config: {
    enabled: false,
    metadata: {
      title: '',
      description: '',
      redirect_url: '',
      actions: [],
    },
  },
  report: {
    pending: [],
    sent: [],
    summary: {
      attributed_revenue: 0,
      clicked: 0,
      delivered: 0,
      sent: 0,
      unique_variants: 0,
      in_queue: 0,
    },
  },
  widget: {
    enabled: false,
    metadata: {
      title: '',
      yes_button: {
        text: '',
      },
      post_subscription: {
        post_subscription_widget: {
          title: '',
        },
      },
    },
  },
});

const backInStockAutomation = createModel<RootModel>()({
  state: initialState(),

  effects: dispatch => ({
    async fetchConfig() {
      const { data, error } = await api.automation.backInStock.getConfig();

      if (!error) {
        this.storeState({ config: data });
      }
    },

    async fetchReportAndWidget() {
      this.setFetching(true);

      const { getReport, getWidget } = api.automation.backInStock;

      const [
        { data: report, error: reportError },
        { data: widget, error: widgetError },
      ] = await Promise.all([getReport(), getWidget()]);

      if (reportError || widgetError) {
        dispatch.saveToast.showError(
          'Your report and/or widget information could not be fetched. Please refresh and try again, or contact support if the issue persists',
        );
        return;
      }

      this.storeState({ report, widget });
    },

    async toggle(payload: boolean, rootState) {
      const state = rootState.backInStockAutomation;
      const newState = {
        ...state,
        widget: {
          ...state.widget,
          enabled: payload,
        },
      };

      this.storeState(newState);

      // make network request
      await api.automation.backInStock.setWidget(newState.widget).then(res => {
        if (!res.error) {
          store.dispatch.saveToast.showDone('Your changes have been saved');
        } else {
          store.dispatch.saveToast.showError(
            `Your back in stock automation status couldn't be saved; please try again or contact support if the issue persists.`,
          );
          this.storeState(state);
        }
      });
    },

    async saveConfigMetadata(
      payload: AnyObject,
      rootState,
    ): Promise<{ data: any; error: any }> {
      const state = rootState.backInStockAutomation;
      const newState = {
        ...state,
        config: {
          ...state.config,
          metadata: payload,
        },
      };

      // make network request
      return api.automation.backInStock.setConfig(newState.config).then(res => {
        if (!res.error) {
          this.storeState(newState);
          store.dispatch.saveToast.showDone('Your changes have been saved');
        } else {
          this.storeState(state);
          store.dispatch.saveToast.showError('Unable to save changes');
        }

        return res;
      });
    },

    async saveWidgetMetadata(payload: AnyObject, rootState) {
      const state = rootState.backInStockAutomation;
      const newState = {
        ...state,
        widget: {
          ...state.widget,
          metadata: payload,
        },
      };

      this.storeState(newState);

      // make network request with debounced function
      saveWidget(
        newState.widget,
        () => {
          store.dispatch.saveToast.showDone('Your changes have been saved');
        },
        () => {
          this.storeState(state);
        },
      );
    },
  }),

  reducers: {
    setFetching(state, isFetching) {
      return {
        ...state,
        isFetching,
      };
    },
    storeState(state, payload) {
      return {
        ...state,
        ...payload,
        isFetching: false,
      };
    },
  },
});

export default backInStockAutomation;
