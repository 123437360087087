import { getShopifySessionToken } from '../utils';
import { getAxios } from './axios';

const applyForTrial = async ({ subdomain, authToken }) => {
  const payload = {
    billing_plan_sku: 'business_tier1_19_trial14',
    utm_source: 'onboarding',
    utm_campaign: 'trial_page',
  };
  const sessionToken = await getShopifySessionToken();

  const options: any = {
    baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/v1`,
    headers: {
      authorization: `JWT ${authToken}`,
    },
  };

  if (sessionToken) {
    options.headers.SessionToken = `JWT ${sessionToken}`;
  }

  const axios = getAxios();

  const response: AnyObject = await axios.post(
    `/shopify/${subdomain}/billing/init/`,
    payload,
    options,
  );

  if (!response) return { error: true };

  return response.data;
};

export default { applyForTrial };
