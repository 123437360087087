import { createModel } from '@rematch/core';
import { DaysInSeconds, TimeFilter } from 'src/components/DaterangeFilter';
import api from 'src/lib/api';
import { RootModel } from 'src/store/models';

export interface State {
  isFetching: boolean;
  timeFilter: TimeFilter;
  allAutomations: {
    impressions: number;
    orders: number;
    revenue: number;
    clicks: number;
    ctr: number;
  };
  abandonedCart: {
    impressions: number;
    orders: number;
    revenue: number;
    clicks: number;
    ctr: number;
  };
  welcomeSeries: {
    impressions: number;
    clicks: number;
    orders: number;
    revenue: number;
    ctr: number;
  };
  browseAbandonment: {
    impressions: number;
    orders: number;
    revenue: number;
    clicks: number;
    ctr: number;
  };
  shippingNotifications: {
    delivered: number;
    clicked: number;
    orders: number;
    revenue: number;
  };
  backInStock: {
    delivered: number;
    clicked: number;
    orders: number;
    revenue: number;
  };
  priceDrop: {
    delivered: number;
    clicked: number;
    orders: number;
    revenue: number;
  };
}

const initialState: State = {
  isFetching: true,
  timeFilter: {
    type: 'fixed',
    value: DaysInSeconds.ALL_TIME,
    label: 'All Time',
  },
  allAutomations: {
    impressions: 0,
    orders: 0,
    revenue: 0,
    clicks: 0,
    ctr: 0,
  },
  abandonedCart: {
    impressions: 0,
    orders: 0,
    revenue: 0,
    clicks: 0,
    ctr: 0,
  },
  welcomeSeries: {
    impressions: 0,
    clicks: 0,
    orders: 0,
    revenue: 0,
    ctr: 0,
  },
  browseAbandonment: {
    impressions: 0,
    orders: 0,
    revenue: 0,
    clicks: 0,
    ctr: 0,
  },
  shippingNotifications: {
    delivered: 0,
    clicked: 0,
    orders: 0,
    revenue: 0,
  },
  backInStock: {
    delivered: 0,
    clicked: 0,
    orders: 0,
    revenue: 0,
  },
  priceDrop: {
    delivered: 0,
    clicked: 0,
    orders: 0,
    revenue: 0,
  },
};

const automationStats = createModel<RootModel>()({
  state: initialState,
  effects: dispatch => ({
    async fetchTotalAutomationStats() {
      this.setState({ isFetching: true });

      const { data, error } = await api.reports.fetchTotalAutomationStats();

      if (error) {
        dispatch.saveToast.showError(
          'Your automation statistics could not be fetched',
        );
        return;
      }

      this.setState({
        ...initialState,
        ...data,
        isFetching: false,
      });
    },
  }),
  reducers: {
    setState(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export default automationStats;
