import { createModel } from '@rematch/core';
// import api from 'src/lib/api';

export interface ImprovementChecklistState {
  isOpen: boolean;
  itemsStatus: {
    [key in
      | 'browserPrompt'
      | 'logo'
      | 'acr'
      | 'welcome'
      | 'heroImage'
      | 'ba']: boolean;
  };
}

const initialState = (): ImprovementChecklistState => ({
  isOpen: false,
  itemsStatus: {
    browserPrompt: false,
    logo: false,
    acr: false,
    welcome: false,
    heroImage: false,
    ba: false,
  },
});

const improvementChecklist = createModel()({
  state: initialState(),
  effects: {},
  reducers: {
    toggleSidebar(state: ImprovementChecklistState) {
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    },
    setState(state: ImprovementChecklistState, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    setDone(
      state,
      {
        key,
        done,
      }: { key: keyof ImprovementChecklistState['itemsStatus']; done: boolean },
    ) {
      return {
        ...state,
        itemsStatus: {
          ...state.itemsStatus,
          [key]: done,
        },
      };
    },
  },
});

export default improvementChecklist;
