import colors from 'barn/tokens/colors';

export const tableLight = {
  color: colors.greys[1],
  borderColor: colors.greys[5],
  tr: {
    bg: colors.greys[6],
  },
};

export const tableDark = {
  color: colors.greys[7],
  borderColor: colors.greys[16],
  tr: {
    bg: colors.greys[11],
  },
};
