import React from 'react';
import Styled from './styled';

const SessionValidation = () => (
  <Styled.Wrapper>
    <Styled.Message>
      This tab is unusable because you logged into some other store in another
      tab. <a href='/'>Click here to refresh</a>.
    </Styled.Message>
  </Styled.Wrapper>
);

export default SessionValidation;
