import { ACRConfig } from 'src/modules/automation/models/abandoned-cart';
import { get, post } from '../axios';

// endpoints
const CONFIG_ENDPOINT = `/config/abandoned-cart/`;

// query helpers
const getConfig = () => get(CONFIG_ENDPOINT);

const setConfig = (data: ACRConfig) => post(CONFIG_ENDPOINT, data);

export default {
  getConfig,
  setConfig,
};
