import { useDispatch, useSelector } from 'react-redux';
import { StoreState, Dispatch } from 'src/store';
import { useRouter } from 'next/router';
import {
  CampaignCreatorState,
  CampaignType,
} from 'src/modules/campaign-creator/models';
import RegexValidations from 'src/lib/regex-validations';
import useCreateNotification from 'src/hooks/use-create-notification';
import { MAX_MESSAGE_LENGTH } from 'src/modules/notification-creator/util/validations';
import { CampaignData } from 'src/modules/campaigns/models';
import { isValidSchedule } from '../modules/campaign-creator/util/validations';
import { useGlobalState } from './use-global-state';

const useCreateCampaign = () => {
  const dispatch: Dispatch = useDispatch();
  const router = useRouter();
  const { state: createNotificationState, actions: createNotificationActions } =
    useCreateNotification();
  const { state: gettingStartedState } = useGlobalState('gettingStarted');

  const queryParams = new URLSearchParams(window.location.search);
  const isTestRun =
    gettingStartedState.step !== null && queryParams.has('test-run');
  const { campaignCreator, notificationCreator } = dispatch;

  const getPropSetter = (prop: string) => (value: any) => {
    campaignCreator.setProp({ value, prop });
  };

  const state = useSelector(
    (storeState: StoreState) => storeState.campaignCreator,
  );

  const actions = {
    start: (value?: Partial<CampaignCreatorState>) => {
      campaignCreator.shut(); // shut if not already done
      campaignCreator.start(value);
    },

    shut: campaignCreator.shut,
    setCampaignType: getPropSetter('campaignType'),
    setSegment: getPropSetter('segment'),
    setSendType: getPropSetter('sendType'),
    setScheduledTime: getPropSetter('scheduledTime'),
    setEndCampaignTime: getPropSetter('endCampaignTime'),
    setSmartDelivery: getPropSetter('smartDelivery'),
    setError: getPropSetter('error'),
    createOrEditCampaign: campaignCreator.createOrEditCampaign,
    validateDiscountCode: campaignCreator.validateDiscountCode,

    duplicate: ({ title, body }: CampaignData) => {
      notificationCreator.loadState({
        title,
        message: body,
        should_shorten_url: true,
      });

      campaignCreator.shut();
      campaignCreator.start();
      campaignCreator.setProp({ prop: 'isDraft', value: true }); // set isDraft to true so that it gets debounced and saved when changes are made

      router.push('/campaigns/create');
    },

    edit: ({ title, body, id }: CampaignData) => {
      notificationCreator.loadState({
        title,
        message: body,
        should_shorten_url: true,
      });
      // set campaign id to make a patch call and sending the id in the patch call
      campaignCreator.setProp({ prop: 'id', value: id });
      campaignCreator.start();
      router.push('/campaigns/create');
    },

    handlePrevious: (newStep: number) => {
      router.replace(
        '/campaigns/create/[step]',
        `/campaigns/create/${newStep}${isTestRun ? '?test-run=true' : ''}`,
      );
    },

    handleNext: (newStep: number) => {
      const {
        title,
        primaryLink,
        discountCode,
        titleError,
        primaryLinkError,
        discountCodeError,
        buttons,
        message,
      } = createNotificationState;

      if (isValidSchedule(state)) {
        if (newStep === 3) {
          if (
            title &&
            RegexValidations.url(primaryLink) &&
            message.length <= MAX_MESSAGE_LENGTH &&
            // Continue if no button is found for which the title or the link is invalid
            !buttons.find(
              button => !button.title || !RegexValidations.url(button.link),
            )
          ) {
            router.replace(
              '/campaigns/create/[step]',
              `/campaigns/create/${newStep}${
                isTestRun ? '?test-run=true' : ''
              }`,
            );
            return;
          }

          // If there is error in step 2 go to step 2
          if (!title && !titleError)
            createNotificationActions.setTitleError(true);
          if (!RegexValidations.url(primaryLink) && !primaryLinkError)
            createNotificationActions.setPrimaryLinkError(true);

          if (RegexValidations.url(discountCode) && !discountCodeError) {
            createNotificationActions.setDiscountCodeError(true);
          }

          if (buttons)
            buttons.forEach(button =>
              createNotificationActions.updateButton({
                ...button,
                titleError: !button.title,
                linkError: !RegexValidations.url(button.link),
              }),
            );

          router.replace(
            '/campaigns/create/[step]',
            `/campaigns/create/2${isTestRun ? '?test-run=true' : ''}`,
          );
        } else {
          router.replace(
            '/campaigns/create/[step]',
            `/campaigns/create/${newStep}${isTestRun ? '?test-run=true' : ''}`,
          );
        }
      }
    },
  };

  return {
    CampaignType,
    actions,
    state,
  };
};

export default useCreateCampaign;
