import Tooltip from 'rc-tooltip';
import { useRef, useLayoutEffect, ReactNode } from 'react';
import noop from 'lodash.noop';

interface PopoverProps {
  placement: string;
  destroyTooltipOnHide?: boolean;
  overlay: ReactNode;
  trigger?: any;
  overlayStyle?: AnyObject;
  onVisibleChange?: (visible: boolean) => void;
  isVisible?: boolean;
  setVisibility?: any;
  canClose?: (e: any) => boolean;
  onAfterClose?: () => void;
  align?: any;
  overlayClassName?: string | null;
}
const Popover: React.FunctionComponent<PopoverProps> = ({
  placement,
  overlay,
  destroyTooltipOnHide,
  onAfterClose,
  trigger = ['click'],
  overlayStyle = {},
  onVisibleChange = noop,
  isVisible = false,
  setVisibility = noop,
  canClose = () => true,
  children,
  align = {},
  overlayClassName = null,
}) => {
  const overlayRef = useRef(null);

  useLayoutEffect(() => {
    if (!isVisible) {
      return;
    }

    const handleClick = (e: any) => {
      // eslint-disable-next-line
      if (
        isVisible &&
        overlayRef.current &&
        overlayRef.current.contains(e.target)
      ) {
        // if click is inside popover content, return
        return;
      }

      if (canClose(e)) {
        setVisibility(false);

        if (typeof onAfterClose === 'function') {
          onAfterClose();
        }
      }
    };

    document.addEventListener('click', handleClick, false);

    // eslint-disable-next-line
    return () => {
      document.removeEventListener('click', handleClick, false);
    };
  });

  return (
    <Tooltip
      placement={placement}
      trigger={trigger}
      overlay={
        <div ref={overlayRef} id='popover-overlay'>
          {overlay}
        </div>
      }
      overlayStyle={overlayStyle}
      onVisibleChange={onVisibleChange}
      visible={isVisible}
      align={align}
      overlayClassName={overlayClassName}
      destroyTooltipOnHide={destroyTooltipOnHide}
    >
      {children as any}
    </Tooltip>
  );
};

export default Popover;
