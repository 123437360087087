import colors from 'barn/tokens/colors';

export const checkboxLight = {
  checkmark: {
    borderColor: colors.greys[1],
    checked: {
      borderColor: colors.greens[4],
    },
    disabled: {
      borderColor: colors.greys[1],
    },
    after: {
      bg: colors.greens[4],
    },
  },
};

export const checkboxDark = {
  checkmark: {
    borderColor: colors.greys[4],
    checked: {
      borderColor: colors.greens[9],
    },
    disabled: {
      borderColor: colors.greys[3],
    },
    after: {
      bg: colors.greens[9],
    },
  },
};
