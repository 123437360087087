import colors from 'barn/tokens/colors';

export const settingsLight = {
  bg: colors.greys[6],
  borderColor: colors.greys[5],
  color: colors.greys[1],
  widget: {
    bg: colors.greys[6],
  },
  tag: {
    color: colors.greys[8],
    bg: colors.blues[5],
  },
  bold: {
    color: colors.greys[0],
  },
  reportsTag: {
    color: colors.greens[4],
    bg: colors.greens[8],
  },
  warning: {
    color: colors.greys[1],
    bg: colors.yellows[4],
  },
  card: {
    bg: colors.greys[8],
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.12)',
  },
};

export const settingsDark = {
  ...settingsLight,
  bg: colors.greys[11],
  borderColor: colors.greys[13],
  color: colors.greys[7],
  widget: {
    bg: colors.greys[12],
  },
  card: {
    bg: colors.greys[11],
    boxShadow: 'none',
  },
  bold: {
    color: colors.greys[7],
  },
  warning: {
    bg: colors.yellows[6],
    color: colors.greys[0],
  },
};
