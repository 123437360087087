import { ReactElement } from 'react';
import styled from 'styled-components';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import space from 'barn/tokens/space';
import { Text } from 'barn/components/Text';
import { SimpleInput } from '../form/input';

const Wrapper = styled.div`
  .react-datepicker__day-names,
  .react-datepicker__day--outside-month {
    display: none;
  }

  .react-datepicker__header--custom {
    padding: ${space.padding[2]};
  }
`;

interface DatepickerProps extends ReactDatePickerProps<any, boolean> {
  selected: any;
  onChange: (date: Date) => void;
  placeholder?: string;
  customInput?: ReactElement;
  dayOnly?: boolean;
  testHandle?: string;
}

const Datepicker = ({
  selected,
  onChange,
  placeholder = '',
  customInput,
  dayOnly = false,
  testHandle = '',
  ...otherProps
}: DatepickerProps) => {
  return dayOnly ? (
    <Wrapper data-test={testHandle}>
      <DatePicker
        renderCustomHeader={() => (
          <Text weight={500} variant='primary'>
            Day
          </Text>
        )}
        selected={new Date(new Date('2020-11-01').setDate(selected))}
        onChange={date => onChange((date[0] || date).getDate())}
        showDisabledMonthNavigation
        customInput={customInput || <SimpleInput />}
        dateFormat='do'
        strictParsing
      />
    </Wrapper>
  ) : (
    <div data-test={testHandle}>
      <DatePicker
        selected={selected}
        onChange={onChange}
        customInput={customInput || <SimpleInput />}
        placeholderText={placeholder}
        strictParsing
        {...otherProps}
      />
    </div>
  );
};

export default Datepicker;
