import React, { ReactElement, useEffect, RefObject } from 'react';
import { v1 as uuidv1 } from 'uuid';
import noop from 'lodash.noop';
import {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  IconWrapper,
  StyledDropdownButton,
} from './styled';
import ChevronUpIcon from '../../icons/ChevronUp';
import ChevronDownIcon from '../../icons/ChevronDown';
import Spinner from '../Spinner';

const StyledButtons = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
  tertiary: TertiaryButton,
};

export type ButtonSize = 'sm' | 'md';
export type ButtonAppearance =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'download';
export type ButtonVariant = 'default' | 'info' | 'danger' | 'success';

interface ButtonProps {
  as?: string;
  style?: object;
  onClick?: ReactEventHandler;
  icon?: ReactElement;
  iconOn?: string;
  active?: boolean;
  appearance?: ButtonAppearance;
  variant?: ButtonVariant;
  disabled?: boolean;
  ref?: any;
  id?: string;
  type?: string;
  isLoading?: boolean;
  ariaLabel?: string;
  testHandle?: string;
  size?: ButtonSize;
  justify?: string;
  textWrap?: string;
}

const Button: React.FunctionComponent<ButtonProps> = ({
  id = `${uuidv1()}`,
  as = 'button',
  ref = null,
  style = {},
  onClick = noop,
  icon = null,
  active = false,
  iconOn = 'left',
  appearance = 'primary',
  variant = 'default',
  disabled = false,
  size = 'md',
  type = 'button',
  isLoading = false,
  justify = 'center',
  ariaLabel,
  testHandle,
  children,
  textWrap,
}) => {
  const iconOnLeft = iconOn === 'left';
  const StyledButton = StyledButtons[appearance][variant];

  return (
    <StyledButton
      as={as}
      type={type}
      onClick={onClick}
      style={style}
      disabled={disabled || isLoading}
      ref={ref}
      id={id}
      aria-label={ariaLabel}
      data-test={testHandle}
      size={size}
      active={active}
      justify={justify}
      whiteSpace={textWrap}
    >
      {icon && iconOnLeft && (
        <IconWrapper iconOn='left' noSpacing={!children}>
          {icon}
        </IconWrapper>
      )}
      {children}
      {icon && !iconOnLeft && (
        <IconWrapper iconOn='right' noSpacing={!children}>
          {icon}
        </IconWrapper>
      )}
      {isLoading ? (
        <div style={{ marginLeft: 6 }}>
          <Spinner small currentColor />
        </div>
      ) : null}
    </StyledButton>
  );
};

interface DropdownButtonProps {
  isOpen?: boolean;
  onClick?: ReactEventHandler;
  closeDropdown?: () => void;
  testHandle?: string | null;
  disabled?: boolean;
  size?: ButtonSize;
  fullWidth?: boolean;
  children: React.ReactChild;
}

export const DropdownButton = React.forwardRef<
  HTMLButtonElement,
  DropdownButtonProps
>(
  (
    {
      children,
      onClick,
      isOpen = false,
      closeDropdown,
      testHandle = null,
      disabled,
      size = 'md',
      fullWidth = false,
    },
    ref,
  ) => {
    /**
     * The dropdown is positioned absolutely inside the body tag.
     *
     * If a nested container has a scroll, the dropdown's positioning will
     * mirror a fixed positioned element.
     *
     * This code will scan for all such containers which will need to have
     * this attr, and attach a scroll listener to close all rendered dropdowns.
     */

    const buttonRef = ref as RefObject<HTMLButtonElement>;

    useEffect(() => {
      const button = buttonRef.current;

      const scrollingParent = button?.closest(
        `[data-select-hide-on-scroll="true"]`,
      );

      if (!scrollingParent) return undefined;

      scrollingParent.addEventListener('scroll', closeDropdown);
      return () => scrollingParent.removeEventListener('scroll', closeDropdown);
    }, []);

    return (
      <StyledDropdownButton
        data-test={testHandle}
        type='button'
        size={size}
        onClick={onClick}
        ref={buttonRef}
        disabled={disabled}
        fullWidth={fullWidth}
      >
        {children}
        <p className='icon'>
          {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </p>
      </StyledDropdownButton>
    );
  },
);

export default Button;
