import { v1 as uuidv1 } from 'uuid';
import colors from 'barn/tokens/colors';

const Document = ({ color = colors.greys[2], style = {} }) => {
  const id = uuidv1();

  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' style={style}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.1652 5.4344L12.9652 2.2344C12.8156 2.084 12.6116 2 12.3996 2H4.39961C3.95801 2 3.59961 2.3584 3.59961 2.8V17.2C3.59961 17.6416 3.95801 18 4.39961 18H15.5996C16.0412 18 16.3996 17.6416 16.3996 17.2V6C16.3996 5.788 16.3156 5.584 16.1652 5.4344ZM14.7996 16.4H5.19961V3.6H12.0684L14.7996 6.3312V16.4ZM12.3996 12.4H7.59961C7.15801 12.4 6.79961 12.7584 6.79961 13.2C6.79961 13.6416 7.15801 14 7.59961 14H12.3996C12.8412 14 13.1996 13.6416 13.1996 13.2C13.1996 12.7584 12.8412 12.4 12.3996 12.4ZM6.79961 10C6.79961 10.4416 7.15801 10.8 7.59961 10.8H12.3996C12.8412 10.8 13.1996 10.4416 13.1996 10C13.1996 9.5584 12.8412 9.2 12.3996 9.2H7.59961C7.15801 9.2 6.79961 9.5584 6.79961 10ZM7.59961 7.6H8.39961C8.84121 7.6 9.19961 7.2416 9.19961 6.8C9.19961 6.3584 8.84121 6 8.39961 6H7.59961C7.15801 6 6.79961 6.3584 6.79961 6.8C6.79961 7.2416 7.15801 7.6 7.59961 7.6Z'
        fill='#31373D'
      />
      <mask
        id={`maskDocument${id}`}
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='3'
        y='2'
        width='14'
        height='16'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16.1652 5.4344L12.9652 2.2344C12.8156 2.084 12.6116 2 12.3996 2H4.39961C3.95801 2 3.59961 2.3584 3.59961 2.8V17.2C3.59961 17.6416 3.95801 18 4.39961 18H15.5996C16.0412 18 16.3996 17.6416 16.3996 17.2V6C16.3996 5.788 16.3156 5.584 16.1652 5.4344ZM14.7996 16.4H5.19961V3.6H12.0684L14.7996 6.3312V16.4ZM12.3996 12.4H7.59961C7.15801 12.4 6.79961 12.7584 6.79961 13.2C6.79961 13.6416 7.15801 14 7.59961 14H12.3996C12.8412 14 13.1996 13.6416 13.1996 13.2C13.1996 12.7584 12.8412 12.4 12.3996 12.4ZM6.79961 10C6.79961 10.4416 7.15801 10.8 7.59961 10.8H12.3996C12.8412 10.8 13.1996 10.4416 13.1996 10C13.1996 9.5584 12.8412 9.2 12.3996 9.2H7.59961C7.15801 9.2 6.79961 9.5584 6.79961 10ZM7.59961 7.6H8.39961C8.84121 7.6 9.19961 7.2416 9.19961 6.8C9.19961 6.3584 8.84121 6 8.39961 6H7.59961C7.15801 6 6.79961 6.3584 6.79961 6.8C6.79961 7.2416 7.15801 7.6 7.59961 7.6Z'
          fill='white'
        />
      </mask>
      <g mask={`url(#maskDocument${id})`}>
        <path className='iconColor' d='M2 2H18.8V18.8H2V2Z' fill={color} />
      </g>
    </svg>
  );
};

export default Document;
