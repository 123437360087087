import { getStorage } from 'src/lib/storage';
import { get, post } from './axios';

export const getCommunicationEmails = () =>
  get(`/dashboard/preferences/?keys=communication_emails`).then(res => ({
    data: res.data[0].value,
    error: res.error,
  }));

const fetchStoreScreenshot = () =>
  get(`/dashboard/preferences/?keys=store_screenshot`).then(res => ({
    data: res.data[0].value,
    error: res.error,
  }));

const setGettingStarted = async (value: string) => {
  const { error } = await post('/dashboard/preferences/', {
    preferences: [
      {
        key: 'getting_started',
        value,
      },
    ],
  });

  if (error) throw Error('Something went wrong. Please try again.');

  // update session
  const storage = getStorage();
  const loggedInUser = storage.get('user');
  if (loggedInUser?.website) {
    loggedInUser.website.getting_started = value;
    storage.set('user', loggedInUser);
  }

  return {
    data: {
      getting_started: value,
    },
    error: null,
  };
};

const setUserPreference = async ({
  key,
  value,
}: {
  key: string;
  value: any;
}) => {
  const { error } = await post('/dashboard/preferences/', {
    preferences: [{ key, value }],
  });

  if (error) return { error };

  const storage = getStorage();
  const user = storage.get('user');
  user.website[key] = value;
  storage.set('user', user);

  return { error: null };
};

export default {
  getCommunicationEmails,
  fetchStoreScreenshot,
  setGettingStarted,
  setUserPreference,
};
