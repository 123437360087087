import { v1 as uuidv1 } from 'uuid';
import colors from 'barn/tokens/colors';

const Optins = ({ color = colors.greys[2], style = {} }) => {
  const id = uuidv1();

  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' style={style}>
      <mask
        id={`maskOptins${id}`}
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='2'
        y='2'
        width='16'
        height='16'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.4 13.2H7.6H3.6V11.6H16.4V13.2H12.4ZM11.9152 16.4H8.0848C8.2136 15.9824 8.3208 15.4544 8.3704 14.8H11.6296C11.6792 15.4544 11.7864 15.9824 11.9152 16.4ZM3.6 10H16.4V3.6H3.6V10ZM17.2 2H2.8C2.3584 2 2 2.3576 2 2.8V14C2 14.4424 2.3584 14.8 2.8 14.8H6.7608C6.6352 16.1256 6.232 16.6344 6.2336 16.6344H6.2344C6.0056 16.8632 5.9368 17.2072 6.0608 17.5064C6.1848 17.8056 6.4768 18 6.8 18H13.2C13.5128 18 13.7872 17.8064 13.9176 17.5216C14.048 17.2368 13.9896 16.8912 13.7848 16.6544C13.78 16.6488 13.3664 16.1416 13.2392 14.8H17.2C17.6416 14.8 18 14.4424 18 14V2.8C18 2.3576 17.6416 2 17.2 2Z'
          fill='#31373D'
        />
      </mask>
      <g mask={`url(#maskOptins${id})`}>
        <rect
          className='iconColor'
          width='16.8'
          height='16.8'
          transform='translate(2 2)'
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Optins;
