import { get, post } from './axios';

export const getAll = () => {
  return get('/dashboard/integrations/');
};

export const saveConfig = ({ handle, config }) => {
  return post(`/dashboard/integrations/${handle}/`, config);
};

export default {
  getAll,
  saveConfig,
};
