import { FunctionComponent } from 'react';
import { ButtonAppearance, ButtonVariant } from 'barn/components/Button';
import { Owly100 } from './Owls/Owly100Percent';
import { Owly70 } from './Owls/Owly70Percent';
import { Owly90 } from './Owls/Owly90Percent';

export enum Action {
  CLOSE = 'close',
  UPGRADE = 'upgrade',
  TOPUP = 'topup',
}

export type MetadataProps = {
  image: FunctionComponent;
  title: string;
  subtitle: string;
  desc: string;
  testHandle: string;
  buttons: Array<{
    text: string;
    action: Action;
    appearance: ButtonAppearance;
    variant: ButtonVariant;
  }>;
};

type MetaData = {
  [index: string]: {
    [index: number]: MetadataProps;
  };
};

export const metadata: MetaData = {
  free: {
    70: {
      image: Owly70,
      title: 'Running Low!',
      subtitle: "You've consumed 70% of your impressions for this month.",
      desc: 'Upgrade to continue sending campaigns and automated notifications.',
      testHandle: 'free-70',
      buttons: [
        {
          text: 'Dismiss',
          action: Action.CLOSE,
          appearance: 'secondary' as ButtonAppearance,
          variant: 'default' as ButtonVariant,
        },
        {
          text: 'Upgrade',
          action: Action.UPGRADE,
          appearance: 'primary' as ButtonAppearance,
          variant: 'info' as ButtonVariant,
        },
      ],
    },
    90: {
      image: Owly90,
      title: 'Attention!',
      subtitle: "You don't have enough impressions for this month.",
      desc: 'Upgrade to continue sending campaigns and automated notifications.',
      testHandle: 'free-90',
      buttons: [
        {
          text: 'Dismiss',
          action: Action.CLOSE,
          appearance: 'secondary' as ButtonAppearance,
          variant: 'default' as ButtonVariant,
        },
        {
          text: 'Upgrade',
          action: Action.UPGRADE,
          appearance: 'primary' as ButtonAppearance,
          variant: 'info' as ButtonVariant,
        },
      ],
    },
    100: {
      image: Owly100,
      title: 'Notifications Paused',
      subtitle: "You've run out of impressions for this month.",
      desc: 'Upgrade to continue sending campaigns and automated notifications.',
      testHandle: 'free-100',
      buttons: [
        {
          text: 'Dismiss',
          action: Action.CLOSE,
          appearance: 'secondary' as ButtonAppearance,
          variant: 'default' as ButtonVariant,
        },
        {
          text: 'Upgrade',
          action: Action.UPGRADE,
          appearance: 'primary' as ButtonAppearance,
          variant: 'info' as ButtonVariant,
        },
      ],
    },
  },

  nonFree: {
    70: {
      image: Owly70,
      title: 'Running Low!',
      subtitle: "You've consumed 70% of your impressions for this month.",
      desc: 'Upgrade or add impressions to continue sending campaigns and automated notifications.',
      testHandle: 'non-free-70',
      buttons: [
        {
          text: 'Upgrade',
          action: Action.UPGRADE,
          appearance: 'secondary' as ButtonAppearance,
          variant: 'default' as ButtonVariant,
        },
        {
          text: 'Add Impressions',
          action: Action.TOPUP,
          appearance: 'primary' as ButtonAppearance,
          variant: 'default' as ButtonVariant,
        },
      ],
    },
    90: {
      image: Owly90,
      title: 'Attention!',
      subtitle: "You don't have enough impressions for this month.",
      desc: 'Upgrade or add impressions to continue sending campaigns and automated notifications.',
      testHandle: 'non-free-90',
      buttons: [
        {
          text: 'Upgrade',
          action: Action.UPGRADE,
          appearance: 'secondary' as ButtonAppearance,
          variant: 'default' as ButtonVariant,
        },
        {
          text: 'Add Impressions',
          action: Action.TOPUP,
          appearance: 'primary' as ButtonAppearance,
          variant: 'default' as ButtonVariant,
        },
      ],
    },
    100: {
      image: Owly100,
      title: 'Notifications Paused',
      subtitle: "You've run out of impressions for this month.",
      desc: 'Upgrade or add impressions to continue sending campaigns and automated notifications.',
      testHandle: 'non-free-100',
      buttons: [
        {
          text: 'Upgrade',
          action: Action.UPGRADE,
          appearance: 'secondary' as ButtonAppearance,
          variant: 'default' as ButtonVariant,
        },
        {
          text: 'Add Impressions',
          action: Action.TOPUP,
          appearance: 'primary' as ButtonAppearance,
          variant: 'default' as ButtonVariant,
        },
      ],
    },
  },
};
