import React, { useState, useEffect, useRef } from 'react';
import colors from 'barn/tokens/colors';
import LockIcon from 'barn/icons/Lock';
import { DropdownButton, ButtonSize } from 'barn/components/Button';
import Popover from 'barn/components/Popover';
import noop from 'lodash.noop';
import tokens from 'barn/tokens';
import {
  StyledSearch,
  StyledList,
  StyledListItemText,
  StyledGroupLabel,
  StyledGroupContainer,
} from '../styled';
import Styled from './styled';

interface SelectDropdownProps {
  data: AnyObject[];
  onChange?: (selectedItem: string) => void;
  name: string;
  selectedItem: string | number;
  primaryKey?: string;
  labelKey?: string;
  withSearch?: boolean;
  searchPlaceholder?: string;
  title: string;
  placement?: string;
  canClose?: (e: any) => boolean;
  onAfterClose?: () => void;
  CustomButton?: any;
  avoidTypeCasting?: boolean /* avoid selected value to be typecasted to string */;
  groupBy?: string;
  redirect?: AnyObject;
  disabled?: boolean;
  buttonSize?: ButtonSize;
  fullWidth?: boolean;
}

export const SelectDropdown: React.FC<SelectDropdownProps> = ({
  children,
  data,
  onChange = noop,
  name,
  onAfterClose = noop,
  selectedItem = null,
  primaryKey = 'value',
  labelKey = 'value',
  withSearch = true,
  searchPlaceholder = 'Search',
  title = 'Select Dropdown',
  placement = 'bottomLeft',
  canClose = () => true,
  CustomButton = DropdownButton,
  avoidTypeCasting,
  groupBy,
  redirect,
  disabled,
  buttonSize = 'md',
  fullWidth = false,
}) => {
  const [isPopoverVisible, setPopoverVisibility] = useState(false);
  const [items, setItems] = useState(data);
  const buttonRef = useRef(null);

  useEffect(() => {
    setItems(data);
  }, [data]);

  const handleSearch: ReactEventHandler = event => {
    const value = event.target.value.trim();

    /* eslint-disable prefer-template */
    const filteredItems: AnyObject[] = value
      ? data.filter((item: AnyObject) =>
          new RegExp(value, 'i').test(String(item[labelKey])),
        )
      : data;

    setItems(filteredItems);
  };

  /**
   * Group items by the value of their [groupBy] prop
   */
  const getItemsToShow = () => {
    if (!groupBy) return items;

    /* eslint-disable no-param-reassign */
    return items.reduce((groups, item) => {
      groups[item[groupBy]] = (groups[item[groupBy]] || []).concat(item);
      return groups;
    }, {});
  };

  const handleItemSelect: any = event => {
    onChange(event.target.value);
    setPopoverVisibility(false);
    onAfterClose();
  };

  const getButtonTitle = () => {
    const selectedItemObject =
      data.find(item => item[primaryKey] === selectedItem) || {};
    return selectedItemObject[labelKey] || title;
  };

  const getOverlayContent = () => {
    const itemsToShow = getItemsToShow();

    const getSelectDropdownItem = (item: AnyObject) => (
      <SelectDropdownItem
        {...{
          item,
          name,
          labelKey,
          primaryKey,
          selectedItem,
          avoidTypeCasting,
          key: item[primaryKey],
          onSelect: handleItemSelect,
        }}
      />
    );

    return (
      <div
        style={{
          width: fullWidth ? buttonRef.current?.clientWidth : 'auto',
        }}
      >
        {withSearch && (
          <StyledSearch
            placeholder={`     ${searchPlaceholder}`}
            onChange={handleSearch}
          />
        )}

        {!items?.length && !children && (
          <div style={{ padding: tokens.space.padding[4], fontSize: '14px' }}>
            No options available
          </div>
        )}

        <StyledList>
          {!groupBy && [
            redirect && (
              <SelectDropdownItem {...redirect} labelKey={labelKey} />
            ),
            items?.map(getSelectDropdownItem),
          ]}

          {groupBy &&
            items && [
              redirect && (
                <SelectDropdownItem {...redirect} labelKey={labelKey} />
              ),
              Object.keys(itemsToShow).map((groupKey: string) => (
                <StyledGroupContainer key={groupKey}>
                  <StyledGroupLabel>{groupKey}</StyledGroupLabel>
                  {itemsToShow[groupKey].map(getSelectDropdownItem)}
                </StyledGroupContainer>
              )),
            ]}

          {children}
        </StyledList>
      </div>
    );
  };

  return (
    <Popover
      placement={placement}
      overlay={getOverlayContent()}
      isVisible={isPopoverVisible}
      setVisibility={setPopoverVisibility}
      canClose={canClose}
      onAfterClose={onAfterClose}
      overlayClassName='barn-select'
    >
      <CustomButton
        ref={buttonRef}
        fullWidth={fullWidth}
        isOpen={isPopoverVisible}
        testHandle={name}
        closeDropdown={() => setPopoverVisibility(false)}
        onClick={e => {
          setPopoverVisibility(!isPopoverVisible);
          if (isPopoverVisible) onAfterClose();

          // Stop bubble up to Popover which triggers visibility close
          e.stopPropagation();
        }}
        disabled={disabled}
        size={buttonSize}
      >
        {getButtonTitle()}
      </CustomButton>
    </Popover>
  );
};

interface SelectDropdownItemProps {
  item?: any;
  primaryKey?: string;
  labelKey: string;
  selectedItem?: any;
  name?: string;
  onSelect?: (any) => void;
  avoidTypeCasting?: boolean;
  icon?: any;
  justify?: string;
  color?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const SelectDropdownItem: React.FC<SelectDropdownItemProps> = ({
  item,
  primaryKey,
  labelKey,
  selectedItem,
  name,
  onSelect,
  avoidTypeCasting,
  icon: Icon,
  justify,
  color,
  onClick,
  disabled,
}) => {
  return (
    <StyledListItemText
      key={item[primaryKey]}
      className={item.disabled || item.locked || disabled ? 'disabled' : ''}
      onClick={() => {
        if (onClick) return onClick();

        return item.locked && item.onClick && item.onClick();
      }}
    >
      <Styled.Radio
        justify-content={justify}
        color={color}
        data-test={`${name}-${(item[labelKey] || '')
          .split(' ')
          .join('-')
          .toLowerCase()}`}
      >
        {Icon && (
          <Styled.IconWrapper iconOn='left' noSpacing={false}>
            <Icon color={color} />
          </Styled.IconWrapper>
        )}
        <input
          type='radio'
          name={name}
          value={item[primaryKey]}
          onChange={e =>
            avoidTypeCasting
              ? // HACK: simulate event object because the handler expects that :P
                onSelect({ target: { value: item[primaryKey] } })
              : onSelect(e)
          }
          checked={item[primaryKey] === selectedItem}
          disabled={item.disabled === true || item.locked === true}
        />
        <span className={disabled ? 'mr-40' : ''}>{item[labelKey]}</span>
        {!Icon && <span className='checkmark' />}
        {(item.locked === true || disabled) && (
          <LockIcon color={colors.blues[5]} />
        )}
      </Styled.Radio>
    </StyledListItemText>
  );
};

export { StyledListItemText as ListItemText, Styled as SC };
export default SelectDropdown;
