import React from 'react';
import { Text } from '../Text';
import TickIcon from '../../icons/Tick';
import { StyledTickCircleHolder, StyledTickCircle } from './styled';

export type TickCircleSize = 'sm' | 'md';

interface TickCircleProps {
  active?: boolean;
  done?: boolean;
  label?: string;
  size?: TickCircleSize;
}

const textSize = {
  sm: 1,
  md: 3,
};

const circleSize = {
  sm: '20px',
  md: '28px',
};

const TickCircle: React.FC<TickCircleProps> = ({
  active = false,
  done = false,
  label = null,
  size = 'md',
}) => {
  let klass = '';

  if (active) klass += 'active';
  if (done) klass += 'complete';
  if (label) klass += '  withLabel';

  return (
    <StyledTickCircleHolder>
      <StyledTickCircle className={klass} size={circleSize[size]}>
        {done && <TickIcon />}
      </StyledTickCircle>
      {label && (
        <Text size={textSize[size]} tag='div'>
          {label}
        </Text>
      )}
    </StyledTickCircleHolder>
  );
};

export default TickCircle;
