import { createModel } from '@rematch/core';
import { DaysInSeconds, TimeFilter } from 'src/components/DaterangeFilter';
import Router from 'next/router';
import api from 'src/lib/api';
import store from 'src/store';
import {
  TrackingElement,
  track,
  TrackingCategory,
  TrackingAction,
  TrackingLocation,
} from 'src/lib/tracking';
import { RootModel } from 'src/store/models';
import { ACR_BA_Report, OrderData } from './types';

export type ACRConfig = {
  enabled: boolean;
  delivery_time: number;
  message: string;
  minimum_cart_value: number;
  should_shorten_url: boolean;
  discount_code: string | null;
  tags: Array<string>;
};

interface ACRState {
  isFetching: boolean;
  config: ACRConfig;
  notificationInEdit: number;
  timeFilter: TimeFilter;
  reports: ACR_BA_Report;
  orderData: OrderData;
}

export const defaultTemplate = {
  title: 'Discount',
  message: `Hi there, we noticed you didn't finish your order. You can checkout at {{checkout_link}}. We look forward to serving you. Thanks!`,
  minimum_cart_value: 1,
  delivery_time: 300,
  should_shorten_url: true,
  discount_code: null,
  enabled: false,
  tags: [],
};

const initialState = (): ACRState => ({
  isFetching: true,
  config: null,
  notificationInEdit: null,
  timeFilter: {
    type: 'fixed',
    value: DaysInSeconds.ALL_TIME,
    label: 'All Time',
  },
  reports: {
    isFetching: true,
    total: {
      impressions: 0,
      clicks: 0,
      revenue: 0,
      cartsRecovered: 0,
    },
    reminders: [],
  },
  orderData: {
    recordsPerPage: 10,
    totalCount: 0,
    hasMore: false,
    orders: [],
  },
});

const abandonedCartAutomation = createModel<RootModel>()({
  state: initialState(),

  effects: dispatch => ({
    async fetchReports({ timeFilter }: { timeFilter: TimeFilter }, rootState) {
      this.storeState({ timeFilter });
      this.storeReports({ isFetching: true });

      const { reports, orderData, error } = await api.reports.fetchACRReports(
        timeFilter,
        rootState.settings.attributionWindow,
      );

      if (error) {
        store.dispatch.saveToast.showError('Your reports could not be fetched');
        return;
      }

      this.storeReports({ isFetching: false, ...reports });
      this.storeState({ orderData });
    },

    async fetchOrderData(_, rootState) {
      const {
        settings: { attributionWindow },
        abandonedCartAutomation: {
          timeFilter,
          orderData: { recordsPerPage },
        },
      } = rootState;

      const { orderData, error } = await api.reports.fetchACROrderData(
        { timeFilter, recordsPerPage },
        attributionWindow,
      );

      if (error) {
        store.dispatch.saveToast.showError(
          'Your order data could not be fetched, please refresh and try again',
        );
        return;
      }

      this.storeState({ orderData });
    },

    async fetchConfig() {
      this.storeState({ isFetching: true });

      const { data, error } = await api.automation.abandonedCart.getConfig();
      if (!error) {
        this.storeState({ config: data, isFetching: false });
      }
    },

    async toggle(
      {
        location,
      }: {
        location:
          | TrackingLocation.AUTOMATION_LIST
          | TrackingLocation.AUTOMATION_PAGE;
      },
      rootState,
    ) {
      const state = rootState.abandonedCartAutomation;

      track({
        element: TrackingElement.ACR_TOGGLE_BUTTON,
        category: TrackingCategory.AUTOMATION,
        location,
        action: TrackingAction.CLICKED,
        additonalParams: { is_enabled: state.config.enabled },
      });

      const newState = {
        ...state,
        config: {
          ...state.config,
          enabled: !state.config.enabled,
        },
      };

      // HACK: when we are toggling ACR ON and there is no message, we
      // set a default message and other values for them to start with
      // Hack continues at the end of this function
      const isFirstToggleOn = !state.config.enabled && !state.config.message;
      if (isFirstToggleOn) {
        newState.config = {
          ...newState.config,
          ...defaultTemplate,
        };
      }
      this.storeState(newState);

      // make network request
      await api.automation.abandonedCart
        .setConfig(newState.config)
        .then(res => {
          if (!res.error) {
            store.dispatch.saveToast.showDone('Your changes have been saved');
          } else {
            store.dispatch.saveToast.showError(
              `Your abandoned cart automation status couldn't be saved; please try again or contact support if the issue persists.`,
            );
            this.storeState(state);
          }
        });

      // HACK: when we are toggling ACR ON and there is no message, we
      // set a default message and other values for them to start with
      if (isFirstToggleOn) {
        dispatch.notificationCreator.loadState({
          message: newState.config.message,
          minimumCartValue: newState.config.minimum_cart_value,
          reminderTime: newState.config.delivery_time,
          shouldShortenURL: true,
          discountCode: newState.config.discount_code,
        });

        Router.push('/automation/abandoned-cart-recovery/notification');
      }
    },

    async saveNotification(
      payload: AnyObject /* notification */,
      rootState,
    ): Promise<{ data: any; error: any }> {
      const state = rootState.abandonedCartAutomation;
      const newState = {
        ...state,
        config: {
          ...state.config,
          ...payload,
        },
        notificationInEdit: null,
      };
      // make network request
      return api.automation.abandonedCart
        .setConfig(newState.config)
        .then(res => {
          if (!res.error) {
            this.storeState(newState);
            store.dispatch.saveToast.showDone('Your changes have been saved');
          } else {
            store.dispatch.saveToast.showError('Unable to save changes');
          }

          return res;
        });
    },
  }),

  reducers: {
    storeState(state, payload: Partial<ACRState>) {
      return {
        ...state,
        ...payload,
      };
    },

    storeReports(state, payload: Partial<ACRState['reports']>) {
      return {
        ...state,
        reports: {
          ...state.reports,
          ...payload,
        },
      };
    },

    initNotificationEditing(state, payload: number) {
      return {
        ...state,
        notificationInEdit: payload,
      };
    },

    shutNotificationEditing(state) {
      return {
        ...state,
        notificationInEdit: null,
      };
    },

    resetTimeFilter(state) {
      return {
        ...state,
        timeFilter: initialState().timeFilter,
      };
    },
  },
});

export default abandonedCartAutomation;
