export const radii = ['0', '1px', '2px', '4px', '8px', '12px'];

// Note: zIndex of intercom widget is 2147483000 resulting in higher values below
export const zIndex = [
  -1, 0, 1, 2, 3, 10000, 2147483022, 2147483023, 2147483090,
];

export const responsiveScreenBreakpoint = '1024px';

export default {
  radii,
  zIndex,
  responsiveScreenBreakpoint,
};
