import { AutomationConfig } from 'src/modules/automation/models/types';
import { get, post } from '../axios';

// endpoints
const CONFIG_ENDPOINT = `/config/welcome-series/`;
const REPORT_ENDPOINT = `/dashboard/welcome-notification/report/`;

// query helpers
const getConfig = () => get(CONFIG_ENDPOINT);

const setConfig = (data: {
  enabled: boolean;
  metadata?: AutomationConfig['metadata'];
  message_configs?: Array<AutomationConfig>;
}) => post(CONFIG_ENDPOINT, data);

const getReport = () => get(REPORT_ENDPOINT);

export default {
  getReport,
  getConfig,
  setConfig,
};
