import styled from 'styled-components';
import { fontSizes } from 'barn/tokens/typography';
import { LoadingOwly } from '../components/LoadingOwly';

export const StyledPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.login.bg};
  color: ${({ theme }) => theme.login.color};
  width: 100vw;
  height: 100vh;
`;

export const StyledLogin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${fontSizes[4]};
`;

const Login = () => (
  <StyledPage data-test='loading-dashboard'>
    <StyledLogin>
      <LoadingOwly />
      <br />
      <p>Getting your dashboard ready...</p>
    </StyledLogin>
  </StyledPage>
);

export default Login;
