import { get, post } from './axios';

const getPricing = () => get(`/pricing/`);

export type PlanChange = {
  billing_plan_sku: string;
  coupon_code?: string;
  utm_source?: string;
  utm_medium?: string;
};

const changePlan = (data: PlanChange) => post(`/dashboard/billing/init/`, data);

const cancelPlan = async (data = {}) =>
  post(`/dashboard/billing/cancel/`, data);

const validateCoupon = async (data: { code: string }) =>
  post(`/dashboard/coupon/validate/`, data);

export default {
  getPricing,
  changePlan,
  cancelPlan,
  validateCoupon,
};
