import Button from 'barn/components/Button';
import InfoRoundedFilled from 'barn/icons/InfoRoundedFilled';
import Switch from 'barn/icons/Switch';
import Tick from 'barn/icons/Tick';
import colors from 'barn/tokens/colors';
import { HStack } from 'barn/components/Stack';
import { Text } from 'barn/components/Text';
import { useState } from 'react';
import Spinner from 'barn/components/Spinner';
import { AnimatePresence } from 'framer-motion';
import SmallClose from 'barn/icons/SmallClose';
import { useGlobalState } from 'src/hooks/use-global-state';
import { startConversationWithUser } from 'src/lib/utils';
import Styled from './styled';
import { SYNC_SUBSCRIBER_STATUS } from '../../models';

export const motionVariants = {
  initial: { height: '0px', opacity: 0 },
  animate: {
    height: 'auto',
    opacity: 1,
    transition: { duration: 0.35, ease: [0.64, 0, 0.56, 1] },
  },
};

export type DynamicPillType = {
  variant: 'inProgress' | 'success' | 'error';
  heading: string;
  subheading?: string;
  onDismiss?: () => void;
  errorCta?: {
    buttonText: string;
    onClick: () => void;
  };
};

const getPillProps = (variant: DynamicPillType['variant']) => {
  switch (variant) {
    case 'inProgress':
      return {
        icon: (
          <Switch
            color={colors.greys[8]}
            style={{
              // the icon SVGs have different viewbox bounds, so need to hardcode a width
              width: '14px',
            }}
          />
        ),
      };
    case 'error':
      return {
        icon: <InfoRoundedFilled color={colors.reds[3]} />,
      };
    case 'success':
      return {
        icon: <Tick color={colors.greens[3]} />,
      };
    default:
      return null;
  }
};

const syncStatusVariantMap: Record<string, DynamicPillType['variant']> = {
  [SYNC_SUBSCRIBER_STATUS.ERRORED]: 'error',
  [SYNC_SUBSCRIBER_STATUS.COMPLETED]: 'success',
  [SYNC_SUBSCRIBER_STATUS.IN_PROGRESS]: 'inProgress',
};

const getDynamicPillData = (
  subscriberCount: number,
): Record<
  DynamicPillType['variant'],
  Pick<DynamicPillType, 'subheading' | 'heading' | 'errorCta'>
> => {
  return {
    inProgress: {
      heading: `Syncing ${subscriberCount} subscribers`,
      subheading: `Syncing and sending Welcome SMS to ${subscriberCount} subscribers`,
    },
    success: {
      heading: `Synced ${subscriberCount} subscribers`,
      subheading: `Synced and sent Welcome SMS to ${subscriberCount} subscribers`,
    },
    error: {
      heading: 'Subscriber sync failed',
      subheading:
        'Failed to sync SMS subscribers from Shopify, contact support for help',
      errorCta: {
        buttonText: 'Contact Support',
        onClick: () =>
          startConversationWithUser(
            'Calling all support superheroes! Help needed on syncing subscribers! Thanks a million!',
          ),
      },
    },
  };
};

const DynamicPillContent = ({
  variant,
  heading,
  subheading,
  onDismiss,
  errorCta,
}: DynamicPillType) => {
  const { icon } = getPillProps(variant);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleOnMouseEnter = () => {
    setIsExpanded(true);
  };

  const handleOnMouseLeave = () => {
    setIsExpanded(false);
  };

  return (
    <Styled.PillContainer
      variant={variant}
      isExpanded={isExpanded}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <HStack height='100%' align='center' justify='space-between'>
        <HStack gap={3}>
          <Styled.PillIconContainer align='center' justify='center'>
            {icon}
          </Styled.PillIconContainer>
          <Styled.PillHeading>{heading}</Styled.PillHeading>
        </HStack>

        <HStack justify='center'>
          {variant === 'inProgress' && <Spinner small currentColor />}
          {variant === 'success' && (
            <Styled.PillDismissButton onClick={onDismiss}>
              <SmallClose />
            </Styled.PillDismissButton>
          )}
        </HStack>
      </HStack>
      <AnimatePresence>
        {isExpanded && (
          <Styled.PillDrawer
            animate={motionVariants.animate}
            initial={motionVariants.initial}
            exit={motionVariants.initial}
            key='pill-drawer'
          >
            <Styled.PillDrawerContent gap={4} align='flex-start'>
              <Text variant={variant === 'inProgress' ? 'tertiary' : 'primary'}>
                {subheading}
              </Text>
              {variant === 'error' && errorCta && (
                <Button
                  onClick={errorCta.onClick}
                  type='button'
                  appearance='secondary'
                >
                  {errorCta.buttonText}
                </Button>
              )}
            </Styled.PillDrawerContent>
          </Styled.PillDrawer>
        )}
      </AnimatePresence>
    </Styled.PillContainer>
  );
};

export const DynamicPill = () => {
  const {
    state: {
      syncSubscribers: { status, newSubscriberCount },
    },
    actions: { setSyncSubscribersStatus },
  } = useGlobalState('home');
  const variant = syncStatusVariantMap[status];
  const pillData = getDynamicPillData(newSubscriberCount);

  if (!Object.keys(syncStatusVariantMap).includes(status)) {
    return null;
  }

  return (
    <DynamicPillContent
      variant={variant}
      heading={pillData[variant].heading}
      subheading={pillData[variant].subheading}
      onDismiss={() =>
        setSyncSubscribersStatus({
          status: SYNC_SUBSCRIBER_STATUS.DISMISSED,
        })
      }
      errorCta={pillData[variant].errorCta}
    />
  );
};
