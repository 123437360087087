import colors from 'barn/tokens/colors';

export const tickCircleLight = {
  borderColor: colors.greys[5],
  bg: colors.greys[5],
  label: {
    color: colors.greys[1],
  },
  active: {
    bg: colors.greys[8],
    borderColor: colors.greens[4],
  },
  complete: {
    bg: colors.greens[4],
    borderColor: colors.greens[4],
    iconColor: {
      fill: colors.greys[8],
    },
  },
};

export const tickCircleDark = {
  borderColor: colors.greys[5],
  bg: colors.greys[9],
  label: {
    color: colors.greys[5],
  },
  active: {
    bg: colors.greys[9],
    borderColor: colors.greens[9],
  },
  complete: {
    bg: colors.greens[9],
    borderColor: colors.greens[9],
    iconColor: {
      fill: colors.greys[9],
    },
  },
};
