import store from 'src/store';
import {
  TrackingElement,
  TrackingLocation,
  TrackingCategory,
  TrackingAction,
} from './constants';

export const track = ({
  category,
  action,
  element,
  location,
  additonalParams = {},
}: {
  category: TrackingCategory;
  action: TrackingAction;
  element: TrackingElement;
  location?: TrackingLocation;
  additonalParams?: Object;
}) => {
  if (typeof window?.analytics?.track !== 'function') return;
  const { isSupportLogin } = store.getState().user.segmentData;

  // Don't track if it is support login
  if (isSupportLogin) return;

  interface TrackingParams extends Object {
    location?: TrackingLocation;
  }

  const params: TrackingParams = additonalParams;

  if (location) params.location = location;

  window.analytics.track(`${category} / ${element} ${action}`, params);
};

export const identify = (identifier: string, traits: AnyObject) => {
  if (typeof window?.analytics?.identify === 'function')
    window.analytics.identify(identifier, traits);
};

export const trackPage = () => {
  if (typeof window?.analytics?.page === 'function') window.analytics.page();
};

export const simpleTrack = (identifier: string) => {
  if (typeof window?.analytics?.track === 'function')
    window.analytics.track(identifier);
};

export { TrackingElement, TrackingLocation, TrackingAction, TrackingCategory };
