import { FlyOutData, OptinsData } from 'src/modules/optins/models';
import { get, post } from './axios';

const CONFIG_OPTIN_ENDPOINT = '/config/optin/sms/';
const CONFIG_HOME_ENDPOINT = '/dashboard/config/js-sdk/home/';

export const getConfig = () => get(CONFIG_OPTIN_ENDPOINT);

export const setConfig = (data: OptinsData) =>
  post(CONFIG_OPTIN_ENDPOINT, data);

export const getFlyoutConfig = () => get(CONFIG_HOME_ENDPOINT);

export const setFlyoutConfig = (data: { flyout: FlyOutData }) =>
  post(CONFIG_HOME_ENDPOINT, data);

export default {
  getConfig,
  setConfig,
  getFlyoutConfig,
  setFlyoutConfig,
};
