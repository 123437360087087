import tokens from 'barn/tokens';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';

const StyledComingSoon = styled.div`
  background-color: ${({ theme }) => theme.comingSoon.bg};
  color: ${({ theme }) => theme.comingSoon.color};
  border-radius: ${tokens.radii[5]};
  display: flex;
  align-items: center;
  padding: ${tokens.space.padding[2]} ${tokens.space.padding[3]};
  font-size: ${tokens.fontSizes[0]};
  font-weight: 700;
  line-height: 1;
`;

export const ComingSoon = () => {
  const { t } = useTranslation('soon');
  return <StyledComingSoon>{t('soon')}</StyledComingSoon>;
};
