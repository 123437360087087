import { useMemo, useState } from 'react';
import { useAppBridge } from '@shopify/app-bridge-react';
import { Fullscreen } from '@shopify/app-bridge/actions';
import DuplicateIcon from 'barn/icons/Duplicate';
import Styled from './styled';

export const AppBridgeFullscreenButton = () => {
  const [isFullscreen, setFullscreen] = useState(false);
  const app = useAppBridge();
  const fullscreen = useMemo(() => Fullscreen.create(app), [app]);

  const enterFullscreen = () => {
    setFullscreen(true);
    fullscreen.dispatch(Fullscreen.Action.ENTER);
  };

  const exitFullscreen = () => {
    setFullscreen(false);
    fullscreen.dispatch(Fullscreen.Action.EXIT);
  };

  return (
    <Styled.LinkListItem
      onClick={isFullscreen ? exitFullscreen : enterFullscreen}
    >
      <DuplicateIcon />
      <span>{isFullscreen ? 'Exit Fullscreen' : 'Open in Fullscreen'}</span>
    </Styled.LinkListItem>
  );
};
