const greens = [
  /* #07140E */ 'hsl(152, 48%, 5%)',
  /* #102F20 */ 'hsl(151, 49%, 12%)',
  /* #1A4931 */ 'hsl(149, 47%, 19%)',
  /* #236443 */ 'hsl(150, 48%, 26%)',
  /* #2F855A */ 'hsl(150, 48%, 35%)',
  /* #63A383 */ 'hsl(150, 26%, 51%)',
  /* #8DBCA4 */ 'hsl(149, 26%, 65%)',
  /* #B6D4C5 */ 'hsl(150, 26%, 77%)',
  /* #E0EDE6 */ 'hsl(148, 27%, 90%)',
  /* #56B988 */ 'hsl(150, 41%, 53%)',
];

const blues = [
  /* #020C26 */ 'hsl(223, 90%, 8%)',
  /* #061D59 */ 'hsl(223, 87%, 19%)',
  /* #092E8C */ 'hsl(223, 88%, 29%)',
  /* #0C3EBF */ 'hsl(223, 88%, 40%)',
  /* #1053FF */ 'hsl(223, 100%, 53%)',
  /* #4C7EFF */ 'hsl(223, 100%, 65%)',
  /* #7CA0FF */ 'hsl(224, 100%, 74%)',
  /* #ABC3FF */ 'hsl(223, 100%, 84%)',
  /* #DBE5FF */ 'hsl(223, 100%, 93%)',
];

const greys = [
  /* #212B36 */ 'hsl(211, 24%, 17%)',
  /* #454F5B */ 'hsl(213, 14%, 31%)',
  /* #637381 */ 'hsl(208, 13%, 45%)',
  /* #919EAB */ 'hsl(210, 13%, 62%)',
  /* #C3CDD5 */ 'hsl(208, 17%, 80%)',
  /* #DFE3E8 */ 'hsl(210, 17%, 89%)',
  /* #F5F5F6 */ 'hsl(213, 8%, 96%)',
  /* #F9FAFB */ 'hsl(210, 20%, 98%)',
  /* #FFFFFF */ 'hsl(0, 0%, 100%)',
  /* #12151E */ 'hsl(225, 25%, 9%)',
  /* #171A22 */ 'hsl(224, 19%, 11%)',
  /* #1B1E27 */ 'hsl(225, 18%, 13%)',
  /* #21242c */ 'hsl(224, 15%, 15%)',
  /* #23252E */ 'hsl(229, 14%, 16%)',
  /* #252830 */ 'hsl(224, 13%, 17%)',
  /* #2A2C34 */ 'hsl(228, 11%, 18%)',
  /* #2C2F37 */ 'hsl(224, 11%, 19%)',
  /* #45484F */ 'hsl(222, 7%, 29%)',
  /* #929292 */ 'hsl(0, 0%, 57%)',
  /* #8D8F93 */ 'hsl(220, 3%, 56%)',
];

const reds = [
  /* #26080F */ 'hsl(346, 65%, 9%)',
  /* #591223 */ 'hsl(346, 66%, 21%)',
  /* #8E1E39 */ 'hsl(346, 65%, 34%)',
  /* #BE264A */ 'hsl(346, 67%, 45%)',
  /* #E92251 */ 'hsl(346, 82%, 52%)',
  /* #FE668A */ 'hsl(346, 99%, 70%)',
  /* #FE8FA9 */ 'hsl(346, 98%, 78%)',
  /* #FEB8C8 */ 'hsl(346, 97%, 86%)',
  /* #FFE0E8 */ 'hsl(345, 100%, 94%)',
];

const yellows = [
  /* #231E0B */ 'hsl(47, 52%, 9%)',
  /* #53461A */ 'hsl(46, 52%, 21%)',
  /* #84712C */ 'hsl(47, 50%, 35%)',
  /* #B19738 */ 'hsl(47, 52%, 46%)',
  /* #ECC94B */ 'hsl(47, 81%, 61%)',
  /* #F1D778 */ 'hsl(47, 81%, 71%)',
  /* #F5E19C */ 'hsl(47, 82%, 79%)',
  /* #F8ECC0 */ 'hsl(47, 80%, 86%)',
  /* #FCF7E4 */ 'hsl(48, 80%, 94%)',
];

export default { greens, blues, greys, reds, yellows };
