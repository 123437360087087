import colors from 'barn/tokens/colors';

export const paneLight = {
  bg: colors.greys[8],
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08)',
  titlebar: {
    borderColor: colors.greys[5],
  },
};

export const paneDark = {
  bg: colors.greys[11],
  boxShadow: 'none',
  titlebar: {
    borderColor: colors.greys[11],
  },
};
