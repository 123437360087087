export const Owly70 = () => {
  return (
    <svg
      width='400'
      height='250'
      viewBox='0 0 400 250'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_f_304_27089)'>
        <path
          d='M101 82.323C133.4 61.923 127.5 29.4897 120.5 15.823C176.5 -3.6768 276 52.8232 271.5 245.823H136.5C160 117.323 136.5 91.323 101 82.323Z'
          fill='#FFCF00'
        />
        <mask
          id='mask0_304_27089'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='101'
          y='12'
          width='171'
          height='234'
        >
          <path
            d='M101 82.323C133.4 61.923 127.5 29.4897 120.5 15.823C176.5 -3.6768 276 52.8232 271.5 245.823H136.5C160 117.323 136.5 91.323 101 82.323Z'
            fill='#FFCF00'
          />
        </mask>
        <g mask='url(#mask0_304_27089)'>
          <path
            d='M138 240.323C137.5 236.823 137.5 230.823 138 228.323L197 230.323C261.5 231.823 265.5 227.323 274 220.323L272.5 240.323H138Z'
            fill='#FFB600'
          />
          <path
            d='M152.5 99.8232L168 1.32324L218 -2.17676C224.833 8.48991 234 32.3232 216 42.3232C198 52.3232 183.833 83.1566 179 97.3232L137.5 106.323L152.5 99.8232Z'
            fill='#FFB600'
          />
        </g>
        <path
          d='M102.5 209.323C98.8999 190.123 125.667 138.323 139.5 114.823C147.1 129.223 146.667 165.157 145.5 181.323L269.5 191.323L286 194.823L298 203.499C298.167 204.44 298.4 207.023 298 209.823C297.5 217.823 296 220.823 295 223.323C294.2 225.323 292 226.157 291 226.323C290.5 228.657 288.1 233.223 282.5 232.823C274.584 232.164 273.83 226.472 274.743 222.253C272.478 226.587 264.749 232.323 245.5 232.323C154 234.323 107 233.323 102.5 209.323Z'
          fill='#FFCF00'
        />
        <mask
          id='mask1_304_27089'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='102'
          y='114'
          width='197'
          height='120'
        >
          <path
            d='M102.5 209.323C98.8999 190.123 125.667 138.323 139.5 114.823C147.1 129.223 146.667 165.157 145.5 181.323L269.5 191.323L286 194.823L298 203.499C298.167 204.44 298.4 207.023 298 209.823C297.5 217.823 296 220.823 295 223.323C294.2 225.323 292 226.157 291 226.323C290.5 228.657 288.1 233.223 282.5 232.823C274.584 232.164 273.83 226.472 274.743 222.253C272.478 226.587 264.749 232.323 245.5 232.323C154 234.323 107 233.323 102.5 209.323Z'
            fill='#FFCF00'
          />
        </mask>
        <g mask='url(#mask1_304_27089)'>
          <path
            d='M127 179.323C139 174.923 143.5 145.99 143.5 132.823C146 145.323 144.833 168.657 143.5 180.823C133 182.157 127 179.323 127 179.323Z'
            fill='#FFB600'
          />
          <path
            d='M255.5 217.823C255.5 209.823 246.167 205.823 241.5 204.823C258 203.323 265.667 197.49 266 190.823L285.5 193.823L297.5 202.823V208.323L298.5 209.823C297.7 216.623 295.5 222.323 294.5 224.323L291.5 225.823C290.333 227.823 286.5 231.823 280.5 231.823C274.5 231.823 273.833 223.157 274.5 219.323C268 234.823 238.5 231.823 221.5 231.823C232.667 230.99 255.5 225.823 255.5 217.823Z'
            fill='#FFB600'
          />
        </g>
        <path
          d='M114.5 26.3232C111.166 21.1566 106.8 10.1232 116 7.32324'
          stroke='black'
          strokeWidth='2'
          strokeLinecap='round'
        />
        <path
          d='M137.5 109.823C139 113.323 141.5 121.823 143 130.323M121 227.013C136.833 229.68 186 232.013 226 232.013C266 232.013 267.5 228.823 275 219.823'
          stroke='black'
          strokeWidth='2'
          strokeLinecap='round'
        />
        <path
          d='M281.5 225.013C279.667 222.18 275.8 217.913 275 223.513C274 230.513 280.5 235.013 287 231.013C288.825 229.89 290.128 228.037 291.044 225.924M285.5 209.013L293 214.513C293.108 217.218 292.739 222.015 291.044 225.924M288.5 199.013C290.333 201.847 294.7 207.813 297.5 209.013C301 210.513 296.589 225.334 291.044 225.924'
          stroke='black'
          strokeWidth='2'
          strokeLinecap='round'
        />
        <path
          d='M210 62.3231C206 56.7231 197.5 54.3235 194 52.3231C191.5 51.3227 192.17 46.975 194.5 45.3235C198 43.3237 200.176 43.891 213.5 50.3238C244 63.3232 300 195.823 251.5 205.823C203 215.823 215 106.323 213 105.823C207 103.823 210 99.3231 208 99.3231C198.5 98.3231 202 85.8231 201.5 84.8231C196 74.8231 211 63.8231 210 62.3231Z'
          fill='#FFCF00'
        />
        <mask
          id='mask2_304_27089'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='192'
          y='44'
          width='79'
          height='163'
        >
          <path
            d='M210 62.3231C206 56.7231 197.5 54.3235 194 52.3231C191.5 51.3227 192.17 46.975 194.5 45.3235C198 43.3237 200.176 43.891 213.5 50.3238C244 63.3232 300 195.823 251.5 205.823C203 215.823 215 106.323 213 105.823C207 103.823 210 99.3231 208 99.3231C198.5 98.3231 202 85.8231 201.5 84.8231C196 74.8231 211 63.8231 210 62.3231Z'
            fill='#FFCF00'
          />
        </mask>
        <g mask='url(#mask2_304_27089)'>
          <path
            d='M225 193.323C285.4 203.323 252.436 109.323 228.5 62.8232C276.5 104.823 286.5 186.323 268.5 203.823C241.961 229.625 225 193.323 225 193.323Z'
            fill='#FFB600'
          />
        </g>
        <path
          d='M270 191.513L285.5 194.013L298 203.013C298 204.68 298 208.213 298 209.013'
          stroke='black'
          strokeWidth='2'
          strokeLinecap='round'
        />
        <path
          d='M211 74.0132C205 80.0132 195.9 93.4132 207.5 99.0132M209 83.0132C207.333 92.0132 206.6 108.713 217 103.513'
          stroke='black'
          strokeWidth='2'
          strokeLinecap='round'
        />
        <path
          d='M141 181.013C159.5 183.013 201.8 187.213 223 188.013'
          stroke='black'
          strokeWidth='2'
          strokeLinecap='round'
        />
        <path
          d='M145 147.013C144.667 157.847 143.8 179.813 143 181.013'
          stroke='black'
          strokeWidth='2'
          strokeLinecap='round'
        />
        <path
          d='M214.5 104.513C220.5 152.513 209.4 220.313 261 201.513'
          stroke='black'
          strokeWidth='2'
          strokeLinecap='round'
        />
        <path
          d='M266 142.823C254 98.8232 237.301 74.8429 235.5 72.0132C232 66.5132 222.5 55.513 212 48.513C201.5 41.513 193 43.513 192.5 47.013C192 50.513 193 50.513 198.5 53.013C202.9 55.013 208 60.1796 210 62.513C206 67.513 198.8 78.9132 202 84.5132'
          stroke='black'
          strokeWidth='2'
          strokeLinecap='round'
        />
        <path
          d='M208.5 79.3232C202.1 72.5232 187.167 61.4899 180.5 56.8232C180 54.8232 176.9 50.7232 168.5 50.3232C158 49.8232 153 80.8232 160 76.8232C167 72.8232 216.5 87.8232 208.5 79.3232Z'
          fill='black'
        />
        <path
          d='M144.5 60.8222C149.3 76.4222 137.167 95.6556 130.5 103.322C156 130.323 176.5 104.156 179.5 91.3232C186 59.3223 149.5 54.3232 144.5 60.8222Z'
          fill='white'
        />
        <path
          d='M152 98.8232C156 90.3793 147.667 84.9899 143 84.3232C141.4 90.7232 137 96.9899 135 99.3232C135 102.823 147 109.379 152 98.8232Z'
          fill='black'
        />
        <ellipse
          cx='165.332'
          cy='38.1193'
          rx='31.7838'
          ry='24.1937'
          transform='rotate(21.3903 165.332 38.1193)'
          fill='white'
        />
        <ellipse
          cx='163.836'
          cy='49.3882'
          rx='10.4001'
          ry='7.27278'
          transform='rotate(21.3903 163.836 49.3882)'
          fill='black'
        />
        <path
          d='M129.5 104.823C144 90.8232 150 65.8232 144 59.3232'
          stroke='black'
          strokeWidth='2'
          strokeLinecap='round'
        />
        <circle cx='144' cy='101.323' r='1.5' fill='white' />
        <ellipse
          cx='164.621'
          cy='55.096'
          rx='2'
          ry='1'
          transform='rotate(8.12911 164.621 55.096)'
          fill='white'
        />
      </g>
      <line
        x1='93.5'
        y1='244.5'
        x2='305.5'
        y2='244.5'
        stroke='black'
        strokeWidth='3'
        strokeLinecap='round'
      />
      <rect
        x='173'
        y='143'
        width='54'
        height='102'
        rx='1'
        fill='white'
        fillOpacity='0.6'
      />
      <mask id='path-31-inside-1_304_27089' fill='white'>
        <rect x='173' y='143' width='54' height='102' rx='1' />
      </mask>
      <rect
        x='173'
        y='143'
        width='54'
        height='102'
        rx='1'
        stroke='black'
        strokeWidth='4'
        mask='url(#path-31-inside-1_304_27089)'
      />
      <rect
        x='1'
        y='-1'
        width='30'
        height='6'
        rx='1'
        transform='matrix(1 0 0 -1 184 140)'
        fill='black'
        stroke='black'
        strokeWidth='2'
      />
      <rect x='176' y='211' width='47' height='9' rx='2' fill='#5A4CF6' />
      <rect x='176' y='222' width='47' height='9' rx='2' fill='#5A4CF6' />
      <rect x='176' y='233' width='47' height='9' rx='2' fill='#5A4CF6' />
      <rect
        x='216'
        y='150'
        width='7'
        height='44'
        rx='3.5'
        fill='white'
        fillOpacity='0.5'
      />
      <rect
        x='216'
        y='197'
        width='7'
        height='7'
        rx='3.5'
        fill='white'
        fillOpacity='0.5'
      />
      <defs>
        <filter
          id='filter0_f_304_27089'
          x='99'
          y='4.32275'
          width='202.748'
          height='243.5'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='1'
            result='effect1_foregroundBlur_304_27089'
          />
        </filter>
      </defs>
    </svg>
  );
};
