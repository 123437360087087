import colors from 'barn/tokens/colors';

export const spinnerLight = {
  borderColor: colors.greys[8],
  animationBorderColor: '#47C1BF',
};

export const spinnerDark = {
  ...spinnerLight,
  borderColor: colors.greys[6],
};
