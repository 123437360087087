import { NextRouter } from 'next/router';
// import cookie from 'src/lib/cookie';
import { getStorage } from 'src/lib/storage';
import { isClientSide } from 'src/lib/utils';
import store from 'src/store';
import { debugLog } from './debug-utils';

export default function checkAuth(router: NextRouter) {
  // return back empty object on server-side since the app is static
  if (!isClientSide()) {
    return { loggedInUser: {} };
  }

  // execute only on client-side
  const storage = getStorage();
  let user = storage.get('user');

  // Fix the corrupted user object in localstorage. This may happen if old dashboard was open
  // before the new dashboard.
  if (user && !user.website) {
    user = undefined;
    storage.clear('user');
  }
  let shouldRenderPage = false;

  // redirect based on if user is logged in
  if (!user && !/login$/.test(router.pathname)) {
    if (window.location.pathname !== '/') {
      const redirectObj = {
        subdomain: window._po_subdomain,
        nextURL: btoa(
          JSON.stringify({ path: window.location.pathname, params: {} }),
        ),
      };

      storage.set('redirect_on_load', redirectObj);
    }

    const appBridgeConfig = storage.get('app_bridge_config');

    if (
      !new URLSearchParams(window.location.search).get('hmac') &&
      !appBridgeConfig
    ) {
      debugLog({
        message: 'redirect Shopify login',
        data: {
          appBridgeConfig,
          search: window.location.search,
        },
      });

      window.location.href = '/shopify-login';
    }
  } else {
    shouldRenderPage = true;
  }

  if (user && user.website && user.website.subdomain) {
    window._po_subdomain = user.website.subdomain;

    // Since cy.visit is a hard navigation and refetchUser is disabled for Cypress
    // we need to update the user state so that GlobalStateInitialiser methods run
    if (window.Cypress) {
      store.dispatch.user.setUserLoggedIn(true);
      store.dispatch.user.setSegmentData(
        JSON.parse(localStorage.getItem('__PUSHOWL__segmentdata')),
      );
    }
  }

  // finally, return user (if logged in)
  return { shouldRenderPage, loggedInUser: user };
}
