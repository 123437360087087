import colors from 'barn/tokens/colors';

export const alertLight = {
  bg: colors.greys[5],
  color: colors.greys[1],
  warning: {
    bg: colors.yellows[6],
  },
  error: {
    bg: colors.reds[7],
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.35);',
  },
};

export const alertDark = {
  ...alertLight,
  warning: {
    bg: colors.yellows[4],
  },
};
