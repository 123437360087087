import { v1 as uuidv1 } from 'uuid';
import colors from 'barn/tokens/colors';

const Automation = ({ color = colors.greys[2], style = {} }) => {
  const id = uuidv1();

  return (
    <svg width='20' height='20' viewBox='-2 -2 18 18' fill='none' style={style}>
      <mask
        id={`mask-${id}`}
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='16'
        height='16'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.6 14.4C13.1592 14.4 12.8 14.0408 12.8 13.6C12.8 13.1592 13.1592 12.8 13.6 12.8C14.0408 12.8 14.4 13.1592 14.4 13.6C14.4 14.0408 14.0408 14.4 13.6 14.4ZM3.2 13.6C3.2 14.0408 2.8408 14.4 2.4 14.4C1.9592 14.4 1.6 14.0408 1.6 13.6C1.6 13.1592 1.9592 12.8 2.4 12.8C2.8408 12.8 3.2 13.1592 3.2 13.6ZM14.1328 4.6728L13.0232 8H3.2V3.3064L14.1328 4.6728ZM13.6 11.2H3.2V9.6H13.6C13.9448 9.6 14.2496 9.38 14.3592 9.0528L15.9592 4.2528C16.0344 4.0256 16.0048 3.776 15.8768 3.5736C15.7488 3.3704 15.5368 3.236 15.2992 3.2064L3.2 1.6936V0.8C3.2 0.3584 2.8416 0 2.4 0H0.8C0.3584 0 0 0.3584 0 0.8C0 1.2416 0.3584 1.6 0.8 1.6H1.6V11.3472C0.6704 11.6792 0 12.5584 0 13.6C0 14.9232 1.0768 16 2.4 16C3.7232 16 4.8 14.9232 4.8 13.6C4.8 13.3176 4.7424 13.0512 4.6528 12.8H11.3472C11.2576 13.0512 11.2 13.3176 11.2 13.6C11.2 14.9232 12.2768 16 13.6 16C14.9232 16 16 14.9232 16 13.6C16 12.2768 14.9232 11.2 13.6 11.2Z'
          fill={color}
        />
      </mask>
      <g mask={`url(#mask-${id})`}>
        <rect width='16.8' height='16.8' fill={color} className='iconColor' />
      </g>
    </svg>
  );
};

export default Automation;
