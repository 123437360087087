import colors from 'barn/tokens/colors';

export const homeLight = {
  bg: colors.greys[8],
  color: colors.greys[2],
  borderColor: colors.greys[5],
  label: {
    color: colors.greys[2],
  },
  bold: {
    color: colors.greys[0],
  },
  small: {
    color: colors.greys[1],
  },
  tooltip: {
    bg: colors.greys[8],
    color: colors.greens[4],
  },
  location: {
    borderColor: colors.greys[6],
  },
  progress: {
    bg: colors.greens[7],
    completed: {
      bg: colors.greens[4],
    },
  },
  line: {
    borderColor: colors.greys[4],
  },
};

export const homeDark = {
  ...homeLight,
  bg: colors.greys[12],
  color: colors.greys[7],
  borderColor: colors.greys[12],
  label: {
    color: colors.greys[4],
  },
  bold: {
    color: colors.greys[8],
  },
  small: {
    color: colors.greys[7],
  },
  tooltip: {
    bg: colors.greys[12],
    color: colors.greens[9],
  },
  location: {
    borderColor: colors.greys[12],
  },
  progress: {
    bg: colors.greens[7],
    completed: {
      bg: colors.greens[9],
    },
  },
  line: {
    borderColor: colors.greys[16],
  },
};
