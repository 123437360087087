import colors from 'barn/tokens/colors';

export const dropdownLight = {
  color: colors.greys[1],
  borderColor: colors.greys[5],
  boxShadow: '0 2px 3px 0 rgba(34, 36, 38, 0.15)',
  search: {
    bg: colors.greys[8],
    placeholder: {
      color: colors.greys[3],
    },
  },
  listItem: {
    bg: colors.greys[8],
    hover: {
      bg: colors.blues[8],
      color: colors.greys[1],
    },
    disabled: {
      bg: colors.greys[8],
    },
  },
  groupContainer: {
    borderColor: colors.greys[4],
  },
  groupLabel: {
    bg: colors.greys[8],
    borderColor: colors.greys[4],
  },
  listItemCheckbox: {
    bg: colors.greys[8],
    checkmark: {
      borderColor: colors.greens[4],
    },
    hover: {
      bg: colors.greys[6],
      color: colors.greys[1],
    },
    secondary: {
      color: colors.greys[3],
    },
  },
  multiSelectItemTag: {
    bg: colors.greys[6],
  },
  menuItem: {
    color: 'rgba(0, 0, 0, 0.87)',
    active: {
      color: 'rgba(0,0,0,.95)',
      bg: 'rgba(0,0,0,.03)',
    },
    selected: {
      color: 'rgba(0,0,0,.95)',
    },
  },
  disabledRadio: {
    color: colors.greys[5],
  },
  multiSelectButton: {
    bg: colors.greys[8],
  },
};

export const dropdownDark = {
  ...dropdownLight,
  color: colors.greys[7],
  borderColor: colors.greys[16],
  boxShadow: 'none',
  search: {
    bg: colors.greys[9],
    placeholder: {
      color: colors.greys[4],
    },
  },
  multiSelectButton: {
    bg: colors.greys[13],
  },
  listItem: {
    bg: colors.greys[9],
    hover: {
      bg: colors.greys[14],
      color: colors.greys[7],
    },
    disabled: {
      bg: colors.greys[9],
    },
  },
  groupLabel: {
    bg: colors.greys[9],
    borderColor: colors.greys[16],
  },
  listItemCheckbox: {
    bg: colors.greys[9],
    checkmark: {
      borderColor: colors.greens[9],
    },
    hover: {
      bg: colors.greys[14],
      color: colors.greys[7],
    },
    secondary: {
      color: colors.greys[3],
    },
  },
  multiSelectItemTag: {
    bg: colors.greys[9],
  },
  menuItem: {
    /* FIXME: Condense all the dropdown selectors to one */
    color: colors.greys[7],
    active: {
      color: colors.greys[7],
      bg: colors.greys[12],
    },
    selected: {
      color: colors.greys[8],
    },
  },
  disabledRadio: {
    color: colors.greys[3],
  },
};
