import styled from 'styled-components';
import tokens from 'barn/tokens';

const DeviceSwitch = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 70px;
  background: ${({ theme }) => theme.deviceSwitch.bg};
  padding: ${tokens.space.padding[4]} 0 0;
  width: 64px;

  > * {
    margin-bottom: ${tokens.space.margin[6]};
  }
`;

export default {
  DeviceSwitch,
};
