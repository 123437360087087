import { getStorage } from 'src/lib/storage';
import { get, getUser, post, put } from './axios';

const setCompanyLogo = async (url: string) => {
  const { error, status } = await post('/dashboard/preferences/', {
    preferences: [
      {
        key: 'company_logo',
        value: url,
      },
    ],
  });

  if (error)
    return {
      error,
      data: null,
    };

  if (status !== 200) throw Error('Something went wrong. Please try again.');

  // update session
  const storage = getStorage();
  const loggedInUser = storage.get('user');
  if (loggedInUser && loggedInUser.website) {
    loggedInUser.website.company_logo = url;
    storage.set('user', loggedInUser);
  }

  return {
    data: {
      company_logo: url,
    },
    error: null,
  };
};

const setNotificationBadge = async (url: string) => {
  const { error, status } = await post('/dashboard/preferences/', {
    preferences: [
      {
        key: 'notification_badge',
        value: url,
      },
    ],
  });

  if (error)
    return {
      error,
      data: null,
    };

  if (status !== 200) throw Error('Something went wrong. Please try again.');

  // update session
  const storage = getStorage();
  const loggedInUser = storage.get('user');
  if (loggedInUser && loggedInUser.website) {
    loggedInUser.website.notification_badge = url;
    storage.set('user', loggedInUser);
  }

  return {
    data: {
      notification_badge: url,
    },
    error: null,
  };
};

const getConsumption = () => post('/consumption', null);

const getGILBreakdown = () => get('/gil/breakdown/');

const getKlaviyoIntegration = () => get('/config/integrations/klaviyo/');

const setKlaviyoIntegration = payload =>
  post('/config/integrations/klaviyo/', payload);

interface FCMData {
  sender_id: string;
  fcm_api_key: string;
}

const setFCM = async (data: FCMData) => {
  const user = getUser();
  const {
    data: responseData,
    error,
    status,
  } = await post(
    `/api/v1/website/${user.website.subdomain}/config/fcm/`,
    data,
    {
      baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}`,
    },
  );

  if (error)
    return {
      error,
      data: null,
    };

  if (status === 400) return { data: null, error };

  return { data: responseData, error: null };
};

const getFCM = async () => {
  const user = getUser();
  const { data, error } = await get(
    `/api/v1/website/${user.website.subdomain}/config/fcm/`,
    null,
    { baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}` },
  );

  if (error)
    return {
      error,
      data: null,
    };

  return { data, error: null };
};

type NotificationAction = {
  enabled: boolean;
  text: string;
};

interface PrivacyData {
  admin_access: boolean;
  customer_id: boolean;
  ip: 'no_ip' | 'forget_last_octet';
  location: boolean;
  notification_preference: {
    enabled: boolean;
    message: string;
    actions: {
      access_data: NotificationAction;
      delete_data: NotificationAction;
      unsubscribe: NotificationAction;
    };
  };
}

const setPrivacy = async (data: PrivacyData) => {
  const user = getUser();
  const { data: responseData, error } = await post(
    `/api/v1/website/${user.website.subdomain}/config/privacy/`,
    data,
    {
      baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}`,
    },
  );

  if (error)
    return {
      error,
      data: null,
    };

  return { data: responseData, error: null };
};

const getPrivacy = async () => {
  const user = getUser();
  const { data, error } = await get(
    `/api/v1/website/${user.website.subdomain}/config/privacy/`,
    null,
    {
      baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}`,
    },
  );

  if (error)
    return {
      error,
      data: null,
    };

  return { data, error: null };
};

const getReports = () => get(`/dashboard/merchant-report/`);

const sendReportEmail = reportId =>
  post(`/dashboard/merchant-report/${reportId}/email/`, null);

const saveReport = report =>
  put(`/dashboard/merchant-report/${report.id}`, report);

const getAttributionWindow = () =>
  get('/dashboard/preferences/?keys=attribution_window');

const saveAttributionWindow = payload =>
  post('/dashboard/preferences/', payload);

export default {
  getConsumption,
  getGILBreakdown,
  getPrivacy,
  setPrivacy,
  setCompanyLogo,
  setNotificationBadge,
  setFCM,
  getFCM,
  getReports,
  sendReportEmail,
  saveReport,
  getAttributionWindow,
  saveAttributionWindow,
  getKlaviyoIntegration,
  setKlaviyoIntegration,
};
