import styled from 'styled-components';
import avatarVariants from '../../variants/avatar';

interface Props {
  size: string;
  alt: string;
  src: string;
}

const StyledAvatar = styled.img`
  border-radius: 50%;
  ${(props: Props) => {
    const size = avatarVariants[props.size];
    return `width: ${size}; height: ${size};`;
  }};
`;

export default StyledAvatar;
