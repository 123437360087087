import { useMemo } from 'react';
import debounce from 'lodash.debounce';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState, Dispatch } from 'src/store';

const useCreateNotification = () => {
  const state = useSelector(
    (storeState: StoreState) => storeState.notificationCreator,
  );
  const campaignCreatorState = useSelector(
    (storeState: StoreState) => storeState.campaignCreator,
  );
  const dispatch: Dispatch = useDispatch();

  const { notificationCreator, campaignCreator } = dispatch;

  const debouncedGetPreview = useMemo(
    () => debounce(notificationCreator.getPreview, 1000),
    [],
  );

  const saveAsDraft = () => {
    if (campaignCreatorState.isDraft) {
      campaignCreator.createDraftCampaign();
    }
  };

  const debouncedSaveAsDraft = useMemo(() => debounce(saveAsDraft, 1000), []);

  const getPropSetter =
    (prop: string) => (value: string | boolean | number) => {
      notificationCreator.setProp({ value, prop });

      debouncedGetPreview();
      debouncedSaveAsDraft();
    };

  const actions = useMemo(
    () => ({
      setCampaignTemplates: payload =>
        notificationCreator.setCampaignTemplates(payload),
      shut: () => notificationCreator.shut(),
      loadState: (newState: AnyObject) =>
        notificationCreator.loadState(newState),
      setTitle: getPropSetter('title'),
      setShouldShortenURL: getPropSetter('shouldShortenURL'),
      setNotificationCreatorType: getPropSetter('notificationCreatorType'),
      setPreviewCountryCode: getPropSetter('countryCode'),
      setErrors: notificationCreator.setErrors,
      setReminderTime: getPropSetter('reminderTime'),
      setMinimumCartValue: getPropSetter('minimumCartValue'),
      setMessage: getPropSetter('message'),
      setIcon: getPropSetter('icon'),
      setPrimaryLink: getPropSetter('primaryLink'),
      // handleDiscountCode,
      setDiscountCode: getPropSetter('discountCode'),
      setTitleError: getPropSetter('titleError'),
      setPrimaryLinkError: getPropSetter('primaryLinkError'),
      setDiscountCodeError: getPropSetter('discountCodeError'),
      addButton: notificationCreator.addButton,
      updateButton: (button: any) => {
        notificationCreator.updateButton(button);
      },
      removeButton: (index: number) => {
        notificationCreator.removeButton(index);
      },
      setDesktopHeroImage: (url: string) => {
        notificationCreator.setHeroImage({
          desktop: url,
        });
      },
      setMobileHeroImage: (url: string) => {
        notificationCreator.setHeroImage({
          mobile: url,
        });
      },
      setMacHeroImage: (url: string) => {
        notificationCreator.setHeroImage({
          macos: url,
        });
      },
    }),
    [getPropSetter],
  );

  return {
    actions,
    state,
  };
};

export default useCreateNotification;
