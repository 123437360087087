import colors from 'barn/tokens/colors';

export const subscribersLight = {
  bold: {
    color: colors.greys[0],
  },
  chart: {
    previous: {
      color: '#4dd5a4',
    },
    current: {
      color: '#16814f',
    },
  },
  tooltip: {
    bg: colors.greys[8],
  },
};

export const subscribersDark = {
  ...subscribersLight,
  bold: {
    color: colors.greys[7],
  },
  tooltip: {
    bg: colors.greys[12],
  },
};
