import RegexValidations from 'src/lib/regex-validations';
import * as yup from 'yup';
import { NCState, Button } from '../models';

export const urlValidation = (value: string) =>
  /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g.test(
    value,
  );

const title = yup.string().trim().required('campaign_creator.empty_title');

const minimumCartValue = yup
  .number()
  .typeError('Minimum Cart Value is required')
  .required('No Minimum Cart Value: Please enter a Minimum Cart Value')
  .min(1, 'Minimum Cart Value should be more than 0');

const message = yup
  .string()
  .required('campaign_creator.empty_description')
  .min(1, 'campaign_creator.min_length_description')
  .test(
    'should not be empty',
    'campaign_creator.whitespace_description',
    value => value.trim().length > 0,
  );

const discountCode = yup
  .string()
  .notRequired()
  .nullable()
  // discount code is not mandatory but when it is present then the following piece of code will run
  .when('discountCode', {
    is: (value: string) => value?.length,
    then: yup
      .string()
      .ensure()
      .test(
        'Discount code should not be empty',
        'Discount code can not be blank spaces',
        value => value.trim().length > 0,
      ),
  })
  // discount code is not mandatory but when it is present then the following piece of code will run
  .when('discountCode', {
    is: (value: string) => value?.length,
    then: yup
      .string()
      .ensure()
      .test(
        'Discount code should not be a url',
        'Enter only the discount code here. Please do not enter a link.',
        value => !urlValidation(value),
      ),
  });

// Whenever there's conditional validation in your schema there's a cyclic dependency created. Yup asks you to specify them like this [['discountCode', 'discountCode']] in a dependency array
export const acrValidationSchema = yup.object().shape(
  {
    minimumCartValue,
    message,
    discountCode,
  },
  [['discountCode', 'discountCode']],
);

export const validationSchema = yup.object().shape(
  {
    message,
    discountCode,
  },
  [['discountCode', 'discountCode']],
);

export const welcomeValidationSchema = yup.object().shape({
  message,
});

export const campaignValidationSchema = yup.object().shape({
  title,
  message,
});

const isValidButton = (button: Button) => {
  return button.title && RegexValidations.url(button.link);
};

const isValidAutomationButton = (button: Button) => {
  return (button.title || '').trim();
};

export const WARN_MESSAGE_LENGTH = 100;
export const MAX_MESSAGE_LENGTH = 9500;

const isValidNotification = (data: NCState) => {
  const { title, message, primaryLink, buttons } = data;

  if (!title || !RegexValidations.url(primaryLink)) {
    return false;
  }

  if (message && message.length > MAX_MESSAGE_LENGTH) {
    return false;
  }

  if (!!buttons && buttons[0] && !isValidButton(buttons[0])) {
    return false;
  }

  if (!!buttons && buttons[1] && !isValidButton(buttons[1])) {
    return false;
  }

  return true;
};

export const isValidAutomationNotification = (
  data: NCState,
  isPrimaryLinkMandatory = false,
): boolean => {
  const { title, buttons } = data;

  if (!(title || '').trim()) {
    return false;
  }

  // If primaryLink is mandatory valdidate it
  if (isPrimaryLinkMandatory && !RegexValidations.url(data.primaryLink)) {
    return false;
  }

  // If primaryLink is not mandatory check if it is valid url when user enters one
  if (
    !isPrimaryLinkMandatory &&
    data.primaryLink &&
    !RegexValidations.url(data.primaryLink)
  ) {
    return false;
  }

  if (!!buttons && buttons[0] && !isValidAutomationButton(buttons[0])) {
    return false;
  }

  if (!!buttons && buttons[1] && !isValidAutomationButton(buttons[1])) {
    return false;
  }

  return true;
};

export default isValidNotification;

export const getLinkError = (hasError: boolean, value: string) => {
  if (!hasError) {
    return undefined;
  }

  if (!value) {
    return 'Empty link: Please enter a URL';
  }

  if (!RegexValidations.url(value)) {
    return 'Enter valid primary link';
  }

  return null;
};

export const getTitleError = (hasError: boolean, value: string) => {
  if (!hasError) {
    return undefined;
  }

  if (!value) {
    return 'No title: Please enter a Title';
  }

  return null;
};

export const getDiscountCodeError = (value: null | string) => {
  if (!value) return null;

  if (RegexValidations.url(value)) {
    return 'Enter only the discount code here. Add discount code link in the "Primary Link" field';
  }

  return null;
};
