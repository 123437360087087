import { post, get, put } from './axios';

const ENDPOINT_PREFIX = '/dashboard/segment';
const getActive = () => get(`${ENDPOINT_PREFIX}/`);

const getAll = () => get(`${ENDPOINT_PREFIX}/?states=active,pending,archived`);

const getCampaignsFor = ({ id, offset }) =>
  get(`${ENDPOINT_PREFIX}/${id}/campaign/?limit=10&offset=${offset}`);

const saveSegment = ({ id, ...data }) => put(`${ENDPOINT_PREFIX}/${id}`, data);

const saveNewSegment = data => post(`${ENDPOINT_PREFIX}/`, data);

const getSegmentSize = data => post(`${ENDPOINT_PREFIX}/estimate/`, data);

const changeSegmentState = (segmentId, action) =>
  post(`${ENDPOINT_PREFIX}/${segmentId}/state-change/?action=${action}`, null);

export default {
  getActive,
  getAll,
  getCampaignsFor,
  saveSegment,
  saveNewSegment,
  getSegmentSize,
  changeSegmentState,
};
