import colors from 'barn/tokens/colors';

export const segmentBuilderLight = {
  base: {
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    bg: colors.greys[8],
    active: {
      borderColor: colors.greens[4],
    },
  },
  whiteStrip: {
    bg: colors.greys[8],
  },
  connector: {
    bg: colors.greys[5],
  },
  container: {
    borderColor: colors.greys[5],
    bg: colors.greys[6],
  },
};

export const segmentBuilderDark = {
  base: {
    boxShadow: 'none',
    bg: colors.greys[9],
    active: {
      borderColor: colors.greens[4],
    },
  },
  whiteStrip: {
    bg: colors.greys[8],
  },
  connector: {
    bg: colors.greys[15],
  },
  container: {
    borderColor: colors.greys[1],
    bg: colors.greys[11],
  },
};
