import { createModel } from '@rematch/core';
import api from 'src/lib/api';
import { RootModel } from 'src/store/models';
import { ButtonVariant, ButtonAppearance } from 'barn/components/Button';

export type ButtonType = {
  text: string;
  handler?: string;
  action?: string;
  url?: string;
  target?: string;
  buttonAction?: BANNER_BUTTON_ACTION;
  disabled?: boolean;
  variant?: ButtonVariant;
  appearance?: ButtonAppearance;
  isLoading?: boolean;
};

type BannerState = {
  banners: [
    {
      buttons: Array<ButtonType>;
      category: string;
      icon: string;
      title: string;
      paths: [string];
      description: string;
    }?,
  ];
  isFetching: boolean;
  isAnimationDone: boolean;
};

export enum BANNER_BUTTON_ACTION {
  START_SYNC_SUBSCRIBERS = 'start_sync_subscribers',
  DISMISS_SYNC_SUBSCRIBERS = 'dismiss_sync_subscribers',
  OPEN_REVIEW_DETAILS_MODAL = 'open_review_details_modal',
  DISMISS_NUMBER_VERIFICATION = 'dismiss_number_verification',
  CONTACT_SUPPORT = 'contact_support',
}

const banners = createModel<RootModel>()({
  state: {
    banners: [],
    isFetching: true,
    isAnimationDone: false,
  } as BannerState,

  reducers: {
    setState(state: BannerState, payload: Partial<BannerState>) {
      return {
        ...state,
        ...payload,
      };
    },
  },

  effects: () => ({
    async fetchBanners() {
      const { data, error } = await api.misc.fetchBanners();
      this.setState({ banners: error ? [] : data, isFetching: false });
    },
  }),
});

export default banners;
