import colors from 'barn/tokens/colors';

export const datePickerLight = {
  borderColor: '#ccc',
  bg: colors.greys[8],
  color: '#000',
  triangle: {
    color: '#aeaeae',
    borderTopColor: colors.greys[8],
    borderBottomColor: '#f0f0f0',
  },
  header: {
    bg: '#f0f0f0',
    borderColor: '#aeaeae',
  },
  list: {
    bg: 'white',
    color: colors.greys[1],
  },
  hover: {
    bg: '#f0f0f0',
    color: colors.greys[9],
  },
  disabled: {
    color: '#ccc',
  },
  selected: {
    bg: '#2a87d0',
    color: colors.greys[8],
    keyboard: {
      bg: '#2a87d0',
      color: colors.greys[8],
    },
    hover: {
      bg: '#1d5d90',
      color: colors.greys[7],
    },
  },
};

export const datePickerDark = {
  borderColor: colors.greys[16],
  bg: colors.greys[12],
  color: colors.greys[7],
  triangle: {
    color: colors.greys[11],
    borderTopColor: colors.greys[11],
    borderBottomColor: colors.greys[11],
  },
  header: {
    bg: colors.greys[9],
    borderColor: colors.greys[9],
  },
  list: {
    bg: colors.greys[11],
    color: colors.greys[8],
  },
  hover: {
    bg: colors.greys[7],
    color: colors.greys[1],
  },
  disabled: {
    color: colors.greys[1],
  },
  selected: {
    bg: colors.greens[9],
    color: colors.greys[8],
    keyboard: {
      bg: colors.greys[9],
      color: colors.greys[8],
    },
    hover: {
      bg: colors.greys[7],
      color: colors.greys[1],
    },
  },
};
