const RegexValidations = {
  url: (value: string) =>
    /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g.test(
      value,
    ),
  email: (value: string) => {
    if (value === '') return false;

    const input = document.createElement('input');
    input.type = 'email';
    input.value = value;

    return input.checkValidity();
  },
};

export default RegexValidations;
