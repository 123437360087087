import colors from 'barn/tokens/colors';

export const radioLight = {
  checkmark: {
    borderColor: colors.greys[1],
    after: {
      bg: colors.greens[4],
    },
  },
  locked: {
    borderColor: colors.greys[5],
    color: colors.greys[3],
  },
  checked: {
    checkmark: {
      borderColor: colors.greens[4],
    },
  },
};

export const radioDark = {
  checkmark: {
    borderColor: colors.greys[4],
    after: {
      bg: colors.greens[9],
    },
  },
  locked: {
    borderColor: colors.greys[3],
    color: colors.greys[3],
  },
  checked: {
    checkmark: {
      borderColor: colors.greens[9],
    },
  },
};
