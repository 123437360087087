import { HStack } from 'barn/components/Stack';
import React, { useEffect, useRef } from 'react';
import tokens from 'barn/tokens';
import { Text } from 'barn/components/Text';
import ColorPicker from './ColorPicker';
import StyledInput, {
  StyledInputContainer,
  StyledLabel,
  StyledAsterisk,
  StyledError,
  StyledHelpText,
} from './styled';

export const SimpleInput = StyledInput;
export const Label = StyledLabel;

interface InputProps {
  onChange?: (value: string | number) => void;
  type?: string;
  value?: string | number;
  placeholder?: string;
  name: string;
  label?: string;
  hasError?: boolean;
  disabled?: boolean;
  style?: AnyObject;
  fullWidth?: boolean;
  isMandatory?: boolean;
  autoFocus?: boolean;
  inactive?: boolean;
  validate?: (value?: any) => boolean;
  tabIndex?: any;
  error?: string | undefined;
  colorPicker?: boolean;
  testHandle?: string;
  min?: number;
  max?: number;
  rightElement?: any;
  helpText?: string;
}
const Input: React.FunctionComponent<InputProps> = ({
  name,
  onChange = null,
  type = 'text',
  value = '',
  placeholder = '',
  label = null,
  autoFocus = false,
  hasError = false,
  disabled = false,
  style = {},
  fullWidth = false,
  isMandatory = false,
  inactive = false,
  tabIndex = 0,
  error,
  colorPicker,
  testHandle,
  min,
  max,
  rightElement,
  helpText = null,
}) => {
  const className = `${error ? 'error' : ''} ${inactive ? 'inactive' : ''}`;
  const inputRef = useRef<HTMLInputElement>(null);
  const cursorPosition = useRef<number>();

  const handleChange: ReactEventHandler = event => {
    const newValue = event.target.value;

    // Save cursor position on user event
    cursorPosition.current = inputRef!.current!.selectionStart; // eslint-disable-line

    if (onChange) {
      onChange(type === 'number' ? parseInt(newValue, 10) : newValue);
    }
  };

  useEffect(() => {
    if (hasError && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [hasError, inputRef]);

  // Async updates cause jumps in cursor. So we reset to the last saved cursor
  useEffect(() => {
    if (!cursorPosition.current) return;
    inputRef.current.setSelectionRange(
      cursorPosition.current,
      cursorPosition.current,
    );
  }, [value]);

  // set 100% width if `fullWidth` is supplied
  if (fullWidth) {
    // eslint-disable-next-line
    style.width = '100%';
  }

  if (colorPicker || rightElement) {
    // eslint-disable-next-line no-param-reassign, prefer-destructuring
    style.paddingRight = tokens.space.padding[9];
  }

  const input = (
    <StyledInput
      ref={inputRef}
      id={name}
      name={name}
      className={className}
      type={type}
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      disabled={disabled || inactive}
      tabIndex={tabIndex}
      style={style}
      data-test={testHandle}
      {...(type === 'number' ? { min, max } : {})}
      autoFocus={autoFocus}
    />
  );

  return (
    <StyledInputContainer>
      {label && (
        <StyledLabel htmlFor={name}>
          {label}
          {isMandatory && <StyledAsterisk>*</StyledAsterisk>}
        </StyledLabel>
      )}
      {helpText ? (
        <StyledHelpText>
          {' '}
          <Text variant='secondary'>{helpText}</Text>
        </StyledHelpText>
      ) : null}

      {colorPicker || rightElement ? (
        <HStack align='center' position='relative'>
          {input}
          {rightElement || (
            <ColorPicker
              value={value as string}
              onChange={onChange}
              inputRef={inputRef}
            />
          )}
        </HStack>
      ) : (
        input
      )}
      {error && (
        <StyledError data-test={`${testHandle}-error`}>{error}</StyledError>
      )}
    </StyledInputContainer>
  );
};

export default Input;
