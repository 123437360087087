import Axios, { AxiosError, Method } from 'axios';
import retry from 'axios-retry';
import store from 'src/store';
import {
  axiosInterceptResponseErrorHandler,
  axiosInterceptorPlatformHandler,
  axiosInterceptRequestHandler,
  isRetryable,
} from './utils';
import { EnhancedAxiosInstance } from './types';

const axios: EnhancedAxiosInstance = Axios.create({});

axios.interceptors.request.use(axiosInterceptorPlatformHandler);
axios.interceptors.request.use(axiosInterceptRequestHandler);
axios.interceptors.response.use(res => res, axiosInterceptResponseErrorHandler);
retry(axios, { retryCondition: isRetryable });

export const getUser = () => {
  if (store) {
    return store.getState().user.user;
  }
  return {
    subdomain: '',
    token: '',
    website: {
      subdomain: '',
    },
  };
};

const getBaseURL = () => {
  const user = getUser();
  if (user) {
    return `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/v1/${user?.website?.subdomain}/dashboard`;
  }

  return ``;
};

// export const getUser = () => {};

/**
 * This map will keep a record of the requests being made by the helpers so that we
 * can warn the user if they attempt to close the app when an operation is in progress
 */
export const actionRequestTracker = new Map<string, true>();

/**
 * Overloaded methods for makeAxiosRequest
 *
 * GET & DELETE do not need data, while it's
 * required for the others.
 */
function makeAxiosRequest(
  method: 'GET' | 'DELETE',
): (
  url: string,
  data?: null,
  config?: { baseURL?: string; dataFallback?: any },
) => Promise<{ data; error: string; status: string | number }>;

function makeAxiosRequest(
  method: 'POST' | 'PUT' | 'PATCH',
): (
  url: string,
  data: AnyObject,
  config?: { baseURL?: string; dataFallback?: any },
) => Promise<{ data; error: string; status: string | number }>;

function makeAxiosRequest(method: Method) {
  return (url: string, data, config) => {
    if (method !== 'GET') actionRequestTracker.set(url, true);

    return axios({
      url,
      method,
      baseURL: config?.baseURL || getBaseURL(),
      data,
    })
      .then(({ data, status }) => ({
        data: data || config?.dataFallback,
        error: null,
        status,
      }))
      .catch((error: AxiosError) => ({
        data: config?.dataFallback || {},
        status: error.response?.status,
        error: error.response?.data?.error || error.message,
      }))
      .finally(() => {
        actionRequestTracker.delete(url);
      });
  };
}

const get = makeAxiosRequest('GET');
const post = makeAxiosRequest('POST');
const put = makeAxiosRequest('PUT');
const patch = makeAxiosRequest('PATCH');
const del = makeAxiosRequest('DELETE');

export { get, post, put, del, patch };

export type { APIResponseObject, AxiosResponse } from './types';

// Updating the base URL of the default axios instance.
// When this function is called it will give an instance with correnct base URL
export const getAxios = () => {
  axios.defaults.baseURL = getBaseURL();
  return axios;
};

export default axios;
