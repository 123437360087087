import colors from 'barn/tokens/colors';

export const dateRangeLight = {
  color: colors.greys[2],
  bg: colors.greys[6],
};

export const dateRangeDark = {
  ...dateRangeLight,
  color: colors.greys[7],
  bg: colors.greys[11],
};
