export const Owly100 = () => {
  return (
    <svg
      width='400'
      height='250'
      viewBox='0 0 400 250'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_304_27179)'>
        <g filter='url(#filter0_f_304_27179)'>
          <path
            d='M156.326 8L129.826 241H268.326L244.826 5C244.326 9.33333 237.126 20 204.326 20C171.526 20 159.493 10.8333 156.326 8Z'
            fill='#FFCF00'
          />
          <path
            d='M123.826 55.5C134.453 34.6137 147.569 18.8463 154.18 12.1186C154.795 11.4932 155.82 11.9431 155.802 12.8197C155.421 30.7141 150.744 44.6848 148.826 49C146.826 53.5 153.326 70.5 150.826 74C146.826 84.4 110.326 96 92.3264 102.5L145.826 108.5L137.826 172C17.8265 140.5 -4.99678 123.773 0.826478 96C7.32635 65 117.212 68.5 123.826 55.5Z'
            fill='#FFCF00'
          />
          <path
            d='M277.826 55.5C267.2 34.6137 254.084 18.8463 247.473 12.1186C246.858 11.4932 245.832 11.9431 245.851 12.8197C246.232 30.7141 250.908 44.6848 252.826 49C254.826 53.5 248.326 70.5 250.826 74C254.826 84.4 289.16 97.3333 305.826 102.5L254.826 108.5L261.826 172.5C381.826 141 405.326 119.5 398.326 92C391.326 64.5 280.826 61 277.826 55.5Z'
            fill='#FFCF00'
          />
          <mask
            id='mask0_304_27179'
            style={{ maskType: 'alpha' }}
            maskUnits='userSpaceOnUse'
            x='129'
            y='5'
            width='140'
            height='236'
          >
            <path
              d='M156.326 8L129.826 241H268.326L244.826 5C244.326 9.33333 237.126 20 204.326 20C171.526 20 159.493 10.8333 156.326 8Z'
              fill='#FFCF00'
            />
          </mask>
          <g mask='url(#mask0_304_27179)'>
            <path
              d='M202.326 103C185.926 92.6003 155.16 98.6669 141.826 103L144.826 62L191.826 57L259.326 59L266.826 101C237.226 89.4001 211.493 97.5002 202.326 103Z'
              fill='#FFB600'
            />
            <path
              d='M268.326 240.5C159.126 240.5 136.826 185.833 139.326 158.5L76.3263 232.5L143.326 277C230.493 264.833 377.526 240.5 268.326 240.5Z'
              fill='#FFB600'
            />
          </g>
          <mask
            id='mask1_304_27179'
            style={{ maskType: 'alpha' }}
            maskUnits='userSpaceOnUse'
            x='245'
            y='11'
            width='155'
            height='162'
          >
            <path
              d='M277.826 55.5C267.2 34.6137 254.084 18.8463 247.473 12.1186C246.858 11.4932 245.832 11.9431 245.851 12.8197C246.232 30.7141 250.909 44.6848 252.826 49C254.826 53.5 248.326 70.5 250.826 74C254.826 84.4 289.16 97.3333 305.826 102.5L254.826 108.5L261.826 172.5C381.826 141 405.326 119.5 398.326 92C391.326 64.5 280.826 61 277.826 55.5Z'
              fill='#FFCF00'
            />
          </mask>
          <g mask='url(#mask1_304_27179)'>
            <path
              d='M367.326 138.5C320.526 107.3 273.493 107.5 255.826 111.5L254.326 108.5L304.326 102.5C388.326 135 396.326 115.5 400.826 113C405.326 110.5 425.826 177.5 367.326 138.5Z'
              fill='#FFB600'
            />
            <path
              d='M248.326 68C259.826 57.5 256.326 34.5 247.326 28.5H239.826L236.826 51.5L248.326 68Z'
              fill='#FFB600'
            />
            <path
              d='M259.877 152.5C273.877 168.5 318.877 155.5 320.377 155.5C307.043 168.833 276.277 195.1 259.877 193.5C239.377 191.5 245.877 136.5 259.877 152.5Z'
              fill='#FFB600'
            />
          </g>
          <mask
            id='mask2_304_27179'
            style={{ maskType: 'alpha' }}
            maskUnits='userSpaceOnUse'
            x='0'
            y='11'
            width='156'
            height='161'
          >
            <path
              d='M123.826 55.5C134.453 34.6137 147.569 18.8463 154.18 12.1186C154.795 11.4932 155.82 11.9431 155.802 12.8197C155.421 30.7141 150.744 44.6848 148.826 49C146.826 53.5 153.326 70.5 150.826 74C146.826 84.4 110.326 96 92.3264 102.5L145.826 108.5L137.826 172C17.8265 140.5 -4.99678 123.773 0.826478 96C7.32635 65 117.212 68.5 123.826 55.5Z'
              fill='#FFCF00'
            />
          </mask>
          <g mask='url(#mask2_304_27179)'>
            <path
              d='M32.4677 138.496C42.8264 108.498 99.8264 104.998 143.968 111.496L145.468 108.496L95.4677 102.496C0.826355 127.997 0.826355 104.997 -3.67365 102.497C-8.17365 99.9972 9.51913 204.954 32.4677 138.496Z'
              fill='#FFB600'
            />
            <path
              d='M152.326 68C140.826 57.5 144.326 34.5 153.326 28.5H160.826L163.826 51.5L152.326 68Z'
              fill='#FFB600'
            />
            <path
              d='M140.326 152.5C126.326 168.5 81.3264 155.5 79.8264 155.5C93.1597 168.833 123.926 195.1 140.326 193.5C160.826 191.5 154.326 136.5 140.326 152.5Z'
              fill='#FFB600'
            />
          </g>
          <path
            d='M139.826 156.5C136.66 184.5 130.326 240.5 130.326 240.5'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
          />
          <path
            d='M259.826 156.5C262.993 184.5 267.826 240.5 267.826 240.5'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
          />
          <path
            d='M46.3264 113C59.1597 111.333 89.4264 105.8 107.826 97'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
          />
          <path
            d='M355.826 116.5C342.993 114.833 303.726 103.8 285.326 95'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
          />
          <path
            d='M200.826 110C196.426 107.6 189.16 82.5 186.826 70.5L199.326 60L213.826 66.5C210.826 81.8333 205.226 112.4 200.826 110Z'
            fill='#1E1E1E'
          />
          <path
            d='M165.996 24.3007C167.663 20.3007 172.996 14.7007 180.996 24.3007'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
          />
          <path
            d='M232.996 24.3007C231.33 20.3007 225.996 14.7007 217.996 24.3007'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
          />
          <ellipse
            cx='174.382'
            cy='61.753'
            rx='26.5645'
            ry='31.7697'
            transform='rotate(19.2557 174.382 61.753)'
            fill='white'
          />
          <mask
            id='mask3_304_27179'
            style={{ maskType: 'alpha' }}
            maskUnits='userSpaceOnUse'
            x='147'
            y='30'
            width='55'
            height='64'
          >
            <ellipse
              cx='174.382'
              cy='61.753'
              rx='26.5645'
              ry='31.7697'
              transform='rotate(19.2557 174.382 61.753)'
              fill='white'
            />
          </mask>
          <g mask='url(#mask3_304_27179)'>
            <path
              d='M157.917 86.0278C155.135 87.7406 148.811 85.3142 145.996 83.8868L159.407 98.8738C167.685 99.3495 184.241 100.23 184.241 99.9443C184.241 99.5874 201.129 92.094 202.619 90.3098C204.109 88.5257 201.129 85.3142 194.672 83.8868C188.215 82.4595 188.712 83.8868 184.241 86.0278C179.771 88.1688 174.804 86.0278 171.327 83.8868C167.851 81.7458 161.394 83.8868 157.917 86.0278Z'
              fill='#2FBCE3'
            />
          </g>
          <ellipse
            rx='26.5645'
            ry='31.7697'
            transform='matrix(-0.944057 0.329784 0.329784 0.944057 226.552 61.753)'
            fill='white'
          />
          <ellipse cx='171.496' cy='70.5' rx='5.5' ry='9.5' fill='#1E1E1E' />
          <circle cx='172.996' cy='76' r='2' fill='#FFFDF9' />
          <ellipse cx='226.496' cy='70.5' rx='5.5' ry='9.5' fill='#1E1E1E' />
          <circle cx='224.996' cy='76' r='2' fill='#FFFDF9' />
          <mask
            id='mask4_304_27179'
            style={{ maskType: 'alpha' }}
            maskUnits='userSpaceOnUse'
            x='199'
            y='30'
            width='55'
            height='64'
          >
            <ellipse
              rx='26.5645'
              ry='31.7697'
              transform='matrix(-0.944057 0.329784 0.329784 0.944057 226.552 61.753)'
              fill='white'
            />
          </mask>
          <g mask='url(#mask4_304_27179)'>
            <path
              d='M246.076 86.0278C248.857 87.7406 255.182 85.3142 257.996 83.8868L244.586 98.8738C236.307 99.3495 219.751 100.23 219.751 99.9443C219.751 99.5874 202.864 92.094 201.373 90.3098C199.883 88.5257 202.864 85.3142 209.321 83.8868C215.778 82.4595 215.281 83.8868 219.751 86.0278C224.221 88.1688 229.188 86.0278 232.665 83.8868C236.142 81.7458 242.599 83.8868 246.076 86.0278Z'
              fill='#2FBCE3'
            />
          </g>
          <path
            d='M248.825 102.604C249.074 104.751 247.534 106.692 245.388 106.94C243.241 107.188 241.3 105.649 241.052 103.502C240.804 101.356 243.742 97.5649 243.816 93.3362C243.89 89.1074 248.577 100.457 248.825 102.604Z'
            fill='#2FBCE3'
          />
        </g>
        <line
          x1='93.5'
          y1='242.5'
          x2='305.5'
          y2='242.5'
          stroke='black'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <rect
          x='172'
          y='139'
          width='54'
          height='102'
          rx='1'
          fill='white'
          fillOpacity='0.5'
        />
        <mask id='path-35-inside-1_304_27179' fill='white'>
          <rect x='172' y='139' width='54' height='102' rx='1' />
        </mask>
        <rect
          x='172'
          y='139'
          width='54'
          height='102'
          rx='1'
          stroke='#FF4952'
          strokeWidth='4'
          mask='url(#path-35-inside-1_304_27179)'
        />
        <rect
          x='1'
          y='-1'
          width='30'
          height='6'
          rx='1'
          transform='matrix(1 0 0 -1 183 136)'
          fill='#FF4952'
          stroke='#FF4952'
          strokeWidth='2'
        />
        <rect
          x='215'
          y='146'
          width='7'
          height='44'
          rx='3.5'
          fill='white'
          fillOpacity='0.5'
        />
        <rect
          x='215'
          y='193'
          width='7'
          height='7'
          rx='3.5'
          fill='white'
          fillOpacity='0.5'
        />
        <path
          d='M190.57 161.835C190.262 157.603 193.612 154 197.855 154C202.02 154 205.342 157.476 205.153 161.637L203.207 204.443C203.091 206.993 200.991 209 198.439 209C195.937 209 193.86 207.068 193.678 204.573L190.57 161.835Z'
          fill='#FF4952'
        />
        <circle cx='199' cy='221' r='6' fill='#FF4952' />
        <line
          x1='198.5'
          y1='116.5'
          x2='198.5'
          y2='111.5'
          stroke='#FF4952'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <line
          x1='240.5'
          y1='191.5'
          x2='245.5'
          y2='191.5'
          stroke='#FF4952'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <line
          x1='240.138'
          y1='161.446'
          x2='244.836'
          y2='159.735'
          stroke='#FF4952'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <line
          x1='233.185'
          y1='131.029'
          x2='237.015'
          y2='127.815'
          stroke='#FF4952'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <line
          x1='152.5'
          y1='191.5'
          x2='157.5'
          y2='191.5'
          stroke='#FF4952'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <line
          x1='153.164'
          y1='158.735'
          x2='157.862'
          y2='160.445'
          stroke='#FF4952'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <line
          x1='161.113'
          y1='127.815'
          x2='164.943'
          y2='131.029'
          stroke='#FF4952'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <line
          x1='1.5'
          y1='-1.5'
          x2='6.5'
          y2='-1.5'
          transform='matrix(0.939693 -0.34202 -0.34202 -0.939693 151.241 222.736)'
          stroke='#FF4952'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <line
          x1='244.595'
          y1='223.633'
          x2='239.897'
          y2='221.923'
          stroke='#FF4952'
          strokeWidth='3'
          strokeLinecap='round'
        />
      </g>
      <defs>
        <filter
          id='filter0_f_304_27179'
          x='-2'
          y='3'
          width='403.447'
          height='240.5'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='1'
            result='effect1_foregroundBlur_304_27179'
          />
        </filter>
        <clipPath id='clip0_304_27179'>
          <rect width='400' height='250' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
