import React from 'react';
import Button from 'barn/components/Button';
import PlusIcon from 'barn/icons/Plus';
import { useNewCampaign } from 'src/hooks/use-new-campaign';
import {
  TrackingLocation,
  TrackingElement,
  track,
  TrackingCategory,
  TrackingAction,
} from 'src/lib/tracking';
import { useGlobalState } from 'src/hooks/use-global-state';
import { SendType } from 'src/modules/campaign-creator/models';
import { useTranslation } from 'next-i18next';

interface Props {
  label?: string;
  location: TrackingLocation;
  sendType?: SendType;
  iconOnly?: Boolean;
}
const NewCampaign = ({
  label,
  location,
  sendType = SendType.NOW,
  iconOnly = false,
}: Props) => {
  const { startNewCampaign } = useNewCampaign();
  const { t } = useTranslation('left_navigation');

  // Wait for the consumption API to complete before allowing new campaign creation
  const {
    state: { consumption },
  } = useGlobalState('settings');

  return (
    <Button
      onClick={() => {
        track({
          element: TrackingElement.CREATE_CAMPAIGN_BUTTON,
          category: TrackingCategory.CAMPAIGN_CREATION,
          location,
          action: TrackingAction.CLICKED,
        });

        startNewCampaign({ sendType });
      }}
      icon={!(consumption.isFetching && iconOnly) && <PlusIcon />}
      testHandle='new-campaign-btn'
      isLoading={consumption.isFetching}
    >
      {!iconOnly && (label || t('new_campaign'))}
    </Button>
  );
};

export default NewCampaign;
