/**
 * NOTE: This is NOT Redux Store.
 * A window.localStorage utility.
 */

import { isShopifyEmbedded } from '@shopify/app-bridge-utils';
import { createBreadcrumb } from '../debug-utils';
import { isClientSide, secureUserObject } from '../utils';
import memstorage from './memstorage';

function createStorage(options = {}) {
  const defaultOpts = {
    namespace: '__PUSHOWL__',
    ttl: 365 * 60 * 60 * 24 * 1000, // One year
  };

  const storage =
    isClientSide() && !isShopifyEmbedded() ? window.localStorage : memstorage;

  // Options to be used by library
  const $opts = {
    ...defaultOpts,
    ...options,
    storage,
  };

  // Utils
  const getPrefixedKey = function getPrefixedKey(key) {
    return `${$opts.namespace}${key}`;
  };

  const getUnprefixedKey = function getUnprefixedKey(key) {
    return key.replace(new RegExp(`^${$opts.namespace}`), '');
  };

  const stringifyJSON = JSON.stringify;
  const parseJSON = JSON.parse;

  // Return the store
  return {
    set(key, data, ttl = $opts.ttl) {
      const prefixedKey = getPrefixedKey(key);
      const value = {
        ttl: Date.now() + ttl,
        data,
      };

      if (key === 'user')
        createBreadcrumb({
          message: 'User object being set',
          data: {
            oldValue: secureUserObject(this.get(key)),
            newValue: secureUserObject(data),
          },
        });

      $opts.storage.setItem(prefixedKey, stringifyJSON(value));
      return data;
    },

    get(key, fallback = null) {
      try {
        const prefixedKey = getPrefixedKey(key);
        const value = parseJSON($opts.storage.getItem(prefixedKey));

        if (value.data !== null) {
          if ('ttl' in value && Number(value.ttl) < Date.now()) {
            this.clear(key);
            return null;
          }

          if ('data' in value) {
            return value.data;
          }

          return fallback;
        }
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line
          console.warn(
            `PushowlStorage [get]: Can't find any value for '${key}' in the store.`,
          );
          return fallback;
        }
      }

      return fallback;
    },

    clear(key = null) {
      if (key === null) {
        $opts.storage.clear();
      } else {
        if (key === 'user')
          createBreadcrumb({ message: 'User object being removed' });

        $opts.storage.removeItem(getPrefixedKey(key));
      }

      return true;
    },

    clearAll() {
      if (isClientSide()) {
        storage.clear();
      }
    },

    keys() {
      return Object.keys($opts.storage).map(getUnprefixedKey);
    },

    has(key) {
      const prefixedKey = getPrefixedKey(key);
      return prefixedKey in $opts.storage;
    },
  };
}

export const getStorage = function getStorage() {
  return createStorage();
};

export default {
  getStorage,
};
