import { createModel } from '@rematch/core';
import api from 'src/lib/api';
import store from 'src/store';
import { RootModel } from 'src/store/models';

export type WelcomeMessageConfig = {
  enabled: boolean;
  message: string;
  should_shorten_url: boolean;
};

interface WelcomeMessageState {
  isFetching: boolean;
  config: WelcomeMessageConfig;
  notificationInEdit: number | null;
}

const initialState = (): WelcomeMessageState => ({
  isFetching: false,
  config: null,
  notificationInEdit: null,
});

const welcomeServiceMessage = createModel<RootModel>()({
  state: initialState(),

  effects: () => ({
    async fetchConfig() {
      this.storeState({ isFetching: true });

      const { data, error } = await api.serviceMessages.welcome.getConfig();
      if (!error) {
        this.storeState({ config: data, isFetching: false });
      } else {
        store.dispatch.saveToast.showError(
          'Unable to fetch config. Please try again',
        );
      }
    },

    async saveNotification(
      payload: AnyObject /* notification */,
      rootState,
    ): Promise<{ data: any; error: any }> {
      const state = rootState.welcomeServiceMessage;
      const newState = {
        ...state,
        config: {
          ...state.config,
          ...payload,
        },
        notificationInEdit: null,
      };
      // make network request
      const { data, error } = await api.serviceMessages.welcome.setConfig(
        newState.config,
      );
      if (!error) {
        this.storeState(newState);
        store.dispatch.saveToast.showDone('Your changes have been saved');
      } else {
        store.dispatch.saveToast.showError('Unable to save changes');
      }

      return data;
    },
  }),

  reducers: {
    storeState(state, payload: Partial<WelcomeMessageState>) {
      return {
        ...state,
        ...payload,
      };
    },

    initNotificationEditing(state, payload: number) {
      return {
        ...state,
        notificationInEdit: payload,
      };
    },

    shutNotificationEditing(state) {
      return {
        ...state,
        notificationInEdit: null,
      };
    },
  },
});

export default welcomeServiceMessage;
