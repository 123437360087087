import { get, post } from '../axios';

// endpoints
const CONFIG_ENDPOINT = `/config/welcome-service-message/`;

// query helpers
const getConfig = () => get(CONFIG_ENDPOINT);

const setConfig = data => post(CONFIG_ENDPOINT, data);

export default {
  getConfig,
  setConfig,
};
