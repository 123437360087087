import styled from 'styled-components';
import tokens from 'barn/tokens';

export const Wrapper = styled.div`
  padding: ${tokens.space.padding[2]} ${tokens.space.padding[2]}
    ${tokens.space.padding[3]};
  width: auto;
  height: auto;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${tokens.space.padding[2]} 0 0;
`;

export const DateText = styled.p`
  background-color: ${({ theme }) => theme.dateRange.bg};
  padding: ${tokens.space.padding[2]};
  color: ${({ theme }) => theme.dateRange.color};
`;
