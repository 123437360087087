import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import * as FullStory from '@fullstory/browser';
import { identify } from 'src/lib/tracking';
import { useGlobalState } from 'src/hooks/use-global-state';
import { gotoNext } from 'src/lib/goto-next';
import { loadAnalytics } from 'src/lib/tracking/utils';
import { isLocalStorageAvailable } from 'src/lib/utils';

export const GlobalStateInitializer = ({ children }) => {
  const {
    actions: { fetchConsumption },
  } = useGlobalState('settings');
  const {
    actions: { fetchReport },
  } = useGlobalState('home');

  const {
    state: {
      isLoggedIn,
      segmentData: {
        isSupportLogin,
        subdomain,
        traits,
        integrations: {
          integrations: {
            Intercom: { user_hash },
          },
        },
      },
    },
  } = useGlobalState('user');
  const router = useRouter();

  const isPostLoginWorkDone = useRef(false);
  const isIntercomLoaded = useRef(false);

  const handleShareWithPushOwl = async ({ redirectRoute, params }) => {
    try {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_ENDPOINT}/${decodeURIComponent(
          redirectRoute as string,
        )}/?${params.toString()}`,
        { redirect: 'manual' },
      );
      const data = await response.json();

      const redirectURL = new URL(data.redirect_url);
      gotoNext(redirectURL.searchParams.get('next') || '');
    } catch (error) {
      router.replace('/');
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(router.asPath);
    const redirect = params.get('redirectToApi');

    if (!isLoggedIn || !redirect) return;

    // Delete redirect as the rest of the query is to be forwarded
    params.delete('redirectToApi');

    // Remove leading and trailing slash (/)
    const redirectRoute = (redirect as string).match(/^\/?(.*?)\/?$/)[1];
    handleShareWithPushOwl({ redirectRoute, params });
  }, [isLoggedIn]);

  useEffect(() => {
    // Fetch global attribution window settings on session load
    if (!isPostLoginWorkDone.current && isLoggedIn) {
      if (!isSupportLogin) {
        identify(subdomain, traits);
        if (isLocalStorageAvailable()) {
          FullStory.identify(subdomain, traits);
        }
      }

      fetchConsumption();
      fetchReport();

      isPostLoginWorkDone.current = true;
    }
  }, [isLoggedIn, isSupportLogin]);

  useEffect(() => {
    if (
      isIntercomLoaded.current ||
      router.pathname.includes('/login') ||
      !isLoggedIn ||
      isSupportLogin
    )
      return;

    window.intercomSettings = {
      app_id: `${process.env.NEXT_PUBLIC_INTERCOM_API_KEY}`,
      alignment: 'right',
      horizontal_padding: 30,
      vertical_padding: 90,
    };

    window.Intercom('boot', {
      app_id: `${process.env.NEXT_PUBLIC_INTERCOM_API_KEY}`,
      user_hash,
      name: traits.name,
      user_id: subdomain,
    });

    setTimeout(() => {
      loadAnalytics();

      isIntercomLoaded.current = true;
    }, 3000);
  }, [isLoggedIn, router.pathname, isSupportLogin]);

  return children;
};
