/* eslint-disable no-console */

import { getAxios } from 'src/lib/api/axios';
import * as Sentry from '@sentry/browser';
import { getStorage } from 'src/lib/storage';
import store from 'src/store';
import { debugLog } from '../debug-utils';
import { getShopifySessionToken } from '../utils';

const storage = getStorage();

const axiosRequest = ({
  payload,
  sentryReport,
  catchHandler,
  shouldInitializeAnalytics,
  isSupportLogin,
}) => {
  const axios = getAxios();
  return axios(payload)
    .then(res => {
      const {
        data: { result, error, status },
      } = res;

      if (sentryReport && (error || result.detail)) {
        const { authToken, subdomain } = payload.data;

        // report login failure to sentry
        Sentry.withScope(scope => {
          scope.setExtras({
            status,
            authToken,
            subdomain,
            error,
          });
          scope.setTag('subdomain', subdomain);

          Sentry.captureException('Login Failed');
        });
      }

      if (error) return { error, user: null, isLoggedIn: false };

      const { account, websites } = result;

      const { id, name, email, token } = account;
      const website = websites.find(
        (site: AnyObject) => site.subdomain === payload.data.subdomain,
      );

      if (website) {
        const traits = {
          email: account.email,
          name: account.username,
          created_at: new Date(account.created_at).getTime() / 1000,
          subdomain: website.subdomain,
        };

        const segmentData = {
          subdomain: website.subdomain,
          shouldInitializeAnalytics,
          isSupportLogin,
          traits,
          integrations: {
            integrations: {
              Intercom: {
                user_hash: website.intercom_identity_hash,
              },
            },
          },
        };

        // save data for later use
        storage.set('segmentdata', segmentData);

        store.dispatch.user.setSegmentData(segmentData);
      }

      const user: any = {
        id,
        name,
        email,
        token,
        website,
        accountFlags: account.flags,
      };
      user.websites = websites;

      return { user, isLoggedIn: true };
    })
    .catch(catchHandler);
};
export interface LoginQueryParams {
  authToken: string;
  subdomain: string;
  platform: string;
}
const login = async (queryParams: LoginQueryParams) => {
  const { authToken, subdomain, platform } = queryParams;

  const catchHandler = error => {
    Sentry.withScope(scope => {
      scope.setExtras({
        queryParams,
        error,
      });
      scope.setTag('subdomain', subdomain);
      Sentry.captureException('Login Error');
    });

    debugLog({
      message: 'redirect to login',
      data: { error },
    });
    // redirect user to backend endpoint for login
    window.location.href = `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/v1/auth/${platform}/pushowl/?subdomain=${subdomain}`;

    return { user: null, isLoggedIn: false };
  };

  const sessionToken = await getShopifySessionToken();
  const headers: any = {
    Authorization: `JWT ${authToken}`,
  };

  if (sessionToken) {
    headers.SessionToken = `JWT ${sessionToken}`;
  }

  return axiosRequest({
    payload: {
      method: 'post',
      url: `${
        process.env.NEXT_PUBLIC_API_ENDPOINT
      }/api/v2/pushowl/token/authenticate/?${
        platform ? `platform=${platform}` : ''
      }`,
      data: {
        authToken,
        subdomain,
      },
      headers,
    },
    sentryReport: true,
    shouldInitializeAnalytics: true,
    isSupportLogin: false,
    catchHandler,
  });
};
interface SupportLoginParams {
  subdomain: string;
  password: string;
  platform: string;
}

const supportLogin = (authData: SupportLoginParams) => {
  const catchHandler = error => {
    console.log(error);
    return {
      user: null,
      isLoggedIn: false,
      error: 'Unauthorised: Invalid username or password',
    };
  };

  return axiosRequest({
    payload: {
      method: 'post',
      url: `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/v1/auth/support/`,
      data: authData,
    },
    sentryReport: false,
    shouldInitializeAnalytics: false,
    isSupportLogin: true,
    catchHandler,
  });
};

const refetchUser = ({
  authToken,
  subdomain,
  platform,
}: {
  authToken: string;
  subdomain: string;
  platform: string;
}) => {
  const segmentdata = storage.get('segmentdata');
  const isSupportLogin = segmentdata?.isSupportLogin;

  return axiosRequest({
    payload: {
      method: 'post',
      url: `${
        process.env.NEXT_PUBLIC_API_ENDPOINT
      }/api/v2/pushowl/token/authenticate/?${
        platform ? `platform=${platform}` : ''
      }`,
      data: {
        authToken,
        subdomain,
      },
    },
    sentryReport: true,
    isSupportLogin,
    shouldInitializeAnalytics: true,
    catchHandler: (error: any) => {
      console.log(error);
    },
  });
};

export default {
  login,
  supportLogin,
  refetchUser,
};
