import colors from 'barn/tokens/colors';

export const skeletonLight = {
  bg1: colors.greys[6],
  bg2: colors.greys[5],
};

export const skeletonDark = {
  ...skeletonLight,

  bg1: colors.greys[14],
  bg2: colors.greys[16],
};

