import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { Context } from '@shopify/app-bridge-react';
import { getShopifyApp } from 'src/lib/utils';

export const AppBridgeProvider = ({ children }) => {
  const router = useRouter();

  const app = useMemo(
    () => getShopifyApp(),
    // Since we have removed hard refresh we need dependency for useMemo.
    // Ideally we would use value in storage as dependency
    // but it is computationally intensive when using local storage
    // We use router.asPath as hack. Because there is a route change after config is set in the storage on /embedded-auth
    [router.asPath],
  );

  if (!router.pathname.includes('/embedded-auth') && app) {
    return <Context.Provider value={app}>{children}</Context.Provider>;
  }

  return children;
};
