import styled from 'styled-components';
import { fontSizes } from 'barn/tokens/typography';
import space from 'barn/tokens/space';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > div:last-child {
    position: absolute;
    bottom: 6px;
    width: 100%;
  }
`;

export const PrimaryRoutesWrapper = styled.div`
  display: grid;
  grid-gap: ${space.margin[2]};
  padding: 0 ${space.padding[4]};
`;

export const SecondaryRoutes = styled.div`
  padding: 0px ${space.padding[4]};
  display: grid;
  grid-gap: ${space.margin[2]};

  > * {
    font-size: ${fontSizes[1]};
  }
`;
