import React from 'react';
import Styled from './styled';

interface Props {
  small?: boolean;
  currentColor?: boolean;
  centered?: boolean;
  inline?: boolean;
}
const Spinner: React.FC<Props> = ({
  small = false,
  currentColor = false,
  centered = false,
  inline = false,
}) => {
  const StyledSpinner = small ? Styled.SmallSpinner : Styled.Spinner;

  return (
    <Styled.Wrapper centered={centered} inline={inline}>
      <StyledSpinner currentColor={currentColor}>
        <div />
        <div />
        <div />
      </StyledSpinner>
    </Styled.Wrapper>
  );
};

export default Spinner;
