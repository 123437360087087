import styled from 'styled-components';
import { fontSizes } from 'barn/tokens/typography';
import tokens from 'barn/tokens';

interface ButtonProps {
  ref?: any;
  size: string;
  active?: boolean;
  justify?: string;
}

const DefaultButton = styled.button``;

export const buttonSize = {
  md: {
    padding: `${tokens.space.padding[3]} ${tokens.space.padding[5]}`,
    'font-size': fontSizes[2],
    'border-radius': tokens.radii[4],
  },
  sm: {
    padding: `${tokens.space.padding[2]} ${tokens.space.padding[3]}`,
    'font-size': fontSizes[1],
    'border-radius': tokens.radii[3],
  },
};

const StyledButton = styled.button<ButtonProps>`
  display: inline-flex;
  justify-content: ${props => props.justify};
  align-items: center;
  border: 0;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  ${props => buttonSize[props.size]};
  font-weight: 600;
  transition: 0.25s ease-in;

  .iconColor {
    fill: currentColor;
  }
`;

const PrimaryDefaultButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.button.primary.default.bg};
  border-color: ${({ theme }) => theme.button.primary.default.borderColor};
  color: ${({ theme }) => theme.button.primary.default.color};

  &:hover {
    background-color: ${({ theme }) => theme.button.primary.default.hover.bg};
    border-color: ${({ theme }) =>
      theme.button.primary.default.hover.borderColor};
    box-shadow: ${({ theme }) => theme.button.primary.default.hover.boxShadow};
  }

  &:disabled,
  &[disabled] {
    opacity: 0.7;
    cursor: default;

    &:hover {
      background-color: ${({ theme }) =>
        theme.button.primary.default.disabled.hover.bg};
      border-color: ${({ theme }) =>
        theme.button.primary.default.disabled.hover.borderColor};
      box-shadow: none;
      cursor: not-allowed;
    }
  }
`;

const PrimaryDangerButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.button.primary.danger.bg};
  border-color: ${({ theme }) => theme.button.primary.danger.borderColor};
  color: ${({ theme }) => theme.button.primary.danger.color};

  &:hover {
    background-color: ${({ theme }) => theme.button.primary.danger.hover.bg};
    border-color: ${({ theme }) =>
      theme.button.primary.danger.hover.borderColor};
    box-shadow: ${({ theme }) => theme.button.primary.danger.hover.boxShadow};
  }

  &:disabled,
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background-color: ${({ theme }) =>
        theme.button.primary.danger.disabled.hover.bg};
      border-color: ${({ theme }) =>
        theme.button.primary.danger.disabled.hover.borderColor};
      box-shadow: none;
    }
  }
`;

const PrimaryInfoButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.button.primary.info.bg};
  border-color: ${({ theme }) => theme.button.primary.info.borderColor};
  color: ${({ theme }) => theme.button.primary.info.color};

  &:hover {
    background-color: ${({ theme }) => theme.button.primary.info.hover.bg};
    border-color: ${({ theme }) => theme.button.primary.info.hover.borderColor};
    box-shadow: ${({ theme }) => theme.button.primary.info.hover.boxShadow};
  }

  &:disabled,
  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;

    &:hover {
      background-color: ${({ theme }) =>
        theme.button.primary.info.disabled.hover.bg};
      border-color: ${({ theme }) =>
        theme.button.primary.info.disabled.hover.borderColor};
      box-shadow: none;
    }
  }
`;

export const PrimaryButton = {
  default: PrimaryDefaultButton,
  info: PrimaryInfoButton,
  danger: PrimaryDangerButton,
  // To prevent crash when this combination is selected
  success: DefaultButton,
};

const SecondaryDefaultButton = styled(StyledButton)`
  border: 1px solid ${({ theme }) => theme.button.secondary.default.borderColor};
  background-color: ${({ theme }) => theme.button.secondary.default.bg};
  color: ${({ theme }) => theme.button.secondary.default.color};
  font-weight: normal;

  &:hover {
    border-color: ${({ theme }) =>
      theme.button.secondary.default.hover.borderColor};
    box-shadow: ${({ theme }) =>
      theme.button.secondary.default.hover.boxShadow};
  }

  &:active {
    border-color: ${({ theme }) =>
      theme.button.secondary.default.active.borderColor};
  }

  &:disabled,
  &[disabled] {
    background-color: ${({ theme }) => theme.button.secondary.default.bg};
    border-color: ${({ theme }) =>
      theme.button.secondary.default.disabled.borderColor};
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: none;
  }
`;

export const SecondaryButton = {
  default: SecondaryDefaultButton,
  // To prevent crash when this combination is selected
  info: DefaultButton,
  success: DefaultButton,
  danger: DefaultButton,
};

const TertiarySuccessButton = styled(StyledButton)`
  background-color: ${props =>
    props.active
      ? props.theme.button.tertiary.success.hover.bg
      : 'transparent'};

  border-color: transparent;
  color: ${({ theme }) => theme.button.tertiary.success.color};
  font-weight: normal;

  &:hover {
    background-color: ${({ theme }) => theme.button.tertiary.success.hover.bg};
  }

  &:disabled,
  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;

    &:hover {
      background-color: transparent;
    }
  }
`;

const TertiaryDefaultButton = styled(StyledButton)`
  background-color: 'transparent';
  border-color: transparent;
  font-weight: normal;
  color: ${({ theme }) => theme.button.tertiary.default.color};

  &:hover {
    background-color: ${({ theme }) => theme.button.tertiary.default.hover.bg};
  }

  &:active {
    color: ${({ theme }) => theme.button.tertiary.default.active.color};
    .iconColor {
      fill: ${({ theme }) =>
        theme.button.tertiary.default.active.iconColor.fill};
    }
  }

  &:disabled,
  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;

    &:hover {
      background-color: transparent;
    }
  }
`;

const TertiaryDangerButton = styled(StyledButton)`
  background-color: transparent;
  border-color: transparent;
  color: ${({ theme }) => theme.button.tertiary.danger.color};
`;

const TertiaryInfoButton = styled(StyledButton)`
  background-color: transparent;
  border-color: transparent;
  color: ${({ theme }) => theme.button.tertiary.info.color};

  .iconColor {
    fill: ${({ theme }) => theme.button.tertiary.info.iconColor.fill};
  }
`;

export const TertiaryButton = {
  default: TertiaryDefaultButton,
  success: TertiarySuccessButton,
  danger: TertiaryDangerButton,
  info: TertiaryInfoButton,
};

export const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  ${({ iconOn, noSpacing }: { iconOn: string; noSpacing: boolean }) => {
    if (!noSpacing) {
      return `
  ${
    iconOn === 'left'
      ? `margin-right: ${tokens.space.margin[3]}`
      : `margin-left: ${tokens.space.margin[3]}`
  }
  `;
    }
    return '';
  }}
`;

export const StyledDropdownButton = styled(StyledButton)<{
  fullWidth: boolean;
}>`
  position: relative;
  justify-content: left;
  border-color: ${({ theme }) => theme.button.dropdownButton.borderColor};
  background-color: ${({ theme }) => theme.button.dropdownButton.bg};
  padding-right: 50px;
  width: ${props => (props.fullWidth ? '100%' : 'max-content')};
  font-weight: 400;
  color: ${({ theme }) => theme.button.dropdownButton.color};
  cursor: pointer;
  text-align: left;
  white-space: nowrap;

  .icon {
    display: flex;
    align-items: center;
    border: 0;
    position: absolute;
    top: 0;
    right: 10px;
    background: transparent;
    height: 100%;
    font-size: ${fontSizes[2]};
    color: ${({ theme }) => theme.button.dropdownButton.icon.color};
  }

  &[disabled] {
    color: ${({ theme }) => theme.button.dropdownButton.disabled.color};
    cursor: not-allowed;

    * {
      fill: ${({ theme }) => theme.button.dropdownButton.disabled.fill};
    }
  }
`;

export const Segment = styled.button`
  box-shadow: ${({ theme }) => theme.button.segmentBase.boxShadow};
  border: 0;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.button.segmentBase.bg};
  color: ${({ theme }) => theme.button.segmentBase.color};
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }

  &.active {
    border: 2px solid ${({ theme }) => theme.button.segmentBase.borderColor};
    opacity: 1;
  }
  > svg {
    width: 30px;
    height: 30px;
  }
`;

export default {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  StyledDropdownButton,
};
