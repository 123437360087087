export const acr = [
  {
    id: `acr1`,
    title: `Friendly reminder 😊`,
    description: `Oops! Did you leave something behind? Don't worry, your cart misses you too. Let's reunite you with your almost-adopted items: {{checkout_link}} 🛒`,
  },
  {
    id: `acr2`,
    title: `FREE Shipping! 📦`,
    description: `Hurry! The items in your cart are going fast. Can you hear them calling your name? Rescue them before it's too late: {{checkout_link}} 🏃‍♀️`,
  },
  {
    id: `acr3`,
    title: `Holiday season  🎁`,
    description: `Your cart is feeling lonely without you. Don't leave your future faves hanging! Give them a loving home: {{checkout_link}} ❤️`,
  },
  {
    id: `acr4`,
    title: `Quirky reminder 🤡`,
    description: `Don't let your dream items slip away! We saved your cart, so you can pick up right where you left off. Reclaim your treasures: {{checkout_link}} 🏴‍☠️`,
  },
  {
    id: `acr5`,
    title: `On Sale! 🏷`,
    description: `In case you've forgotten, we've kept your cart safe and sound. Jog your memory and complete your purchase: {{checkout_link}} 🧠`,
  },
];

export const browseAbandonment = [
  {
    id: `ba1`,
    title: `Don't let it get away!`,
    description: `Our {{product_link}} is a customer favorite for a reason! Shop now at {{store_link}} and see for yourself.`,
  },
  {
    id: `ba2`,
    title: `Buy the product you liked!`,
    description: `You're so close to owning our {{product_link}}! Don't let it slip away. Shop now at {{store_link}}.`,
  },

  {
    id: `ba3`,
    title: `Limited period discount on a product you visited!`,
    description: `Hey there! We noticed you were checking out our {{product_link}}. Don't miss out on this must-have item! Shop now at {{store_link}}.`,
  },
  {
    id: `ba4`,
    title: `You don't want to miss a product you liked!`,
    description: `We're loving your style! Our {{product_link}} would be the perfect addition to your wardrobe. Shop now at {{store_link}}.`,
  },
  {
    id: `ba5`,
    title: `Great deal on a product you liked!`,
    description: `You have great taste! Our {{product_link}} is a fan favorite. Shop now at {{store_link}} before it's gone.`,
  },
  {
    id: `ba6`,
    title: `Super discount on something you saw!`,
    description: `Don't be shy! Our {{product_link}} is waiting for you at {{store_link}}. Add it to your cart and let's get this party started.`,
  },
  {
    id: `ba7`,
    title: `Buy before the product is gone!`,
    description: `We know it can be tough to make a decision, but our {{product_link}} is definitely worth it! It's still available at {{store_link}} if you're interested.`,
  },
];

export const welcome = [
  {
    id: `wel1`,
    title: `Exclusive Welcome Surprise`,
    description: `Welcome to the club! Get ready to shop 'til you drop at {{store_link}} and enjoy a special welcome surprise just for you.`,
  },
  {
    id: `wel2`,
    title: `Treats & Exclusive Finds`,
    description: `You're in for a treat! Join us at {{store_link}} for exciting new collections, limited edition items, and a whole lot of surprises.`,
  },
  {
    id: `wel3`,
    title: `Join the Party`,
    description: `Welcome to the party! We're thrilled to have you join our community at {{store_link}}. Get ready for exclusive deals, new arrivals, and more!`,
  },
  {
    id: `wel4`,
    title: `New Arrivals & Discount`,
    description: `Welcome to our store! We're so excited to have you here. Check out our latest arrivals at {{store_link}} and use code WELCOME10 for 10% off your first purchase.`,
  },
  {
    id: `wel5`,
    title: `Inner Circle Access`,
    description: `Welcome to our inner circle! Get ready for insider access to our latest products and promotions at {{store_link}}.`,
  },
  {
    id: `wel6`,
    title: `VIP Club Membership`,
    description: `You're officially part of our VIP club! Stay tuned for special offers and promotions at {{store_link}}.`,
  },
];

export const campaign = [
  {
    id: `cam1`,
    title: `Sun's Out, Fun's Out' Summer Kickoff`,
    description: `The sun's out, and so are we! Join us in welcoming summer at {{store_link}}. SPF not included. 🔥`,
  },
  {
    id: `cam2`,
    title: `Sassy Summer Shopping' Spectacular`,
    description: `😎 Got summer plans? We're just here, adding a 'sun'shine to your shopping list at {{store_link}}. Stay sassy! 😜`,
  },
  {
    id: `cam3`,
    title: `Don't Melt Away' Summer Frenzy`,
    description: `🍦 Ice cream melts and so do our summer offerings! Don't let them drip away at {{store_link}}. Catch them while they're chill! 🌞`,
  },
  {
    id: `cam4`,
    title: `Tan Lines and Good Times' Summer Blast`,
    description: `🌴 Summer rule #1: If you can't tone it, tan it! Find your summer essentials at {{store_link}}. 🏖️`,
  },
  {
    id: `cam5`,
    title: `No Beach, No Problem' Summer Specials`,
    description: `🍹 Missing out on summer at {{store_link}} would be a 'beach'. Don't let the tide wash away our sizzling offerings! 🌊`,
  },
];
