import colors from 'barn/tokens/colors';

export const topbarLight = {
  bg: colors.greys[8],
  borderColor: colors.greys[5],
};

export const topbarDark = {
  bg: colors.greys[11],
  borderColor: colors.greys[11],
};
