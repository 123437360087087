import { isSmallScreen } from 'src/lib/utils';

type PrimaryRoutes =
  | {
      key: string;
      path: string;
      name: string;
      isActive?: undefined;
      subRoutes?: undefined;
      isComingSoon?: boolean;
      isNew?: false;
    }
  | {
      key: string;
      path: string;
      name: string;
      isActive: (currentPath: any) => any;
      isComingSoon?: boolean;
      subRoutes: {
        key: string;
        path: string;
        name: string;
        isActive: (currentPath: any) => boolean;
        isNew?: false;
      }[];
    };

export const primaryRoutes: PrimaryRoutes[] = [
  { key: 'page.home', path: '/', name: 'Home' },
  {
    key: 'page.campaigns',
    path: '/campaigns',
    name: 'Campaigns',
  },
  {
    key: 'page.automation',
    path: '/automation',
    name: 'Automation',
    isActive: currentPath => {
      // On small screen we will just show top level navigation,
      // so it should be highlighted
      if (isSmallScreen()) {
        return currentPath.match(/\/automation/);
      }

      return (
        currentPath.match(/\/automation\/.?/) &&
        currentPath.indexOf('automation/abandoned-cart-recovery') === -1
      );
    },
    subRoutes: [
      {
        key: 'page.automation',
        path: '/automation',
        name: 'All Automations',
        isNew: false,
        isActive: currentPath => {
          return currentPath === '/automation';
        },
      },
      {
        key: 'page.acr',
        path: '/automation/abandoned-cart-recovery',
        name: 'Abandoned Carts',
        isActive: currentPath => {
          return /\/automation\/abandoned-cart-recovery.*/.test(currentPath);
        },
      },
    ],
  },
  { key: 'page.optins', path: '/optins', name: 'Opt-ins' },
  // {
  //   key: 'page.service-messages',
  //   path: '/service-messages',
  //   name: 'Service Messages',
  // },
];

export const secondaryRoutes = [
  /* {
    key: 'page.gettingStarted',
    path: '',
    name: 'Getting Started',
    isLocked: false,
  }, */
  {
    key: 'page.compliance',
    path: 'https://docs.reachbee.com/en/articles/6745909-sms-compliance-for-us-subscribers',
    name: 'SMS Compliance',
    isLocked: false,
    target: '_blank',
  },
  {
    key: 'page.settings',
    path: '/settings',
    name: 'Settings',
    isLocked: false,
    target: null,
  },
];
