export const sidebarWidth = '220px';
export const responsiveSidebarWidth = '62px';

export default {
  bottombar: {
    right: 0,
    bottom: 0,
    left: 0,
    height: '60px',
  },

  topbar: {
    top: 0,
    right: 0,
    left: 0,
    height: '52px',
  },

  sidebar: {
    top: 0,
    bottom: 0,
    left: 0,
    width: sidebarWidth,
    responsiveWidth: responsiveSidebarWidth,
    height: '100vh',
  },

  dashboard: {
    navbar: {
      left: sidebarWidth,
    },
  },
};
