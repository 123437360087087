import { Component } from 'react';
import * as Sentry from '@sentry/browser';
import * as FullStory from '@fullstory/browser';
import { isShopifyEmbedded } from '@shopify/app-bridge-utils';
import SentryFullStory from '@sentry/fullstory';
import { logErrorToSentry } from './debug-utils';
import { getStorage } from './storage';
import { isLocalStorageAvailable, secureUserObject } from './utils';

class LoggingErrorBoundary extends Component {
  componentDidMount() {
    // Initializing here ensures that the following code only runs on the client

    if (isLocalStorageAvailable()) {
      FullStory.init({
        orgId: 'o-1EAS6T-na1',
        recordOnlyThisIFrame: isShopifyEmbedded(),
      });
    }

    Sentry.init({
      dsn: 'https://43bd1d8428f94e51bf995066d5f17525@o79286.ingest.sentry.io/4504179243745280',
      release: process.env.BUILD_ID,
      environment: process.env.NODE_ENV,
      allowUrls: ['reachbee.com'],
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
      integrations: [new SentryFullStory('reachbee', { client: FullStory })],
    });

    Sentry.configureScope(scope => {
      const user = secureUserObject(getStorage().get('user') || {});

      scope.setExtra('user', JSON.stringify(user));
      scope.setExtra('isEmbeddedApp', isShopifyEmbedded());
      scope.setTag('subdomain', user.website?.subdomain);
    });
  }

  componentDidCatch(error, errorInfo) {
    logErrorToSentry({ error, extras: errorInfo });
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default LoggingErrorBoundary;
