import colors from 'barn/tokens/colors';

export const buttonLight = {
  primary: {
    // Primary default
    default: {
      bg: colors.greens[4],
      borderColor: colors.greens[4],
      color: colors.greys[8],
      hover: {
        bg: colors.greens[3],
        borderColor: colors.greens[3],
        boxShadow: '0px 6px 12px rgba(52, 57, 70, 0.16)',
      },
      disabled: {
        hover: {
          bg: colors.greens[4],
          borderColor: colors.greens[4],
        },
      },
    },

    // Primary danger
    danger: {
      bg: colors.reds[4],
      borderColor: colors.reds[4],
      color: colors.greys[8],
      hover: {
        bg: colors.reds[3],
        borderColor: colors.reds[3],
        boxShadow: '0px 6px 12px rgba(52, 57, 70, 0.16)',
      },
      disabled: {
        hover: {
          bg: colors.reds[4],
          borderColor: colors.reds[4],
        },
      },
    },

    // Primary Info
    info: {
      bg: colors.blues[5],
      borderColor: colors.blues[5],
      color: colors.greys[8],
      hover: {
        bg: colors.blues[2],
        borderColor: colors.blues[2],
        boxShadow: '0px 6px 12px rgba(52, 57, 70, 0.16)',
      },
      disabled: {
        hover: {
          bg: colors.blues[5],
          borderColor: colors.blues[5],
        },
      },
    },
  },

  secondary: {
    // Secondary default button
    default: {
      borderColor: colors.greys[4],
      color: colors.greys[1],
      bg: colors.greys[8],
      hover: {
        borderColor: colors.greys[3],
        boxShadow: '0px 6px 12px rgba(52, 57, 70, 0.16)',
      },
      active: {
        borderColor: colors.greys[3],
      },
      disabled: {
        borderColor: colors.greys[4],
      },
    },
  },

  tertiary: {
    default: {
      // Tertiary default
      color: colors.greys[1],
      hover: {
        bg: colors.greys[6],
      },
      active: {
        color: colors.blues[2],
        iconColor: {
          fill: colors.blues[2],
        },
      },
    },
    success: {
      // Tertiary success
      color: colors.greens[4],
      hover: {
        bg: colors.greens[8],
      },
    },
    danger: {
      // Tertiary danger
      color: colors.reds[4],
    },
    info: {
      // Tertiary info
      color: colors.greys[8],
      iconColor: {
        fill: colors.greys[8],
      },
    },
  },

  dropdownButton: {
    borderColor: colors.greys[5],
    bg: colors.greys[8],
    color: colors.greys[1],
    icon: {
      color: colors.blues[5],
    },
    disabled: {
      color: colors.greys[2],
      fill: colors.greys[4],
    },
  },

  segmentBase: {
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    bg: colors.greys[8],
    borderColor: colors.greens[4],
    color: colors.greys[1],
  },
};

export const buttonDark = {
  ...buttonLight,
  primary: {
    // Primary default
    default: {
      bg: colors.greens[9],
      borderColor: colors.greens[9],
      color: colors.greys[0],
      hover: {
        bg: colors.greens[4],
        borderColor: colors.greens[4],
        boxShadow: '0px 4px 8px rgba(52, 57, 70, 0.12)',
      },
      disabled: {
        hover: {
          bg: colors.greens[4],
          borderColor: colors.greens[4],
        },
      },
    },

    // Primary danger
    danger: {
      bg: colors.reds[4],
      borderColor: colors.reds[4],
      color: colors.greys[8],
      hover: {
        bg: colors.reds[3],
        borderColor: colors.reds[3],
        boxShadow: '0px 4px 8px rgba(52, 57, 70, 0.12)',
      },
      disabled: {
        hover: {
          bg: colors.reds[4],
          borderColor: colors.reds[4],
        },
      },
    },

    // Primary Info
    info: {
      bg: colors.blues[5],
      borderColor: colors.blues[5],
      color: colors.greys[8],
      hover: {
        bg: colors.blues[2],
        borderColor: colors.blues[2],
        boxShadow: '0px 4px 8px rgba(52, 57, 70, 0.12)',
      },
      disabled: {
        hover: {
          bg: colors.blues[5],
          borderColor: colors.blues[5],
        },
      },
    },
  },

  secondary: {
    // Secondary default button
    default: {
      borderColor: colors.greys[1],
      color: colors.greys[7],
      bg: colors.greys[11],
      hover: {
        borderColor: colors.greys[2],
        boxShadow: '0px 4px 8px rgba(52, 57, 70, 0.12)',
      },
      active: {
        borderColor: colors.greys[2],
      },
      disabled: {
        borderColor: colors.greys[4],
      },
    },
  },

  tertiary: {
    default: {
      // Tertiary default
      color: colors.greys[4],
      hover: {
        bg: colors.greys[11],
      },
      active: {
        color: colors.greys[8],
        iconColor: {
          fill: colors.greys[8],
        },
      },
    },
    success: {
      // Tertiary success
      color: colors.greens[4],
      hover: {
        bg: colors.greens[8],
      },
    },
    danger: {
      // Tertiary danger
      color: colors.reds[4],
    },
    info: {
      // Tertiary info
      color: colors.greys[8],
      iconColor: {
        fill: colors.greys[8],
      },
    },
  },

  dropdownButton: {
    borderColor: colors.greys[1],
    bg: colors.greys[13],
    color: colors.greys[7],
    icon: {
      color: colors.blues[5],
    },
    disabled: {
      color: colors.greys[4],
      fill: colors.greys[4],
    },
  },

  segmentBase: {
    boxShadow: 'none',
    bg: colors.greys[9],
    borderColor: colors.greens[9],
    color: colors.greys[8],
  },
};
