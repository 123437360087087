import styled from 'styled-components';
import layout from 'barn/tokens/layout';
import tokens from 'barn/tokens';

const StyledTopbar = styled.div`
  z-index: ${tokens.zIndex[2]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.topbar.bg};
  border-bottom: 1px solid ${({ theme }) => theme.topbar.borderColor};
  padding: 0 ${tokens.space.padding[7]};
  height: ${layout.topbar.height};
`;

export default StyledTopbar;
