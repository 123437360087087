import { get, post } from '../axios';

// endpoints
const CONFIG_ENDPOINT = `/config/browse-abandonment/`;

// query helpers
const getConfig = () => get(CONFIG_ENDPOINT);

const setConfig = (data: {
  enabled: boolean;
  message_configs: Array<AnyObject>;
}) => post(CONFIG_ENDPOINT, data);

export default {
  getConfig,
  setConfig,
};
