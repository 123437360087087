import { createModel } from '@rematch/core';
import { getStorage } from 'src/lib/storage';
import api from 'src/lib/api';
import { gotoNext } from 'src/lib/goto-next';
import { debugLog } from 'src/lib/debug-utils';
import { RootModel } from '.';

type UserState = {
  isLoggedIn: boolean;
  segmentData: {
    subdomain: string;
    shouldInitializeAnalytics: boolean;
    isSupportLogin: boolean;
    traits: {
      created_at: number;
      email: string;
      name: string;
      subdomain: string;
    };
    integrations: {
      integrations: {
        Intercom: {
          user_hash: string;
        };
      };
    };
  };
  error: AnyObject;
  user: AnyObject;
};

const storage = getStorage();
const user = createModel<RootModel>()({
  state: {
    isLoggedIn: false,
    segmentData: {
      subdomain: '',
      shouldInitializeAnalytics: true,
      isSupportLogin: false,
      traits: {
        created_at: 0,
        email: '',
        name: '',
        subdomain: '',
      },
      integrations: {
        integrations: {
          Intercom: {
            user_hash: '',
          },
        },
      },
    },
    error: {},
    user: storage.get('user') || {},
  } as UserState,

  reducers: {
    setUserLoggedIn(state, isLoggedIn: boolean) {
      return {
        ...state,
        isLoggedIn,
      };
    },

    setSegmentData(state, segmentData: UserState['segmentData']) {
      return {
        ...state,
        segmentData,
      };
    },

    setUser(state, payload: AnyObject) {
      return {
        ...state,
        user: payload,
      };
    },
    setError(state, payload: AnyObject) {
      return {
        ...state,
        error: payload,
      };
    },

    setWebsitePreference(
      state,
      { key, value }: { key: string; value: string | number },
    ): UserState {
      return {
        ...state,
        user: {
          ...state.user,
          website: {
            ...state.user.website,
            [key]: value,
          },
        },
      };
    },
  },
  effects: {
    async login(_, rootState) {
      const {
        user: { user },
      } = rootState;
      const storage = getStorage();
      const queryParams = new URLSearchParams(window.location.search);

      const nextURL = queryParams.get('next');

      const loginData = {
        authToken: queryParams.get('authToken'),
        subdomain: queryParams.get('subdomain'),
        platform: queryParams.get('platform'),
      };

      if (!loginData.authToken && !loginData.subdomain && !user.token) {
        debugLog({
          message: 'redirect Shopify login',
          data: loginData,
        });
        window.location.href = '/shopify-login';
        return null;
      }

      const redirectObj = storage.get('redirect_on_load');
      const appBridgeConfig = storage.get('app_bridge_config');
      const theme = storage.get('theme');

      // clean session
      storage.clear();
      storage.clearAll();

      // but persist with nextURL for redirect on login
      if (redirectObj) storage.set('redirect_on_load', redirectObj);
      if (appBridgeConfig) storage.set('app_bridge_config', appBridgeConfig);
      if (theme) storage.set('theme', theme);

      /**
       * Additional logic to handle an external link with a next URL param
       * for a subdomain that doesn't match the currently logged in one
       * ex: admin links for merchants with multiple stores
       */

      if (
        nextURL &&
        loginData.subdomain &&
        user.token &&
        loginData.subdomain !== user.website.subdomain
      ) {
        // This will be looked up on Home load to initiate redirect
        storage.set('redirect_on_load', {
          subdomain: loginData.subdomain,
          nextURL,
        });

        window.location.href = `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/v1/auth/${loginData.platform}/pushowl/?subdomain=${loginData.subdomain}`;
        return null;
      }

      if ((!loginData.authToken || !loginData.subdomain) && user.token) {
        loginData.authToken = user.token;
        loginData.subdomain = user.website.subdomain;
      }

      if (loginData.subdomain && !loginData.authToken) {
        window.location.href = `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/v1/auth/${loginData.platform}/pushowl/?subdomain=${loginData.subdomain}`;
        return null;
      }

      try {
        return api.auth.login(loginData).then((res: any) => {
          if (res.isLoggedIn) {
            storage.set('user', res.user);
            this.setUser(res.user);
            window._po_subdomain = res.user.website.subdomain;
            this.setUserLoggedIn(true);

            // because react router doesn't work here
            gotoNext(nextURL);
            return {
              user: res.user,
              error: null,
              isLoggedIn: res.isLoggedIn,
            };
          }
          return { user: null, isLoggedIn: false, error: res.error };
        });
      } catch (err) {
        this.setError(err);
        return { user: null, error: err };
      }
    },

    async supportLogin(data: {
      subdomain: string;
      password: string;
      platform: string;
    }) {
      const storage = getStorage();

      try {
        const urlParams = new URLSearchParams(window.location.search);
        const nextURL = urlParams.get('next');

        return api.auth.supportLogin(data).then((res: any) => {
          if (res.isLoggedIn) {
            storage.set('user', res.user);
            this.setUser(res.user);
            window._po_subdomain = res.user.website.subdomain;
            this.setUserLoggedIn(true);
            // because react router doesn't work here
            gotoNext(nextURL);
            return { user: res.user, isLoggedIn: res.isLoggedIn };
          }
          return { user: null, isLoggedIn: false, error: res.error };
        });
      } catch (err) {
        this.setError(err);
        return { user: null, error: err };
      }
    },

    async logout() {
      const storage = getStorage();
      try {
        storage.clear();
        storage.clearAll();
        // because react router doesn't work here
        window.location.href = '/shopify-login';
        return { user: null, error: null };
      } catch (err) {
        this.setError(err);
        return { user: null, error: err };
      }
    },
  },
});

export default user;
