import { createModel } from '@rematch/core';
import { isEqual } from 'date-fns';
import { RootModel } from '.';
import store from '..';

export const TOAST_TTL = 5000;

export type ToastType = 'error' | 'success';

interface SaveToastState {
  hidden: boolean;
  isLoading: boolean;
  message: string | null;
  timeOfRender: Date | null;
  type: ToastType;
}

const initialState = (): SaveToastState => ({
  hidden: true,
  isLoading: false,
  message: null,
  timeOfRender: null,
  type: 'success',
});

const saveToast = createModel<RootModel>()({
  state: initialState(),

  effects: {
    showProgress(payload?: string) {
      this.show({
        hidden: false,
        isLoading: true,
        message: payload || 'Saving your changes',
      });
    },

    showDone(payload: string) {
      const timeOfRender = new Date();

      setTimeout(() => {
        if (isEqual(timeOfRender, store.getState().saveToast.timeOfRender)) {
          this.hide();
        }
      }, TOAST_TTL);

      this.show({
        timeOfRender,
        hidden: false,
        isLoading: false,
        type: 'success',
        message: payload || 'Your changes have been saved',
      });
    },

    showError(payload: string) {
      const timeOfRender = new Date();

      setTimeout(() => {
        if (isEqual(timeOfRender, store.getState().saveToast.timeOfRender)) {
          this.hide();
        }
      }, TOAST_TTL);

      this.show({
        timeOfRender,
        hidden: false,
        isLoading: false,
        type: 'error',
        message: payload || 'Unexpected error occurred',
      });
    },
  },

  reducers: {
    hide(state: SaveToastState): SaveToastState {
      return {
        ...state,
        ...initialState(),
      };
    },

    show(state: SaveToastState, payload: any): SaveToastState {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export default saveToast;
