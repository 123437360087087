import { withScope, captureException, addBreadcrumb } from '@sentry/browser';
import { Extras, Breadcrumb } from '@sentry/types';
import { log } from '@fullstory/browser';
import { isLocalStorageAvailable } from './utils';

interface SentryLoggerArgs {
  error: Error;
  extras?: Extras;
}

export const logErrorToSentry = ({ error, extras }: SentryLoggerArgs) => {
  withScope(scope => {
    scope.setExtras({
      ...extras,
    });

    captureException(error);
  });
};

export const createBreadcrumb = (
  crumb: Pick<Breadcrumb, 'message' | 'data'>,
) => {
  // Sentry
  addBreadcrumb({ ...crumb, timestamp: Date.now() });

  // FullStory
  if (isLocalStorageAvailable()) {
    log('info', JSON.stringify(crumb));
  }
};

interface DebugLogParams {
  message: string;
  data?: AnyObject;
}

/**
 * Log to our log sink for debugging purposes
 * @param payload DebugLogParams Log data.
 */
export const debugLog = (payload: DebugLogParams) => {
  // TODO: remove this early return when we have the logsink API
  return;

  fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}/logs/v1/`, {
    method: 'POST',
    headers: {
      'Accept-Type': 'text/plain',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify([
      {
        level: 'warn',
        subdomain: window._po_subdomain,
        message: payload.message,
        timestamp: new Date().toISOString(),
        data: {
          url: window.location.href,
          /* eslint-disable-next-line dot-notation */
          connectionType: window.navigator['connection']?.effectiveType,
          buildId: window._po_build,
          ...payload.data,
        },
      },
    ]),
  });
};
