import auth from './auth';
import segments from './segments';
import campaigns from './campaigns';
import settings from './settings';
import automation from './automation';
import optins from './optins';
import home from './home';
import integrations from './integrations';
import pricing from './pricing';
import subscribers from './subscribers';
import preferences from './preferences';
import feedback from './feedback';
import onboarding from './onboarding';
import reports from './reports';
import misc from './misc';
import serviceMessages from './service-messages';

export default {
  // authentication
  auth,

  // segments
  segments,

  // campaigns
  campaigns,

  // settings
  settings,

  // automation
  automation,

  // opt-ins
  optins,

  // homepage
  home,

  // integrations
  integrations,

  // pricing and plans
  pricing,

  // subscribers
  subscribers,

  preferences,

  // user feedback
  feedback,

  onboarding,

  reports,

  misc,

  serviceMessages,
};
