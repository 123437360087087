import Axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { getShopifySessionToken } from 'src/lib/utils';
import { debugLog } from '../../debug-utils';
import { getStorage } from '../../storage';
import { getUser } from './index';

export const axiosInterceptResponseErrorHandler = error => {
  const user = getUser();
  debugLog({
    message: !error?.response ? 'empty response' : 'api failed',
    data: {
      error,
    },
  });

  if (
    ![401, 403].includes(error?.response?.status) ||
    // We don't want to redirect user to shopify login page
    // when incorrect details are entered in support login page
    [/support\/login/].find(regex => regex.test(window.location.href))
  )
    return Promise.reject(error);

  const storage = getStorage();
  const platform = user?.website?.platform || 'shopify';
  storage.clear();
  storage.clearAll();

  // Save current path to redirect to later after login, except when its homepage bcoz
  // that open by default
  if (window.location.pathname !== '/') {
    const redirectObj = {
      subdomain: window._po_subdomain,
      nextURL: btoa(
        JSON.stringify({ path: window.location.pathname, params: {} }),
      ),
    };
    storage.set('redirect_on_load', redirectObj);
  }

  window.location.href = `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/v1/auth/${platform}/pushowl/?subdomain=${user.website.subdomain}&reason=unauthorised_token`;

  return Promise.reject(error);
};

/**
 * This interceptor will append the platform
 * query parameter to the request URL
 */
export const axiosInterceptorPlatformHandler = (req: AxiosRequestConfig) => {
  const user = getUser();
  if (!user?.website?.platform) return req;

  // constructor requires an absolute URL which is to be removed later
  const url = new URL(`${req.baseURL}${req.url}`);

  if (url.searchParams.has('platform')) return req;

  url.searchParams.append('platform', user?.website?.platform);

  // req.url takes in a relative URL, remove baseURL here
  req.url = url.toString().replace(req.baseURL, '');

  return req;
};

export const axiosInterceptRequestHandler = async (
  req: AxiosRequestConfig,
): Promise<AxiosRequestConfig> => {
  if (window._po_disabled)
    return {
      ...req,
      cancelToken: new Axios.CancelToken(cancel =>
        cancel('Session disabled. Network requests are disallowed.'),
      ),
    };

  const user = getUser();

  // Following endpoints don't require JWT
  if (
    ![/auth\/support/, /authenticate/, /s3\.amazonaws/, /billing\/init/].find(
      regex => regex.test(req.url),
    )
  ) {
    const sessionToken = await getShopifySessionToken();
    req.headers.Authorization = `JWT ${user.token}`;
    if (sessionToken) {
      req.headers.SessionToken = `JWT ${sessionToken}`;
    }
  }

  return req;
};

/**
 * Evaluates retry criteria for a failed network request.
 *
 * axios-retry checks for error.config
 * Status codes that are safe to retry: 429, 502, 503
 * Network errors are only to be tried with safe methods.
 *
 * @param error AxiosError
 * @returns boolean
 */
export const isRetryable = (error: AxiosError) =>
  error.config &&
  ([429, 502, 503].includes(error.response?.status) ||
    (/(timeout of \d+ms exceeded)|(Network Error)/.test(error.message) &&
      ['delete', 'get', 'options', 'head'].includes(error.config.method)));
