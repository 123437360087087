import { FC, useState, useEffect } from 'react';
import ChromePicker from 'react-color/lib/components/chrome/Chrome';
import { StyledColorPicker, StyledColorPickerWidget } from './styled';

interface Props {
  value: string;
  onChange: (string) => void;
  inputRef: AnyObject;
}

const decimalToHex = (alpha: number) =>
  alpha === 0 ? '00' : Math.round(255 * alpha).toString(16);

const handleColorChange = ({ hex, rgb }) =>
  `${hex}${decimalToHex(rgb.a)}`.toUpperCase();

const ColorPicker: FC<Props> = ({ value, onChange, inputRef }) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  useEffect(() => {
    const clickHandler = e => {
      if (e.target !== inputRef.current && !e.defaultPrevented)
        setIsPickerOpen(false);
    };

    const keyboardHandler = e => {
      if (e.key === 'Escape') {
        setIsPickerOpen(false);
      }
    };

    document.addEventListener('keydown', keyboardHandler, false);
    document.addEventListener('click', clickHandler);

    return () => {
      document.removeEventListener('click', clickHandler);
      document.removeEventListener('keydown', keyboardHandler, false);
    };
  }, []);

  return (
    <>
      <StyledColorPickerWidget
        {...{
          value,
        }}
        type='button'
        onClick={e => {
          setIsPickerOpen(!isPickerOpen);
          e.preventDefault();
        }}
      />

      {isPickerOpen && (
        <div
          style={{
            position: 'relative',
          }}
        >
          <StyledColorPicker onClick={e => e.preventDefault()}>
            <ChromePicker
              color={value}
              onChange={color => onChange(handleColorChange(color))}
            />
          </StyledColorPicker>
        </div>
      )}
    </>
  );
};

export default ColorPicker;
