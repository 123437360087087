import { subDays } from 'date-fns';
import { get } from './axios';

export const getSubscriberReport = async (params?: any) => {
  const { start_date, end_date } = params;
  const endDate = end_date || new Date();
  const startDate = start_date || subDays(endDate, 30);

  return get(
    `/dashboard/subscriber/report/?start_time=${startDate.toISOString()}&end_time=${endDate.toISOString()}`,
  );
};

export const getSubscriberList = () => get('/dashboard/subscriber/list/');

export default {
  getSubscriberReport,
  getSubscriberList,
};
