import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Modal from 'barn/components/Modal/Modal';
import { HStack, VStack } from 'barn/components/Stack';
import { Text } from 'barn/components/Text';
import Button from 'barn/components/Button';
import { useGlobalState } from 'src/hooks/use-global-state';
import { UTMMedium } from 'src/modules/pricing/models';
import { useCheckRenderLogic } from './use-check-render-logic';
import { Action } from './metadata';

export const ConsumptionWarningPopup = () => {
  const { shouldRenderPopup, popupData } = useCheckRenderLogic();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const router = useRouter();
  const {
    actions: { showPricing },
  } = useGlobalState('pricing');

  useEffect(() => {
    setIsPopupVisible(shouldRenderPopup);
  }, [shouldRenderPopup]);

  if (!popupData) return null;

  const {
    title,
    subtitle,
    desc,
    image: Image,
    buttons,
    testHandle,
  } = popupData;

  const handlers = {
    [Action.CLOSE]: () => setIsPopupVisible(false),
    [Action.TOPUP]: () => {
      handlers[Action.CLOSE]();
      router.push(
        `/tools/topup/?utm_source=pushowl&utm_medium=${
          'impression_milestone_popup' as UTMMedium
        }`,
      );
    },
    [Action.UPGRADE]: () => {
      handlers[Action.CLOSE]();
      showPricing({ utmMedium: 'impression_milestone_popup' });
    },
  };

  return (
    <Modal
      isOpen={isPopupVisible}
      showCloseButton
      onRequestClose={() => setIsPopupVisible(false)}
      type='popup'
      testHandle={`consumption-warning-${testHandle}`}
    >
      <VStack gap={6}>
        <figure style={{ alignSelf: 'center' }}>
          <Image />
        </figure>

        <VStack gap={3}>
          <Text tag='h1' size={7} weight='bold'>
            {title}
          </Text>
          <Text tag='h2' size={4} weight='bold'>
            {subtitle}
          </Text>
        </VStack>

        <Text tag='p' size={2}>
          {desc}
        </Text>

        <HStack gap={4} justify='flex-end'>
          {buttons.map(({ text, action, appearance, variant }) => (
            <Button
              appearance={appearance}
              variant={variant}
              onClick={handlers[action]}
              testHandle={`consumption-warning-action-${action}`}
            >
              {text}
            </Button>
          ))}
        </HStack>
      </VStack>
    </Modal>
  );
};
