import Link from 'next/link';
import { Text } from 'barn/components/Text';
import { useGlobalState } from 'src/hooks/use-global-state';
import { isSmallScreen } from 'src/lib/utils';

import StyledSidebar, {
  StyledLogoHolder,
  StyledCTAHolder,
} from './StyledSidebar';

const Sidebar = ({
  cta,
  children,
}: {
  cta?: React.ReactNode;
  children: React.ReactChild;
}) => {
  const {
    state: { isSidebarOpen },
  } = useGlobalState('common');

  return (
    <StyledSidebar data-test='sidebar' isSidebarOpen={isSidebarOpen}>
      <Link href='/' aria-label='visit home page'>
        <StyledLogoHolder>
          <img
            src='/static/reachbee-logo.png'
            alt='Reachbee Logo'
            height='30'
          />
          {!isSmallScreen() && (
            <Text weight='600' variant='primary'>
              ReachBee
            </Text>
          )}
        </StyledLogoHolder>
      </Link>
      {cta && <StyledCTAHolder>{cta}</StyledCTAHolder>}
      {children}
    </StyledSidebar>
  );
};

export default Sidebar;
