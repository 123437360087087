import styled from 'styled-components';
import { fontSizes } from 'barn/tokens/typography';
import tokens from 'barn/tokens';

export const StyledNavItem = styled.a<{ isComingSoon?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border: 0;
  width: 100%;
  cursor: ${({ isComingSoon }) => (isComingSoon ? 'default' : 'pointer')};
  color: ${({ theme }) => theme.navlink.color};
  font-size: ${fontSizes[2]};
  font-weight: 600;
  font-weight: normal;
  padding: ${tokens.space.padding[4]};
  border-radius: ${tokens.radii[4]};
  transition: 0.2s ease;

  @media (min-height: 800px) {
    padding: ${tokens.space.padding[4]};
  }

  > div {
    display: flex;
    align-items: center;
    gap: ${tokens.space.margin[3]};
  }

  .iconColor {
    fill: currentColor;
    stroke: currentColor;
  }

  &:hover {
    background: ${({ theme }) => theme.navlink.hover.bg};
    color: ${({ theme }) => theme.navlink.hover.color};
  }

  &.active {
    color: ${({ theme }) => theme.navlink.active.color};
    background: ${({ theme }) => theme.navlink.active.bg};
    font-weight: 600;

    &:not(.locked) .active-indicator {
      display: block;
      position: absolute;
      right: 14px;
      width: 4px;
      height: 16px;
      background: currentColor;
      border-radius: 40px;

      @media screen and (max-width: ${tokens.responsiveScreenBreakpoint}) {
        display: none;
      }
    }
  }

  &.sublink {
    font-size: ${fontSizes[1]};
    padding-left: ${tokens.space.padding[10]};
  }
`;

export const NewDot = styled.div`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background: ${tokens.colors.blues[3]};
`;
