import { createModel } from '@rematch/core';
import Router from 'next/router';
import api from 'src/lib/api';
import { handleRedirect } from 'src/lib/utils';
import { RootModel } from 'src/store/models';
import { BillingFrequency } from '../constants';

type Plan = {
  sku: string;
  impressions_limit: number;
  price: number;
  name: string;
  charge_type: string;
  plan_type: string;
};

type MontlyPlans = {
  free: Array<Plan>;
  business: Array<Plan>;
  enterprise: Array<Plan>;
};

type AnnualPlans = {
  free: Array<Plan>;
  business: Array<Plan>;
  enterprise: Array<Plan>;
};

type RecommendedPlans = {
  monthly: Plan;
  annnual: Plan;
};

type Override = {
  extraImpressions?: number;
};

export type Topup = {
  sms_credits: number;
  name: string;
  price: number;
  sku: string;
};

export type UTMMedium =
  | 'top_nav'
  | 'profile_dropdown'
  | 'abandoned_cart'
  | 'browse_abandonment'
  | 'flash_sale'
  | 'hero_image'
  | 'segmentation'
  | 'shipping_notification'
  | 'smart_delivery'
  | 'impression_milestone_banner'
  | 'impression_milestone_popup'
  | 'impression_milestone_email'
  | 'post_campaign_overconsumed_popup'
  | null;

type BusinessAndEnterprisePlansConfig = Pick<
  AnnualPlansConfig,
  'tagline' | 'discount'
>;

type AnnualPlansConfig = {
  tagline: string;
  discount: number;
  enterprise: BusinessAndEnterprisePlansConfig;
  business: BusinessAndEnterprisePlansConfig;
};

type PricingState = {
  isOpen: boolean;
  billingFrequency: BillingFrequency.Monthly | BillingFrequency.Annual;
  isAnnualPricingEnabled: boolean;
  hasExhaustedImpression: boolean;
  coupon: AnyObject | null;
  enableBookACall: boolean;
  scheduleMeeting: boolean;
  newPlanType: string | null;
  annualPlansConfig: AnnualPlansConfig;
  utmMedium: UTMMedium;
  override: Override;
  topups: Array<Topup>;
  selectedIndex: {
    business: number;
    enterprise: number;
  };
  plans: {
    isFetching: boolean;
    current: Plan | null;
    monthly: MontlyPlans;
    annual: AnnualPlans;
    recommended: RecommendedPlans | null;
  };
};

const initialState: PricingState = {
  isOpen: false,
  utmMedium: null,
  billingFrequency: BillingFrequency.Monthly,
  isAnnualPricingEnabled: false,
  hasExhaustedImpression: false,
  enableBookACall: false,
  newPlanType: null,
  scheduleMeeting: false,
  coupon: null,
  annualPlansConfig: null,
  override: {},
  selectedIndex: {
    business: 0,
    enterprise: 0,
  },
  topups: [],
  plans: {
    isFetching: true,
    current: null,
    monthly: { free: [], business: [], enterprise: [] },
    annual: { free: [], business: [], enterprise: [] },
    recommended: null,
  },
};

const pricing = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setFetching(state, isFetching: boolean) {
      return {
        ...state,
        plans: {
          ...state.plans,
          isFetching,
        },
      };
    },
    setState(state, payload: Partial<PricingState>) {
      return {
        ...state,
        ...payload,
      };
    },
    closePricing(state) {
      return {
        ...state,
        isOpen: false,
        scheduleMeeting: false,
      };
    },
    setBillingFrequency(state: PricingState, payload) {
      return {
        ...state,
        billingFrequency: payload,
        plans: {
          ...state.plans,
        },
      };
    },
    setCoupon(state: PricingState, payload: AnyObject | null) {
      return {
        ...state,
        coupon: payload,
      };
    },
    setPlanIndex(
      state: PricingState,
      payload: {
        planType: string;
        index: number;
      },
    ) {
      return {
        ...state,
        selectedIndex: {
          ...state.selectedIndex,
          [payload.planType]: payload.index,
        },
      };
    },
    scheduleMeeting(state, payload: boolean) {
      return {
        ...state,
        isOpen: payload ? false : state.isOpen,
        scheduleMeeting: payload,
      };
    },
  },
  effects: dispatch => ({
    async fetchPricing() {
      this.setFetching(true);

      const res = await api.pricing.getPricing();
      if (res.error) {
        dispatch.saveToast.showError(
          'Unable to load pricing plans. Please try again',
        );
        return;
      }

      this.setState({
        plans: {
          isFetching: false,
        },
        topups: res.data.topups,
      });
    },

    async showPricing(payload: {
      utmMedium?: PricingState['utmMedium'];
      plan_type?: any;
      recommended?: { plan_type: any; sku: any };
      shouldAutoSelectPlanForUpgrade?: boolean;
    }) {
      this.setState({
        isOpen: true,
        utmMedium: payload?.utmMedium,
      });

      // this.fetchPricing(payload);
    },

    async applyCoupon(payload: string) {
      const res = await api.pricing.validateCoupon({ code: payload });

      if (!res.error && typeof res.data.sku === 'string') {
        this.setCoupon({ ...res.data, code: payload });
      } else {
        dispatch.saveToast.showError(res.error);
      }
    },

    async changePlan(payload: AnyObject, rootState) {
      const { coupon, utmMedium } = rootState.pricing;
      const isFreePlan = payload.sku === 'default';

      this.setState({
        newPlanType: payload.plan_type,
      });

      if (!isFreePlan) {
        const res = await api.pricing.changePlan({
          billing_plan_sku: payload.sku,
          utm_source: 'pushowl',
          utm_medium: utmMedium,
          coupon_code: coupon ? coupon.code : null,
        });

        if (res.error) dispatch.saveToast.showError(res.error);
        else {
          handleRedirect(res.data.confirmation_url);
          return;
        }
      } else {
        const res = await api.pricing.cancelPlan();
        if (!res.error) {
          const { user } = rootState.user;

          this.closePricing();
          Router.push(
            `/login?${new URLSearchParams({
              authToken: user.token,
              subdomain: user.website.subdomain,
              platform: user.website.platform,
            })}`,
          );

          return;
        }
      }
      this.setState({
        newPlanType: null,
        utmMedium: null,
      });
    },
  }),
});

export default pricing;
