import { createModel } from '@rematch/core';
import api from 'src/lib/api';
import { RootModel } from 'src/store/models';
import { segmentDefinitionRequestTransform } from '../util/transformer';

interface Segment {
  offset: number;
  campaigns: Array<AnyObject>;
  summary: AnyObject;
  isFetching: boolean;
}

export interface SegmentCondition {
  conditionType: 'property' | 'event';
  condition: string;
}

interface SegmentsState {
  isFetching: boolean;
  segments: Array<AnyObject>;
  campaignsMap: Record<any, Segment>;
  newSegmentName: string;
  segmentBeingEdited?: any;
  newSegment: Array<Array<SegmentCondition>>;
  newCreatedSegment: AnyObject;
  isFetchingSegmentSize: boolean;
  segmentSize: number;
}
const initialState = (): SegmentsState => ({
  isFetching: true,
  segments: null,
  campaignsMap: null,
  newSegmentName: '',
  segmentBeingEdited: {},
  newSegment: [[]],
  newCreatedSegment: null,
  isFetchingSegmentSize: false,
  segmentSize: -1,
});

// Creates temporary data structure for segment-campaigns map
function segmentsToCampaignsMapper(segments) {
  return segments.reduce((accumulator, currentValue) => {
    accumulator[currentValue.id] = {
      offset: 0,
      campaigns: null,
      summary: currentValue,
      isFetching: false,
    };
    return accumulator;
  }, {});
}

const segments = createModel<RootModel>()({
  state: initialState(),

  effects: {
    // eslint-disable-next-line
    async fetchSegments() {
      // fetch only active segments
      const { data, error } = await api.segments.getActive();
      if (!error) {
        const segmentList = data;
        const campaignsMap = segmentsToCampaignsMapper(segmentList);

        this.storeState({
          campaignsMap,
          isFetching: false,
          segments: segmentList,
        });
      }
    },

    async fetchAllSegments() {
      this.storeState({ isFetching: true });

      const { data, error } = await api.segments.getAll();
      if (!error) {
        const segmentList = data;
        const campaignsMap = segmentsToCampaignsMapper(segmentList);

        this.storeState({
          campaignsMap,
          isFetching: false,
          segments: segmentList,
        });
      }
    },

    // fetches segments
    async fetchCampaignsForSegmentID(segmentID, rootState) {
      const {
        offset,
        summary,
        campaigns = [],
      } = rootState.segments.campaignsMap[segmentID];
      this.storeCampaigns({
        summary,
        isFetching: true,
      });

      // fetch campaigns
      const { data, error } = await api.segments.getCampaignsFor({
        offset,
        id: segmentID,
      });

      if (!error) {
        this.storeCampaigns({
          summary,
          isFetching: false,
          offset: offset + 10,
          campaigns: [...(campaigns || []), ...data],
        });
      }
    },

    // easy api for use in components
    async fetchCampaignsFor(segmentID: string, rootState) {
      // if segments are not fetched already, fetch them first
      if (rootState.segments.segments === null) {
        await this.fetchAllSegments();
      }

      // finally, fetch campaigns
      this.fetchCampaignsForSegmentID(segmentID);
    },

    async saveSegment(payload, rootState): Promise<{ data: any; error: any }> {
      const { newSegmentName, newSegment, segmentBeingEdited } =
        rootState.segments;

      const newSegmentClone = segmentDefinitionRequestTransform(newSegment);

      // save to backend
      if (segmentBeingEdited.id) {
        return api.segments.saveSegment({
          id: segmentBeingEdited.id,
          segment_name: newSegmentName,
          segment_definition: newSegmentClone,
          definition_version: 'v3',
        });
      }
      return api.segments.saveNewSegment({
        segment_name: newSegmentName,
        segment_definition: newSegmentClone,
        definition_version: 'v3',
      });
    },

    async getSegmentSize(payload: any) {
      const newSegmentClone = segmentDefinitionRequestTransform(payload);

      this.storeState({
        isFetchingSegmentSize: true,
      });
      const { data, error } = await api.segments.getSegmentSize({
        definition: newSegmentClone,
        definition_version: 'v3',
      });

      if (!error) {
        this.storeState({
          isFetchingSegmentSize: false,
          segmentSize: data.subscriber_count,
        });
      } else {
        this.storeState({
          isFetchingSegmentSize: false,
          segmentSize: 0,
        });
      }
    },

    async changeSegmentState(
      {
        segmentId,
        state,
      }: { segmentId: number; state: 'active' | 'deleted' | 'archived' },
      rootState,
    ) {
      const stateToActionMap = {
        archived: 'archive',
        active: 'activate',
        deleted: 'delete',
      };
      const { error } = await api.segments.changeSegmentState(
        segmentId,
        stateToActionMap[state],
      );
      if (!error) {
        const segment = rootState.segments.segments.find(
          s => s.id === segmentId,
        );
        segment.state = state;
        // segments
        this.storeState({
          segments: [...rootState.segments.segments],
        });
      }
    },
  },

  reducers: {
    storeState(state, payload): SegmentsState {
      return {
        ...state,
        ...payload,
      };
    },

    addSegment(state, payload: AnyObject): SegmentsState {
      return {
        ...state,
        segments: [...state.segments, payload],
      };
    },

    setNewSegmentName(state, payload: { name: string }): SegmentsState {
      return {
        ...state,
        newSegmentName: payload.name,
      };
    },

    setNewSegment(
      state,
      payload: Array<Array<SegmentCondition>>,
    ): SegmentsState {
      return {
        ...state,
        newSegment: payload,
      };
    },

    editSegment(state, payload: AnyObject): SegmentsState {
      return {
        ...state,
        segmentBeingEdited: payload,
      };
    },

    setNewCreatedSegment(state, payload: AnyObject): SegmentsState {
      return {
        ...state,
        newCreatedSegment: payload,
      };
    },

    setNewSegmentSize(state, payload: number): SegmentsState {
      return {
        ...state,
        segmentSize: payload,
      };
    },

    storeCampaigns(state, payload): SegmentsState {
      return {
        ...state,
        campaignsMap: {
          ...state.campaignsMap,
          [payload.summary.id]: {
            ...state.campaignsMap[payload.summary.id],
            ...payload,
          },
        },
      };
    },
  },
});

export default segments;
