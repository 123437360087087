import * as styled from 'styled-components';
import tokens from 'barn/tokens';
import space from 'barn/tokens/space';
import { closeTimeoutMS } from '../components/Modal/Modal';

export const GlobalStyle = styled.createGlobalStyle`
  :root {
    --changelog-top: 50px;
  }

  @media screen and (prefers-reduced-motion: reduce) {
    /**
        * Use this class on elements that have transitions/ animations
        * which are solely present for aethetics	
        */
    .non-essential-animation {
      transition: none !important;
    }
  }

  body {
    background: ${({ theme }) => theme.global.body.bg};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: normal;
    color: ${({ theme }) => theme.global.body.color};
  }

  @media screen and (max-width: ${tokens.responsiveScreenBreakpoint}) {
    html,
    body {
      font-size: 12px;
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  svg text {
    font-family: Arial, Helvetica, sans-serif;
  }

  a {
    color: ${({ theme }) => theme.global.a.color};
  }

  * {
    box-sizing: border-box;
    text-decoration: none;
    line-height: 1.4em;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
      sans-serif, Apple Color Emoji, Segoe UI Emoji;
    scroll-behavior: smooth;
  }

  p,
  ul,
  li {
    margin: 0;
  }

  button {
    background-color: transparent;
  }

  /**
    * Custom scrollbar
    * Only Weblkit has support for custom scrollbars i.e Chrome Browsers
    */
  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.global.scrollbarTrack.bg};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.global.scrollbarThumb.bg};
    border-radius: 10px;
    transition: 0.3s ease;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.global.scrollbarThumb.hover.bg};
  }

  /* Custom styles for libraries */
  /* @reach/tabs */
  div[data-reach-tab-panels] {
    height: 100%;
    overflow: auto;
  }

  div[role='tablist'] {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid ${({ theme }) => theme.global.tablist.borderColor};
    padding: 0 ${space.padding[6]};
    height: 50px;
  }
  div[role='tablist'][data-reach-tablist-centered] {
    justify-content: center;
  }

  div[role='tablist'] button[role='tab'] {
    border: 0;
    /* outline: 0; */
    border-bottom: 2px solid transparent;
    padding: 0 5px;
    margin-right: 40px;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    background: transparent;
    cursor: pointer;
    color: ${({ theme }) => theme.global.tab.color};
  }

  div[role='tablist'] button[aria-selected='true'] {
    border-color: ${({ theme }) => theme.global.tab.selected.borderColor};
    color: ${({ theme }) => theme.global.tab.selected.color};
    font-weight: 600;
  }

  div[role='tabpanel'] {
    padding: 20px;
    height: 100%;
  }

  div[role='tabpanel'][data-reach-tabpanel-no-padding] {
    padding: 0;
  }

  /* @reach/menu-item */
  [data-reach-menu-button] {
    color: ${({ theme }) => theme.global.dropdownMenuButton.color};
  }
  [data-reach-menu-item] [data-reach-menu-selected] {
    background-color: ${({ theme }) => theme.global.dropdownMenuItem};
  }

  [data-reach-menu],
  [data-reach-menu-popover] {
    display: block;
    position: absolute;
  }

  [data-reach-menu][hidden],
  [data-reach-menu-popover][hidden] {
    display: none;
  }

  [data-reach-menu-button] {
    display: flex;
    align-items: center;
    border: 0;
    background: 0;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
    transition: background 0.2s ease;
  }
  [data-reach-menu-button][aria-expanded='true'],
  [data-reach-menu-button]:hover {
    background: ${({ theme }) => theme.global.dropdownMenuButton.hover.bg};
  }
  [data-reach-menu-list] {
    background-color: ${({ theme }) => theme.global.dropdownMenuList.bg};
    border-radius: 4px;
    border: 1px solid
      ${({ theme }) => theme.global.dropdownMenuList.borderColor};
    box-shadow: ${({ theme }) => theme.global.dropdownMenuList.boxShadow};
    min-height: 34px;
  }
  [data-reach-menu-item] {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 0;
    cursor: pointer;
    padding: 10px 14px;
    user-select: none;
  }

  [data-reach-menu-list],
  [data-reach-menu-items] {
    white-space: nowrap;
  }
  [data-reach-menu-item][data-selected] {
    background: ${({ theme }) => theme.global.dropdownMenuItem.selected.bg};
    color: ${({ theme }) => theme.global.dropdownMenuItem.selected.color};
  }
  [data-reach-menu-item] > svg:first-child {
    margin-right: 8px;
  }

  /* rc-tooltip */
  .rc-tooltip.rc-tooltip-zoom-enter,
  .rc-tooltip.rc-tooltip-zoom-leave {
    display: block;
  }
  .rc-tooltip-zoom-enter,
  .rc-tooltip-zoom-appear {
    opacity: 0;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
    animation-play-state: paused;
  }
  .rc-tooltip-zoom-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
    animation-play-state: paused;
  }
  .rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
  .rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
    animation-name: rcToolTipZoomIn;
    animation-play-state: running;
  }
  .rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
    animation-name: rcToolTipZoomOut;
    animation-play-state: running;
  }
  @keyframes rcToolTipZoomIn {
    0% {
      opacity: 0;
      transform-origin: 50% 50%;
      transform: scale(0, 0);
    }
    100% {
      opacity: 1;
      transform-origin: 50% 50%;
      transform: scale(1, 1);
    }
  }
  @keyframes rcToolTipZoomOut {
    0% {
      opacity: 1;
      transform-origin: 50% 50%;
      transform: scale(1, 1);
    }
    100% {
      opacity: 0;
      transform-origin: 50% 50%;
      transform: scale(0, 0);
    }
  }
  .rc-tooltip {
    position: absolute;
    z-index: ${tokens.zIndex[6]};
    display: block;
    visibility: visible;
    font-size: 12px;
    line-height: 1.5;
    opacity: 1;
  }
  .rc-tooltip-hidden {
    display: none;
  }
  .rc-tooltip-placement-top,
  .rc-tooltip-placement-topLeft,
  .rc-tooltip-placement-topRight {
    padding: 5px 0 0 0;
  }
  .rc-tooltip-placement-right,
  .rc-tooltip-placement-rightTop,
  .rc-tooltip-placement-rightBottom {
    padding: 0 5px 0 0;
  }
  .rc-tooltip-placement-bottom,
  .rc-tooltip-placement-bottomLeft,
  .rc-tooltip-placement-bottomRight {
    padding: 0 0 5px 0;
  }
  .rc-tooltip-placement-left,
  .rc-tooltip-placement-leftTop,
  .rc-tooltip-placement-leftBottom {
    padding: 0 0 0 5px;
  }
  .rc-tooltip-inner {
    text-align: left;
    text-decoration: none;
    background-color: ${({ theme }) => theme.global.rcTooltipInner.bg};
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.global.rcTooltipInner.borderColor};
    box-shadow: ${({ theme }) => theme.global.rcTooltipInner.boxShadow};
    min-height: 34px;
  }
  .rc-tooltip-arrow {
    display: none;
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  .rc-tooltip-arrow::after {
    content: '';
    position: absolute;
    border-style: solid;
    border-color: transparent;
  }
  .rc-tooltip-placement-top .rc-tooltip-arrow,
  .rc-tooltip-placement-topLeft .rc-tooltip-arrow,
  .rc-tooltip-placement-topRight .rc-tooltip-arrow {
    bottom: 4px;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: ${({ theme }) => theme.global.rcTooltipArrow.borderColor};
  }
  .rc-tooltip-placement-top .rc-tooltip-arrow::after,
  .rc-tooltip-placement-topLeft .rc-tooltip-arrow::after,
  .rc-tooltip-placement-topRight .rc-tooltip-arrow::after {
    bottom: 2px;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: ${({ theme }) =>
      theme.global.rcTooltipArrow.after.borderColor};
  }
  .rc-tooltip-placement-top .rc-tooltip-arrow {
    left: 50%;
  }
  .rc-tooltip-placement-topLeft .rc-tooltip-arrow {
    left: 15%;
  }
  .rc-tooltip-placement-topRight .rc-tooltip-arrow {
    right: 15%;
  }
  .rc-tooltip-placement-right .rc-tooltip-arrow,
  .rc-tooltip-placement-rightTop .rc-tooltip-arrow,
  .rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    left: 4px;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: ${({ theme }) =>
      theme.global.rcTooltipArrow.borderColor};
  }
  .rc-tooltip-placement-right .rc-tooltip-arrow::after,
  .rc-tooltip-placement-rightTop .rc-tooltip-arrow::after,
  .rc-tooltip-placement-rightBottom .rc-tooltip-arrow::after {
    left: 2px;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: ${({ theme }) =>
      theme.global.rcTooltipArrow.after.borderColor};
  }
  .rc-tooltip-placement-right .rc-tooltip-arrow {
    top: 50%;
  }
  .rc-tooltip-placement-rightTop .rc-tooltip-arrow {
    top: 15%;
    margin-top: 0;
  }
  .rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    bottom: 15%;
  }
  .rc-tooltip-placement-left .rc-tooltip-arrow,
  .rc-tooltip-placement-leftTop .rc-tooltip-arrow,
  .rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    right: 4px;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: ${({ theme }) =>
      theme.global.rcTooltipArrow.borderColor};
  }
  .rc-tooltip-placement-left .rc-tooltip-arrow::after,
  .rc-tooltip-placement-leftTop .rc-tooltip-arrow::after,
  .rc-tooltip-placement-leftBottom .rc-tooltip-arrow::after {
    right: 2px;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: ${({ theme }) =>
      theme.global.rcTooltipArrow.after.borderColor};
  }
  .rc-tooltip-placement-left .rc-tooltip-arrow {
    top: 50%;
  }
  .rc-tooltip-placement-leftTop .rc-tooltip-arrow {
    top: 15%;
    margin-top: 0;
  }
  .rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    bottom: 15%;
  }
  .rc-tooltip-placement-bottom .rc-tooltip-arrow,
  .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
  .rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    top: 4px;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: ${({ theme }) =>
      theme.global.rcTooltipArrow.borderColor};
  }
  .rc-tooltip-placement-bottom .rc-tooltip-arrow::after,
  .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow::after,
  .rc-tooltip-placement-bottomRight .rc-tooltip-arrow::after {
    top: 2px;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: ${({ theme }) =>
      theme.global.rcTooltipArrow.after.borderColor};
  }
  .rc-tooltip-placement-bottom .rc-tooltip-arrow {
    left: 50%;
  }
  .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
    left: 15%;
  }
  .rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    right: 15%;
  }

  /* React Modal */
  .ReactModal__Content--full-screen {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 0;
    border-radius: 0;
  }

  .ReactModal__Overlay:before {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    opacity: 0;
    background-color: ${({ theme }) =>
      theme.global.ReactModalOverlay.before.bg};
    transition: opacity 400ms cubic-bezier(0.15, 1, 0.3, 1);
    will-change: opacity;
  }

  .ReactModal__Overlay--after-open:before {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close:before {
    opacity: 0;
  }

  .ReactModal__Content {
    --animate-y-offset: 15px;
    opacity: 0;
    transform: translateY(var(--animate-y-offset));
    transition: 500ms cubic-bezier(0.15, 1, 0.3, 1) 0ms;
    transition-property: opacity, transform;
    will-change: opacity, transform;
  }

  .ReactModal__Content--after-open {
    transform: translateY(0px);
    opacity: 1;
  }

  .ReactModal__Content--before-close {
    transform: translateY(var(--animate-y-offset));
    opacity: 0;
    transition-duration: ${closeTimeoutMS}ms;
  }

  /* Changelog Canny App */
  #canny-changelog-iframe {
    position: fixed !important;
    top: var(--changelog-top) !important;
  }

  /* modal styles with react-modal classes */
  .barn-modal {
    background: ${({ theme }) => theme.global.barnModal.bg};
    border-radius: 12px;
    position: relative;
    padding: ${tokens.space.padding[6]};
    min-width: 400px;
    max-height: 90vh;
    overflow: auto;
    margin: ${tokens.space.padding[3]};
  }

  .barn-modal-no-close-button {
    padding: 20px !important;
  }

  .barn-modal-no-padding {
    padding: 0 !important;
  }

  .barn-modal--widget {
    padding: 0;
    min-width: 814px;
  }
  .barn-modal--meeting {
    padding: 0;
    min-width: 1024px;
  }

  .barn-modal--promotion {
    padding: 0;
    width: 750px;
    height: 490px;
  }

  .barn-modal--consumption {
    padding: 0;
    min-width: 447px;
  }

  .barn-modal--popup {
    max-width: 55ch;
  }

  .barn-modal__overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* Moving barn modal above intercom widget */
    z-index: ${tokens.zIndex[7]};
  }

  .rc-tooltip-placement-top {
    /* Make sure the tooltip appears above the intercom widget */
    z-index: ${tokens.zIndex[6]};
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .getting-started-tooltip {
    opacity: 0;
    animation: fadeIn 1s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
  }

  .getting-started-tooltip .rc-tooltip-inner {
    border: none;
    border-radius: 12px;
  }

  .barn-select,
  .date-range-filter,
  .barn-emoji-picker,
  [data-reach-listbox-popover] {
    z-index: ${tokens.zIndex[7]};
  }
`;

export const SelectBox = styled.createGlobalStyle`
  [data-reach-listbox-popover] {
    text-align: left;
    text-decoration: none;
    color: ${({ theme }) => theme.selectBox.listboxPopover.color};
    background-color: ${({ theme }) => theme.selectBox.listboxPopover.bg};
    border-radius: ${tokens.radii[3]};
    border: 1px solid
      ${({ theme }) => theme.selectBox.listboxPopover.borderColor};
    box-shadow: ${({ theme }) => theme.selectBox.listboxPopover.boxShadow};
    min-height: 34px;
  }

  [data-reach-listbox-popover] li {
    color: ${({ theme }) => theme.selectBox.listboxPopover.color};
  }

  [data-reach-listbox-list] {
    max-height: 150px;
    overflow-y: auto;
  }

  [data-reach-listbox-button] {
    border-color: ${({ theme }) => theme.selectBox.listboxButton.borderColor};
    background-color: ${({ theme }) => theme.selectBox.listboxButton.bg};
    color: ${({ theme }) => theme.selectBox.listboxButton.color};
    font-size: ${tokens.fontSizes[2]};
    padding: ${tokens.space.padding[1]};
    border-radius: ${tokens.radii[4]};
    cursor: pointer;
    display: inline-flex;
    align-items: center;
  }

  [data-reach-listbox-option] {
    display: flex;
    justify-content: space-between;
    color: initial;
    align-items: center;
    cursor: pointer;
    user-select: none;
    padding: ${tokens.space.padding[3]} ${tokens.space.padding[4]};
    font-size: ${tokens.fontSizes[2]};
  }

  [data-reach-listbox-option][data-current-selected] {
    font-weight: normal;
  }

  [data-reach-listbox-option][data-current-nav] {
    background-color: ${({ theme }) => theme.selectBox.hover.bg};
    color: ${({ theme }) => theme.selectBox.hover.color};
  }
`;
