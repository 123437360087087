import Link from 'next/link';
import { useGlobalState } from 'src/hooks/use-global-state';
import { isSmallScreen } from 'src/lib/utils';
import { motion } from 'framer-motion';
import colors from 'barn/tokens/colors';
import LockIcon from 'barn/icons/Lock';
import { ComingSoon } from 'src/components/ComingSoon';
import { StyledNavItem, NewDot } from './Styled';
import { HStack } from '../Stack';

const spring = {
  type: 'spring',
  stiffness: 350,
  duration: 2,
  damping: 27,
};

interface NavlinkProps {
  href: string;
  icon?: any;
  label: string;
  active: boolean;
  locked: boolean;
  sublink?: boolean;
  fillIcon?: boolean;
  target?: string;
  isNew?: boolean;
  isComingSoon?: boolean;
}
export const Navlink: React.FunctionComponent<NavlinkProps> = ({
  href,
  icon,
  label,
  active,
  locked,
  sublink = false,
  fillIcon = false,
  target = null,
  isNew = false,
  isComingSoon = false,
}) => {
  const {
    state: { isSidebarOpen },
  } = useGlobalState('common');

  return (
    <Link href={href} passHref>
      <StyledNavItem
        className={`${locked ? 'locked' : ''} ${active ? 'active' : ''} ${
          sublink && 'sublink'
        } ${fillIcon && 'fillIcon'}`}
        data-test={`sidebar-${(label || '')
          .toLowerCase()
          .split(' ')
          .join('-')}`}
        target={target}
        isComingSoon={isComingSoon}
      >
        <div>
          {icon}
          {(!isSmallScreen() || isSidebarOpen) && (
            <HStack gap={3} align='center' tag='span'>
              <span>{label}</span>
              {isNew && <NewDot />}
            </HStack>
          )}
        </div>
        <div>{locked && <LockIcon color={colors.blues[5]} />}</div>
        {active && (
          <motion.span
            className='active-indicator'
            layoutId='activeRouteIndicator'
            initial={false}
            transition={spring}
          />
        )}
        {(!isSmallScreen() || isSidebarOpen) && isComingSoon && <ComingSoon />}
      </StyledNavItem>
    </Link>
  );
};

export const NavButton = ({ icon, label, locked }) => {
  return (
    <StyledNavItem
      as='button'
      className={(locked && 'locked') || ''}
      type='button'
      data-test={`sidebar-${(label || '').toLowerCase().split(' ').join('-')}`}
    >
      <div>
        {icon}
        {label}
      </div>
      <div>{locked && <LockIcon color={colors.blues[5]} />}</div>
    </StyledNavItem>
  );
};
