import colors from 'barn/tokens/colors';

export const profileMenuLight = {
  color: colors.greys[1],
  borderColor: colors.greys[4],
  planName: {
    color: colors.greys[1],
  },
  popover: {
    bg: colors.greys[8],
  },
  plan: {
    color: colors.greys[2],
  },
  item: {
    borderColor: colors.greys[5],
    hover: {
      bg: colors.greys[6],
    },
  },
};

export const profileMenuDark = {
  color: colors.greys[7],
  borderColor: colors.greys[15],
  planName: {
    color: colors.greys[5],
  },
  popover: {
    bg: colors.greys[9],
  },
  plan: {
    color: colors.greys[5],
  },
  item: {
    borderColor: colors.greys[12],
    hover: {
      bg: colors.greys[14],
    },
  },
};
