import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useGlobalState } from 'src/hooks/use-global-state';
import api from 'src/lib/api';
import useAuth from 'src/hooks/use-auth';
import { metadata, MetadataProps } from './metadata';

export const useCheckRenderLogic = (): {
  shouldRenderPopup: boolean;
  popupData?: MetadataProps;
} => {
  const {
    state: {
      consumption: { isFetching, impressionsConsumed, impressionsLimit },
    },
  } = useGlobalState('settings');
  const {
    state: {
      isLoggedIn,
      segmentData: { isSupportLogin },
    },
  } = useGlobalState('user');
  const {
    user: {
      website: { consumption_popup_level: popupLevel = null },
      plan: { pricing_tier: pricingTier },
      isGILChild,
    },
    setWebsitePreference,
  } = useAuth();
  const router = useRouter();

  const [shouldRenderPopup, setShouldRenderPopup] = useState(false);
  const [popupData, setPopupData] = useState(null);

  useEffect(() => {
    if (
      isFetching ||
      !isLoggedIn ||
      isGILChild ||
      router.asPath.includes('#pricing')
    ) {
      setShouldRenderPopup(false);
      return;
    }

    const consumptionPercent = (impressionsConsumed * 100) / impressionsLimit;

    if (consumptionPercent < 70 || popupLevel === 100) {
      setShouldRenderPopup(false);
      return;
    }

    const setPreferencesAndState = (value: 70 | 90 | 100) => {
      if (!isSupportLogin) {
        api.preferences.setUserPreference({
          key: 'consumption_popup_level',
          value,
        });
      }
      setWebsitePreference({ key: 'consumption_popup_level', value });
      setShouldRenderPopup(true);
      setPopupData(
        metadata[pricingTier === 'free' ? 'free' : 'nonFree'][value],
      );
    };

    if (popupLevel === null) {
      if (consumptionPercent < 90) setPreferencesAndState(70);
      else if (consumptionPercent < 100) setPreferencesAndState(90);
      else setPreferencesAndState(100);
    }

    if (popupLevel === 70) {
      if (consumptionPercent < 100 && consumptionPercent >= 90)
        setPreferencesAndState(90);
      else if (consumptionPercent >= 100) setPreferencesAndState(100);
    }

    if (popupLevel === 90 && consumptionPercent >= 100)
      setPreferencesAndState(100);
  }, [isFetching, isLoggedIn]);

  return { shouldRenderPopup, popupData };
};
