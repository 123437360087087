import colors from 'barn/tokens/colors';

export const cardsLight = {
  automation: {
    meta: {
      borderColor: colors.greys[6],
    },
  },
  campaign: {
    detailsWrapper: {
      borderColor: colors.greys[6],
    },
    message: {
      color: colors.greys[2],
    },
  },
  infoCard: {
    borderColor: colors.greys[5],
    bg: colors.greys[8],
    hover: {
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.12)',
    },
    disabled: {
      bg: colors.greys[7],
    },
  },
  statItem: {
    label: {
      color: colors.greys[2],
    },
    stat: {
      color: colors.greys[1],
    },
  },
  tag: {
    color: colors.greys[1],
  },
  smartTag: {
    bg: '#e5f1ff',
  },
  flashTag: {
    bg: '#fff9c4',
  },
};

export const cardsDark = {
  ...cardsLight,
  automation: {
    meta: {
      borderColor: colors.greys[16],
    },
  },
  campaign: {
    detailsWrapper: {
      borderColor: colors.greys[6],
    },
    message: {
      color: colors.greys[4],
    },
  },
  infoCard: {
    borderColor: colors.greys[12],
    bg: colors.greys[12],
    hover: {
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.12)',
    },
    disabled: {
      bg: colors.greys[2],
    },
  },
  statItem: {
    label: {
      color: colors.greys[8],
    },
    stat: {
      color: colors.greys[8],
    },
  },
  tag: {
    color: colors.greys[1],
  },
};
