const InfoRoundedFilled = ({
  style = {},
  size = 20,
  color = 'currentColor',
}) => {
  return (
    <svg
      fill='none'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      style={style}
    >
      <path
        fill={color}
        d='M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'
      />
    </svg>
  );
};

export default InfoRoundedFilled;
