import { useRouter } from 'next/router';
import useCreateCampaign from 'src/hooks/use-create-campaign';
import { format } from 'date-fns';
import useCreateNotification from 'src/hooks/use-create-notification';
import { NOTIFICATION_CREATOR_TYPE } from 'src/modules/notification-creator/models';
import { campaign as campaignTemplates } from 'src/modules/notification-creator/components/TemplatesSidebar/templates';

const useNewCampaign = () => {
  const router = useRouter();
  const { actions: createCampaignActions } = useCreateCampaign();
  const { actions: createNotificationActions } = useCreateNotification();

  const getCampaignTitle = () => {
    const todaysDate = format(new Date(), 'do MMM yyyy');
    const formattedTodaysDate = `New Campaign - ${todaysDate}`;

    return formattedTodaysDate;
  };

  // click and other action handlers
  const startNewCampaign = (campaignProps = {}) => {
    createNotificationActions.loadState({
      title: getCampaignTitle(),
      message: campaignTemplates[0].description,
      notificationCreatorType: NOTIFICATION_CREATOR_TYPE.CAMPAIGNS,
      error: {},
    });
    createCampaignActions.start({ ...campaignProps, isDraft: true, id: null });

    router.push('/campaigns/create');
  };

  return { startNewCampaign };
};

export { useNewCampaign };
