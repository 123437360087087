import abandonedCart from './abandoned-cart';
import backInStock from './back-in-stock';
import browseAbandonment from './browse-abandonment';
import priceDrop from './price-drop';
import shipping from './shipping';
import welcome from './welcome';

export default {
  abandonedCart,
  backInStock,
  browseAbandonment,
  priceDrop,
  shipping,
  welcome,
};
