import colors from 'barn/tokens/colors';

export const textLight = {
  primary: {
    color: colors.greys[0],
  },
  default: {
    color: colors.greys[1],
  },
  secondary: {
    color: colors.greys[2],
  },
  tertiary: {
    color: colors.greys[3],
  },
  success: {
    color: colors.greens[4],
  },
  warning: {
    color: colors.reds[4],
  },
  danger: {
    color: colors.reds[3],
  },
};

export const textDark = {
  ...textLight,
  default: {
    color: colors.greys[7],
  },
  primary: {
    color: colors.greys[8],
  },
  secondary: {
    color: colors.greys[4],
  },
  tertiary: {
    color: colors.greys[3],
  },
  danger: {
    color: colors.reds[6],
  },
  failure: {
    color: colors.reds[3],
  },
  success: {
    color: colors.greens[9],
  },
};
