import styled from 'styled-components';
import { fontSizes } from 'barn/tokens/typography';
import tokens from 'barn/tokens';

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLabel = styled.label`
  margin: 0 0 ${tokens.space.margin[2]};
  font-size: ${fontSizes[2]};
  color: ${({ theme }) => theme.input.label.color};
  font-weight: 500;
`;

export const StyledHelpText = styled.div`
  margin: 0 0 ${tokens.space.margin[2]};
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  border-radius: ${tokens.radii[4]};
  outline: transparent;
  border: 1px solid ${({ theme }) => theme.input.borderColor};
  background: ${({ theme }) => theme.input.bg};
  padding: ${tokens.space.padding[3]} ${tokens.space.padding[4]};
  height: 36px;
  font-size: ${fontSizes[2]};
  color: ${({ theme }) => theme.input.color};
  max-width: 100ch;

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.input.hover.borderColor};
  }

  &:focus {
    /*  
       Using box-shadow instead of outline 
       since outline won't have border-radius in Safari and old Edge (v18)
      */
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.input.hover.borderColor};
  }

  &[disabled],
  &:disabled {
    color: ${({ theme }) => theme.input.disabled.color};
    cursor: not-allowed;

    &:hover {
      border-color: ${({ theme }) => theme.input.disabled.hover.borderColor};
    }
  }

  &.error {
    border-color: ${({ theme }) => theme.input.error.borderColor};
  }

  &.error:focus {
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.input.error.borderColor};
  }

  &.inactive {
    background: ${({ theme }) => theme.input.inactive.bg};
    border-color: ${({ theme }) => theme.input.inactive.borderColor};
    opacity: 1;
    color: ${({ theme }) => theme.input.inactive.color};
  }
`;

export const StyledAsterisk = styled.span`
  margin: 0 0 0 ${tokens.space.margin[1]};
  color: ${({ theme }) => theme.input.asterisk.color};
  font-size: ${fontSizes[4]};
`;

export const StyledError = styled.div`
  margin-top: ${tokens.space.margin[2]};
  font-size: ${fontSizes[1]};
  color: ${({ theme }) => theme.input.error.color};
`;

export default StyledInput;

export const StyledColorPickerWidget = styled.button<{
  value: string;
}>`
  position: absolute;
  height: 20px;
  width: 20px;
  border: 1px solid ${({ theme }) => theme.input.colorPicker.borderColor};
  border-radius: ${tokens.radii[3]};
  cursor: pointer;
  background-color: ${props => props.value};
  right: 10px;
`;

export const StyledColorPicker = styled.div`
  position: absolute;
  left: 10px;
  top: -100px;
  z-index: ${tokens.zIndex[5]};

  * {
    font-family: inherit !important;
  }

  *:not(input) {
    cursor: pointer;
  }
`;
