import { createModel } from '@rematch/core';
import api from 'src/lib/api';
import { RootModel } from 'src/store/models';
import steps from '../constants/steps';

export interface GettingStartedState {
  step: number | null;
  screenshot: string | null;
  isLoadingHeroImage: boolean;
  showExitWarning: boolean;
}

const initialState = (): GettingStartedState => ({
  step: null,
  screenshot: null,
  isLoadingHeroImage: false,
  showExitWarning: false,
});

const gettingStarted = createModel<RootModel>()({
  state: initialState(),
  effects: {
    async initGettingStarted() {
      // Fetch screenshot
      const { data, error } = await api.preferences.fetchStoreScreenshot();

      this.setState({
        step: steps.INITIAL_MODAL,
        screenshot: error ? null : data,
        isLoadingHeroImage: false,
        showExitWarning: false,
      });
    },
    async setGettingStarted(value: string) {
      try {
        if (value === 'completed') {
          this.setState({
            step: null,
            screenshot: null,
          });
        }
        await api.preferences.setGettingStarted(value);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
    async setStep(payload: number) {
      this.setState({
        step: payload,
      });
    },
  },
  reducers: {
    setState(
      state: GettingStartedState,
      payload: Partial<GettingStartedState>,
    ) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export default gettingStarted;
