import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { fontSizes } from 'barn/tokens/typography';

type TextVariant =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'success'
  | 'warning'
  | 'danger';

interface TextProps {
  tag?: string;
  weight?: any;
  size?: number;
  htmlFor?: string;
  textTransform?: string;
  noMargin?: boolean;
  maxWidth?: string;
  textAlign?: string;
  textWrap?: string;
  variant?: TextVariant;
  testHandle?: string;
  id?: string;
}

export const Text: React.FunctionComponent<TextProps> = ({
  tag = 'span',
  weight = 'normal',
  variant = 'default',
  noMargin = false,
  size = 1,
  textTransform,
  textAlign = 'start',
  children,
  maxWidth = 'auto',
  textWrap,
  htmlFor,
  testHandle,
  id,
}) => {
  const Tag: any = tag as keyof JSX.IntrinsicElements;
  const theme = useContext(ThemeContext);

  return (
    <Tag
      htmlFor={htmlFor}
      style={{
        color: theme.text[variant].color,
        fontWeight: weight,
        fontSize: fontSizes[size],
        textTransform,
        margin: noMargin ? 0 : null,
        maxWidth,
        textAlign,
        whiteSpace: textWrap,
      }}
      data-test={testHandle}
      id={id}
    >
      {children}
    </Tag>
  );
};
