import { v1 as uuidv1 } from 'uuid';
import colors from 'barn/tokens/colors';

const ChevronDown = ({ color = colors.greys[2], style = {} }) => {
  const id = uuidv1();

  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' style={style}>
      <mask
        id={`maskChevronDown${id}`}
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='20'
        height='20'
      >
        <rect className='iconColor' width='20' height='20' fill={color} />
      </mask>
      <g mask={`url(#maskChevronDown${id})`}>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10 12.6666C9.82934 12.6666 9.65868 12.6013 9.52869 12.4713L6.19549 9.13812C5.93484 8.87746 5.93484 8.45615 6.19549 8.19549C6.45615 7.93484 6.87746 7.93484 7.13812 8.19549L10 11.0574L12.8619 8.19549C13.1225 7.93484 13.5439 7.93484 13.8045 8.19549C14.0652 8.45615 14.0652 8.87746 13.8045 9.13812L10.4713 12.4713C10.3413 12.6013 10.1707 12.6666 10 12.6666Z'
          fill={color}
        />
      </g>
    </svg>
  );
};

export default ChevronDown;
