import colors from 'barn/tokens/colors';

export const islandLight = {
  bg: colors.greys[6],
  titlebar: {
    borderColor: colors.greys[9],
  },
};

export const islandDark = {
  bg: colors.greys[15],
  titlebar: {
    borderColor: colors.greys[4],
  },
};
