import layout from 'barn/tokens/layout';
import styled from 'styled-components';
import tokens from 'barn/tokens';

const { top, bottom, width, height, responsiveWidth } = layout.sidebar;

const StyledSidebar = styled.div<{ isSidebarOpen: boolean }>`
  z-index: ${tokens.zIndex[4]};
  display: flex;
  flex-direction: column;
  position: fixed;
  top: ${top};
  bottom: ${bottom};
  width: ${width};
  height: ${height};
  background: ${({ theme }) => theme.sidebar.bg};
  border-right: 1px solid ${({ theme }) => theme.sidebar.borderColor};
  min-height: 584px;

  > * {
    margin-bottom: ${tokens.space.margin[4]};
  }

  @media screen and (max-width: ${tokens.responsiveScreenBreakpoint}) {
    width: ${props => (props.isSidebarOpen ? width : responsiveWidth)};
  }
`;

export const StyledCTAHolder = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${tokens.space.margin[7]};
  flex-shrink: 0;
`;

export const StyledLogoHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  gap: ${tokens.space.margin[2]};
`;

export default StyledSidebar;
