export const Owly90 = () => {
  return (
    <svg
      width='400'
      height='250'
      viewBox='0 0 400 250'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_f_304_27134)'>
        <path
          d='M153.5 11.5L125.5 241H273L246.5 7C246 11.3333 236.8 20 204 20C171.2 20 156.667 14.3333 153.5 11.5Z'
          fill='#FFCF00'
        />
        <mask
          id='mask0_304_27134'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='125'
          y='11'
          width='148'
          height='230'
        >
          <path
            d='M153.5 11.5L125.5 241H273L251.745 53.3183C251.032 47.0194 210.339 20 204 20C171.2 20 156.667 14.3333 153.5 11.5Z'
            fill='#FFCF00'
          />
        </mask>
        <g mask='url(#mask0_304_27134)'>
          <path
            d='M199 117C181.4 97.7999 153.333 107.333 141.5 114.5L144.5 84L180 72.5L254.5 76L258 111.5C234.8 99.1 209 110 199 117Z'
            fill='#FFB600'
          />
        </g>
        <path
          d='M155 111.5L105 241H163C173.167 204.167 193.6 127.6 194 116C194.4 104.4 188.167 108.167 185 111.5C183.8 101.9 177.833 107.167 175 111C173.4 101 167.667 106.167 165 110C162.2 97.9998 157.167 106 155 111.5Z'
          fill='#FFCF00'
        />
        <path
          d='M244.518 111.5L294.518 241H236.518C226.352 204.167 205.918 127.6 205.518 116C205.118 104.4 211.352 108.167 214.518 111.5C215.718 101.9 221.685 107.167 224.518 111C226.118 101 231.852 106.167 234.518 110C237.318 97.9998 242.352 106 244.518 111.5Z'
          fill='#FFCF00'
        />
        <path
          d='M155 111.5L105 241H163C173.167 204.167 193.6 127.6 194 116C194.4 104.4 188.167 108.167 185 111.5C183.8 101.9 177.833 107.167 175 111C173.4 101 167.667 106.167 165 110C162.2 97.9998 157.167 106 155 111.5Z'
          stroke='black'
          strokeWidth='2'
          strokeLinejoin='round'
        />
        <path
          d='M244.518 111.5L294.518 241H236.518C226.352 204.167 205.918 127.6 205.518 116C205.118 104.4 211.352 108.167 214.518 111.5C215.718 101.9 221.685 107.167 224.518 111C226.118 101 231.852 106.167 234.518 110C237.318 97.9998 242.352 106 244.518 111.5Z'
          stroke='black'
          strokeWidth='2'
        />
        <path
          d='M111.27 57.0029C111.522 58.4574 112.176 59.7168 112.176 61.216C112.176 62.6079 111.804 63.7348 111.471 65.0643C111.289 65.7945 110.82 66.4293 110.478 67.0765C110.366 67.2881 110.052 67.7526 110.025 67.982C109.994 68.2497 109.71 68.7335 109.597 69.0006C109.412 69.4421 109.693 70.2065 109.824 70.6481C110.213 71.9583 111.294 73.1685 111.974 74.3455C112.707 75.6127 112.97 77.2178 113.282 78.6215C113.567 79.9026 113.321 81.515 112.855 82.7213C112.585 83.4203 112.251 83.9732 111.836 84.5952C111.718 84.7718 111.371 85.5006 111.27 85.2994'
          stroke='black'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M118.967 58.7007C119.398 59.4555 119.716 60.3615 120.036 61.1719C120.244 61.699 120.644 62.2818 120.665 62.8634C120.704 63.9649 120.714 64.8562 120.501 65.9572C120.244 67.2862 119.465 68.3793 119.055 69.6546C118.598 71.0773 119.151 72.6932 119.64 74.0374C120.373 76.053 120.9 78.0919 120.671 80.2689C120.479 82.0871 120.347 84.1242 119.533 85.7521'
          stroke='black'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M290.955 36C290.703 37.4545 290.049 38.7138 290.049 40.213C290.049 41.6049 290.421 42.7319 290.754 44.0614C290.936 44.7916 291.405 45.4264 291.747 46.0736C291.859 46.2852 292.173 46.7496 292.2 46.979C292.231 47.2468 292.515 47.7306 292.628 47.9977C292.813 48.4392 292.532 49.2036 292.401 49.6452C292.012 50.9553 290.931 52.1656 290.251 53.3426C289.519 54.6098 289.255 56.2149 288.943 57.6185C288.658 58.8997 288.904 60.5121 289.37 61.7184C289.64 62.4174 289.974 62.9703 290.389 63.5922C290.507 63.7688 290.854 64.4976 290.955 64.2965'
          stroke='black'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M283.258 37.6978C282.827 38.4526 282.509 39.3586 282.189 40.169C281.981 40.696 281.581 41.2789 281.56 41.8605C281.521 42.962 281.511 43.8532 281.724 44.9542C281.981 46.2832 282.76 47.3764 283.17 48.6516C283.628 50.0744 283.074 51.6903 282.585 53.0345C281.852 55.0501 281.325 57.0889 281.554 59.266C281.746 61.0842 281.878 63.1213 282.692 64.7492'
          stroke='black'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M197.217 118.663C190.759 110.954 185.191 88.8911 183 77.5002C201.5 64.5002 216 77.5002 216 78.5002C214.237 92.2514 206.646 108.826 201.79 117.812C200.805 119.635 198.548 120.252 197.217 118.663Z'
          fill='#1E1E1E'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M138.232 64.2191C138.079 65.4399 138 66.6839 138 67.9462C138 84.3299 151.282 97.6115 167.665 97.6115C184.049 97.6115 197.331 84.3299 197.331 67.9462C197.331 52.2825 185.191 39.4542 169.807 38.3569C168.576 47.9607 161.288 57.2155 150.301 61.6711C146.292 63.2967 142.191 64.121 138.232 64.2191Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M258.185 58.497C258.561 59.7185 258.865 60.9769 259.091 62.2684C262.029 79.0281 250.824 94.9961 234.064 97.9339C217.304 100.872 201.336 89.6669 198.399 72.9072C195.59 56.8839 205.708 41.5843 221.249 37.7033C224.23 47.3068 233.345 55.467 245.383 58.0546C249.775 58.9987 254.118 59.1066 258.185 58.497Z'
          fill='white'
        />
        <path
          d='M135.5 65C146.333 64.1667 168.5 58.6 170.5 37'
          stroke='black'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M260.761 57.4696C249.972 58.7507 227.147 57.5837 221 36.7803'
          stroke='black'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <ellipse cx='169.5' cy='87' rx='9.5' ry='8' fill='#1E1E1E' />
        <ellipse cx='169.5' cy='91.9272' rx='3.5' ry='2' fill='white' />
        <ellipse
          cx='227.461'
          cy='87.4717'
          rx='10.0131'
          ry='8.43212'
          transform='rotate(-13.0248 227.461 87.4717)'
          fill='#1E1E1E'
        />
        <ellipse
          cx='227.667'
          cy='93.7721'
          rx='2.44773'
          ry='1.25251'
          transform='rotate(-13.0248 227.667 93.7721)'
          fill='white'
        />
      </g>
      <line
        x1='93.5'
        y1='241.5'
        x2='305.5'
        y2='241.5'
        stroke='black'
        strokeWidth='3'
        strokeLinecap='round'
      />
      <rect
        x='172'
        y='140'
        width='54'
        height='102'
        rx='1'
        fill='white'
        fillOpacity='0.5'
      />
      <mask id='path-23-inside-1_304_27134' fill='white'>
        <rect x='172' y='140' width='54' height='102' rx='1' />
      </mask>
      <rect
        x='172'
        y='140'
        width='54'
        height='102'
        rx='1'
        stroke='black'
        strokeWidth='4'
        mask='url(#path-23-inside-1_304_27134)'
      />
      <rect
        x='1'
        y='-1'
        width='30'
        height='6'
        rx='1'
        transform='matrix(1 0 0 -1 183 137)'
        fill='black'
        stroke='black'
        strokeWidth='2'
      />
      <rect x='175' y='230' width='47' height='9' rx='2' fill='#FF4952' />
      <rect
        x='215'
        y='147'
        width='7'
        height='44'
        rx='3.5'
        fill='white'
        fillOpacity='0.5'
      />
      <rect
        x='215'
        y='194'
        width='7'
        height='7'
        rx='3.5'
        fill='white'
        fillOpacity='0.5'
      />
      <defs>
        <filter
          id='filter0_f_304_27134'
          x='102'
          y='5'
          width='195.976'
          height='239'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='1'
            result='effect1_foregroundBlur_304_27134'
          />
        </filter>
      </defs>
    </svg>
  );
};
