import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import tokens from 'barn/tokens';

const StyledProgressBar: any = styled.div<{
  progress: number;
  progressColor: string;
  barWidth: number;
}>`
  background: ${({ theme }) => theme.progressBar.bg};
  border-radius: ${tokens.radii[3]};
  position: relative;
  width: ${props => props.barWidth};
  height: 8px;
  border: 1px solid ${({ theme }) => theme.progressBar.borderColor};
  overflow: hidden;

  &::after {
    content: '';
    background: ${props => props.progressColor};
    border-radius: ${tokens.radii[3]};
    position: absolute;
    top: 0;
    left: 0;
    width: ${props => props.progress}%;
    height: 100%;
  }
`;

interface Props {
  max?: number;
  value?: number;
  width?: number | string;
  breakpoints?: Array<number>;
}
export const ProgressBar: React.FunctionComponent<Props> = ({
  value = 0,
  max = 100,
  width = 100,
  breakpoints = [40, 80],
}) => {
  const theme = useContext(ThemeContext);
  const barWidth = typeof width === 'number' ? `${width}px` : width;
  const progress = (value / max) * 100;
  let color = theme.progressBar.info;

  if (progress > breakpoints[0]) {
    color = theme.progressBar.warn;
  }

  if (progress > breakpoints[1]) {
    color = theme.progressBar.danger;
  }

  return (
    <StyledProgressBar
      barWidth={barWidth}
      progress={progress > 100 ? 100 : progress}
      progressColor={color}
    />
  );
};
