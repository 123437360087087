import colors from 'barn/tokens/colors';

export const automationLight = {
  color: colors.greys[1],
  bg: colors.greys[8],
  imgContainer: {
    redCircle: {
      bg: colors.reds[5],
    },
    blueCircle: {
      bg: colors.blues[5],
    },
  },
};

export const automationDark = {
  color: colors.greys[8],
  bg: colors.greys[11],
  imgContainer: {
    redCircle: {
      bg: colors.reds[5],
    },
    blueCircle: {
      bg: colors.blues[5],
    },
  },
};
