import { get, post } from '../axios';

// endpoints
const CONFIG_ENDPOINT = `/dashboard/config/shipping/fulfillment-complete/notification/`;
const REPORT_ENDPOINT = `/dashboard/fulfillment-complete/report/`;

// query helpers
const getConfig = () => get(CONFIG_ENDPOINT);

const setConfig = (data: { enabled: boolean; metadata: AnyObject }) =>
  post(CONFIG_ENDPOINT, data);

const getReport = () => get(REPORT_ENDPOINT);

export default {
  getReport,
  getConfig,
  setConfig,
};
