import { v1 as uuidv1 } from 'uuid';
import colors from 'barn/tokens/colors';

const Campaigns = ({ color = colors.greys[2], style = {} }) => {
  const id = uuidv1();

  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' style={style}>
      <mask
        id={`maskCampaigns${id}`}
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='2'
        y='2'
        width='16'
        height='16'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16.4 13.68C14.8 12.48 12.48 11.84 10.8 11.6V7.5208C12.48 7.3608 14.8 6.64 16.4 5.44V13.68ZM9.2 16.4H8.1208L6.48 13.2H9.2V16.4ZM9.2 11.6H5.6C4.48 11.6 3.6 10.7208 3.6 9.6C3.6 8.48 4.48 7.6 5.6 7.6H9.2V11.6ZM17.2 2C16.8 2 16.4 2.4 16.4 2.8C16.4 4.4 12.32 6 10 6H5.6C3.6 6 2 7.6 2 9.6C2 11.28 3.12 12.64 4.64 13.04L6.64 17.04C6.88 17.6 7.44 18 8.08 18H9.2C10.08 18 10.8 17.28 10.8 16.4V13.28C13.2 13.5208 16.4 14.9608 16.4 16.4C16.4 16.8 16.8 17.2 17.2 17.2C17.6 17.2 18 16.8 18 16.4V2.8C18 2.4 17.6 2 17.2 2Z'
          fill='#31373D'
        />
      </mask>
      <g mask={`url(#maskCampaigns${id})`}>
        <rect
          className='iconColor'
          width='16'
          height='16'
          transform='translate(2 2)'
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Campaigns;
