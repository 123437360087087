import { createModel } from '@rematch/core';
import { subDays, startOfDay } from 'date-fns';
import { BANNER_BUTTON_ACTION, ButtonType } from 'src/components/Banners/model';
import {
  DaysInSeconds,
  TimeFilter as DateRangeTimeFilter,
} from 'src/components/DaterangeFilter';
import { ReportsGranularity } from 'src/components/ReportSaver/models';
import api from 'src/lib/api';
import { BACKGROUND_TASKS } from 'src/lib/api/home';
import { RootModel } from 'src/store/models';
import { gettingStartedSteps } from '../constants';

type TimeFilter = [Date, Date, DateRangeTimeFilter];

export const gettingStartedKeys = [
  gettingStartedSteps.PRIVACY,
  gettingStartedSteps.TOS,
  gettingStartedSteps.OPTIN,
  gettingStartedSteps.ACR,
];

const getGettingStartedStep = data => {
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < gettingStartedKeys.length; index++) {
    const step = gettingStartedKeys[index];
    if (!data[step]) {
      return index;
    }
  }

  return gettingStartedKeys.length + 1;
};

export const getNumberVerificationBannerData = ({
  status,
  newSubscribers,
}: {
  status: PHONE_NUMBER_VERIFICATION_STATUS;
  newSubscribers?: number;
}): {
  buttons: Array<ButtonType>;
  category?: string;
  icon?: string;
  title: string;
  description: string;
} => {
  switch (status) {
    case PHONE_NUMBER_VERIFICATION_STATUS.NOT_STARTED:
      return {
        icon: 'info',
        title: 'Number verification pending',
        description:
          'We kindly request that you thoroughly review and verify the details provided for the number verification process.',
        buttons: [
          {
            text: 'Review Details',
            buttonAction: BANNER_BUTTON_ACTION.OPEN_REVIEW_DETAILS_MODAL,
            appearance: 'secondary',
          },
        ],
        category: 'warn',
      };

    case PHONE_NUMBER_VERIFICATION_STATUS.PENDING:
      return {
        icon: 'info',
        title: 'Number verification in progress by our carrier partner',
        description:
          'Your verification is underway. It may be ready in an hour (up to 48 hours max). Queries? Our Support team loves a good chat!',
        buttons: [
          {
            text: 'Contact support',
            buttonAction: BANNER_BUTTON_ACTION.CONTACT_SUPPORT,
            appearance: 'secondary',
          },
        ],
      };

    case PHONE_NUMBER_VERIFICATION_STATUS.IN_PROGRESS:
      return {
        icon: 'info',
        title: 'Number verification in progress by our carrier partner',
        description:
          'Your verification is underway. It may be ready in an hour (up to 48 hours max). Queries? Our Support team loves a good chat!',
        buttons: [
          {
            text: 'Contact support',
            buttonAction: BANNER_BUTTON_ACTION.CONTACT_SUPPORT,
            appearance: 'secondary',
          },
        ],
      };

    case PHONE_NUMBER_VERIFICATION_STATUS.COMPLETED:
      if (newSubscribers <= 0)
        return {
          icon: 'success',
          title: 'Number verification successful',
          description:
            'Start sending texts now. For high-volume use, await full authorization. Limits apply.',
          buttons: [
            {
              text: 'Dismiss',
              buttonAction: BANNER_BUTTON_ACTION.DISMISS_NUMBER_VERIFICATION,
              appearance: 'secondary',
            },
          ],
          category: 'promo',
        };

      return {
        icon: 'success',
        title: 'Number verification successful',
        description:
          'We kindly request that you thoroughly review and verify the details provided for the number verification process.',
        buttons: [
          {
            text: 'Dismiss',
            buttonAction: BANNER_BUTTON_ACTION.DISMISS_NUMBER_VERIFICATION,
            appearance: 'secondary',
          },
          {
            text: 'Import Subscribers',
            buttonAction: BANNER_BUTTON_ACTION.START_SYNC_SUBSCRIBERS,
            appearance: 'primary',
          },
        ],
        category: 'promo',
      };

    // even when it is failing we still show data for 'in progress' state because we don't want to show them that the verification failed. On failure PM will contact the merchant about this
    case PHONE_NUMBER_VERIFICATION_STATUS.ERRORED:
      return {
        icon: 'info',
        title: 'Number verification in progress by our carrier partner',
        description:
          'Your verification is underway. It may be ready in an hour (up to 48 hours max). Queries? Our Support team loves a good chat!',
        buttons: [
          {
            text: 'Contact support',
            buttonAction: BANNER_BUTTON_ACTION.CONTACT_SUPPORT,
            appearance: 'secondary',
          },
        ],
      };

    default:
      return null;
  }
};

interface HomepageReport {
  isFetching: boolean;
  isSettingGettingStarted: boolean;
  isFetchingGettingStarted: boolean;
  summary: {
    total_subscribers: null | number;
    total_revenue_generated: null | number;
    total_orders: null | number;
  };
  numberVerification: {
    status: PHONE_NUMBER_VERIFICATION_STATUS;
    isNumberVerified: boolean;
    isNumberVerificationModalOpen: boolean;
  };
  syncSubscribers: {
    newSubscriberCount: number;
    status: SYNC_SUBSCRIBER_STATUS;
  };
  reports: {
    isFetching: boolean;
    filters: {
      timeFilter: TimeFilter;
      granularity: ReportsGranularity;
    };
    subscriberGrowthBreakdown: { date: Date; count: number }[];
    totalSubsGained: number;
    subsCountryBreakdown: { country: string; count: number }[];
    totalAttributedRevenue: number;
  };
  gettingStartedStep: number | null;
  completedGettingStartedStep: number;
}

export enum SYNC_SUBSCRIBER_STATUS {
  NOT_STARTED = 'not_started',
  STARTED = 'started',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  ERRORED = 'errored',
  DISMISSED = 'dismissed',
}

export enum PHONE_NUMBER_VERIFICATION_STATUS {
  NOT_STARTED = 'not_started',
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  ERRORED = 'errored',
  DISMISSED = 'dismissed',
}

const initialState = (): HomepageReport => ({
  isFetching: false,
  numberVerification: {
    isNumberVerified: false,
    isNumberVerificationModalOpen: false,
    status: PHONE_NUMBER_VERIFICATION_STATUS.NOT_STARTED,
  },
  isSettingGettingStarted: false,
  isFetchingGettingStarted: false,
  syncSubscribers: {
    status: SYNC_SUBSCRIBER_STATUS.NOT_STARTED,
    newSubscriberCount: 0,
  },
  summary: {
    total_subscribers: null,
    total_revenue_generated: null,
    total_orders: null,
  },
  reports: {
    isFetching: true,
    filters: {
      timeFilter: [
        startOfDay(subDays(new Date(), 30)),
        new Date(),
        {
          type: 'fixed',
          value: DaysInSeconds.LAST_30_DAYS,
          label: 'Last 30 days',
        },
      ],
      granularity: ReportsGranularity.DAILY,
    },
    subscriberGrowthBreakdown: [],
    totalSubsGained: 0,
    subsCountryBreakdown: [],
    totalAttributedRevenue: 0,
  },
  gettingStartedStep: null,
  completedGettingStartedStep: -1,
});

const home = createModel<RootModel>()({
  state: initialState(),

  effects: dispatch => ({
    async fetchReport() {
      this.storeState({ isFetching: true });

      const { data, error } = await api.home.getSummary();

      if (error) {
        this.storeState({ isFetching: false });
        return;
      }
      this.storeState({ summary: data, isFetching: false });
    },

    async syncSubscribers(_, rootState) {
      const { error } = await api.home.syncSubscribers();

      if (error) {
        this.setBannerState({
          syncSubscriberStatus: SYNC_SUBSCRIBER_STATUS.ERRORED,
        });
        return;
      }

      const newSubscribers = rootState.home;
      this.setBannerState({
        syncSubscriberStatus: SYNC_SUBSCRIBER_STATUS.IN_PROGRESS,
        newSubscriberCount: newSubscribers,
      });
    },

    setNumberVerificationModal(value: boolean, rootState) {
      this.storeState({
        numberVerification: {
          ...rootState.home.numberVerification,
          isNumberVerificationModalOpen: value,
        },
      });
    },

    setBannerState({
      newSubscribers,
      numberVerificationStatus,
    }: {
      newSubscribers?: number;
      numberVerificationStatus?: PHONE_NUMBER_VERIFICATION_STATUS;
    }) {
      // TODO: This a hack since banner API is not there in ReachBee
      // We will replace this with banner API in future

      let bannerData;

      if (
        numberVerificationStatus === PHONE_NUMBER_VERIFICATION_STATUS.DISMISSED
      ) {
        dispatch.banners.setState({ banners: [] });
      } else {
        bannerData = getNumberVerificationBannerData({
          status: numberVerificationStatus,
          newSubscribers,
        });
      }

      if (bannerData) {
        dispatch.banners.setState({
          banners: [
            {
              icon: 'switch',
              paths: ['/'],
              category: 'info',
              ...bannerData,
            },
          ],
          isFetching: false,
        });
      }
    },

    async setSyncSubscribersStatus(payload: { status: string }, rootState) {
      const { data, error } = await api.home.setTaskTypeStatus({
        ...payload,
        task_type: BACKGROUND_TASKS.SYNC_SUBSCRIBERS_FROM_SHOPIFY,
      });

      if (error) {
        dispatch.saveToast.showError(
          'There was an error dismissing the info. Please try again later.',
        );
        return;
      }

      this.storeState({
        syncSubscribers: {
          ...rootState.home.syncSubscribers,
          status: data.status,
        },
      });
    },

    async setPhoneNumberVerificationStatus(
      {
        numberVerificationStatus,
        syncSubscriberStatus,
      }: {
        numberVerificationStatus: PHONE_NUMBER_VERIFICATION_STATUS;
        syncSubscriberStatus: SYNC_SUBSCRIBER_STATUS;
      },
      rootState,
    ) {
      const { error } = await api.home.setTaskTypeStatus({
        status: numberVerificationStatus,
        task_type: BACKGROUND_TASKS.NUMBER_VERIFICATION,
      });

      if (error) {
        this.setBannerState({
          numberVerificationStatus: PHONE_NUMBER_VERIFICATION_STATUS.ERRORED,
        });
        return;
      }

      this.setBannerState({
        numberVerificationStatus,
      });

      this.storeState({
        syncSubscribers: {
          ...rootState.home.syncSubscribers,
          status: syncSubscriberStatus,
        },
      });
    },

    async getSyncSubscribers() {
      const { data, error } = await api.home.getSyncSubscribersStatus();
      let newStatus: SYNC_SUBSCRIBER_STATUS =
        SYNC_SUBSCRIBER_STATUS.IN_PROGRESS;

      if (error) {
        newStatus = SYNC_SUBSCRIBER_STATUS.ERRORED;
      } else {
        newStatus = data.data.status;
      }

      this.storeState({
        syncSubscribers: {
          status: newStatus,
          newSubscriberCount: data.data?.new_subscribers,
        },
      });

      this.setBannerState({
        syncSubscriberStatus: newStatus,
        newSubscribers: data.data?.new_subscribers,
      });
    },

    async getNumberVerificationStatus() {
      const { data: numberVerificationData, error: numberVerificationError } =
        await api.home.getNumberVerificationStatus();
      const { data: syncSubscribersData, error: syncSubscribersError } =
        await api.home.getSyncSubscribersStatus();

      if (numberVerificationError || syncSubscribersError) {
        dispatch.saveToast.showError(
          'Error fetching home page Banner data. Please refresh the page to retry.',
        );
        return;
      }

      if (
        numberVerificationData.data.status ===
        PHONE_NUMBER_VERIFICATION_STATUS.COMPLETED
      )
        this.storeState({
          numberVerification: {
            isNumberVerified: true,
          },
        });

      this.setBannerState({
        numberVerificationStatus: numberVerificationData.data.status,
        newSubscribers: syncSubscribersData.data.new_subscribers,
      });
    },

    async fetchTotalAttributedRevenue(_, rootState) {
      const {
        settings: { attributionWindow },
      } = rootState;

      const { totalAttributedRevenue, error } =
        await api.reports.fetchTotalAttributedRevenue(attributionWindow);

      if (error) {
        dispatch.saveToast.showError(
          'Your total attributed revenue could not be fetched',
        );
        return;
      }

      this.storeReports({ totalAttributedRevenue });
    },

    async fetchSubscriberGrowthReport(payload: {
      timeWindow: number | { start: Date; end: Date };
      granularity: ReportsGranularity;
    }) {
      const res: any = await api.reports.fetchSubscriberGrowthReport(payload);

      if (res.error) {
        dispatch.saveToast.showError(
          'Your subscriber growth reports could not be fetched, please refresh and try again',
        );
        return;
      }
      const totalSubsGained = (res.data.report.data || []).reduce(
        (acc, curr) => acc + curr.count,
        0,
      );

      this.storeReports({
        subscriberGrowthBreakdown: res.data.report.data,
        totalSubsGained,
        isFetching: false,
      });
    },

    updateFiltersAndFetchGrowthReport(
      payload: { timeFilter?: TimeFilter; granularity?: ReportsGranularity },
      rootState,
    ) {
      this.updateFilters(payload);
      this.storeReports({ isFetching: true });

      // eslint-disable-next-line
      let { timeFilter, granularity } = rootState.home.reports.filters;

      const [_, __, { value: timeWindow }] = payload.timeFilter || timeFilter;
      granularity = payload.granularity || granularity;

      // This method is called with either the new timeFilter or the new granularity. In the case of the latter,
      // we optimize the request payload to avoid fetching redundant reports
      this.fetchSubscriberGrowthReport({
        timeWindow,
        granularity,
      });
    },

    async fetchGettingStarted() {
      this.storeState({
        isFetchingGettingStarted: true,
      });
      const { data, error } = await api.home.getGettingStarted();

      if (error) {
        this.storeState({
          isFetchingGettingStarted: false,
        });
        return;
      }

      const index = getGettingStartedStep(data);

      this.storeState({
        isFetchingGettingStarted: false,
        completedGettingStartedStep: index - 1,
        gettingStartedStep: index,
      });
    },

    async setGettingStarted(index: number) {
      const step = gettingStartedKeys[index];
      this.storeState({
        isSettingGettingStarted: true,
      });

      const { data, error } = await api.home.setGettingStarted({
        [step]: true,
      });

      this.storeState({
        isSettingGettingStarted: false,
        completedGettingStartedStep: index,
      });

      if (error) {
        dispatch.saveToast.showError('Changes were not saved');

        this.storeState({
          isSettingGettingStarted: false,
        });
        return;
      }

      this.storeState({
        gettingStartedStep: getGettingStartedStep(data),
        isSettingGettingStarted: false,
      });
    },
  }),

  reducers: {
    storeState(state: HomepageReport, payload: AnyObject): HomepageReport {
      return {
        ...state,
        ...payload,
      };
    },

    storeReports(state: HomepageReport, payload: any): HomepageReport {
      return {
        ...state,
        reports: {
          ...state.reports,
          ...payload,
        },
      };
    },

    updateFilters(state, payload) {
      return {
        ...state,
        reports: {
          ...state.reports,
          filters: {
            ...state.reports.filters,
            ...payload,
          },
        },
      };
    },
  },
});

export default home;
