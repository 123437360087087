import { paneDark } from 'barn/components/Pane/theme';
import { alertDark } from './components/Alert/theme';
import { autoCompleteInputDark } from './components/AutoCompleteInput/theme';
import { bottombarDark } from './components/Bottombar/theme';
import { breadcrumbsDark } from './components/Breadcrumbs/theme';
import { buttonDark } from './components/Button/theme';
import { deviceSwitchDark } from './components/DeviceSwitch/theme';
import { dropdownDark } from './components/Dropdown/theme';
import { checkboxDark } from './components/form/checkbox/theme';
import { inputDark } from './components/form/input/theme';
import { radioDark } from './components/form/radio/theme';
import { textareaDark } from './components/form/textarea/theme';
import { hyperlinkDark } from './components/Hyperlink/theme';
import { modalDark } from './components/Modal/theme';
import { navlinkDark } from './components/Navlink/theme';
import { progressBarDark } from './components/ProgressBar/theme';
import { protipDark } from './components/Protip/theme';
import { drawerDark } from './components/Drawer/theme';
import { segmentBuilderDark } from './components/SegmentBuilder/theme';
import { spinnerDark } from './components/Spinner/theme';
import { tagDark } from './components/Tag/theme';
import { textDark } from './components/Text/theme';
import { tickCircleDark } from './components/TickCircle/theme';
import { toastDark } from './components/Toast/theme';
import { toggleDark } from './components/Toggle/theme';
import { tooltipDark } from './components/Tooltip/theme';
import { topbarDark } from './components/Topbar/theme';
import { datePickerDark } from './globalStyle/datepickerTheme';
import { globalDark, selectBoxDark } from './globalStyle/theme';
import { skipLinksDark } from './skipThemeLinks';
import { islandDark } from './components/Island/theme';

const dark = {
  island: islandDark,
  drawer: drawerDark,
  pane: paneDark,
  bottombar: bottombarDark,
  global: globalDark,
  selectBox: selectBoxDark,
  alert: alertDark,
  autoCompleteInput: autoCompleteInputDark,
  breadcrumbs: breadcrumbsDark,
  button: buttonDark,
  deviceSwitch: deviceSwitchDark,
  navlink: navlinkDark,
  checkbox: checkboxDark,
  input: inputDark,
  radio: radioDark,
  textarea: textareaDark,
  topbar: topbarDark,
  hyperlink: hyperlinkDark,
  tooltip: tooltipDark,
  toggle: toggleDark,
  tickCircle: tickCircleDark,
  spinner: spinnerDark,
  protip: protipDark,
  tag: tagDark,
  datePicker: datePickerDark,
  text: textDark,
  progressBar: progressBarDark,
  toast: toastDark,
  dropdown: dropdownDark,
  segmentBuilder: segmentBuilderDark,
  modal: modalDark,
  skipLinks: skipLinksDark,
};

export default dark;
