import { FC } from 'react';
import styled from 'styled-components';
import space from 'barn/tokens/space';
import { isSmallScreen } from 'src/lib/utils';

interface Props {
  inline?: boolean;
  justify?: string;
  align?: string;
  gap?: number;
  tag?: string;
  wrap?: string;
  height?: string;
  minHeight?: string;
  overflow?: string;
  position?: string;
  testHandle?: string;
  alignSelf?: string;
  rowGap?: number;
  columnGap?: number;
}

const StyledStack = styled.div<{ styles: AnyObject }>`
  ${({ styles }) => styles}
`;

const Stack: FC<Props & { direction?: string }> = ({
  inline,
  justify = 'flex-start',
  align = 'stretch',
  direction,
  gap = 0,
  tag = 'div',
  wrap = 'nowrap',
  height = 'auto',
  overflow,
  position = 'static',
  testHandle,
  alignSelf,
  minHeight,
  rowGap,
  columnGap,
  ...props
}) => {
  let flexDirection;

  if (direction === 'responsive') {
    if (isSmallScreen()) {
      flexDirection = 'column';
    } else {
      flexDirection = 'row';
    }
  } else {
    flexDirection = direction === 'vertical' ? 'column' : 'row';
  }

  const stylesObj: any = {
    display: inline ? 'inline-flex' : 'flex',
    // width: '100%', // causes weirdness in nested avatar. todo: debug
    justifyContent: justify,
    alignItems: align,
    flexWrap: wrap,
    height,
    overflow,
    position,
    alignSelf,
    minHeight,
    flexDirection,
    gap: gap ? space.margin[gap] : null,
    rowGap: rowGap ? space.margin[rowGap] : null,
    columnGap: columnGap ? space.margin[columnGap] : null,
  };
  stylesObj['> *'] = {
    margin: 0,
  };

  return (
    <StyledStack
      styles={stylesObj}
      data-test={testHandle}
      {...props}
      as={tag as keyof JSX.IntrinsicElements}
    />
  );
};

const HStack: React.FC<Props> = props => (
  <Stack direction='horizontal' {...props} />
);

const VStack: React.FC<Props> = props => (
  <Stack direction='vertical' {...props} />
);

export { Stack, HStack, VStack };
