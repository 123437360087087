import { get, post } from '../axios';

// endpoints
const WIDGET_ENDPOINT = `/dashboard/config/back-in-stock/widget/`;
const CONFIG_ENDPOINT = `/dashboard/config/back-in-stock/notification/`;
const REPORT_ENDPOINT = `/dashboard/back-in-stock/report/`;

// query helpers
const getWidget = () => get(WIDGET_ENDPOINT);

const setWidget = (data: { enabled: boolean; metadata: AnyObject }) =>
  post(WIDGET_ENDPOINT, data);

const getConfig = () => get(CONFIG_ENDPOINT);

const setConfig = (data: { enabled: boolean; metadata: AnyObject }) =>
  post(CONFIG_ENDPOINT, data);

const getReport = () => get(REPORT_ENDPOINT);

export default {
  getReport,
  getConfig,
  setConfig,
  getWidget,
  setWidget,
};
