import colors from 'barn/tokens/colors';

export const popoverLight = {
  color: colors.greys[8],
  bg: colors.greys[0],
};

export const popoverDark = {
  color: colors.greys[7],
  bg: colors.greys[11],
};
