import colors from 'barn/tokens/colors';

export const protipLight = {
  bg: colors.greys[8],
  color: colors.greys[1],
};

export const protipDark = {
  bg: colors.greys[11],
  color: colors.greys[7],
};
