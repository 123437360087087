import colors from 'barn/tokens/colors';

export const overlayConfigLight = {
  modal: {
    bg: 'rgba(0, 0, 0, 0.6)',
    content: {
      color: colors.greys[8],
    },
  },
};

export const overlayConfigDark = overlayConfigLight;
