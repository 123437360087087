import colors from 'barn/tokens/colors';

export const notificationTemplateLight = {
  bg: colors.greys[8],
  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)',
};

export const notificationTemplateDark = {
  bg: colors.greys[16],
  boxShadow: 'none',
};
