/* eslint-disable no-alert */
/* eslint-disable no-console */

import { isRunningEmbedded } from '../utils';
import {
  isSafariBrowser,
  isMobile,
  addProtocolIfMissing,
  applyImageTransformations,
  isWindows,
} from './util';

function genericErrorHandler(error) {
  console.log(error);
}

export const getPrimaryLinkWithDiscount = ({ primaryLink, discountCode }) => {
  // If there is no discount code or if the primary link has discount code
  if (!discountCode || primaryLink.toLowerCase().includes('/discount/')) {
    return primaryLink;
  }

  try {
    const url = new URL(primaryLink);

    const { search, hash, pathname, origin } = url;

    // If redirect url has subsection use redirect=/subsection
    if (pathname !== '/') {
      return `${origin}/discount/${discountCode}/${
        search ? `${search}&` : '?'
      }redirect=${pathname}${hash}`;
    }

    return `${origin}/discount/${discountCode}/${search}${hash}`;
  } catch (_) {
    return primaryLink;
  }
};

export const registerServiceWorker = () => {
  if (!('serviceWorker' in navigator)) return;

  function handleSWRegistration(serviceWorkerRegistration) {
    window.swRegisteration = serviceWorkerRegistration;
  }

  navigator.serviceWorker
    .register('/sw.js')
    .then(handleSWRegistration)
    .catch(genericErrorHandler);
};

export const requestPermission = async () => {
  if (isRunningEmbedded()) {
    await new Promise<void>(resolve => {
      const win = window.open('/subscribe', '_blank');
      const timer = setInterval(() => {
        if (win == null || win.closed) {
          clearInterval(timer);
          resolve();
        }
      }, 500);
      // Also, set a timeout to clear the interval if the window doesn't close ever, just in case
      setTimeout(() => {
        clearInterval(timer);
      }, 60000);
    });
  } else if (isSafariBrowser()) {
    // Permission is default. Let's ask for it and re-invoke this function.
    await new Promise(resolve => Notification.requestPermission(resolve));
  } else {
    await Notification.requestPermission();
  }
};

const showNotification = async data => {
  if (isSafariBrowser()) {
    const notification = new Notification(data.title, {
      body: data.description,
    });

    notification.onclick = () => notification.close();
  } else {
    await navigator.serviceWorker.ready;

    const require_interaction = false;
    const tag = Math.floor(Math.random() * 10000);
    const redirect_url = getPrimaryLinkWithDiscount({
      primaryLink: addProtocolIfMissing(data.redirect_url),
      discountCode: data.discountCode,
    });

    const { image: desktop_image, mac_image, mobile_image, icon } = data;

    const previewInfo = selectPreviewInfo({
      desktop_image,
      mac_image,
      mobile_image,
      icon,
    });

    const image = applyImageTransformations(...previewInfo);

    const actions = data.actions.map(action => ({
      ...action,
      redirect_url: addProtocolIfMissing(action.redirect_url),
    }));

    if (navigator.serviceWorker.controller?.postMessage) {
      navigator.serviceWorker.controller.postMessage({
        ...data,
        require_interaction,
        tag,
        redirect_url,
        image,
        // Mac expects a single image in the "icon" field
        icon: previewInfo[1] === 'mac' ? image : icon,
        actions,
      });
    }
  }
};

/**
 * Return an array with [image_string, preview]
 * depending on the user's system
 * where preview in [windows, android, mac]
 */
const selectPreviewInfo: (AnyObject) => [string, string] = ({
  desktop_image,
  mac_image,
  mobile_image,
  icon,
}) => {
  if (isMobile()) return [mobile_image || desktop_image, 'android'];

  if (isWindows()) return [desktop_image || mobile_image, 'windows'];

  return [mac_image || icon, 'mac'];
};

export const preview = async (
  setOverlay: (overlay: boolean) => void,
  setWarning: (overlay: boolean) => void,
  data?: AnyObject,
) => {
  if (!window.Notification) return;

  let { permission } = Notification;
  // Show a warning when permission is denied in non-embedded mode.
  // Because embedded mode will by default return "denied" as permission
  if (!isRunningEmbedded() && permission === 'denied') {
    setOverlay(false);
    setWarning(true);
    return;
  }

  // We request permission when the current permission is `default`. Unless
  // we're running embedded, in which case we always get 'denied' as permission.
  // So in embedded mode we always try to request permission
  if (
    (!isRunningEmbedded() && permission === 'default') ||
    (isRunningEmbedded() && permission !== 'granted')
  ) {
    // We don't show overlay on Safari because the browser prompt itself shows in
    // the center, overlapping the overlay
    if (!isSafariBrowser()) {
      setOverlay(true);
    }

    await requestPermission();
    setOverlay(false);
  }

  permission = Notification.permission;

  if (permission === 'denied') {
    setWarning(true);
  }

  if (permission === 'granted' && data) {
    showNotification(data);
  }
};
