import styled from 'styled-components';
import { fontSizes } from 'barn/tokens/typography';
import tokens from 'barn/tokens';

const Radio = styled.label`
  display: flex;
  justify-content: ${props => props['justify-content'] || 'space-between'};
  color: ${props => props.color || 'initial'};
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: ${tokens.space.padding[3]} ${tokens.space.padding[4]};
  font-size: ${fontSizes[2]};
  color: inherit;

  & input {
    display: none;
  }

  .checkmark {
    position: absolute;
    width: 0;
    height: 0;
    margin: 0 0 0 ${tokens.space.margin[2]};
  }

  & input:checked ~ .checkmark {
    visibility: initial;
    position: relative;
    width: 16px;
    height: 16px;
  }

  & input:disabled + span {
    color: ${({ theme }) => theme.dropdown.disabledRadio.color};
  }

  .checkmark:after {
    content: '';
    display: none;
    position: absolute;
    top: 0;
    left: -2px;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    border-radius: ${tokens.radii[2]};
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & .checkmark:after {
    background-image: url('/static/svg/GreenTick.svg');
    background-position: center;
    background-repeat: no-repeat;
  }
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  ${({ iconOn, noSpacing }: { iconOn: string; noSpacing: boolean }) => {
    if (!noSpacing) {
      return `
  ${
    iconOn === 'left'
      ? `margin-right: ${tokens.space.margin[3]}`
      : `margin-left: ${tokens.space.margin[3]}`
  }
  `;
    }
    return '';
  }}
  margin: ${tokens.space.margin[3]} 0 0 0;
`;

export default { Radio, IconWrapper };
