import { v1 as uuidv1 } from 'uuid';
import colors from 'barn/tokens/colors';

const Close = ({ color = colors.greys[2], style = {} }) => {
  const id = uuidv1();

  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' style={style}>
      <mask
        id={`maskClose${id}`}
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='2'
        y='1'
        width='17'
        height='17'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.4143 10L17.7072 3.70701C18.0982 3.31601 18.0982 2.68401 17.7072 2.29301C17.3162 1.90201 16.6842 1.90201 16.2933 2.29301L10.0002 8.58601L3.70725 2.29301C3.31625 1.90201 2.68425 1.90201 2.29325 2.29301C1.90225 2.68401 1.90225 3.31601 2.29325 3.70701L8.58625 10L2.29325 16.293C1.90225 16.684 1.90225 17.316 2.29325 17.707C2.48825 17.902 2.74425 18 3.00025 18C3.25625 18 3.51225 17.902 3.70725 17.707L10.0002 11.414L16.2933 17.707C16.4882 17.902 16.7443 18 17.0002 18C17.2562 18 17.5122 17.902 17.7072 17.707C18.0982 17.316 18.0982 16.684 17.7072 16.293L11.4143 10Z'
          fill='#31373D'
        />
      </mask>
      <g mask={`url(#maskClose${id})`}>
        <rect className='iconColor' width='20' height='20' fill={color} />
      </g>
    </svg>
  );
};

export default Close;
