import colors from 'barn/tokens/colors';

export const sessionValidationLight = {
  bg: 'rgba(0, 0, 0, 0.8)',
  messageBox: {
    bg: colors.greys[8],
  },
};

export const sessionValidationDark = {
  ...sessionValidationLight,
  messageBox: {
    bg: colors.greys[10],
  },
};
