import { AxiosError } from 'axios';
import * as Sentry from '@sentry/browser';
import { CampaignType } from 'src/modules/campaigns/models';
import {
  getAxios,
  getUser,
  AxiosResponse,
  APIResponseObject,
  post,
  patch,
} from './axios';
import { getShopifySessionToken } from '../utils';

export interface CampaignParams {
  type?: CampaignType;
  limit?: number;
  offset?: number;
  start_time?: Date;
  end_time?: Date;
}

const genericErrorMessage =
  'An unexpected error has occurred; please try again. Contact support if the issue persists. Error: ';

const get: (args: CampaignParams) => AxiosResponse = ({
  type = CampaignType.SENT,
  limit = 10,
  offset = 0,
  start_time = new Date(0),
  end_time = new Date(),
}) => {
  const axios = getAxios();
  return axios
    .get(
      `/dashboard/campaign/?campaign_type=${type}&limit=${limit}&offset=${offset}&start_time=${start_time.toISOString()}&end_time=${end_time.toISOString()}`,
    )
    .then(res => {
      return { data: res.data.result, error: null };
    })
    .catch(error => {
      Sentry.withScope(scope => {
        scope.setExtras({
          error,
          method: 'GET',
          url: `/dashboard/campaign/?campaign_type=${type}&limit=${limit}&offset=${offset}&start_time=${start_time.toISOString()}&end_time=${end_time.toISOString()}`,
        });

        scope.setTag('subdomain', String(window._po_subdomain));

        Sentry.captureException('Failed to fetch campaign');
      });

      return {
        error,
        data: null,
      };
    });
};

const postSentryHandler = (error: string | AxiosError, campaign): void => {
  const user = getUser();
  Sentry.withScope(scope => {
    scope.setExtras({
      campaign,
      error:
        typeof error === 'string' ? error : error.response || error.message,
      method: 'POST',
      url: `/accounts/${user.website.subdomain}/campaigns/`,
    });

    scope.setTag('subdomain', String(window._po_subdomain));

    Sentry.captureException('Failed to create campaign');
  });
};

const patchSentryHandler = (error: string | AxiosError, campaign): void => {
  const user = getUser();
  Sentry.withScope(scope => {
    scope.setExtras({
      campaign,
      error:
        typeof error === 'string' ? error : error.response || error.message,
      method: 'PATCH',
      url: `/accounts/${user.website.subdomain}/campaigns`,
    });

    scope.setTag('subdomain', String(window._po_subdomain));

    Sentry.captureException('Failed to edit campaign');
  });
};

const createDraftCampaign = async (campaign: any) => {
  const {
    data: { data, error },
  } = await post(`/campaigns/`, campaign);

  if (error) {
    postSentryHandler(error, campaign);
    return { data: null, error: genericErrorMessage };
  }

  return { data, error: null };
};

const createCampaign = async (campaign: any) => {
  const user = getUser();
  const axios = getAxios();

  const {
    data: { data, error },
  } = await axios.post(
    `/${user.website.subdomain}/dashboard/campaigns/send/`,
    campaign,
    {
      baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/v1`,
    },
  );

  if (error) {
    postSentryHandler(error, campaign);
    return { data: null, error: genericErrorMessage };
  }

  return { data, error: null };
};

const editDraftCampaign = async (campaign: any, id: number) => {
  const {
    data: { data, error },
  } = await patch(`/campaigns/${id}/`, campaign);

  if (error) {
    patchSentryHandler(error, campaign);
    return { data: null, error: genericErrorMessage };
  }

  return { data, error: null };
};

const editCampaign = async (campaign: any, id: number) => {
  const user = getUser();
  const axios = getAxios();

  const {
    data: { data, error },
  } = await axios.patch(
    `/${user.website.subdomain}/dashboard/campaigns/${id}/send/`,
    campaign,
    {
      baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/v1`,
    },
  );

  if (error) {
    patchSentryHandler(error, campaign);
    return { data: null, error: genericErrorMessage };
  }

  return { data, error: null };
};

const sendCampaign = async (id: string) => {
  const axios = getAxios();

  const {
    data: { data, error },
  } = await axios.post(`/campaigns/${id}/send/`);
  if (!error) return { data, error: null };

  return { data: null, error: genericErrorMessage };
};

const putSentryHandler = (
  error: string | AxiosError,
  campaign,
  id: number,
  exceptionString: string,
): void => {
  const user = getUser();
  Sentry.withScope(scope => {
    scope.setExtras({
      campaign,
      error:
        typeof error === 'string' ? error : error.response || error.message,
      method: 'PUT',
      url: `/accounts/${user.website.subdomain}/campaigns/${id}`,
    });

    scope.setTag('subdomain', String(window._po_subdomain));

    Sentry.captureException(exceptionString);
  });
};

const put = (id: number, campaign: any) => {
  const user = getUser();
  const axios = getAxios();
  return axios
    .put(`/accounts/${user.website.subdomain}/campaigns/${id}/`, campaign, {
      baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/v1`,
    })
    .then(res => {
      if (!res) {
        putSentryHandler(
          'Response is undefined for campaign creator put request',
          campaign,
          id,
          'Response is undefined',
        );
        return { error: `${genericErrorMessage}"Response is undefined"` };
      }

      const {
        data: { result, error },
      } = res;

      if (!error) return { data: result };

      putSentryHandler(error, campaign, id, 'Failed to edit campaign');

      return { error: `${genericErrorMessage}"${error}"` };
    })
    .catch((error: AxiosError<APIResponseObject>) => {
      putSentryHandler(error, campaign, id, 'Failed to edit campaign');

      return {
        error:
          error.response?.data?.error === 'Can not update sent campaign'
            ? 'Your campaign has already been sent; please exit and/or create a new campaign.'
            : `${genericErrorMessage}"${error.message}"`,
      };
    });
};

const deleteCampaign = async (campaignId: number) => {
  const user = getUser();
  const axios = getAxios();
  const sessionToken = await getShopifySessionToken();

  const headers: any = {
    Authorization: `JWT ${user.token}`,
  };

  if (sessionToken) {
    headers.SessionToken = `JWT ${sessionToken}`;
  }

  return axios
    .delete(
      `/api/v1/accounts/${user.website.subdomain}/campaigns/${campaignId}/`,
      {
        headers,
        baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}`,
      },
    )
    .then(res => {
      if (res.data.status === 200) {
        return { data: res.data.result, error: null };
      }
      return {
        data: null,
        error: Error('Unsuccessful deletion'),
      };
    })
    .catch(error => ({
      error,
      data: null,
    }));
};

const resendCampaign = async (campaignId: number) => {
  const axios = getAxios();

  const {
    data: { data, error },
  } = await axios.post(`/campaigns/${campaignId}/re-send/`, {});

  if (error) return { data: null, error: new Error('Unsuccessful resend') };

  return { data, error: null };
};

const validateDiscountCode = (discountCode: string) => {
  const user = getUser();
  const axios = getAxios();
  return axios
    .get(
      `/shopify/discounts/${user.website.subdomain}/${encodeURIComponent(
        discountCode || '',
      )}/validate/`,
      {
        baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/v1`,
      },
    )
    .then(res => ({ data: res.data.result, error: null }))
    .catch(error => ({
      error,
      data: {},
    }));
};

export default {
  get,
  createCampaign,
  editCampaign,
  put,
  delete: deleteCampaign,
  resend: resendCampaign,
  validateDiscountCode,
  sendCampaign,
  createDraftCampaign,
  editDraftCampaign,
};
