import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Button from 'barn/components/Button';
import Topbar from 'barn/components/Topbar';

import { sidebarWidth, responsiveSidebarWidth } from 'barn/tokens/layout';
import ProfileMenu from 'src/components/ProfileMenu';
import useAuth from 'src/hooks/use-auth';
import tokens from 'barn/tokens';
import { useGlobalState } from 'src/hooks/use-global-state';
import { ExpandRight } from 'barn/icons/ExpandRight';
import { isSmallScreen } from 'src/lib/utils';
import { DynamicPill } from 'src/modules/home/components/DynamicPill';

const StyledTopbar = styled(Topbar)<{ isSidebarOpen: boolean }>`
  justify-content: space-between;
  padding-left: ${props => (props.isSidebarOpen ? sidebarWidth : 0)};
  z-index: ${tokens.zIndex[3]};

  @media screen and (max-width: ${tokens.responsiveScreenBreakpoint}) {
    padding-left: ${props =>
      props.isSidebarOpen ? sidebarWidth : responsiveSidebarWidth};
  }
`;

const Navbar = () => {
  const { user } = useAuth();
  const {
    actions: { toggleSidebar },
    state: { isSidebarOpen },
  } = useGlobalState('common');

  const buttonRef = useRef(null);

  useEffect(() => {
    if (!buttonRef.current) return;
    const rect = buttonRef.current.getBoundingClientRect();
    document.documentElement.style.setProperty(
      '--changelog-top',
      `${rect.bottom + 10}px`,
    );
  }, [user]);

  if (!user || !Object.keys(user).length) {
    return null;
  }

  return (
    <StyledTopbar data-test='main-header' isSidebarOpen={isSidebarOpen}>
      <div
        style={{
          position: 'relative',
          left: -8,
        }}
      >
        {isSmallScreen() && (
          <Button
            ariaLabel='toggle sidebar'
            appearance='secondary'
            onClick={toggleSidebar}
          >
            <div
              style={{
                display: 'flex',
                transform: isSidebarOpen ? 'rotate(180deg)' : null,
              }}
            >
              <ExpandRight />
            </div>
          </Button>
        )}
      </div>

      <span style={{ display: 'flex' }}>
        <DynamicPill />
        <button
          aria-label='Changelog'
          type='button'
          id='js-changelog-wrap'
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            border: 0,
            background: 'none',
            marginRight: 20,
            padding: 0,
          }}
          data-canny-changelog
          ref={buttonRef}
        >
          <svg style={{ width: 24, height: 24 }} viewBox='0 0 16 20'>
            <path
              d='M8 20a2 2 0 002-2H6a2 2 0 002 2zM0 10a8 8 0 1116 0v3a4 4 0 01-4 4H4a4 4 0 01-4-4v-3z'
              fill='#9FB2C5'
            />
            <rect x='7' width='2' height='3' rx='1' fill='#9FB2C5' />
          </svg>
        </button>
        <ProfileMenu />
      </span>
    </StyledTopbar>
  );
};

export default Navbar;
