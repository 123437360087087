import colors from 'barn/tokens/colors';

export const toggleLight = {
  switchWrap: {
    bg: colors.greys[4],
    checked: {
      bg: colors.greens[4],
    },
  },
  handle: {
    bg: colors.greys[8],
  },
};

export const toggleDark = {
  switchWrap: {
    bg: colors.greys[16],
    checked: {
      bg: colors.greens[9],
    },
  },
  handle: {
    bg: colors.greys[7],
  },
};
