import colors from 'barn/tokens/colors';

export const automationFlowLight = {
  bg: colors.greys[6],
  color: colors.greys[1],
  borderColor: colors.greys[5],
  title: {
    color: colors.greys[3],
  },
  card: {
    bg: colors.greys[8],
  },
  label: {
    color: colors.greys[2],
  },
  container: {
    bg: colors.greys[8],
  },
  reminder: {
    bg: colors.greys[7],
  },
  inactive: {
    bg: 'rgba(255, 255, 255, 0.5)',
  },
  main: {
    bg: `linear-gradient(to right, transparent 0%, transparent 49.95%, ${colors.greys[5]} 50%, ${colors.greys[5]} 50%, transparent 50.05%, transparent 100%)`,
  },
};

export const automationFlowDark = {
  bg: colors.greys[12],
  color: colors.greys[6],
  borderColor: colors.greys[14],
  title: {
    color: colors.greys[3],
  },
  label: {
    color: colors.greys[7],
  },
  card: {
    bg: colors.greys[12],
    color: colors.greys[7],
  },
  container: {
    bg: colors.greys[11],
  },
  reminder: {
    bg: colors.greys[12],
  },
  inactive: {
    bg: 'hsla(224, 15%, 15%,0.5)',
  },
  main: {
    bg: `linear-gradient(to right, transparent 0%, transparent 49.95%, ${colors.greys[4]} 50%, ${colors.greys[4]} 50%, transparent 50.05%, transparent 100%)`,
  },
};
