import colors from 'barn/tokens/colors';

export const promotionPopupsLight = {
  color: colors.greys[1],
  before: {
    bg: colors.reds[5],
  },
  after: {
    bg: colors.blues[5],
  },
  plan: {
    color: colors.blues[5],
  },
  fwLight: {
    color: colors.greys[2],
  },
};

export const promotionPopupsDark = {
  ...promotionPopupsLight,
  color: colors.greys[7],
  fwLight: {
    color: colors.greys[7],
  },
};
