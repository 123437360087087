import { v1 as uuidv1 } from 'uuid';
import colors from 'barn/tokens/colors';

const Plus = ({ color = colors.greys[2], style = {} }) => {
  const id = uuidv1();

  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' style={style}>
      <mask
        id={`maskPlus${id}`}
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='3'
        y='3'
        width='14'
        height='14'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.5996 9.19998H10.7996V4.39998C10.7996 3.95838 10.442 3.59998 9.99961 3.59998C9.55721 3.59998 9.19961 3.95838 9.19961 4.39998V9.19998H4.39961C3.95721 9.19998 3.59961 9.55838 3.59961 9.99998C3.59961 10.4416 3.95721 10.8 4.39961 10.8H9.19961V15.6C9.19961 16.0416 9.55721 16.4 9.99961 16.4C10.442 16.4 10.7996 16.0416 10.7996 15.6V10.8H15.5996C16.042 10.8 16.3996 10.4416 16.3996 9.99998C16.3996 9.55838 16.042 9.19998 15.5996 9.19998Z'
          fill={color}
        />
      </mask>
      <g mask={`url(#maskPlus${id})`}>
        <rect
          className='iconColor'
          width='16'
          height='16'
          transform='translate(2 2)'
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Plus;
