import colors from 'barn/tokens/colors';

export const sidebarLight = {
  bg: colors.greys[8],
  borderColor: colors.greys[5],
};

export const sidebarDark = {
  bg: colors.greys[11],
  borderColor: colors.greys[16],
};
