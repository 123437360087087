import styled from 'styled-components';

interface WrapperProps {
  centered: boolean;
  inline?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
  ${props =>
    props.centered &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

interface SpinnerProps {
  currentColor: boolean;
}
const Spinner = styled.div`
  display: flex;
  position: relative;
  width: 40px;
  height: 40px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 33px;
    height: 33px;
    border: 3px solid ${({ theme }) => theme.spinner.borderColor};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props: SpinnerProps) =>
        props.currentColor
          ? 'currentColor'
          : ({ theme }) => theme.spinner.animationBorderColor}
      transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SmallSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;

  div {
    width: 20px;
    height: 20px;
    border-width: 3px;
  }
`;

export default {
  Wrapper,
  Spinner,
  SmallSpinner,
};
