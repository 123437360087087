// TODO: Needs fixing. Probably a better rewrite.
import { useRef, useState, useEffect } from 'react';
import { subSeconds, format, endOfDay } from 'date-fns';
import Datepicker from 'barn/components/Datepicker';
import Dropdown, { SC, ListItemText } from 'barn/components/Dropdown/Select';
import Popover from 'barn/components/Popover';
import Button, { ButtonSize } from 'barn/components/Button';
import CalendarIcon from 'barn/icons/Calendar';
import * as Styled from './styled';

export enum DaysInSeconds {
  LAST_24_HOURS = 86400,
  LAST_7_DAYS = 604800,
  LAST_30_DAYS = 2592000,
  LAST_90_DAYS = 7776000,
  ALL_TIME = 189216000,
}

export type TimeFilter = {
  type: 'fixed' | 'range';
  value: DaysInSeconds | { start: Date; end: Date };
  label: string;
};

interface Props {
  onChange: (startTime: Date, endTime: Date, timeFilter: TimeFilter) => void;
  CustomButton?: (t: any) => JSX.Element;
  state?: any;
  minDate?: Date | null;
  testHandle?: string;
  buttonSize?: ButtonSize;
  disabled?: boolean;
  disableCustomRange?: boolean;
}

export const dateRangeFilterOptions = [
  // This option will be restored later when backend supports it
  // {
  //   value: DaysInSeconds.LAST_24_HOURS,
  //   label: 'Last 24 hours',
  //   type: 'fixed',
  // },
  {
    value: DaysInSeconds.LAST_7_DAYS,
    label: 'Last 7 days',
    type: 'fixed',
  },
  {
    value: DaysInSeconds.LAST_30_DAYS,
    label: 'Last 30 days',
    type: 'fixed',
  },
  {
    value: DaysInSeconds.LAST_90_DAYS,
    label: 'Last 90 days',
    type: 'fixed',
  },
  // {
  //   value: DaysInSeconds.ALL_TIME,
  //   label: 'All Time',
  //   type: 'fixed',
  // },
];

const DaterangeFilter = ({
  state,
  CustomButton,
  onChange,
  testHandle,
  minDate = null,
  buttonSize = 'md',
  disabled = false,
  disableCustomRange = false,
}: Props) => {
  const overlayRef = useRef(null);
  const now = new Date();
  const isFixedFilter = state.type === 'fixed';
  const [startDate, setStartDate] = useState(
    !isFixedFilter ? state.value.start : null,
  );
  const [endDate, setEndDate] = useState(
    !isFixedFilter ? state.value.end : null,
  );
  const [isDatePopoverVisible, setDatePopoverVisibility] = useState(false);
  const dropdownTitle =
    startDate && endDate
      ? `${format(startDate, 'P')} - ${format(endDate, 'P')}`
      : 'Custom Date';

  // eslint-disable-next-line
  const TimeFilterButton = ({ children, onClick, size, disabled }) => (
    <Button
      appearance='secondary'
      icon={<CalendarIcon />}
      onClick={onClick}
      id='datefilterbutton'
      testHandle={testHandle}
      size={size}
      disabled={disabled}
    >
      {children}
    </Button>
  );

  const handleDateFilterApply = () => {
    onChange(startDate, endDate, {
      type: 'range',
      value: {
        start: startDate,
        end: endDate,
      },
      label: 'Custom Range',
    });
    document.body.click();
    setTimeout(() => {
      document.body.click();
    }, 5);
  };

  useEffect(() => {
    if (!isDatePopoverVisible) return null;

    const handleClick = function handleClick(e: HTMLElementEvent) {
      /**
       * Close the Date Picker popover if the target is in the Date Picker component
       * OR
       * the target is no longer in the DOM. This occurs when the Date Picker renders
       * before this listener fires.
       *
       * ex: Clicking on the next navigation button to go to the latest 2 months
       */
      if (
        isDatePopoverVisible &&
        (overlayRef.current?.contains(e.target) ||
          !document.body.contains(e.target))
      )
        return;

      setDatePopoverVisibility(false);

      if (e.target?.click) e.target.click();
    };

    document.addEventListener('click', handleClick, false);

    return () => document.removeEventListener('click', handleClick, false);
  }, [isDatePopoverVisible]);

  return (
    <Dropdown
      buttonSize={buttonSize}
      data={dateRangeFilterOptions}
      selectedItem={isFixedFilter ? state.value : null}
      onChange={timeInSeconds => {
        const timeToSubtract = parseInt(timeInSeconds, 10);
        onChange(subSeconds(now, timeToSubtract), now, {
          type: 'fixed',
          value: timeToSubtract,
          label: dateRangeFilterOptions.find(
            option => option.value === timeToSubtract,
          ).label,
        });
      }}
      name='daterange-filter'
      title={dropdownTitle}
      labelKey='label'
      withSearch={false}
      placement='bottomLeft'
      canClose={() => !isDatePopoverVisible}
      onAfterClose={() => {
        setDatePopoverVisibility(false);
      }}
      CustomButton={CustomButton || TimeFilterButton}
      disabled={disabled}
    >
      {!disableCustomRange && (
        <Popover
          placement='right'
          isVisible={isDatePopoverVisible}
          overlayClassName='date-range-filter'
          overlay={
            <Styled.Wrapper ref={overlayRef}>
              <Datepicker
                selected={null}
                minDate={minDate}
                onChange={(dates: any) => {
                  const [start, end] = dates;
                  setStartDate(start);
                  setEndDate(end ? endOfDay(end) : null);
                }}
                monthsShown={2}
                startDate={startDate}
                endDate={endDate}
                maxDate={now}
                inline
                selectsRange
                shouldCloseOnSelect={false}
              />
              <Styled.Footer>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Styled.DateText>
                    {startDate && format(startDate, 'P')}
                  </Styled.DateText>
                  &nbsp;-&nbsp;
                  <Styled.DateText>
                    {endDate && format(endDate, 'P')}
                  </Styled.DateText>
                </div>
                <div>
                  <Button
                    disabled={!startDate || !endDate}
                    onClick={handleDateFilterApply}
                  >
                    Apply
                  </Button>
                </div>
              </Styled.Footer>
            </Styled.Wrapper>
          }
          canClose={() => true}
        >
          <ListItemText>
            <SC.Radio
              onClick={e => {
                setDatePopoverVisibility(!isDatePopoverVisible);

                // Stop bubble up to Popover which triggers visibility close
                e.stopPropagation();
              }}
            >
              <input
                type='radio'
                checked={!isFixedFilter}
                /* Stopping propagation here otherwise click handler is firing twice */
                onClick={e => e.stopPropagation()}
              />
              <span>{dropdownTitle}</span>
              <span className='checkmark' />
            </SC.Radio>
          </ListItemText>
        </Popover>
      )}
    </Dropdown>
  );
};

export { DaterangeFilter };
