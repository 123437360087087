import { getStorage } from 'src/lib/storage';
import { formatDistanceStrict, getYear } from 'date-fns';
import createApp from '@shopify/app-bridge';
import { Redirect } from '@shopify/app-bridge/actions';
import { getSessionToken, isShopifyEmbedded } from '@shopify/app-bridge-utils';
import tokens from 'barn/tokens';
import { logErrorToSentry } from './debug-utils';

/**
 * Utility for exhaustive conditionals when checking a single var.
 * Ensures all possible well-typed possibilities are accounted for,
 * with a default value in the case of TS being overridden.
 *
 * Example:-
 *
 * let var: "str1" | "str2";
 *
 * ...
 *
 * if(var === "str1") return 1;
 *
 * else if(var === "str2") return 2;
 *
 * else return getUnreachableDefault(var, 3);
 *
 * @param {never} neverVar The var whose possible values are being checked
 * @param {any} defaultAlt A usable default value if executed at runtime
 * @returns {typeof defaultAlt} defaultAlt
 */
export const getUnreachableDefault = (neverVar: never, defaultAlt: any) =>
  defaultAlt;

export const isClientSide = () => typeof window !== 'undefined';

export const getTheme = () => {
  if (isClientSide()) {
    const storage = getStorage();
    const savedTheme = storage.get('theme') || 'light';
    if (savedTheme === 'system') {
      return getSystemTheme();
    }

    return savedTheme;
  }

  return 'light';
};

export const isSmallScreen = () => {
  if (
    isClientSide() &&
    window.matchMedia(`(max-width: ${tokens.responsiveScreenBreakpoint})`)
      .matches
  ) {
    return true;
  }

  return false;
};

export const getSystemTheme = () => {
  if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    return 'dark';
  }

  return 'light';
};

export const capitalize = phrase =>
  phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const secureUserObject = user => {
  if (!user) return user;

  /**
   * Without explicitly creating a new website object, the below
   * intercom hash deletion will also affect the original user object
   */
  const newUser = { ...user, website: { ...user?.website } };

  delete newUser.websites;
  delete newUser.token;
  delete newUser.website?.intercom_identity_hash;

  return newUser;
};

/* eslint-disable */
export function getTimezone() {
  const date = new Date();
  let fullName = 'Indian Standard Time';
  try {
    fullName = date.toString().split('(')[1].split(')')[0];
  } catch (err) {
    console.error(err);
  }

  fullName = fullName.replace('India ', 'Indian ');

  const offset = date.getTimezoneOffset();
  const location = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return {
    location,
    offset,
    fullName,
  };
}

/**
 * Starts a conversation from the user's end with the passed message. Uses Intercom
 * if available, otherwise opens default mail client.
 * @param message Message to start conversation with
 */
export function startConversationWithUser(message) {
  // If this is not present, Intercom has been blocked
  if (document.querySelector('#intercom-frame')) {
    window.Intercom('showNewMessage', message);
    return;
  }

  const a = window.document.createElement('a');
  a.href = `mailto:contact@reachbee.com?subject=${encodeURIComponent(message)}`;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  a.remove();
}
export default { getTimezone };

/**
 * Converts a number to compact form according to locale.
 * Eg. 1000 to 1K, 120180 to 120K for en-US locale
 * @param number Number Number to format
 */
export function getCompactNumber(number) {
  // using try-catch here bcoz Intl.Number fails on mac 10.x + safari 14 combi
  // Ref: https://gitmemory.com/issue/google/site-kit-wp/3255/830391313
  try {
    return new Intl.NumberFormat(undefined, {
      //@ts-ignore
      notation: 'compact',
    }).format(number);
  } catch (e) {
    return number;
  }
}

/**
 * Will pause async execution for the given delay
 * @param delay time in seconds
 */
export const asyncWait = async (delay: number) =>
  new Promise(r => setTimeout(() => r(null), delay));

export const timeZoneList = [
  {
    value: 'Pacific/Midway',
    label: 'GMT-11 Midway Island, Samoa',
  },
  {
    value: 'Pacific/Honolulu',
    label: 'GMT-10 Hawaii',
  },
  {
    value: 'America/Juneau',
    label: 'GMT-8 Alaska',
  },
  {
    value: 'America/Dawson',
    label: 'GMT-7 Dawson, Yukon',
  },
  {
    value: 'America/Phoenix',
    label: 'GMT-7 Arizona',
  },
  {
    value: 'America/Tijuana',
    label: 'GMT-7 Tijuana, Pacific Time',
  },
  {
    value: 'America/Boise',
    label: 'GMT-6 Mountain Time',
  },
  {
    value: 'America/Chihuahua',
    label: 'GMT-6 Chihuahua, La Paz, Mazatlan',
  },
  {
    value: 'America/Regina',
    label: 'GMT-6 Saskatchewan',
  },
  {
    value: 'America/Belize',
    label: 'GMT-6 Central America',
  },
  {
    value: 'America/Chicago',
    label: 'GMT-5 Central Time',
  },
  {
    value: 'America/Mexico_City',
    label: 'GMT-5 Guadalajara, Mexico City, Monterrey',
  },
  {
    value: 'America/Bogota',
    label: 'GMT-5 Bogota, Lima, Quito',
  },
  {
    value: 'America/Lima',
    label: 'GMT-5 Pittsburgh',
  },
  {
    value: 'America/Detroit',
    label: 'GMT-4 Eastern Time',
  },
  {
    value: 'America/Caracas',
    label: 'GMT-4 Caracas, La Paz',
  },
  {
    value: 'America/Santiago',
    label: 'GMT-4 Santiago',
  },
  {
    value: 'America/Sao_Paulo',
    label: 'GMT-3 Brasilia',
  },
  {
    value: 'America/Argentina/Buenos_Aires',
    label: 'GMT-3 Buenos Aires, Georgetown',
  },
  {
    value: 'America/St_Johns',
    label: 'GMT-2:30 Newfoundland and Labrador',
  },
  {
    value: 'America/Godthab',
    label: 'GMT-2 Greenland',
  },
  {
    value: 'Atlantic/Cape_Verde',
    label: 'GMT-1 Cape Verde Islands',
  },
  {
    value: 'Atlantic/Azores',
    label: 'GMT+0 Azores',
  },
  {
    value: 'GMT',
    label: 'GMT+0 UTC',
  },
  {
    value: 'Africa/Casablanca',
    label: 'GMT+0 Casablanca, Monrovia',
  },
  {
    value: 'Europe/London',
    label: 'GMT+1 Edinburgh, London',
  },
  {
    value: 'Europe/Dublin',
    label: 'GMT+1 Dublin',
  },
  {
    value: 'Europe/Lisbon',
    label: 'GMT+1 Lisbon',
  },
  {
    value: 'Atlantic/Canary',
    label: 'GMT+1 Canary Islands',
  },
  {
    value: 'Africa/Algiers',
    label: 'GMT+1 West Central Africa',
  },
  {
    value: 'Europe/Belgrade',
    label: 'GMT+2 Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  },
  {
    value: 'Europe/Sarajevo',
    label: 'GMT+2 Sarajevo, Skopje, Warsaw, Zagreb',
  },
  {
    value: 'Europe/Brussels',
    label: 'GMT+2 Brussels, Copenhagen, Madrid, Paris',
  },
  {
    value: 'Europe/Amsterdam',
    label: 'GMT+2 Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  },
  {
    value: 'Africa/Cairo',
    label: 'GMT+2 Cairo',
  },
  {
    value: 'Africa/Harare',
    label: 'GMT+2 Harare, Pretoria',
  },
  {
    value: 'Europe/Berlin',
    label: 'GMT+2 Frankfurt',
  },
  {
    value: 'Europe/Bucharest',
    label: 'GMT+3 Bucharest',
  },
  {
    value: 'Europe/Helsinki',
    label: 'GMT+3 Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  },
  {
    value: 'Europe/Athens',
    label: 'GMT+3 Athens, Istanbul, Minsk',
  },
  {
    value: 'Asia/Jerusalem',
    label: 'GMT+3 Jerusalem',
  },
  {
    value: 'Europe/Moscow',
    label: 'GMT+3 Moscow, St. Petersburg, Volgograd',
  },
  {
    value: 'Asia/Kuwait',
    label: 'GMT+3 Kuwait, Riyadh',
  },
  {
    value: 'Africa/Nairobi',
    label: 'GMT+3 Nairobi',
  },
  {
    value: 'Asia/Baghdad',
    label: 'GMT+3 Baghdad',
  },
  {
    value: 'Asia/Dubai',
    label: 'GMT+4 Abu Dhabi, Muscat',
  },
  {
    value: 'Asia/Tehran',
    label: 'GMT+4:30 Tehran',
  },
  {
    value: 'Asia/Kabul',
    label: 'GMT+4:30 Kabul',
  },
  {
    value: 'Asia/Baku',
    label: 'GMT+5 Baku, Tbilisi, Yerevan',
  },
  {
    value: 'Asia/Yekaterinburg',
    label: 'GMT+5 Ekaterinburg',
  },
  {
    value: 'Asia/Karachi',
    label: 'GMT+5 Islamabad, Karachi, Tashkent',
  },
  {
    value: 'Asia/Calcutta',
    label: 'GMT+5:30 Chennai, Kolkata, Mumbai, New Delhi',
  },
  {
    value: 'Asia/Colombo',
    label: 'GMT+5:30 Sri Jayawardenepura',
  },
  {
    value: 'Asia/Kathmandu',
    label: 'GMT+5:45 Kathmandu',
  },
  {
    value: 'Asia/Dhaka',
    label: 'GMT+6 Astana, Dhaka',
  },
  {
    value: 'Asia/Almaty',
    label: 'GMT+6 Almaty, Novosibirsk',
  },
  {
    value: 'Asia/Rangoon',
    label: 'GMT+6:30 Yangon Rangoon',
  },
  {
    value: 'Asia/Bangkok',
    label: 'GMT+7 Bangkok, Hanoi, Jakarta',
  },
  {
    value: 'Asia/Krasnoyarsk',
    label: 'GMT+7 Krasnoyarsk',
  },
  {
    value: 'Asia/Shanghai',
    label: 'GMT+8 Beijing, Chongqing, Hong Kong, Urumqi',
  },
  {
    value: 'Asia/Kuala_Lumpur',
    label: 'GMT+8 Kuala Lumpur, Singapore',
  },
  {
    value: 'Asia/Taipei',
    label: 'GMT+8 Taipei',
  },
  {
    value: 'Australia/Perth',
    label: 'GMT+8 Perth',
  },
  {
    value: 'Asia/Irkutsk',
    label: 'GMT+8 Irkutsk, Ulaanbaatar',
  },
  {
    value: 'Asia/Seoul',
    label: 'GMT+9 Seoul',
  },
  {
    value: 'Asia/Tokyo',
    label: 'GMT+9 Osaka, Sapporo, Tokyo',
  },
  {
    value: 'Australia/Darwin',
    label: 'GMT+9:30 Darwin',
  },
  {
    value: 'Australia/Adelaide',
    label: 'GMT+9:30 Adelaide',
  },
  {
    value: 'Asia/Yakutsk',
    label: 'GMT+10 Yakutsk',
  },
  {
    value: 'Australia/Sydney',
    label: 'GMT+10 Canberra, Melbourne, Sydney',
  },
  {
    value: 'Australia/Brisbane',
    label: 'GMT+10 Brisbane',
  },
  {
    value: 'Australia/Hobart',
    label: 'GMT+10 Hobart',
  },
  {
    value: 'Asia/Vladivostok',
    label: 'GMT+10 Vladivostok',
  },
  {
    value: 'Pacific/Guam',
    label: 'GMT+10 Guam, Port Moresby',
  },
  {
    value: 'Asia/Magadan',
    label: 'GMT+11 Magadan, Solomon Islands, New Caledonia',
  },
  {
    value: 'Asia/Kamchatka',
    label: 'GMT+12 Kamchatka, Marshall Islands',
  },
  {
    value: 'Pacific/Fiji',
    label: 'GMT+12 Fiji Islands',
  },
  {
    value: 'Pacific/Auckland',
    label: 'GMT+12 Auckland, Wellington',
  },
  {
    value: 'Pacific/Tongatapu',
    label: "GMT+13 Nuku'alofa",
  },
];

export const isValidDate = (date: string) => {
  // @ts-ignore
  const isValid = new Date(date) !== 'Invalid Date' && !isNaN(new Date(date));

  if (isValid) {
    // Checking if year has only 4 digits since it is not valid in Chrome but valid in Safari
    return getYear(new Date(date)).toString().length === 4;
  }

  return isValid;
};

export const getDiscountCodeFromURL = (url: string | null) => {
  if (url) {
    try {
      // Decoding URL twice since Shopify double encodes them
      const urlWithoutQueryParam = decodeURIComponent(
        decodeURIComponent(url),
      ).split(/[?#]/)[0];

      const discountCodeURL = urlWithoutQueryParam.split(/\/discount\//gi);

      if (discountCodeURL.length > 1) {
        return discountCodeURL[1] || '';
      }

      // eslint-disable-next-line no-empty
    } catch (_) {}
  }
  return null;
};

export const isRunningEmbedded = () => {
  return window.top !== window.self;
};

export const openChargeUrl = (url: string) => {
  const storage = getStorage();
  const platform = storage.get('user')?.website?.platform || 'shopify';

  // Specifically for embedded mode in Shopify
  // TODO: replace with app bridge later
  if (isRunningEmbedded() && platform === 'shopify') {
    const win = window.open(url, '_blank');

    // refresh the page when charge window is closed
    const timer = setInterval(function () {
      if (win == null || win.closed) {
        clearInterval(timer);
        window.location.reload();
      }
    }, 1000);
  } else {
    window.location.href = url;
  }
};

// Redirects current window URL in embedded mode using app bridge
export const handleRedirect = (url: string) => {
  try {
    const storage = getStorage();
    const appBridgeConfig = storage.get('app_bridge_config');

    if (
      isShopifyEmbedded() &&
      appBridgeConfig?.host &&
      appBridgeConfig?.apiKey
    ) {
      const redirect = Redirect.create(getShopifyApp());
      redirect.dispatch(Redirect.Action.REMOTE, url);
    } else if (isShopifyEmbedded()) {
      openChargeUrl(url);
    } else {
      window.location.href = url;
    }
  } catch (error) {}
};

export const getShopifyApp = () => {
  try {
    if (!isClientSide() || !isShopifyEmbedded()) return null;

    if (!window.PushOwlShopifyAppInstance) {
      const storage = getStorage();
      const appBridgeConfig = storage.get('app_bridge_config');

      // TODO: There is some race condition in which appBridgeConfig becomes null.
      // Remove this null check after fix that issue
      if (!appBridgeConfig?.host) return null;

      window.__SHOPIFY_DEV_HOST = appBridgeConfig.host;
      window.PushOwlShopifyAppInstance = createApp(appBridgeConfig);
    }
    return window.PushOwlShopifyAppInstance;
  } catch (error) {
    logErrorToSentry({ error });
    return null;
  }
};

export const getShopifySessionToken = async () => {
  try {
    if (!isShopifyEmbedded()) return null;

    const app = getShopifyApp();
    const token = await getSessionToken(app);
    return token;
  } catch (error) {
    logErrorToSentry({ error });
    return null;
  }
};

export const getSeconds = ({ seconds = 0, minutes = 0, hours = 0, days = 0 }) =>
  seconds + minutes * 60 + hours * 60 * 60 + days * 60 * 60 * 24;

export const getDistanceFromSeconds = (seconds: number) =>
  formatDistanceStrict(0, seconds * 1000);

export const isLocalStorageAvailable = () => {
  try {
    const test = 'reachbee_test';
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};
