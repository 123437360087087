/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */

import { useEffect, useRef, useState } from 'react';
import Router, { useRouter } from 'next/router';
import Head from 'next/head';
import smoothscroll from 'smoothscroll-polyfill';
import { Provider } from 'react-redux';
import { AnimateSharedLayout } from 'framer-motion';
import { getPersistor } from '@rematch/persist';
import { PersistGate } from 'redux-persist/integration/react';
import styled, { ThemeProvider } from 'styled-components';
import store from 'src/store';
import { getStorage } from 'src/lib/storage';
import { GlobalStyle, SelectBox } from 'barn/globalStyle';
import { DatePicker } from 'barn/globalStyle/datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import 'emoji-mart/css/emoji-mart.css';
import Spinner from 'barn/components/Spinner';
import SessionValidation from 'src/components/SessionValidation';
import LoggingErrorBoundary from 'src/lib/LoggingErrorBoundary';
import Navbar from 'src/components/Navbar';
import LeftNavigation from 'src/components/LeftNavigation/LeftNavigation';
import withAuth from 'src/lib/with-auth';
import tokens from 'barn/tokens';
import api from 'src/lib/api';
import Login from 'src/modules/login/views';
import { isClientSide, getTheme, getSystemTheme } from 'src/lib/utils';
import { theme } from 'src/theme';
import { trackPage } from 'src/lib/tracking';
import { GlobalStateInitializer } from 'src/components/GlobalStateInitialiser';
import { actionRequestTracker } from 'src/lib/api/axios';
import { ConsumptionWarningPopup } from 'src/components/ConsumptionWarningPopup';
import { AppBridgeProvider } from 'src/components/AppBridgeProvider';
import { registerServiceWorker } from 'src/lib/notification';
import { appWithTranslation } from 'next-i18next';

const LeftNavigationWithAuth: any = withAuth(LeftNavigation);
const NavbarWithAuth: any = withAuth(Navbar);
const ConsumptionWarningPopupWithAuth: any = withAuth(ConsumptionWarningPopup);

const StyledSkipLink = styled.a`
  border-radius: ${tokens.radii[2]};
  background-color: ${({ theme }) => theme.skipLinks.bg};
  z-index: ${tokens.zIndex[5]};
  padding: ${tokens.space.padding[4]} ${tokens.space.padding[5]};
  position: fixed;
  transform: translateY(-100%);
  transition: transform 0.3s;
  border: 1px solid ${({ theme }) => theme.skipLinks.borderColor};
  color: ${({ theme }) => theme.skipLinks.color};
  font-weight: bold;

  &:focus {
    transform: translateY(0%);
  }
`;

const Dashboard = ({ Component, pageProps }) => {
  const router = useRouter();
  const [savedTheme, setSavedTheme] = useState(getTheme());

  if (isClientSide()) {
    smoothscroll.polyfill();
  }

  useEffect(() => {
    document.querySelector('html').style.colorScheme = getTheme();

    const changeHandler = (event: any) => {
      const { theme } = event.detail;
      const newTheme = theme === 'system' ? getSystemTheme() : theme;
      setSavedTheme(newTheme);
      document.documentElement.style.colorScheme = newTheme;
    };

    document.addEventListener('pushOwlThemeChanged', changeHandler);
    return () => {
      document.removeEventListener('pushOwlThemeChanged', changeHandler);
    };
  }, []);

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isAppDisabled, setIsAppDisabled] = useState(false);
  const path = router.asPath;

  const [isFetchingUser, setIsFetchingUser] = useState(
    !/login|onboarding|embedded-auth|(subscribe$)/.test(path),
  );

  const storageRef = useRef(null);

  const shouldShowPageShell = () => {
    return (
      !router.pathname.includes('/campaigns/create') &&
      !router.pathname.includes('/onboarding') &&
      router.pathname !== '/subscribe' &&
      !router.pathname.includes('/embedded-auth') &&
      !/login$/.test(router.pathname) &&
      !/automation\/.*\/notification$/.test(router.pathname) &&
      !/optins\/prompt$/.test(router.pathname) &&
      !/service-messages\/.*\/notification$/.test(router.pathname)
    );
  };

  useEffect(() => {
    storageRef.current = getStorage();

    // Refetch user object on refresh
    const user = storageRef.current.get('user');

    if (
      user?.website?.subdomain &&
      user?.token &&
      isFetchingUser &&
      !window.Cypress
    ) {
      api.auth
        .refetchUser({
          subdomain: user.website.subdomain,
          authToken: user.token,
          platform: user.website.platform,
        })
        .then((res: any) => {
          storageRef.current.set('user', res.user);
          store.dispatch.user.setUserLoggedIn(true);
          setIsFetchingUser(false);
        })
        .catch(() => {
          setIsFetchingUser(false);
        });
    } else {
      setIsFetchingUser(false);
    }

    Router.events.on('routeChangeComplete', () => {
      setIsPageLoading(false);
      trackPage();
    });

    Router.events.on('routeChangeError', () => {
      setIsPageLoading(false);
    });

    Router.events.on('routeChangeStart', () => {
      setIsPageLoading(true);
    });

    // Shut down backend on subdomain change, ex: switch thru multi-store login
    const handlePageVisibilityChange = () => {
      const user = storageRef.current.get('user');

      const subdomain = user?.website?.subdomain;

      if (!subdomain || !window._po_subdomain) return;

      if (
        subdomain !== window._po_subdomain &&
        !/onboarding/.test(window.location.href) &&
        !/embedded-auth/.test(window.location.href)
      ) {
        setIsAppDisabled(true);
        window._po_disabled = true;
      }
    };

    document.addEventListener('visibilitychange', handlePageVisibilityChange);

    window.addEventListener('beforeunload', e => {
      if (!actionRequestTracker.size) return;

      e.preventDefault();
      e.returnValue =
        'There are some operations in progress; please wait for ~5s or you might lose your changes.';
    });

    window._po_build = process.env.BUILD_ID;

    registerServiceWorker();
  }, []);

  return (
    <LoggingErrorBoundary>
      <Head>
        <meta name='viewport' content='' />
      </Head>

      <AppBridgeProvider>
        <Provider store={store}>
          <ThemeProvider theme={theme[savedTheme]}>
            <AnimateSharedLayout>
              <GlobalStyle />
              <SelectBox />
              <DatePicker />

              {isPageLoading && (
                <div
                  style={{
                    position: 'fixed',
                    top: '5px',
                    left: '50%',
                    zIndex: tokens.zIndex[6],
                  }}
                >
                  <Spinner />
                </div>
              )}

              {isFetchingUser && <Login />}

              {isAppDisabled && <SessionValidation />}

              {!isFetchingUser && shouldShowPageShell() && (
                <>
                  <StyledSkipLink href='#main-content'>
                    Skip to content
                  </StyledSkipLink>
                  <LeftNavigationWithAuth />
                  <NavbarWithAuth />

                  {/* <ImprovementChecklist.Button /> */}
                  {/* <ImprovementChecklist.Sidebar /> */}
                </>
              )}

              {!isFetchingUser &&
                !isAppDisabled &&
                !/(login$|onboarding|embedded-auth)/.test(router.pathname) && (
                  <ConsumptionWarningPopupWithAuth />
                )}

              {!isFetchingUser && !isAppDisabled && (
                <PersistGate persistor={getPersistor()}>
                  <GlobalStateInitializer>
                    <Component {...pageProps} />
                  </GlobalStateInitializer>
                </PersistGate>
              )}
            </AnimateSharedLayout>
          </ThemeProvider>
        </Provider>
      </AppBridgeProvider>
    </LoggingErrorBoundary>
  );
};

export default appWithTranslation(Dashboard);
