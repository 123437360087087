import { createModel } from '@rematch/core';
import _set from 'lodash.set';
import cloneDeep from 'lodash.clonedeep';
import api from 'src/lib/api';
import store from 'src/store';
import { RootModel } from 'src/store/models';
import {
  TrackingAction,
  TrackingCategory,
  TrackingElement,
  TrackingLocation,
  track,
} from 'src/lib/tracking';
import Router from 'next/router';

export interface OptinsData {
  active: string;
  one_step: {
    title: string;
    description: string;
    timeout: {
      default: number;
      mobile: number;
    };
    primary_button_text: string;
    secondary_button_text: string;
    theme: {
      primary_button_background_color: string;
      primary_button_color: string;
      secondary_button_background_color: string;
      secondary_button_color: string;
      title_text_color: string;
      description_text_color: string;
      background_color: string;
      text_color: string;
    };
    position: {
      default: string;
      mobile: string;
    };
    maxCountPerSession: number;
    deferForDays: number;

    // first_name and last_name are converted into toggleElements array for our Checkbox compatibility
    toggleElements: string[];
    last_name?: boolean;
    first_name?: boolean;
  };
}

export interface FlyOutData {
  button_text: string;
  enabled: boolean;
  post_subscription_message: string;
  title: string;
  position: { default: string; mobile: string };
  theme: { primaryColor: string; secondaryColor: string };
  overlay: { enabled: boolean; title: string; description: string };
}

interface OptinsState {
  isChanged: boolean;
  isSaving: boolean;
  optin: {
    isFetching: boolean;
    current: OptinsData;
    edited: OptinsData;
  };
  flyout: {
    isFetching: boolean;
    current: FlyOutData;
    edited: FlyOutData;
  };
  errors: AnyObject;
}

const initialState: () => OptinsState = () => ({
  isChanged: false,
  isSaving: false,
  optin: {
    isFetching: false,
    current: null,
    edited: null,
  },
  flyout: {
    isFetching: false,
    current: null,
    edited: null,
  },
  errors: {},
});

const optins = createModel<RootModel>()({
  state: initialState(),

  effects: {
    async fetchOptins(_payload: undefined, rootState) {
      const { optin } = rootState.optins;
      this.storeConfig({
        optin: {
          ...optin,
          isFetching: true,
        },
      });

      await api.optins.getConfig().then(res => {
        if (!res.error) {
          let { data } = res;

          // TODO: Remove after backend backfills existing users
          data = {
            ...data,
            one_step: {
              ...data.one_step,
              theme: {
                title_text_color: '#212B36',
                description_text_color: '#454F5B',
                background_color: '#FFF',
                text_color: '#454F5B',
                ...data.one_step.theme,
              },
            },
          };

          data.one_step.toggleElements = ['phone'];
          if (data.one_step.last_name) {
            data.one_step.toggleElements.push('last_name');
          }
          if (data.one_step.first_name) {
            data.one_step.toggleElements.push('first_name');
          }
          delete data.one_step.last_name;
          delete data.one_step.first_name;

          this.storeConfig({
            optin: {
              current: data,
              // Objects is cloned so that current and edited refers to different objects
              edited: cloneDeep(data),
              isFetching: false,
            },
          });
        }
      });
    },

    async fetchFlyout() {
      await api.optins.getFlyoutConfig().then(res => {
        if (!res.error) {
          this.storeConfig({
            flyout: {
              current: res.data.flyout,
              // Objects is cloned so that current and edited refers to different objects
              edited: cloneDeep(res.data.flyout),
              isFetching: false,
            },
          });
        }
      });
    },

    setOptinsProp(payload: { path: string; value: any }, rootState) {
      const optinsState = rootState.optins;

      _set(optinsState, payload.path, payload.value);

      this.storeConfig(optinsState);
    },

    setFlyoutProp(payload: { path: string; value: any }, rootState) {
      const optinsState = rootState.optins;

      _set(optinsState, payload.path, payload.value);

      this.storeConfig(optinsState);
      this.storeConfig({ isChanged: true });
    },

    cancelChanges(payload, rootState) {
      const optinsState = rootState.optins;

      this.storeConfig({
        optin: {
          current: optinsState.optin.current,
          edited: cloneDeep(optinsState.optin.current),
        },
      });

      this.storeConfig({ isChanged: false, isSaving: false, errors: {} });
    },

    async saveChanges(
      {
        optinType,
        location,
      }: {
        optinType: 'off' | 'one_step';
        location: TrackingLocation.OPTIN_PAGE | TrackingLocation.OPTIN_EDITOR;
      },
      rootState,
    ) {
      const { optin } = rootState.optins;

      const dataToSave = cloneDeep(optin.edited);
      dataToSave.one_step.last_name =
        dataToSave.one_step.toggleElements.includes('last_name');
      dataToSave.one_step.first_name =
        dataToSave.one_step.toggleElements.includes('first_name');
      dataToSave.active = optinType;
      delete dataToSave.one_step.toggleElements;

      try {
        track({
          element:
            location === TrackingLocation.OPTIN_PAGE
              ? TrackingElement.OPTIN_TOGGLE_BUTTON
              : TrackingElement.OPTIN_SAVE_BUTTON,
          category: TrackingCategory.OPTIN_PROMPT,
          action: TrackingAction.CLICKED,
          location,
          additonalParams: {
            is_enabed: optinType,
          },
        });

        this.storeConfig({ isSaving: true, errors: {} });
        await api.optins.setConfig(dataToSave);
        await Router.push('/optins');

        store.dispatch.saveToast.showDone(
          optinType === 'off'
            ? 'Opt-in prompt disabled'
            : 'Opt-in prompt changes saved and enabled',
        );

        const currentOptinValue = {
          ...optin.edited,
          active: optinType,
        };

        this.storeConfig({
          optin: {
            current: currentOptinValue,
            edited: cloneDeep(currentOptinValue),
          },
          isChanged: false,
          isSaving: false,
        });
      } catch (error) {
        this.storeConfig({ isChanged: false, isSaving: false });
        store.dispatch.saveToast.showError('Unable to save changes');
      }
    },
  },

  reducers: {
    storeConfig(state: OptinsState, payload: OptinsState): OptinsState {
      return {
        ...state,
        ...payload,
      };
    },
    setErrors(state: OptinsState, payload: AnyObject): OptinsState {
      const { merge = true, errors } = payload;
      const newErrors = merge ? { ...state.errors, ...errors } : errors;
      return {
        ...state,
        errors: newErrors,
      };
    },
  },
});

export default optins;
