import colors from 'barn/tokens/colors';

export const bannersLight = {
  color: colors.greys[1],
  promo: {
    bg: colors.greens[8],
    borderColor: colors.greens[4],
    icon: {
      color: colors.greens[3],
    },
  },
  info: {
    bg: colors.blues[8],
    borderColor: colors.blues[3],
    icon: {
      color: colors.blues[4],
    },
  },
  success: {
    bg: colors.greys[8],
    borderColor: colors.greys[3],
    icon: {
      color: colors.greens[9],
    },
  },
  warn: {
    bg: colors.yellows[8],
    borderColor: colors.yellows[4],
    icon: {
      color: colors.yellows[3],
    },
  },
  danger: {
    bg: colors.reds[8],
    borderColor: colors.reds[4],
    icon: {
      color: colors.reds[4],
    },
  },
};

export const bannersDark = {
  ...bannersLight,
  color: colors.greys[7],
  info: {
    bg: colors.blues[0],
    borderColor: colors.blues[3],
    icon: {
      color: colors.blues[3],
    },
  },
  promo: {
    bg: colors.greens[1],
    borderColor: colors.greens[9],
    icon: {
      color: colors.greens[9],
    },
  },
  warn: {
    ...bannersLight.warn,
    bg: colors.yellows[0],
  },
  danger: {
    ...bannersLight.danger,
    bg: colors.reds[0],
  },
};
