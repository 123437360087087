import React from 'react';
import { useRouter } from 'next/router';
import { useGlobalState } from 'src/hooks/use-global-state';
import * as Sentry from '@sentry/browser';
import { Navlink, NavButton } from 'barn/components/Navlink';
import HomeIcon from 'barn/icons/Home';
import AutomationIcon from 'barn/icons/Automation';
import CampaignsIcon from 'barn/icons/Campaigns';
import OptinsIcon from 'barn/icons/Optins';
import SettingsIcon from 'barn/icons/Settings';
import Sidebar from 'src/components/Sidebar';
import DocumentIcon from 'barn/icons/Document';
import useAuth from 'src/hooks/use-auth';
import { isClientSide, isSmallScreen } from 'src/lib/utils';
import { TrackingLocation } from 'src/lib/tracking';
import * as Styled from './styled';
import { primaryRoutes, secondaryRoutes } from './routes';
import NewCampaign from '../NewCampaign';

const icons = {
  'page.home': HomeIcon,
  'page.campaigns': CampaignsIcon,
  'page.automation': AutomationIcon,
  'page.optins': OptinsIcon,
  'page.settings': SettingsIcon,
  'page.compliance': DocumentIcon,
  'page.service-messages': DocumentIcon,
};

const LeftNavigation: React.FunctionComponent = () => {
  const {
    state: { isSidebarOpen },
  } = useGlobalState('common');

  const { route } = useRouter();
  const { user } = useAuth();
  const isPlatformBigCommerce = user?.website?.platform === 'bigcommerce';

  const isRouteActive = (currentPath, path) => {
    return path === '/'
      ? currentPath === path
      : currentPath.replace(/^\//, '').startsWith(path.replace(/^\//, ''));
  };

  // Spread the array so that we don't accidentally modify the actual exported array
  let filteredPrimaryRoutes = [...primaryRoutes];
  if (isPlatformBigCommerce) {
    filteredPrimaryRoutes = filteredPrimaryRoutes.filter(
      item => !/integrations/.test(item.path),
    );
  }

  if (!user || !Object.keys(user).length) {
    return null;
  }

  return (
    <Sidebar
      cta={
        <NewCampaign
          iconOnly={isSmallScreen() && !isSidebarOpen}
          location={TrackingLocation.LEFT_NAVBAR}
        />
      }
    >
      <Styled.Wrapper>
        <Styled.PrimaryRoutesWrapper>
          {filteredPrimaryRoutes.map(
            ({ key, path, name, isActive, subRoutes, isComingSoon }) => {
              const Icon = icons[key];

              if (!user?.website)
                Sentry.withScope(scope => {
                  scope.setExtras({ user });

                  if (isClientSide()) {
                    scope.setTag('subdomain', String(window._po_subdomain));
                  }
                  Sentry.captureException('Storage user null');
                });

              const { platform } = user?.website || {};

              // Only the main automations link should be present if the platform isn't shopify
              if (subRoutes && platform && platform !== 'shopify')
                /* eslint-disable no-param-reassign */
                subRoutes = subRoutes.filter(
                  sub => sub.key === 'page.automation',
                );

              return (
                <React.Fragment key={key}>
                  <Navlink
                    href={path}
                    label={name}
                    icon={<Icon />}
                    isComingSoon={isComingSoon}
                    active={
                      isActive ? isActive(route) : isRouteActive(route, path)
                    }
                    locked={
                      key === 'page.segments'
                        ? user &&
                          user.website.website_plan &&
                          !user.website.website_plan.enable_segmentation
                        : false
                    }
                    fillIcon={subRoutes
                      ?.map(({ isActive }) => isActive(route))
                      .includes(true)}
                  />
                  {subRoutes &&
                    /*
                     * Don't show subroutes on smaller screen as
                     * the nav would be too small. They would be accessible
                     * when the user opens up the sidebar
                     */
                    (!isSmallScreen() || isSidebarOpen) &&
                    subRoutes.map(
                      ({
                        key: subRouteKey,
                        name: subRouteName,
                        path: subRoutePath,
                        isActive: isSubPathActive,
                        isNew,
                      }) => (
                        <Navlink
                          key={subRouteKey}
                          href={subRoutePath}
                          label={subRouteName}
                          active={isSubPathActive(route)}
                          locked={
                            subRouteKey === 'page.acr'
                              ? user &&
                                user.website.website_plan &&
                                !user.website.website_plan.enable_abandoned_cart
                              : false
                          }
                          sublink
                          isNew={isNew}
                        />
                      ),
                    )}
                </React.Fragment>
              );
            },
          )}
        </Styled.PrimaryRoutesWrapper>
        <div>
          <Styled.SecondaryRoutes>
            {secondaryRoutes.map(({ key, path, name, isLocked, target }) => {
              const Icon = icons[key];

              return path ? (
                <Navlink
                  key={key}
                  href={path}
                  label={name}
                  icon={<Icon />}
                  active={route
                    .replace(/^\//, '')
                    .startsWith(path.replace(/^\//, ''))}
                  locked={isLocked}
                  target={target}
                />
              ) : (
                <NavButton
                  key={key}
                  label={name}
                  locked={isLocked}
                  icon={<Icon />}
                />
              );
            })}
          </Styled.SecondaryRoutes>
        </div>
      </Styled.Wrapper>
    </Sidebar>
  );
};

export default LeftNavigation;
