import colors from 'barn/tokens/colors';

export const navlinkLight = {
  color: colors.greys[2],
  hover: {
    bg: colors.greys[6],
    color: colors.greys[1],
  },
  active: {
    color: colors.greens[4],
    bg: colors.greys[6],
  },
};

export const navlinkDark = {
  color: colors.greys[5],
  hover: {
    bg: colors.greys[12],
    color: colors.greys[7],
  },
  active: {
    bg: colors.greys[12],
    color: colors.greens[9],
  },
};
