import { v1 as uuidv1 } from 'uuid';
import colors from 'barn/tokens/colors';

const Lock = ({ color = colors.greys[2], style = {} }) => {
  const id = uuidv1();

  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' style={style}>
      <mask
        id={`maskLock${id}`}
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='4'
        y='2'
        width='13'
        height='16'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16 8.4H15.2V6.8C15.2 4.1528 13.0472 2 10.4 2C7.7528 2 5.6 4.1528 5.6 6.8V8.4H4.8C4.3576 8.4 4 8.7576 4 9.2V17.2C4 17.6424 4.3576 18 4.8 18H16C16.4424 18 16.8 17.6424 16.8 17.2V9.2C16.8 8.7576 16.4424 8.4 16 8.4ZM5.6 16.4H15.2V10H5.6V16.4ZM7.2 6.8C7.2 5.0352 8.6352 3.6 10.4 3.6C12.1648 3.6 13.6 5.0352 13.6 6.8V8.4H7.2V6.8ZM10.4 14.8C10.8424 14.8 11.2 14.4424 11.2 14V12.4C11.2 11.9576 10.8424 11.6 10.4 11.6C9.9576 11.6 9.6 11.9576 9.6 12.4V14C9.6 14.4424 9.9576 14.8 10.4 14.8Z'
          fill='#31373D'
        />
      </mask>
      <g mask={`url(#maskLock${id})`}>
        <rect width='20' height='20' fill={color} />
      </g>
    </svg>
  );
};

export default Lock;
