import colors from 'barn/tokens/colors';

export const tooltipLight = {
  tiptext: {
    bg: colors.greys[1],
    color: colors.greys[8],
  },
  link: {
    color: colors.greys[8],
    borderColor: colors.greys[8],
  },
};

export const tooltipDark = {
  tiptext: {
    bg: colors.greys[14],
    color: colors.greys[7],
  },
  link: {
    color: colors.greys[7],
    borderColor: colors.greys[14],
  },
};
