import colors from 'barn/tokens/colors';

const light = {
  layout: {
    color: colors.greys[1],
    borderColor: colors.greys[5],
    container: {
      bg: colors.greys[8],
    },
    secondary: {
      color: colors.greys[2],
    },
    bold: {
      color: colors.greys[0],
    },
    link: {
      bg: colors.greys[8],
      borderColor: colors.greys[4],
    },
  },
};

const dark = {
  layout: {
    color: colors.greys[7],
    borderColor: colors.greys[12],
    container: {
      bg: colors.greys[11],
    },
    secondary: {
      color: colors.greys[4],
    },
    bold: {
      color: colors.greys[7],
    },
    link: {
      bg: colors.greys[9],
      borderColor: colors.greys[9],
    },
  },
};

export default {
  light,
  dark,
};
