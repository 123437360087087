import { createModel } from '@rematch/core';
import { DaysInSeconds, TimeFilter } from 'src/components/DaterangeFilter';
import api from 'src/lib/api';
import store from 'src/store';
import {
  TrackingLocation,
  TrackingElement,
  track,
  TrackingCategory,
  TrackingAction,
} from 'src/lib/tracking';
import { RootModel } from 'src/store/models';
import { ACR_BA_Report, AutomationConfig, OrderData } from './types';

interface BrowseAbandonmentState {
  isFetching: boolean;
  config: {
    product_stay_time: number;
    product_interaction_enabled: boolean;
    enabled: boolean;
    message_configs: Array<AutomationConfig>;
  };
  notificationInEdit: number;
  timeFilter: TimeFilter;
  reports: ACR_BA_Report;
  orderData: OrderData;
}

const initialState = (): BrowseAbandonmentState => ({
  isFetching: false,
  config: null,
  notificationInEdit: null,
  timeFilter: {
    type: 'fixed',
    value: DaysInSeconds.ALL_TIME,
    label: 'All Time',
  },
  reports: {
    isFetching: true,
    total: {
      impressions: 0,
      clicks: 0,
      revenue: 0,
      cartsRecovered: 0,
    },
    reminders: [],
  },
  orderData: {
    recordsPerPage: 10,
    totalCount: 0,
    hasMore: false,
    orders: [],
  },
});

const browseAbandonmentAutomation = createModel<RootModel>()({
  state: initialState(),

  effects: {
    async fetchReports({ timeFilter }: { timeFilter: TimeFilter }, rootState) {
      this.storeState({ timeFilter });
      this.storeReports({ isFetching: true });

      const { reports, orderData, error } = await api.reports.fetchBAReports(
        timeFilter,
        rootState.settings.attributionWindow,
      );

      if (error) {
        store.dispatch.saveToast.showError('Your reports could not be fetched');
        return;
      }

      this.storeReports({ isFetching: false, ...reports });
      this.storeState({ orderData });
    },

    async fetchOrderData(_, rootState) {
      const {
        settings: { attributionWindow },
        browseAbandonmentAutomation: {
          timeFilter,
          orderData: { recordsPerPage },
        },
      } = rootState;

      const { orderData, error } = await api.reports.fetchBAOrderData(
        { timeFilter, recordsPerPage },
        attributionWindow,
      );

      if (error) {
        store.dispatch.saveToast.showError(
          'Your order data could not be fetched, please refresh and try again',
        );
        return;
      }

      this.storeState({ orderData });
    },

    async fetchConfig() {
      this.storeState({ isFetching: true });

      const { data, error } =
        await api.automation.browseAbandonment.getConfig();

      if (!error) {
        this.storeState({
          config: {
            // TODO: Remove this default value after data has been backfilled for older stores
            product_stay_time: 3,
            product_interaction_enabled: false,
            ...data,
          },
          isFetching: false,
        });
      }
    },

    async toggle(
      {
        isActive,
        location,
      }: {
        isActive: boolean;
        location:
          | TrackingLocation.AUTOMATION_LIST
          | TrackingLocation.AUTOMATION_PAGE;
      },
      rootState,
    ) {
      const state = rootState.browseAbandonmentAutomation;

      track({
        element: TrackingElement.BA_TOGGLE_BUTTON,
        category: TrackingCategory.AUTOMATION,
        location,
        action: TrackingAction.CLICKED,
        additonalParams: { is_enabled: state.config.enabled },
      });

      let message_configs = state.config?.message_configs || [];

      message_configs = message_configs.map(item => ({
        ...item,
        enabled: isActive,
      }));

      const newState = {
        ...state,
        config: {
          ...state.config,
          message_configs,
          enabled: isActive,
        },
      };

      this.storeState(newState);

      // make network request
      await api.automation.browseAbandonment
        .setConfig(newState.config)
        .then(res => {
          if (!res.error) {
            store.dispatch.saveToast.showDone('Your changes have been saved');
          } else {
            store.dispatch.saveToast.showError(
              `Your browse abandonment automation status couldn't be saved; please try again or contact support if the issue persists.`,
            );
            this.storeState(state);
          }
        });
    },

    async saveNotification(
      payload: AnyObject /* notification */,
      rootState,
    ): Promise<{ data: any; error: any }> {
      const { _id, ...restPayload } = payload;

      const state = rootState.browseAbandonmentAutomation;

      const notificationIndex = Number.isInteger(state.notificationInEdit)
        ? state.notificationInEdit
        : _id;
      const { message_configs } = state.config;
      const notification = {
        ...message_configs[notificationIndex],
        ...restPayload,
      };

      message_configs[notificationIndex] = notification;

      track({
        element: TrackingElement.BA_SMS_TOGGLE_BUTTON,
        category: TrackingCategory.AUTOMATION,
        action: TrackingAction.CLICKED,
        additonalParams: {
          is_enabled: !notification.enabled,
          sms_number: notificationIndex + 1,
        },
      });

      const newState = {
        ...state,
        config: {
          ...state.config,
          message_configs,
        },
        notificationInEdit: null,
      };

      // make network request
      return api.automation.browseAbandonment
        .setConfig(newState.config)
        .then(res => {
          if (!res.error) {
            this.storeState(newState);
            store.dispatch.saveToast.showDone('Your changes have been saved');
          } else {
            store.dispatch.saveToast.showError('Unable to save changes');
          }

          return res;
        });
    },

    async saveConfig(
      payload: AnyObject /* notification */,
      rootState,
    ): Promise<{ data: any; error: any }> {
      const state = rootState.browseAbandonmentAutomation;

      const oldState = { ...state };

      const newState = {
        ...state,
        config: {
          ...state.config,
          ...payload,
        },
      };

      this.storeState(newState);

      return api.automation.browseAbandonment
        .setConfig(newState.config)
        .then(res => {
          if (!res.error) {
            store.dispatch.saveToast.showDone('Your changes have been saved');
          } else {
            this.storeState(oldState);
            store.dispatch.saveToast.showError('Unable to save changes');
          }

          return res;
        });
    },
  },

  reducers: {
    storeState(state, payload: Partial<BrowseAbandonmentState>) {
      return {
        ...state,
        ...payload,
      };
    },

    storeReports(state, payload: Partial<BrowseAbandonmentState['reports']>) {
      return {
        ...state,
        reports: {
          ...state.reports,
          ...payload,
        },
      };
    },

    initNotificationEditing(state, payload: number) {
      return {
        ...state,
        notificationInEdit: payload,
      };
    },

    shutNotificationEditing(state) {
      return {
        ...state,
        notificationInEdit: null,
      };
    },

    resetTimeFilter(state) {
      return {
        ...state,
        timeFilter: initialState().timeFilter,
      };
    },
  },
});

export default browseAbandonmentAutomation;
