import { DaysInSeconds } from 'src/components/DaterangeFilter';
import { ReportTypes } from 'src/components/ReportSaver/models';
import { transformAttributionSettingsRequest as getAttributionWindow } from 'src/modules/settings/models';
import { get, post, put, del, patch } from '../axios';
import {
  getTimeWindow,
  makeReportsRequest,
  makeAutomationReportRequest,
  transformAllReportsResponse,
  fetchCampaignListing,
  makeAutomationOrderRequest,
} from './helpers';

const fetchAllReports = async () => {
  const {
    data: { data, ...restData },
    error,
  } = await get('/dashboard/report/merchantreports/');

  if (error) return { data: null, error };

  // Convert to redux reportSaver shape
  const transformedResults = data.map(transformAllReportsResponse);

  return { data: { ...restData, results: transformedResults }, error };
};

const fetchSubscriberGrowthReport = async ({ timeWindow, granularity }) => {
  try {
    // Converting seconds to days since API expects days
    // we will revert this once API starts supporting seconds
    const days = Math.floor(timeWindow / (3600 * 24));

    const reportQuery = {
      frequency_count: days,
      frequency_unit: granularity,
    };

    const reportData = btoa(JSON.stringify(reportQuery));

    const res = await get(
      `/reports/subscriber_gain/?report_data=${reportData}`,
    );

    return { data: res.data, error: null };
  } catch (error) {
    return { data: {}, error };
  }
};

const fetchTotalAutomationStats = async () => {
  try {
    const { data }: any = await get(`/reports/all_automation_performance/`);

    const reports: AnyObject = {};

    data.report.data.forEach(item => {
      reports[item.automation] = item;
    });

    return {
      data: {
        abandonedCart: reports.abandoned_cart,
        browseAbandonment: reports.browse_abandonment,
        welcomeSeries: reports.welcome_series,
        allAutomations: reports.all_automations,
      },
      error: null,
    };
  } catch (error) {
    return { data: {}, error: error || true };
  }
};

const fetchACRReports = async (payload, attributionSettings) =>
  makeAutomationReportRequest('abandoned_cart', payload, attributionSettings);

const fetchBAReports = async (payload, attributionSettings) =>
  makeAutomationReportRequest(
    'browse_abandonment',
    payload,
    attributionSettings,
  );

const fetchACROrderData = async (payload, attributionSettings) =>
  makeAutomationOrderRequest('abandoned_cart', payload, attributionSettings);

const fetchBAOrderData = async (payload, attributionSettings) =>
  makeAutomationOrderRequest(
    'browse_abandonment',
    payload,
    attributionSettings,
  );

const fetchReportingSegments = async () => {
  const timeWindow = getTimeWindow(DaysInSeconds.ALL_TIME);

  const reportData = btoa(
    JSON.stringify([
      {
        name: ReportTypes.CAMPAIGN_SEGMENTS,
        params: {
          time_window: timeWindow,
        },
      },
    ]),
  );

  const { data, error } = await get(
    `/dashboard/report/?report_data=${reportData}`,
  );

  if (error || data.find(a => a.status === 'failed'))
    return { data, error: error || true };

  const segmentsList = data[0].report.map(
    ({
      segment_id: id,
      segment_name: name,
      campaigns_sent: campaignsSent,
    }) => ({
      id,
      name,
      campaignsSent,
    }),
  );

  return { segmentsList };
};

const fetchTotalCampaignStats = async () => {
  try {
    const { data } = await get(`/reports/overall_campaign_performance/`);

    // TODO: Fix this hack of checking empty object and ask backend to have an error key instead of returning an empty object when the API fails
    if (!data || Object.keys(data).length === 0)
      return { data: null, error: true };

    return { data: data.report.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

const fetchSentCampaigns = (limit: number, offset: number) =>
  fetchCampaignListing('sent', limit, offset);

const fetchScheduledCampaigns = (limit: number, offset: number) =>
  fetchCampaignListing('scheduled', limit, offset);

const fetchPausedCampaigns = (limit: number, offset: number) =>
  fetchCampaignListing('paused', limit, offset);

const fetchDraftCampaigns = (limit: number, offset: number) =>
  fetchCampaignListing('draft', limit, offset);

const fetchTotalAttributedRevenue = async attributionSettings => {
  const attribution_window = getAttributionWindow(attributionSettings);

  // Total Revenue will always consider an "All Time" time window
  const timeWindow = getTimeWindow(DaysInSeconds.ALL_TIME);

  const reportData = btoa(
    JSON.stringify([
      {
        name: ReportTypes.REVENUE_GENERATED,
        params: {
          time_window: timeWindow,
          attribution_window,
        },
      },
    ]),
  );

  const { data, error } = await get(
    `/dashboard/report/?report_data=${reportData}`,
  );

  if (error || data.find(a => a.status === 'failed'))
    return { data, error: error || true };

  return {
    totalAttributedRevenue: data[0].report[0].generated_revenue,
    error: null,
  };
};

const saveReport = async (payload, reportState) =>
  makeReportsRequest({
    method: post,
    url: `/dashboard/report/merchantreports/?sendnow=${payload.sendNow}`,
    reportState,
    filters: payload,
  });

const updateReport = (payload, reportState) => {
  const { id } = reportState;
  return makeReportsRequest({
    method: put,
    url: `/dashboard/report/merchantreport/${id}`,
    reportState,
    filters: payload,
  });
};

const sendReportNow = id =>
  post(`/dashboard/report/merchantreport/${id}`, null);

const patchReport = payload =>
  patch(`/dashboard/report/merchantreport/${payload.id}`, payload);

const deleteReport = id => del(`/dashboard/report/merchantreport/${id}`);

export default {
  fetchSubscriberGrowthReport,
  fetchTotalAutomationStats,
  saveReport,
  updateReport,
  fetchAllReports,
  deleteReport,
  sendReportNow,
  patchReport,
  fetchACRReports,
  fetchACROrderData,
  fetchBAReports,
  fetchBAOrderData,
  fetchTotalCampaignStats,
  fetchReportingSegments,
  fetchSentCampaigns,
  fetchScheduledCampaigns,
  fetchPausedCampaigns,
  fetchTotalAttributedRevenue,
  fetchDraftCampaigns,
};
